// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dnd_handler__add--FgGH2eFQ3nf9APlW51hC{display:inline-block;font-size:16px;color:var(--color-primary-action);position:relative;cursor:pointer;border-bottom:1px dashed var(--color-primary-action)}.dnd_handler__add_container--BTyDnl8Br3rV8jn0mKeO{margin-top:5px;display:block;text-align:center}", "",{"version":3,"sources":["webpack://./frontend/src/Components/DragAndDropSelect/style.module.scss"],"names":[],"mappings":"AACE,wCACE,oBAAA,CACA,cAAA,CACA,iCAAA,CACA,iBAAA,CACA,cAAA,CACA,oDAAA,CAEA,kDACE,cAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".dnd_handler {\n  &__add {\n    display: inline-block;\n    font-size: 16px;\n    color: var(--color-primary-action);\n    position: relative;\n    cursor: pointer;\n    border-bottom: 1px dashed var(--color-primary-action);\n\n    &_container {\n      margin-top: 5px;\n      display: block;\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var dndHandlerAdd = "dnd_handler__add--FgGH2eFQ3nf9APlW51hC";
export var dndHandlerAddContainer = "dnd_handler__add_container--BTyDnl8Br3rV8jn0mKeO";
export default ___CSS_LOADER_EXPORT___;
