import React, { FC, KeyboardEvent, useState } from "react";
import c from "classnames";

import { isEmpty, Button, Preloader } from "@omnichat/arm_ui_kit";

import { InputWithOptions, Tooltip } from "../../../../Components";
import Period from "./Period";
import ExpandedFilterFilds, { IFilterOptions } from "./ExpandedFilterFilds";

import * as s from "./styles.module.scss";

export type IData = {
  date_start: Date;
  date_end: Date;
  client: string;
  appeal: string;
  channel: string;
  who_processed: string;
  status: string;
  region: string;
  mrf: string;
  rf: string;
  sl: string;
  csi: string;
  remark_fio: string;
  remark_phone: string;
  remark_birthday: string;
  without_themes?: string;
};

/**
 * Модель свойств панели фильтрации.
 *
 * @prop {boolean} [isTimeAvailable] Признак, который показывает доступность проставить время.
 */
interface IFilterPanel {
  data: IData;
  changedFields: object;
  options: IFilterOptions;
  onAppealChange: (value: number) => void;
  onReset: () => void;
  onChange: (value: object) => void;
  onConfirm: () => void;
  onAlert: (value: string) => string;
  onToggleFilterPanel: () => void;
  openedExpandedFields: boolean;
  isTimeAvailable?: boolean;
  confirmInProgress?: boolean;
  recentAppeals: string[];
}
const FilterPanel: FC<IFilterPanel> = ({
  data,
  changedFields,
  options,
  onAppealChange,
  onReset = () => {},
  onAlert = () => {},
  onChange,
  onConfirm,
  onToggleFilterPanel,
  openedExpandedFields,
  isTimeAvailable = false,
  confirmInProgress = false,
  recentAppeals
}): JSX.Element => {
  const [appealId, setAppealId] = useState(data.appeal || "");

  const clearObjectValues = (checkedObject: object, propsObject: object) => {
    let result: object = {};
    for (let key in checkedObject) {
      if (checkedObject[key] !== propsObject[key]) {
        result[key] = checkedObject[key];
      }
    }
    return result;
  };

  const onChangeFields = (filedsData: {}): void => {
    const newFiledsData = clearObjectValues(filedsData, data);
    if (!isEmpty(newFiledsData)) {
      onChange({ ...data, ...newFiledsData });
    }
  };

  const handleChange = (value: string) => {
    let appealId = value;
    if (value && !/^[0-9]+$/.test(value)) {
      appealId = value.replace(/[^0-9]+/g, "");
    }
    setAppealId(appealId);
  };

  const handleBlur = () => {
    appealId && onAppealChange(+appealId);
    setAppealId("");
  };

  return (
    <div className={s["filterPanel"]}>
      <div className={s["filterPanelHead"]}>
        <Period
          data={{
            date_start: data.date_start,
            date_end: data.date_end
          }}
          onChange={(data) => {
            onChangeFields(data);
            setTimeout(onConfirm, 0);
          }}
          onAlert={onAlert}
          isTimeAvailable={isTimeAvailable}
          confirmInProgress={confirmInProgress}
        />

        <div className={c(s["filterPanelHeadRightGroup"])}>
          {typeof data.appeal !== "undefined" && (
            <div className={c(s["filterPanelCol"], s["filterAppealInput"])}>
              <InputWithOptions
                label="Обращение"
                placeholder="Введите номер обращения"
                options={recentAppeals.map((a) => ({
                  label: `Обращение #${a}`,
                  value: a
                }))}
                value={appealId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}

          <div className={s["filterPanelToggleButton"]}>
            <Preloader size="mini" show={confirmInProgress} color={"blue"}>
              <Tooltip content="Расширенные фильтры" position="left">
                <Button
                  type="bare"
                  iconName={"tune"}
                  iconColor={openedExpandedFields ? "white" : "blue"}
                  onClick={onToggleFilterPanel}
                  extraClass={[
                    s["expandedFiltersBtn"],
                    c(openedExpandedFields ? s["openedExpandedFields"] : "")
                  ]}
                />
              </Tooltip>
            </Preloader>
          </div>
        </div>
      </div>

      {openedExpandedFields && (
        <ExpandedFilterFilds
          options={options}
          data={data}
          changedFields={changedFields}
          onChange={(data) => {
            onChangeFields(data);
          }}
          onConfirm={onConfirm}
          onReset={onReset}
          confirmInProgress={confirmInProgress}
        />
      )}
    </div>
  );
};

export default FilterPanel;
