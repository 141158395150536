import React, { FC, memo, MouseEvent } from "react";
import c from "classnames";

import {
  Icon,
  Checkbox,
  SwitcherContainer,
  Avatar
} from "@omnichat/arm_ui_kit";

import { UserData } from "../../UserGroupsManager.d";
import hasUserRight from "../../../../Utils/hasUserRight";

import * as s from "./styles.module.scss";

const CB = SwitcherContainer(Checkbox);

interface UserProps {
  isArhive: boolean;
  user?: UserData;
  onSelect: (id: string) => void;
  onEdit: (id: string) => void;
}

const UserItem: FC<UserProps> = ({
  isArhive,
  user: { id, secName, name, photo, skillGroups, checked },
  onSelect,
  onEdit
}) => {
  const handleEdit = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onEdit(id);
  };

  const handleCheck = () => onSelect(id);

  return (
    <div
      id={`user_${id}`}
      className={c(s["item"], s["clearFix"])}
      onClick={handleCheck}
    >
      {hasUserRight("create_users") && (
        <div className={s["itemCheckboxWrap"]}>
          <CB
            caption=""
            checked={checked}
            extraClass={[s["itemCheckbox"]]}
            onChange={handleCheck}
          />
        </div>
      )}
      <div className={s["itemBody"]}>
        <div className={s["itemPhoto"]}>
          <Avatar
            person={"user"}
            url={photo ? `/fileStorage/files/crop/${photo}` : ""}
          />
        </div>
        <div className={s["itemTextWrap"]}>
          <div>
            <div className={s["itemName"]}>{`${secName} ${name}`}</div>
            <div className={s["itemRight"]}>
              {!isArhive && hasUserRight("create_users") && (
                <div
                  id={`editUser_${id}`}
                  className={s["itemEdit"]}
                  onClick={handleEdit}
                >
                  <Icon
                    color="blue"
                    name="edit"
                    width="18px"
                    height="18px"
                    extraClass={[s["hover"]]}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={c(s["itemSkills"], s["clearFix"])}>
            <span>
              {!isArhive
                ? `Кол-во групп: ${skillGroups?.length ?? 0}`
                : "Архив"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UserItem);
