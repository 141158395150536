import React, { memo } from "react";
import { Grid } from "@mui/material";
import Checkbox from "../../../../Components/Checkbox";

import { SytemSetting as ISytemSetting } from "../../SystemSettings";

import * as s from "./SystemSetting.module.scss";
import { UpdateSystemSetting } from "../../SystemSettings.hooks";
import hasUserRight from "../../../../Utils/hasUserRight";

interface SystemSettingProps {
  systemSetting: ISytemSetting;
  onSave: (setting: UpdateSystemSetting) => Promise<void>;
}

export const SystemSettingsInteger: React.FC<SystemSettingProps> = ({
  systemSetting,
  onSave
}) => {
  const value = systemSetting.settingValue === "true";

  const toggle = () =>
    onSave({ id: systemSetting.id, settingValue: String(!value) });

  const isUnavailableOption = (settingKey) =>
    !hasUserRight("vip") && settingKey === "vip-client-feature-is-on";

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={4}
        container
        alignItems="center"
        p={1}
        borderBottom="1px solid #cbd7da"
      >
        <p
          className={s["text"]}
          style={{
            opacity: isUnavailableOption(systemSetting.settingKey) ? 0.5 : 1
          }}
        >
          {systemSetting.settingDescription}
        </p>
      </Grid>
      <Grid
        item
        xs={3}
        container
        alignItems="center"
        p={1}
        borderBottom="1px solid #cbd7da"
      >
        <Checkbox
          caption={value ? "Включено" : "Выключено"}
          captionPosition="end"
          type="slider"
          checked={value}
          onChange={toggle}
          disabled={isUnavailableOption(systemSetting.settingKey)}
        />
      </Grid>
    </Grid>
  );
};

export default memo(SystemSettingsInteger);
