export const NAMESPACE = "MoveAppealModal";
export const GET_SKILLGROUPS_TRANSFER_DICTIONARY = `${NAMESPACE}_GET_SKILLGROUPS_TRANSFER_DICTIONARY`;
export const GET_USERS_TRANSFER_DICTIONARY = `${NAMESPACE}_GET_USERS_TRANSFER_DICTIONARY`;
export const REDUSER_COMMON_NAME = `available${NAMESPACE}Data`;
export const SEND_TRANSFER = `SEND_TRANSFER`;
export const SEND_REOPEN = `SEND_REOPEN`;

export const defaultFormData = {
  target: {
    selectedValue: null,
    userId: null,
    groupId: null
  },
  comment: ""
};
