import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import initialState from "../../../ReactCore/store/initialState";

import {
  prepareFields,
  changedValues
} from "../../../ReactFeatures/ThematicModal/Services/ThematicModal.service";

export const GET_ALL_CLASSIFIERS = "GET_ALL_CLASSIFIERS";
export const GET_CLASSIFIERS = "GET_CLASSIFIERS";
export const GET_CLASSIFIER_PAGE = "GET_CLASSIFIER_PAGE";
export const GET_CLISSIFIER_THEMES_FIELDS = "GET_CLISSIFIER_THEMES_FIELDS";
export const GET_CLISSIFIER_FIELDS = "GET_CLISSIFIER_FIELDS";
export const GET_CLISSIFIER_FIELD_VALUES = "GET_CLISSIFIER_FIELD_VALUES";

export const getAllClassifiers = createAction<number>(GET_ALL_CLASSIFIERS);
export const getClassifiers = createAction<number>(GET_CLASSIFIERS);
export const getClassifiersPage = createAction<number>(GET_CLASSIFIER_PAGE);
export const getThemesFields = createAction<any>(GET_CLISSIFIER_THEMES_FIELDS);
export const getClassifierFields = createAction<any>(GET_CLISSIFIER_FIELDS);
export const getClassifierFieldValues = createAction<any>(
  GET_CLISSIFIER_FIELD_VALUES
);

const clasifierSlice = createSlice({
  name: "classifierFieldForm",
  initialState: initialState.Classifiers,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setClassifiers(state, action) {
      state.classifiers = action.payload;
    },
    setThemesFields(state, action) {
      state.fields = prepareFields(action.payload);
    },
    setSelectedThemesFieldsValues(state, action) {
      state.fields = changedValues(action.payload, state.fields);
    },
    setClassifierFieldsList(state, action) {
      state.classifierFields = action.payload;
    },
    resetFieldTree(state) {
      state.classifierFields = initialState.Classifiers.classifierFields;
      state.classifierFieldValuesList =
        initialState.Classifiers.classifierFieldValuesList;
    },
    setClassifierFieldValuesList(state, action) {
      const { fieldId, values } = action.payload;
      state.classifierFieldValuesList = {
        ...state.classifierFieldValuesList,
        [fieldId]: values
      };
    }
  }
});

export const {
  setClassifiers,
  setSelectedThemesFieldsValues,
  setClassifierFieldValuesList,
  setClassifierFieldsList,
  setThemesFields,
  resetFieldTree,
  startLoading,
  finishLoading
} = clasifierSlice.actions;

export default clasifierSlice.reducer;
