import * as React from "react";
import { IMessage } from "../../Common/Interfaces/Message.interfaces";
import { getMediaThumb, getMediaUrl } from "../Utils";

import ImageMessage from "./ImageMessage/ImageMessage";

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {string} filesUrl Идентификатор ресурса откуда грузить файл.
 * @prop {IMessage} messageData Данные о сообщении.
 * @prop {Function} onShowImageViewer Колбэк на открытие компонента по просмотру изображения.
 */
interface IOwnProps {
  filesUrl: string;
  messageData: IMessage;
  onShowImageViewer: (imgSrc: string) => void;
}

/**
 * Конструктор сообщения с изображением.
 */
const ImageMessageConstructor = ({
  filesUrl,
  messageData,
  onShowImageViewer,
}: IOwnProps) => {
  // const splitedMediaThumb = messageData.mediaThumb.split("/");
  let mediaThumb = getMediaThumb(messageData.mediaThumb);

  const ext = messageData.mediaUrl.split(".").pop();

  /** У гифок миниатюрок нету */
  let mediaUrl = getMediaUrl(messageData.mediaUrl);

  if (ext === "gif") {
    mediaThumb = mediaUrl;
  }

  return (
    <ImageMessage
      imgSrc={filesUrl + mediaUrl}
      imgThumbSrc={filesUrl + mediaThumb}
      onShowImageViewer={onShowImageViewer}
    />
  );
};

export default ImageMessageConstructor;
