import React from "react";

import { Icon, Checkbox, SwitcherContainer } from "@omnichat/arm_ui_kit";

import { Reply } from "./RepliesList";
import * as s from "./styles.module.scss";

const CB = SwitcherContainer(Checkbox);

export type IRepliesItemProps = Reply & {
  onSelect: (id: number) => void;
  onEdit: (id: number) => void;
};

/**
 * Блок шаблона ответа
 */
const RepliesItem = ({
  id,
  text,
  title,
  checked,
  disabled,
  category,
  attachmentsCount,
  onEdit,
  onSelect,
}: IRepliesItemProps): JSX.Element => {
  const edit = (e: any) => {
    e.stopPropagation();
    onEdit(id);
  };

  return (
    <div className={s["item"]} onClick={() => onSelect(id)}>
      <div className={s["itemCheckboxContainer"]}>
        <CB
          caption=""
          checked={checked}
          extraClass={[s["itemCheckbox"]]}
          onChange={() => onSelect(id)}
        />
      </div>
      <div className={s["itemBody"]}>
        <div className={s["itemTitle"]}>{title}</div>
        <div className={s["itemText"]}>{text}</div>
        <div className={s["itemCategory"]}>{category}</div>
      </div>
      <div className={s["itemRight"]}>
        <div className={s["itemEdit"]} onClick={(e) => edit(e)}>
          <Icon
            color="blue"
            name="edit"
            width="18px"
            height="18px"
            extraClass={[{ [s["hover"]]: true }]}
          />
        </div>
        {attachmentsCount > 0 && (
          <div className={s["itemFiles"]}>
            <span>{attachmentsCount}</span>
            <Icon
              color="blue"
              name="file"
              width="18px"
              height="18px"
              extraClass={[{ [s["hover"]]: true }]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RepliesItem;
