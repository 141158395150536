import { fork } from "redux-saga/effects";

import getDialogDataSaga from "./getDialogData.saga";
import sendMessagesSaga from "./sendMessages.saga";
import getPaginatedMessagesSaga from "./getPaginatedMessages.saga";
import getRepliesGreetingData from "./getRepliesGreetingData.saga";
import sendOperatorsTyping from "./operatorsTyping.saga";
import setActiveDialogSaga from "./setActiveDialog.saga";
import appealCommentSagas from "./appealComment.saga";
import fullTextSearchOfReplyTemplatesSaga from "./fullTextSearchOfReplyTemplates.saga";
import getPrompterAnswers from "./getPrompterAnswers.saga";

export default function*() {
  yield fork(getDialogDataSaga);
  yield fork(sendMessagesSaga);
  yield fork(getPaginatedMessagesSaga);
  yield fork(getRepliesGreetingData);
  yield fork(sendOperatorsTyping);
  yield fork(setActiveDialogSaga);
  yield fork(appealCommentSagas);
  yield fork(fullTextSearchOfReplyTemplatesSaga);
  yield fork(getPrompterAnswers);
}
