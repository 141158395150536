import { takeEvery, put } from "redux-saga/effects";

import {
  setSubstatus,
  setUserStatusPending,
  getUserSubstatus
} from "../Store/OnlineToggle.reducer";

import {
  saveOfflineTimerToStorage,
  getOfflineTimerFromStorage
} from "../Utils";
import { SUB_STATUS } from "../consts";

function* getUserSubstatusSaga({ payload }) {
  const currentStatus = payload;
  yield put(setUserStatusPending(true));

  try {
    const current = getOfflineTimerFromStorage();

    if (current && currentStatus === "OFFLINE") {
      const { substatus, startDate } = current;
      yield put(setSubstatus({ startDate, substatus }));
    } else {
      const startDate = Date.now();
      yield saveOfflineTimerToStorage(SUB_STATUS.NONAME, startDate);
      yield put(setSubstatus({ startDate, substatus: SUB_STATUS.NONAME }));
    }
  } catch (error) {
  } finally {
    yield put(setUserStatusPending(false));
  }
}

export default function*() {
  yield takeEvery([getUserSubstatus], getUserSubstatusSaga);
}
