import { takeEvery, put, select } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { isEmpty } from "@omnichat/arm_ui_kit";

import { CategoryModalTypes } from "../Interfaces";

import {
  saveCategory,
  getSkillgroups,
  setSkillgroups,
  setCategory,
  getCategory,
  removeCategory
} from "../Actions/CategoryModal.action";

import { ACTIVE, NOT_ACTIVE, REMOVE_CATEGORY } from "../Consts";

import { getCategories } from "../../CategoriesList/Actions/CategoriesList.action";

function* saveCategoryModalData(action) {
  const close = action.payload;
  const { all, categoryId, name, selectedGroups }: CategoryModalTypes.State =
    yield select(({ CategoryModal }) => CategoryModal);

  const actionName = categoryId ? "PUT" : "POST";
  const path = categoryId ? `/${categoryId}` : "";
  const body = {
    name,
    skillGroups: selectedGroups,
    status: action.type === REMOVE_CATEGORY ? NOT_ACTIVE : ACTIVE
  };

  const response = yield httpRequest(
    actionName,
    `bff/settings/categories${path}`,
    {
      body,
      query: {
        isAllSkillGroups: all
      }
    }
  );

  if (response?.data) {
    yield put(getCategories());
    close();
  }
}

function* getSkillgroupsData(action) {
  const response = yield httpRequest("GET", `bff/dictionary/skillgroups`);

  if (response?.success) {
    yield put(setSkillgroups(response.data));
  }
}

function* getCategoryData(action) {
  const categoryId = action.payload;
  const response = yield httpRequest(
    "GET",
    `bff/settings/categories/${categoryId}`
  );

  if (response?.success) {
    yield put(
      setCategory({
        id: response.data.id,
        all: response.data.forAllSkillGroups,
        name: response.data.name,
        skillgroups: response.data.skillGroups?.map((item) => item.id)
      })
    );
  }
}

export const saveCategoryModalSaga = function* () {
  yield takeEvery([saveCategory, removeCategory], saveCategoryModalData);
};

export const getSkillgroupsSaga = function* () {
  yield takeEvery([getSkillgroups], getSkillgroupsData);
};

export const getCategorySaga = function* () {
  yield takeEvery([getCategory], getCategoryData);
};
