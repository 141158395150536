import React, { FC, useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Preloader } from "@omnichat/arm_ui_kit";

import * as style from "./style.module.scss";

import { commonChartsOption, chartsOptions, chartNames } from "../../utils";

type Props = {
  title: string;
  sum: string;
  name: chartNames;
  data?: Highcharts.SeriesOptionsType[];
  notEmpty: boolean;
  loading: boolean;
  onDateChange: (start, end) => void;
  onCsiChange: (csi) => void;
};

const Chart: FC<Props> = ({
  name,
  data,
  notEmpty,
  sum,
  title,
  loading,
  onCsiChange,
  onDateChange
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const options: Highcharts.Options = {
    ...commonChartsOption,
    ...(name === "csi"
      ? chartsOptions[name](onCsiChange)
      : chartsOptions[name](onDateChange)),
    ...(data ? { series: data } : {})
  };

  return (
    <div className={style.chartBlock}>
      <div className={style.title}>
        {title}
        <div className={style.sum}>{notEmpty ? sum : ""}</div>
      </div>
      <Preloader size="mini" show={loading}>
        {notEmpty ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
          />
        ) : (
          <div className={style.empty}>Нет данных за выбранный период</div>
        )}
      </Preloader>
    </div>
  );
};

export default Chart;
