import React, { FC } from "react";
import c from "classnames";

import * as s from "./styles.module.scss";

interface StatsQueueProps {
  type: "group" | "agent";
  id: number;
  isHovered?: boolean;
  appealsCount: number;
  appealsLimit?: number;
  timeMax?: string;
}

const StatsQueue: FC<StatsQueueProps> = ({
  type,
  id,
  isHovered = false,
  appealsCount,
  appealsLimit,
  timeMax
}) => {
  const appealsLoad = `${appealsCount ?? 0} из ${
    !!appealsLimit ? appealsLimit : "∞"
  }`;

  const heightBar = {
    height:
      appealsCount > 0 && appealsLimit > 0
        ? `${(100 * appealsCount) / appealsLimit}%`
        : 0
  };

  if (type === "group") {
    const skillId = `skill-${id}`;

    return (
      <div
        id={`queue-${skillId}`}
        className={c(s["groupQueue"], { [s["visible"]]: !!appealsCount })}
      >
        <div
          className={c(s["info"], s["groupQueueInfo"], {
            [s["isHovered"]]: isHovered
          })}
        >
          <div>Очередь</div>
          <div id={`queue-count-${skillId}`}>{appealsLoad}</div>
          <div>{timeMax}</div>
        </div>
        <div className={s["bar"]}>
          <span id={`queue-bar-${skillId}`} style={heightBar} />
        </div>
      </div>
    );
  }

  if (type === "agent") {
    const agentId = `agent-${id}`;

    return (
      <div id={`load-${agentId}`} className={c(s["agentLoad"], s["visible"])}>
        <div className={c(s["info"], s["agentLoadTitle"])}>
          <div
            className={c({
              [s["isHovered"]]: isHovered
            })}
          >
            Нагрузка
          </div>

          <div id={`load-count-${agentId}`}>{appealsLoad}</div>
        </div>

        <div
          className={c(s["bar"], {
            [s["loadUnlim"]]: !appealsLimit
          })}
        >
          <span id={`load-bar-${agentId}`} style={heightBar} />
        </div>
      </div>
    );
  }
};

export default StatsQueue;
