import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as Highcharts from "highcharts";

import { ScrollBox } from "@omnichat/arm_ui_kit";

import { parseParamsToUrl } from "../DashboardSection/utils";

import { useActions } from "./Store/Charts.reducer";
import { changeFilterPanelData } from "../DashboardFilters/Store/DashboardFilters.action";

import { charts, loading } from "./Store/Charts.selector";

import useQuery from "../../ReactFeatures/Common/Hooks/useQuery";

import Chart from "./Components/Chart";

import { DashboardQueryParams } from "../DashboardSection/DashboardSection";
import { parseQueryToChartsFilter } from "../DashboardSection/utils";

import { chartNames, chartTitle } from "./utils";

export const chartsList: chartNames[] = [
  "appeals",
  "aht",
  "ort",
  "sl",
  "lcr",
  "csi"
];

const Charts: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { query } = useQuery<DashboardQueryParams>();
  const chartsData = useSelector(charts);
  const isLoading = useSelector(loading);
  const { getChart, resetCharts } = useActions();
  let time = +query.date_start * 1000;

  Highcharts.setOptions({
    lang: {
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      weekdays: [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота"
      ],
      shortMonths: [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июнь",
        "Июль",
        "Авг",
        "Сент",
        "Окт",
        "Нояб",
        "Дек"
      ]
    }
  });

  useEffect(() => {
    resetCharts();
    chartsList.map((name) => {
      getChart({
        name,
        filters: parseQueryToChartsFilter(query)
      });
    });
  }, [query]);

  useEffect(
    () => () => {
      resetCharts();
    },
    []
  );

  return (
    <ScrollBox autoHeight hasScrollControlBackground>
      {chartsList.map((chart, i) => (
        <Chart
          key={i}
          loading={isLoading}
          name={chart}
          title={chartTitle[chart] || ""}
          sum={chartsData?.[chart]?.text || ""}
          notEmpty={chartsData?.[chart]?.count > 0}
          data={
            chartsData?.[chart] && [
              {
                type: "column",
                data: chartsData[chart].chart,
                pointStart: Date.UTC(
                  chartsData[chart].date.y,
                  chartsData[chart].date.m,
                  chartsData[chart].date.d,
                  new Date(time).getHours()
                ),
                pointInterval:
                  chartsData[chart].group == "day" ? 86400000 : 3600000
              }
            ]
          }
          onCsiChange={(csi) => {
            navigate({
              search: parseParamsToUrl({ ...query, csi })
            });
            dispatch(
              changeFilterPanelData({
                csi: `${csi}`
              })
            );
          }}
          onDateChange={(date_start, date_end) => {
            navigate({
              search: parseParamsToUrl({ ...query, date_start, date_end })
            });
            dispatch(
              changeFilterPanelData({
                date_start: date_start * 1000,
                date_end: date_end * 1000
              })
            );
          }}
        />
      ))}
    </ScrollBox>
  );
};

export default Charts;
