import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  sendTemplateData,
  SEND_TEMPLATE_DATA
} from "../Store/ClassifierFieldForm.reducer";
import { Template } from "../ClassifierFieldForm";
import { ITextTemplateData } from "../Components/TextTemplates/TextTemplate";

import Informer from "../../../arm/Wrappers/Informer";

export function* saveTemplateSaga(action) {
  yield put(startLoading(SEND_TEMPLATE_DATA));

  const { fieldId, formData, cb } = action.payload;

  const preparedData = ((data: ITextTemplateData): Template => ({
    id: data.id,
    name: data.name,
    context: data.content,
    order: data.order
  }))(formData);

  try {
    const method = preparedData?.id ? "PUT" : "POST";
    const id = preparedData?.id || "";

    const res = yield httpRequest(
      method,
      `bff/settings/classifier/fields/${fieldId}/templates/${id}`,
      {
        body: preparedData
      }
    );

    if (res?.success) {
      let actionName = id ? "отредактирован" : "создан";

      cb(res.data.id);

      const info = new Informer(
        `Шаблон «${res.data.name}» был ${actionName}`,
        2000
      );
      info.show();
    } else {
      if (res.error) {
        console.error(res.error);
      }
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(SEND_TEMPLATE_DATA));
  }
}

export default function* () {
  yield takeEvery([sendTemplateData], saveTemplateSaga);
}
