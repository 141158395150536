import { createAction } from "@reduxjs/toolkit";

import { CategoryForRepliesModalTypes } from "../Interfaces";

import * as ACTION from "../Consts";

export const getNextPage = createAction<number>(ACTION.GET_NEXT_PAGE);
export const getCategories = createAction<number>(ACTION.GET_CATEGORIES);
export const setCategories = createAction<
  CategoryForRepliesModalTypes.Category[]
>(ACTION.SET_CATEGORIES);
export const saveReplies = createAction<{
  id: number;
  onSuccess: () => void;
  onError: () => void;
}>(ACTION.SAVE_REPLIES);
export const resetData = createAction<number>(ACTION.RESET);
