import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactHookFormErrorHandler } from "../../../Utils/HookForm.helper";
import FormFieldConnector from "../../../Components/FormFieldConnector";

import {
  Button,
  Select,
  ModalWindow,
  REQUIRED_FIELD_ERROR
} from "@omnichat/arm_ui_kit";

import { Input, ModalHeader } from "../../../Components";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

const ModalContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;

const ActionButtons = styled.div`
  margin-top: 10px;
  justify-content: center;
  display: flex;
  & > button {
    margin-right: 10px;
  }
`;

enum Channels {
  Webchat = 9,
  Mobile = 10,
  Email = 11
}

const ChannelTypes = [
  { value: Channels.Webchat, label: "Веб-чат" },
  { value: Channels.Mobile, label: "Мобильное приложение" },
  { value: Channels.Email, label: "Почта" }
];

const schema = Yup.object({
  name: Yup.string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  type: Yup.number().required(REQUIRED_FIELD_ERROR),
  email: Yup.string().when("type", {
    is: Channels.Email,
    then: (schema) => schema.required(REQUIRED_FIELD_ERROR),
    otherwise: (schema) => schema.nullable()
  })
});

const defaultValues = {
  name: "",
  type: null,
  email: null
};

export interface IChannelForm extends Yup.InferType<typeof schema> {}

export function CreateChannel({ open, handleClose }) {
  const [emailList, setEmailList] = useState<string[]>();
  const methods = useForm<IChannelForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch, reset } = methods;

  const selectedType = watch("type");

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (selectedType === Channels.Email) {
      httpRequest("GET", "bff/dictionary/email-addresses").then((res) => {
        if (res?.success) {
          setEmailList(res.data);
        } else {
          console.error(res?.error);
        }
      });
    }
  }, [selectedType]);

  const handleSave = (form) => {
    httpRequest("POST", "bff/settings/channels", {
      body: {
        name: form.name,
        type: form.type,
        account: form.email || undefined
      }
    }).then((res) => {
      if (res?.success) {
      } else {
        console.error(res?.error);
      }
      handleClose?.();
    });
  };

  return (
    <ModalWindow open={open} onCloseModal={handleClose} isButtonClose>
      <ModalContainer id="CreateChannelModal">
        <FormProvider {...methods}>
          <ModalHeader>Создание канала</ModalHeader>

          <FormFieldConnector
            name="name"
            label="Название"
            required
            Component={(p) => (
              <Input {...p} onChange={p.onChange} value={p.value} />
            )}
          />

          <FormFieldConnector
            name="type"
            label="Тип канала"
            required
            Component={(p) => (
              <Select
                {...p}
                label={""}
                options={ChannelTypes}
                selected={p.value ? [p.value] : []}
                onSelectOption={(v) => p.onChange(v[0])}
              ></Select>
            )}
          />

          {selectedType === Channels.Email && (
            <FormFieldConnector
              name="email"
              label="Выберите почтовый ящик"
              required
              Component={(p) => (
                <Select
                  {...p}
                  label={""}
                  options={
                    emailList?.map((e) => ({ value: e, label: e })) || []
                  }
                  selected={p.value ? [p.value] : []}
                  onSelectOption={(v) => p.onChange(v[0])}
                ></Select>
              )}
            />
          )}

          <ActionButtons>
            <Button
              text="Сохранить"
              theme="green"
              type="default"
              onClick={handleSubmit(handleSave, ReactHookFormErrorHandler)}
            />
          </ActionButtons>
        </FormProvider>
      </ModalContainer>
    </ModalWindow>
  );
}
