import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { StoreState } from "../../ReactCore/store/types";
import {
  getFieldsList,
  getValuesList,
  resetFieldsValuesList
} from "./Store/ClassifierLinkForm.reducer";

import * as selectors from "./Store/ClassifierLinkForm.selector";

import ClassifierLinkForm from "./Components/ClassifierLinkForm";
import { Link } from "./ClassifierLinkForm";

type IOwnProps = {
  fieldId: string;
  classifierId: string;
  name: string;
  onSave: (link: Link[]) => void;
};

type IProps = IOwnProps & ConnectedProps<typeof connector>;

const ClassifierLinkFormProvider: FC<IProps> = ({
  loading,
  name,
  fieldValuesList,
  fieldId,
  classifierId,
  onSave,
  getFieldsList,
  getValuesList,
  resetFieldsValuesList
}) => {
  useEffect(() => {
    getFieldsList(classifierId);
    return () => resetFieldsValuesList();
  }, []);

  return (
    <ClassifierLinkForm
      loading={loading}
      name={name}
      onSave={onSave}
      onAddField={(fieldId) => getValuesList(fieldId)}
      fieldsList={fieldValuesList}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetFieldsValuesList: () => dispatch(resetFieldsValuesList()),
  getFieldsList: (data) => dispatch(getFieldsList(data)),
  getValuesList: (data) => dispatch(getValuesList(data))
});

const mapStateToProps = (state: StoreState.State, ownProps: IOwnProps) => {
  const { fieldId } = ownProps;

  return {
    loading: selectors.getLoadingStatus(state),
    fieldValuesList: selectors.fieldValuesList(state, fieldId)
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ClassifierLinkFormProvider);
