import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { SkillGroupData } from "../UserGroupsManager";
import Informer, { INFORMER_DELAY } from "../../../arm/Wrappers/Informer";
import { actionUsers, SKILLGROUP_DELETE } from "../Store/Users.reducer";

export function* skillgroupDelete({
  payload: { skillGroupId }
}: PayloadAction<{
  skillGroupId: SkillGroupData["id"];
}>) {
  try {
    const res = yield httpRequest(
      "DELETE",
      `bff/settings/skill-groups/${skillGroupId}/delete`,
      { body: { skillGroup: { id: skillGroupId } } }
    );

    if (res?.success) {
      yield put(actionUsers.deletedSkillgroupSuccess(skillGroupId));
      const info = new Informer(`Изменения успешно сохранены`, INFORMER_DELAY);
      info.show();
    } else {
      const info = new Informer(
        `Не удалось удалить скилл-группу`,
        INFORMER_DELAY
      );
      info.show();
    }
  } catch (error) {
    console.error(error);
  }
}

export default function*() {
  yield takeEvery(SKILLGROUP_DELETE, skillgroupDelete);
}
