import React, { FC, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import c from "classnames";

import { Button, REQUIRED_FIELD_ERROR, Tooltip } from "@omnichat/arm_ui_kit";

import { Input, FormFieldConnector } from "../../../../Components";
import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

import * as s from "./style.module.scss";

export type ITextValidateData = ITextValidateForm & {
  id?: string;
};

type ITextValidateForm = {
  regexp?: string;
  errorText?: string;
};

export type ModeVariants = "view" | "edit" | "create";

type ITextValidateProps = {
  validateData: ITextValidateData;
  onSave: (data: ITextValidateData) => void;
  onDelete: (data: ITextValidateData) => void;
  onCancelCreation: () => void;
};

const schema = Yup.object({
  regexp: Yup.string().required(REQUIRED_FIELD_ERROR),
  errorText: Yup.string().required(REQUIRED_FIELD_ERROR)
});

const defaultValues = {
  regexp: "",
  errorText: ""
};

const TextValidate: FC<ITextValidateProps> = ({
  validateData,
  onSave,
  onDelete,
  onCancelCreation
}) => {
  const [mode, setMode] = useState<ModeVariants>(
    validateData?.id ? "view" : "create"
  );
  const methods = useForm<ITextValidateForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (validateData) {
      reset({
        errorText: validateData.errorText || "",
        regexp: validateData.regexp || ""
      });
    } else {
      reset(defaultValues);
    }
  }, [validateData]);

  const handleSave = (form: ITextValidateForm) => {
    onSave({
      ...validateData,
      ...form
    });
  };

  return (
    <div className={s.textValidate}>
      <FormProvider {...methods}>
        <div className={c(s.head)}>
          <div className={s.name}>
            {mode === "view" && (
              <>
                <div className={s.nameText}>{validateData?.regexp}</div>
                <div className={s.nameText}>{validateData?.errorText}</div>
              </>
            )}
            {(mode === "edit" || mode === "create") && (
              <>
                <FormFieldConnector
                  required
                  name="regexp"
                  showErrorText={false}
                  extraClass={[s.nameInput]}
                  Component={(p, s) => (
                    <Input isError={s.error} placeholder="Маска" {...p} />
                  )}
                />
                <FormFieldConnector
                  required
                  name="errorText"
                  showErrorText={false}
                  extraClass={[s.nameInput]}
                  Component={(p, s) => (
                    <Input
                      isError={s.error}
                      placeholder="Текст ошибки"
                      {...p}
                    />
                  )}
                />
              </>
            )}
          </div>
          <div className={s.buttons}>
            {mode === "view" && (
              <>
                <Tooltip content="Редактировать" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="blue"
                    iconName="edit"
                    onClick={() => setMode("edit")}
                  />
                </Tooltip>
                <Tooltip content="Удалить" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="red"
                    iconName="cross"
                    onClick={() => onDelete(validateData)}
                  />
                </Tooltip>
              </>
            )}
            {(mode === "edit" || mode === "create") && (
              <>
                <Tooltip content="Сохранить" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="green"
                    iconName="check"
                    onClick={handleSubmit(
                      handleSave,
                      ReactHookFormErrorHandler
                    )}
                  />
                </Tooltip>
                <Tooltip content="Отменить" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="black"
                    iconName="cross"
                    onClick={() =>
                      mode === "create" ? onCancelCreation() : setMode("view")
                    }
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default TextValidate;
