import { Options } from "highcharts";
import {
  toUnixTimeStamp,
  convertToMoscowTimestamp
} from "../../ReactFeatures/Common/Utils/DateAndTime.utils";

/**
 * Метод для получения количества дней в месяце
 * @param {Date} date дата
 * @returns {Number}
 */
const getDaysInMonth = function(date) {
  // if you'll set non-existent day (in our case = 33) to Date obj, you'll get the corresponding day of the next month (33.01 -> returns 2, so daysInMonth would be 33 - 2 = 31)
  return 33 - new Date(date.getFullYear(), date.getMonth(), 33).getDate();
};

/**
 * Метод добавляющий/отнимающий месяцы к/от переданной даты
 * @param {Date} date дата для редактирвоания
 * @param {Number} value количество месяцев. Если значение отрицательное, метод будет отнимать месяцы!
 * @returns {Date} преобразованная дата
 */
const addMonths = function(date, value) {
  var d = new Date(date);
  var n = date.getDate();
  var daysInCurrentMonth = getDaysInMonth(date);

  d.setDate(1);
  d.setMonth(d.getMonth() + value);

  var daysInUpdatedMonth = getDaysInMonth(d);

  // assure that 28.02.2018 plus 1 month will be 31.03.2018
  var changeDay =
    daysInUpdatedMonth !== daysInCurrentMonth ? daysInCurrentMonth - n : null;
  changeDay = changeDay > n ? null : changeDay;

  var dateToSet =
    changeDay !== null
      ? getDaysInMonth(d) - changeDay
      : Math.min(n, getDaysInMonth(d));
  d.setDate(dateToSet);
  return d;
};

const formatDate = function(date) {
  return (
    ("0" + date.getDate()).slice(-2) +
    "." +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "." +
    date.getFullYear()
  );
};

/**
 * Вернет дату начала отсчета + 23 ч. 59 мин. (конец дня).
 *
 * @param {Date} date_start Дата начала отсчета.
 */
const getDateEnd = (date_start) => {
  return new Date(+date_start + (23 * 60 * 60 + 59 * 60) * 1000);
};

const updateDateWithToday = (event, cb) => {
  let date_start = new Date(event.point.x);
  let timeOffsetInMS = date_start.getTimezoneOffset() * 60000;

  date_start = new Date(event.point.x + timeOffsetInMS);
  date_start.setHours(0, 0, 0, 0);
  const date_end = getDateEnd(date_start);

  date_update(
    "set_custom_date_ms",
    toUnixTimeStamp(convertToMoscowTimestamp(date_start)),
    toUnixTimeStamp(convertToMoscowTimestamp(date_end)),
    cb
  );
};

const date_update = function(period, date_start, date_end, cb) {
  let start = new Date() as any;
  let end = formatDate(start);
  const week_length = 7; // days
  const quarter_length = 3; // months

  //warn: js Date obj has autocheks for invalid dates (e.g. (01.01.2018 - 1 month) will be 01.12.2017), but has no checks for edge cases (leap years and so on)
  switch (period) {
    case "today":
      start = end;
      break;
    case "yesterday":
      start.setDate(start.getDate() - 1);
      start = end = formatDate(start);
      break;
    case "week":
      start.setDate(start.getDate() - week_length);
      start = formatDate(start);
      break;
    case "month":
      start = addMonths(start, -1);
      start = formatDate(start);
      break;
    case "quarter":
      start = addMonths(start, -quarter_length);
      start = formatDate(start);
      break;
    case "set_custom_date_ms":
      if (date_start && !date_end) {
        start = end = date_start;
      } else if (!date_start && date_end) {
        start = end = date_end;
      } else if (date_start && date_end) {
        start = date_start;
        end = date_end;
      } else {
        console.warn("no date to set for stats");
        return false;
      }
      break;
    default:
      console.warn("unexpected stats period requested");
      return false;
  }
  let newUrl = "";
  for (let key in core.nav.url.params) {
    newUrl += key + "=";
    if (key === "date_start") {
      newUrl += start + "&";
    } else if (key === "date_end") {
      newUrl += end + "&";
    } else {
      newUrl += core.nav.url.params[key] + "&";
    }
  }

  cb(start, end);
};

const appeals = (cb): Options => ({
  chart: {
    renderTo: "chart_appeals",
    type: "column",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    animation: false,
    height: 200
  },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: null
    },
    gridLineColor: "#E9EEEF",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      enabled: true,
      style: {
        color: "#a0abb1",
        fontSize: "11px"
      }
    }
  },
  xAxis: {
    type: "datetime",
    labels: {
      style: {
        color: "#a0abb1",
        fontSize: "1.2em"
      }
    }
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<b>{point.key}</b><table>",
    pointFormat:
      "<tr><td><small>Количество обращений:</small></td>" +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    hideDelay: 300,
    backgroundColor: "rgba(0,0,0,.8)",
    borderRadius: 5,
    borderWidth: 0,
    shadow: false,
    style: {
      color: "#FFF"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return this.y;
          } else {
            return null;
          }
        }
      },
      color: "#bbe082",
      states: {
        hover: {
          color: "#A4D45B"
        }
      },
      point: {
        events: {
          click: (e) => updateDateWithToday(e, cb)
        }
      },
      animation: false,
      pointPadding: -0.3
    },
    series: {
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        style: {
          color: "#000",
          fontSize: "11px"
        }
      },

      states: {
        hover: {
          brightness: -0.2
        }
      }
    }
  }
});

const aht = (cb): Options => ({
  chart: {
    renderTo: "chart_aht",
    type: "column",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    animation: false,
    height: 200
  },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: null
    },
    gridLineColor: "#E9EEEF",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      enabled: true,
      style: {
        color: "#a0abb1",
        fontSize: "11px"
      }
    }
  },
  xAxis: {
    type: "datetime",
    labels: {
      style: {
        color: "#a0abb1",
        fontSize: "1.2em"
      }
    }
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<b>{point.key}</b><table>",
    pointFormat:
      "<tr><td><small>Среднее время работы с обращением:</small></td>" +
      '<td style="text-align: right"><b>{point.y} сек</b></td></tr>',
    footerFormat: "</table>",
    hideDelay: 300,
    backgroundColor: "rgba(0,0,0,.8)",
    borderRadius: 5,
    borderWidth: 0,
    shadow: false,
    style: {
      color: "#FFF"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return this.y;
          } else {
            return null;
          }
        }
      },
      color: "#E9EB88",
      states: {
        hover: {
          color: "#E1E45C"
        }
      },
      point: {
        events: {
          click: (e) => updateDateWithToday(e, cb)
        }
      },
      animation: false,
      pointPadding: -0.3
    },

    series: {
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return `${this.y} сек`;
          } else {
            return null;
          }
        },
        style: {
          color: "#000",
          fontSize: "11px"
        }
      },
      states: {
        hover: {
          brightness: -0.2
        }
      }
    }
  }
});

const csi = (cb): Options => ({
  chart: {
    renderTo: "chart_csi",
    type: "column",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    height: 200
  },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: null
    },
    gridLineColor: "#E9EEEF",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      enabled: true,
      style: {
        color: "#a0abb1",
        fontSize: "11px"
      }
    }
  },
  xAxis: {
    type: "category",
    labels: {
      style: {
        color: "#a0abb1",
        fontSize: "1.2em"
      }
    },
    startOnTick: false
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<b>CSI {point.key}</b><table>",
    pointFormat:
      "<tr><td><small>Количество оценок:</small></td>" +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    hideDelay: 300,
    backgroundColor: "rgba(0,0,0,.8)",
    borderRadius: 5,
    borderWidth: 0,
    shadow: false,
    style: {
      color: "#FFF"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return this.y;
          } else {
            return null;
          }
        },
        style: {
          fontSize: "11px"
        }
      },
      stickyTracking: false,
      states: {
        hover: {
          brightness: -0.15
        }
      },
      animation: false
    },
    column: {
      colorByPoint: true,
      colors: [
        "#dedede",
        "#ff9b8f",
        "#ffd88f",
        "#ffef8f",
        "#f6fc8e",
        "#c2f389"
      ],
      point: {
        events: {
          click: function(event) {
            cb(event.point.category);
          }
        }
      },
      pointPadding: -0.2
    }
  }
});

const ort = (cb): Options => ({
  chart: {
    renderTo: "chart_ort",
    type: "column",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    animation: false,
    height: 200
  },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: null
    },
    gridLineColor: "#E9EEEF",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      enabled: true,
      style: {
        color: "#a0abb1",
        fontSize: "11px"
      }
    }
  },
  xAxis: {
    type: "datetime",
    labels: {
      style: {
        color: "#a0abb1",
        fontSize: "1.2em"
      }
    }
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<b>{point.key}</b><table>",
    pointFormat:
      "<tr><td><small>Среднее время ответа оператора (ORT): </small></td>" +
      '<td style="text-align: right"><b>{point.y} %</b></td></tr>',
    footerFormat: "</table>",
    hideDelay: 300,
    backgroundColor: "rgba(0,0,0,.8)",
    borderRadius: 5,
    borderWidth: 0,
    shadow: false,
    style: {
      color: "#FFF"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return this.y;
          } else {
            return null;
          }
        }
      },
      color: "#ffd88f",
      states: {
        hover: {
          color: "#FFC65C"
        }
      },
      point: {
        events: {
          click: (e) => updateDateWithToday(e, cb)
        }
      },
      animation: false,
      pointPadding: -0.3
    },
    series: {
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        style: {
          color: "#000",
          fontSize: "11px"
        }
      },
      states: {
        hover: {
          brightness: -0.2
        }
      }
    }
  }
});

const sl = (cb): Options => ({
  chart: {
    renderTo: "chart_sl",
    type: "column",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    animation: false,
    height: 200
  },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: null
    },
    gridLineColor: "#E9EEEF",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      enabled: true,
      style: {
        color: "#a0abb1",
        fontSize: "11px"
      }
    }
  },
  xAxis: {
    type: "datetime",
    labels: {
      style: {
        color: "#a0abb1",
        fontSize: "1.2em"
      }
    }
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<b>{point.key}</b><table>",
    pointFormat:
      "<tr><td><small>Средний уровень SL: </small></td>" +
      '<td style="text-align: right"><b>{point.y} %</b></td></tr>',
    footerFormat: "</table>",
    hideDelay: 300,
    backgroundColor: "rgba(0,0,0,.8)",
    borderRadius: 5,
    borderWidth: 0,
    shadow: false,
    style: {
      color: "#FFF"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return this.y;
          } else {
            return null;
          }
        }
      },
      color: "#ffd88f",
      states: {
        hover: {
          color: "#FFC65C"
        }
      },
      point: {
        events: {
          click: (e) => updateDateWithToday(e, cb)
        }
      },
      animation: false,
      pointPadding: -0.3
    },
    series: {
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        style: {
          color: "#000",
          fontSize: "11px"
        }
      },

      states: {
        hover: {
          brightness: -0.2
        }
      }
    }
  }
});

const lcr = (cb): Options => ({
  chart: {
    renderTo: "chart_lcr",
    type: "column",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    animation: false,
    height: 200
  },
  title: {
    text: ""
  },
  yAxis: {
    title: {
      text: null
    },
    gridLineColor: "#E9EEEF",
    lineWidth: 0,
    endOnTick: false,
    labels: {
      enabled: true,
      style: {
        color: "#a0abb1",
        fontSize: "11px"
      }
    }
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {},
    labels: {
      style: {
        color: "#a0abb1",
        fontSize: "1.2em"
      }
    }
  },
  tooltip: {
    useHTML: true,
    headerFormat: "<b>{point.key}</b><table>",
    pointFormat:
      "<tr><td><small>Количество веб-чатов:</small></td>" +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    hideDelay: 300,
    backgroundColor: "rgba(0,0,0,.8)",
    borderRadius: 5,
    borderWidth: 0,
    shadow: false,
    style: {
      color: "#FFF"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y != 0) {
            return this.y;
          } else {
            return null;
          }
        }
      },
      color: "#ff9b8f",
      states: {
        hover: {
          color: "#FF6D5C"
        }
      },
      point: {
        events: {
          click: (e) => updateDateWithToday(e, cb)
        }
      },
      animation: false,
      pointPadding: -0.3
    },
    series: {
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        style: {
          color: "#000",
          fontSize: "11px"
        }
      },
      states: {
        hover: {
          brightness: -0.2
        }
      }
    }
  }
});

export const commonChartsOption: Options = {
  credits: {
    enabled: false
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  }
};

export const chartsOptions = {
  appeals,
  aht,
  ort,
  sl,
  lcr,
  csi
};

export type chartNames = keyof typeof chartsOptions;

export const chartTitle: { [key: string]: string } = {
  appeals: `Количество обращений`,
  aht: `Среднее время работы с обращением`,
  csi: `Средняя оценка CSI`,
  ort: `Среднее время ответа оператора (ORT)`,
  sl: `Средний уровень SL`,
  lcr: `Пропущенные веб-чаты`
};
