import { createSelector } from "reselect";
import { isEmpty } from "@omnichat/arm_ui_kit";

import { StoreState } from "../../../ReactCore/store/types";
import {
  IRating,
  IOrganization,
  IRole,
  IArea,
  ITerritory,
  ISkillGroup
} from "../Dictionaries";
import { USERS_PROFILE_RATING } from "../consts";

export const getMoveAppealList = (state) => {
  return state.Dictionaries.moveAppealList || [];
};

export const getRegions = (state) => {
  return state.Dictionaries.regions || [];
};

export const getRating = (state: StoreState.State): IRating[] =>
  USERS_PROFILE_RATING;

export const getOrganizations = (state: StoreState.State): IOrganization[] =>
  state.Dictionaries.organizations;

export const getRoles = (state: StoreState.State): IRole[] =>
  state.Dictionaries.roles;

export const getAreas = (state: StoreState.State): IArea[] =>
  state.Dictionaries.areas;

export const getTerritories = (state: StoreState.State): ITerritory[] =>
  state.Dictionaries.territories;

export const getSkillGroups = (state: StoreState.State): ISkillGroup[] =>
  state.Dictionaries.skillGroups;

export const getRatingToForm = createSelector(
  getRating,
  (rating) =>
    rating?.map((item) => ({
      value: item.id,
      label: item.name
    })) || []
);

export const getTerritoriesToForm = createSelector(
  getTerritories,
  (territories) =>
    territories?.map((item) => ({
      value: item.id,
      label: item.name,
      active: item.active
    })) || []
);

export const getOrganizationsToForm = createSelector(
  getOrganizations,
  (organizations) =>
    organizations?.map((item) => ({
      value: item.id,
      label: item.name,
      active: item.active
    })) || []
);

export const getAreasToForm = createSelector(
  getAreas,
  (areas) =>
    areas?.map((item) => ({
      value: item.id,
      label: item.name,
      active: item.active
    })) || []
);

export const getSkillGroupsToForm = createSelector(
  getSkillGroups,
  (skillGroups) =>
    skillGroups?.map((item) => ({
      value: item.id,
      label: item.name
    })) || []
);

export const getRolesToForm = createSelector(
  getRoles,
  (roles) =>
    roles?.map((item) => ({
      value: item.id,
      label: item.name
    })) || []
);
export const getIsUsersDictionariesLoaded = createSelector(
  getAreas,
  getRoles,
  getOrganizations,
  getTerritories,
  getSkillGroups,
  (areas, roles, organizations, territories, skillGroups) =>
    areas !== null &&
    roles !== null &&
    organizations !== null &&
    territories !== null &&
    skillGroups !== null
);
