import { createSlice } from "@reduxjs/toolkit";
import {
  setFulfilledReducer,
  setPendingReducer,
  setRejectedReducer
} from "../../Common/Reducers/LoadingData.reducers";
import {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction
} from "../../Common/Utils/LoadingData.utils";
import { EDOWNLOAD_STATUS } from "../../Common/Enums/LoadingData.enums";
import { NAMESPACE } from "../Consts";

/**
 * Начальное состояние данных для горячих клавиш.
 */
export const hotKeysInitialState = {
  availableHotKeysData: {
    status: EDOWNLOAD_STATUS.IDLE,
    data: [],
    error: null
  }
};

/**
 * Редьюсер для горячих клавиш.
 *
 * @param state Текущее состояние.
 * @param action Экшн.
 */
const hotKeysSlice = createSlice({
  name: NAMESPACE,
  initialState: hotKeysInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction, setPendingReducer)
      .addMatcher(isFulfilledAction, setFulfilledReducer)
      .addMatcher(isRejectedAction, setRejectedReducer);
  }
});

export default hotKeysSlice.reducer;
