import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  removeValidate,
  REMOVE_VALIDATE
} from "../Store/ClassifierFieldForm.reducer";

export function* removeValidateSaga(action) {
  const { fieldId, validateId, cb } = action.payload;
  yield put(startLoading(REMOVE_VALIDATE));

  try {
    const res = yield httpRequest(
      "DELETE",
      `bff/settings/classifier/fields/${fieldId}/validations/${validateId}`
    );

    if (res?.success) {
      yield cb();
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(REMOVE_VALIDATE));
  }
}

export default function* () {
  yield takeEvery([removeValidate], removeValidateSaga);
}
