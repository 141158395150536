import { chatInitialState } from "../../Chat/store/Chat.reducer";

import { StoreState } from "./types";

import { appealInfoInitialState } from "../../ReactFeatures/AppealInfo/Consts";
import { hotKeysInitialState } from "../../ReactFeatures/AvailableHotKeys/Reducers/HotKeys.reducer";

/**
 * Регистрация InitState для всех ReactFeatures
 */
const featureInitStates = {};
const requireFeaturesInitState = require.context(
  "../../ReactFeatures",
  true,
  /\.initialData\.ts/
);
requireFeaturesInitState.keys().forEach((name) => {
  const initialDataName = name.split("/")[1];
  featureInitStates[initialDataName] = requireFeaturesInitState(name).default;
});

/**
 * Регистрация InitState для всех Settings
 */
const settingsInitStates = {};
const requireSettingsInitState = require.context(
  "../../Settings",
  true,
  /\.initialData\.ts/
);
requireSettingsInitState.keys().forEach((name) => {
  const initialDataName = name.split("/")[1];
  settingsInitStates[initialDataName] = requireSettingsInitState(name).default;
});

/**
 * Регистрация InitState для всех Dashboard компонентов
 */
const dashboardInitStates = {};
const requireDashboardInitState = require.context(
  "../../Dashboard",
  true,
  /\.initialData\.ts/
);
requireDashboardInitState.keys().forEach((name) => {
  const initialDataName = name.split("/")[1];
  dashboardInitStates[initialDataName] = requireDashboardInitState(
    name
  ).default;
});

const initialState: StoreState.State = {
  ...featureInitStates,

  ...settingsInitStates,

  ...dashboardInitStates,

  AvailableHotKeys: hotKeysInitialState,

  AppealInfo: appealInfoInitialState,

  Chat: chatInitialState
};

export default initialState;
