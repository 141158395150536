import React, {
  memo,
  useState,
  useEffect,
  SyntheticEvent,
  FC,
  KeyboardEvent
} from "react";
import { isEmpty } from "@omnichat/arm_ui_kit";
import {
  Box,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField
} from "@mui/material";
import FormField from "../MuiFormField/FormField";

export interface IOption {
  value: string;
  label: string;
  [key: string]: any;
}

interface Props {
  options: IOption[];
  disabled?: boolean;
  onOpen?: () => void;
  onBlur?: () => void;
  onChange?: (v: string) => void;
  placeholder?: string;
  isSearchable?: boolean;
  isError?: boolean;
  label?: string;
  actionText?: string;
  value?: string;
}

const InputWithOptions: FC<Props> = ({
  options = [],
  onOpen,
  onChange = () => {},
  onBlur = () => {},
  isError = false,
  disabled = false,
  actionText,
  placeholder = "Не указано",
  label,
  value,
  ...props
}) => {
  let inputRef;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      inputRef.blur();
      return;
    }
    inputRef.focus();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e: SyntheticEvent<Element, Event>): void => {
    if (disabled || open || isEmpty(options)) {
      return;
    }
    setOpen(true);
    onOpen?.();
  };

  const handleChange = (e, v: string) => onChange(v);

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    e.key === "Enter" && onBlur();
  };

  return (
    <Box display="flex" flexDirection="column" onClick={handleOpen}>
      <MuiAutocomplete
        {...props}
        freeSolo
        disableClearable
        clearIcon={null}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        inputValue={value}
        onInputChange={handleChange}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyDown={handleEnter}
        disabled={disabled}
        options={options.map((o) => o.value)}
        getOptionLabel={(v) => options.find((o) => o.value === v)?.label || ""}
        renderInput={(params) => (
          <FormField
            label={label}
            disabled={disabled}
            actionText={actionText}
            isError={isError}
          >
            <MuiTextField
              {...params}
              inputRef={(input) => {
                inputRef = input;
              }}
              variant="standard"
              placeholder={placeholder || ""}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
            />
          </FormField>
        )}
        sx={{
          "& .MuiInput-root.MuiAutocomplete-inputRoot": {
            paddingTop: "6px",
            paddingBottom: "6px"
          },

          input: {
            ":focus, :active": {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: "currentColor"
            }
          }
        }}
      />
    </Box>
  );
};

export default memo(InputWithOptions);
