import produce from "immer";

import initialState from "../../ReactCore/store/initialState";
import {
  prepareFileData,
  updateFileData,
  removeFileByUuid,
  cancelPanding
} from "./fileStorage.service";

import {
  PUSH_FILES,
  UPDATE_PROGRESS,
  FILE_LOAD_FAILED,
  FILE_LOADED,
  SEND_FILE,
  REMOVE_FILE,
  CLEAN_FILE_LIST,
  CANCEL_PENDING
} from "./consts";

export default function dataReducer(
  state = initialState.FileStorage,
  { type, payload }
) {
  switch (type) {
    case PUSH_FILES:
      return produce(state, (draft) => {
        const newFiles = prepareFileData(payload);
        draft.files = [...draft.files, ...newFiles];
      });

    case CANCEL_PENDING:
      return produce(state, (draft) => {
        cancelPanding(draft.files, payload);
        return state;
      });

    case REMOVE_FILE:
      return produce(state, (draft) => {
        draft.files = removeFileByUuid(draft.files, payload);
      });

    case CLEAN_FILE_LIST:
      return produce(state, (draft) => {
        draft.files = [];
      });

    case SEND_FILE:
      return produce(state, (draft) => {
        const { uuid } = payload;
        draft.files = updateFileData(draft.files, uuid, { pending: true });
      });

    case UPDATE_PROGRESS:
      return produce(state, (draft) => {
        const { uuid, progress, cancel } = payload;

        draft.files = updateFileData(draft.files, uuid, { progress, cancel });
      });

    case FILE_LOADED:
      return produce(state, (draft) => {
        const { uuid, data } = payload;

        draft.files = updateFileData(draft.files, uuid, {
          pending: false,
          sended: true,
          failed: false,
          data
        });
      });

    case FILE_LOAD_FAILED:
      return produce(state, (draft) => {
        const { uuid } = payload;
        draft.files = updateFileData(draft.files, uuid, { failed: true });
      });

    default:
      return state;
  }
}
