import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionRoles } from "../Store/Roles.reducer";
import Informer from "../../../arm/Wrappers/Informer";
import { ERROR_MESSAGE_WHILE_GET_DATA } from "../const";

function* getRightsSaga({ payload }) {
  const { roleId } = payload;

  try {
    const res = yield httpRequest("GET", `bff/settings/roles/${roleId}/rights`);
    if (res.success) {
      yield put(actionRoles.setRights(res.data));
    }
  } catch (error) {
    const info = new Informer(ERROR_MESSAGE_WHILE_GET_DATA);
    info.show();
  } finally {
  }
}

export default function*() {
  yield takeEvery([actionRoles.getRights], getRightsSaga);
}
