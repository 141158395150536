import { ALL } from "./Consts";

export const getSkillGroup = state => {
  return state.CategoriesList.skillGroup;
};

export const getSkillGroups = state => {
  let skillGroups = state.CategoriesList.skillGroups.map(item => ({
    label: item.name,
    value: item.id
  }));

  skillGroups.unshift({
    label: "Все группы",
    value: 0
  });

  return skillGroups;
};

export const getCategory = state => {
  return state.CategoriesList.category;
};

export const getCategories = state => {
  let result = [];
  const current = getCategory(state);

  const categories = state.CategoriesList.categories?.map(item => ({
    id: item.id,
    name: item.name,
    current: item.id === current,
    editable: true
  }));

  if (categories) result = [...categories];

  result.unshift({
    id: ALL,
    name: "Все категории",
    current: ALL === current,
    editable: false
  });

  return result;
};
