import { useMemo } from "react";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { AppealsData, AppealFiltersData, Channel } from "../Appeals";
import initialState from "../../../ReactCore/store/initialState";

const NAMESPACE = "dashboardAppeals/";

export const GET_APPEALS = `${NAMESPACE}GET_APPEALS`;
export const GET_CHANNELS = `${NAMESPACE}GET_CHANNELS`;

export const getAppeals = createAction<{
  page: number;
  filters: AppealFiltersData;
}>(GET_APPEALS);

export const getChannels = createAction(GET_CHANNELS);

const AppealsSlice = createSlice({
  name: "appeals",
  initialState: initialState.Appeals,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setChannels(state, action: PayloadAction<Channel[]>) {
      state.channels = action.payload;
    },
    setAppeals(state, action: PayloadAction<AppealsData[]>) {
      state.appeals = action.payload;
    },
    addAppeals(state, action: PayloadAction<AppealsData[]>) {
      state.appeals = [...state.appeals, ...action.payload];
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setLastPage(state, action: PayloadAction<boolean>) {
      state.lastPage = action.payload;
    },
    resetAppealList(state) {
      state.page = 0;
      state.lastPage = null;
      state.loading = [];
      state.appeals = [];
    }
  }
});

export const actions = {
  ...AppealsSlice.actions,
  getChannels,
  getAppeals
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export default AppealsSlice.reducer;
