import React from "react";

import { Icon, Tooltip, ScrollBox } from "@omnichat/arm_ui_kit";

import {
  Input,
  Checkbox,
  DropdownButton,
  MenuOption
} from "../../../../Components";
import RepliesItem from "./RepliesItem";
import { useDidUptateByDelayedSendQuery } from "../../../Common/Hooks/useDelayedSendQuery";

import * as s from "./styles.module.scss";

export interface IRepliesListProps {
  all: boolean;
  repliesList: Reply[];
  onNextPage: () => void;
  onSelect: (id: number) => void;
  onSelectAll: () => void;
  onSearch: (query?: string) => void;
  onRemove: () => void;
  onChangeCategory: () => void;
  onEdit: (id: number) => void;
  onAdd: () => void;
}

export interface Reply {
  id: number;
  text: string;
  title: string;
  category: string;
  checked: boolean;
  disabled: boolean;
  attachmentsCount: number;
}

/**
 * Раздел списка шаблонов
 */
const RepliesList = ({
  all,
  onNextPage,
  onSelect,
  onSelectAll,
  onChangeCategory,
  onEdit,
  onSearch,
  onRemove,
  onAdd,
  repliesList = []
}: IRepliesListProps): JSX.Element => {
  const { inputText, onInputQuery } = useDidUptateByDelayedSendQuery({
    cb: onSearch
  });

  const repliesListCount = repliesList.filter((item) => item.checked).length;

  return (
    <div className={s["replies"]}>
      <div className={s["repliesHeader"]}>
        <div className={s["repliesHeaderBtnWrap"]}>
          <h4 className={s["repliesTitle"]}>Шаблоны ответов</h4>
          <Tooltip
            content="Добавить шаблон"
            position="left"
            modifiers={{ offset: 10 }}
          >
            <div className={s["repliesAdd"]} onClick={onAdd}>
              <Icon
                color={"blue"}
                name={"plus_bold"}
                width="18px"
                height="18px"
              />
            </div>
          </Tooltip>
        </div>

        <div>
          <Input
            placeholder="Введите название шаблона"
            onChange={(value) => onInputQuery(value)}
            value={inputText}
          />
        </div>
        <div className={s["repliesControl"]}>
          <Checkbox
            caption="Выбрать все"
            captionPosition="end"
            checked={all}
            onChange={onSelectAll}
            disabled={!repliesList.length}
          />

          <DropdownButton
            disabled={!repliesListCount}
            text="Действия"
            anchorTransformPosition="right"
            menuItems={[
              {
                id: "changeCategoryTemplate",
                content: <MenuOption text="Перенести в категорию" />,
                onClick: onChangeCategory
              },
              {
                id: "removeTemplate",
                content: <MenuOption text="Удалить шаблон" />,
                onClick: onRemove
              }
            ]}
            extraSX={{
              button: {
                btnWrap: {
                  height: "26px",
                  width: "102px"
                }
              }
            }}
          />
        </div>
      </div>

      {repliesList?.length ? (
        <div className={s["repliesList"]}>
          <ScrollBox
            autoHeight
            hasScrollControlBackground
            onPaginationBottom={() => onNextPage()}
          >
            <div className={s["repliesListContentBlock"]}>
              <div className={s["marginContentBlock"]}></div>
              {repliesList.length &&
                repliesList.map((item, i) => (
                  <RepliesItem
                    key={i}
                    id={item.id}
                    disabled={all}
                    category={item.category}
                    checked={item.checked}
                    title={item.title}
                    text={item.text}
                    attachmentsCount={item.attachmentsCount}
                    onSelect={(id) => onSelect(id)}
                    onEdit={(id) => onEdit(id)}
                  />
                ))}
            </div>
          </ScrollBox>
        </div>
      ) : (
        <div className={s["repliesEmpty"]}>Нет совпадений</div>
      )}
    </div>
  );
};

export default RepliesList;
