import React, { FC } from "react";
import { Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";

export interface IChipProps extends MuiChipProps {}

const Chip: FC<IChipProps> = ({ ...props }) => {
  return <MuiChip {...props} />;
};

export default Chip;
