import React, { FC } from "react";
import { default as TableMui } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import * as s from "./style.module.scss";

interface Props {
  columns: ColumnType[];
  rows: RowType[];
  disableHead?: boolean;
  loadMore?: (index: number) => void;
  onRowClick?: (row: RowType) => void;
}

export type ColumnType = {
  field: string;
  headerName: string;
  customHeader?: (name: any) => React.ReactNode;
  customColumn?: (data: any) => React.ReactNode;
};

type RowType = {
  [key: string]: any;
};

const VirtuosoTableComponents = ({ onClick }): TableComponents<RowType> => ({
  // TODO при пагинации скролл сбрасывается наверх. Решения пока не найдено
  // Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
  //   <TableContainer {...props} ref={ref} />
  // )),
  Table: (props) => (
    <TableMui
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => (
    <TableRow {...props} onClick={() => onClick?.(_item)} />
  ),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  ))
});

const Table: FC<Props> = ({
  rows,
  columns,
  disableHead,
  loadMore,
  onRowClick
}) => {
  return (
    <TableVirtuoso
      className={s.container}
      data={rows}
      components={VirtuosoTableComponents({ onClick: onRowClick })}
      endReached={loadMore}
      fixedHeaderContent={
        disableHead
          ? null
          : () => (
              <TableRow>
                {columns.map(({ field, headerName, customHeader }, i) => (
                  <TableCell key={`head-${field}-${i}`} variant="head">
                    {customHeader ? customHeader(headerName) : `${headerName}`}
                  </TableCell>
                ))}
              </TableRow>
            )
      }
      itemContent={(i, row) =>
        columns.map(({ field, customColumn }) => (
          <TableCell key={`${field}-${i}`}>
            {customColumn ? customColumn(row[field]) : row[field]}
          </TableCell>
        ))
      }
    />
  );
};

export default Table;
