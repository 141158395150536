const NAMESPACE = "CategoryModal";

export const ACTIVE = `ACTIVE`;
export const NOT_ACTIVE = "NOT_ACTIVE";

export const GET_SKILLGROUPS = `${NAMESPACE}_GET_SKILLGROUPS`;
export const GET_CATEGORY = `${NAMESPACE}_GET_CATEGORY`;

export const SET_CATEGORY = `${NAMESPACE}_SET_CATEGORY`;
export const SET_SKILLGROUPS = `${NAMESPACE}_SET_SKILLGROUPS`;
export const SET_ALL = `${NAMESPACE}_SET_ALL`;
export const SET_NAME = `${NAMESPACE}_SET_NAME`;
export const SET_SELECTED_SKILLGROUPS = `${NAMESPACE}_SET_SELECTED_SKILLGROUPS`;

export const SAVE_CATEGORY = `${NAMESPACE}_SAVE_CATEGORY`;
export const REMOVE_CATEGORY = `${NAMESPACE}_REMOVE_CATEGORY`;

export const RESET = `${NAMESPACE}_RESET`;
