import React, { FC, memo } from "react";

import SettingsListItem from "../../../../Components/SettingsListItem";
import { Role } from "../../Roles.d";
import { ADMIN_ROLE_ID } from "../../const";

interface Props {
  current: number;
  list: Role[];
  onEdit?: (id: string) => void;
  onSelect?: (id: string) => void;
}

const RolesList: FC<Props> = ({ current = 0, list, onEdit, onSelect }) => (
  <>
    {list.map(({ id, name }) => (
      <SettingsListItem
        key={id}
        id={id}
        caption={name}
        editable={false}
        isActive={Number(current) === id}
        onSelect={onSelect}
        onEdit={onEdit}
      />
    ))}
  </>
);

export default memo(RolesList);
