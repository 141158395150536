import { fork } from "redux-saga/effects";

import {
  getAllDataSaga,
  nextTemplateModalCategoryPageSaga
} from "./getAllData.saga";
import getReplyDataSaga from "./getReplyData.saga";
import sendRepliesDataSaga from "./sendRepliesData.saga";

export default function*() {
  yield fork(getReplyDataSaga);
  yield fork(sendRepliesDataSaga);
  yield fork(getAllDataSaga);
  yield fork(nextTemplateModalCategoryPageSaga);
}
