import { createSlice } from "@reduxjs/toolkit";
import { EDOWNLOAD_STATUS } from "../../../ReactFeatures/Common/Enums/LoadingData.enums";
import { AutorepliesSettingsTypes } from "../Interfaces";

export const AutorepliesSettingsInitialState: AutorepliesSettingsTypes.State = {
  status: EDOWNLOAD_STATUS.IDLE,
  data: null,
};

const dialogSlice = createSlice({
  name: "autoreplies",
  initialState: AutorepliesSettingsInitialState,
  reducers: {
    setAutorepliesSettings: (state, { payload }) => {
      state.data = payload || [];
    },
    resetAutorepliesSettings: (state) => {
      state.data = AutorepliesSettingsInitialState.data;
    },
  },
});

export const { setAutorepliesSettings, resetAutorepliesSettings } =
  dialogSlice.actions;

export default dialogSlice.reducer;
