import React, { FC, memo, MouseEventHandler } from "react";
import { Tooltip } from "@omnichat/arm_ui_kit";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";

interface Props {
  disabled?: boolean;
  onClick: MouseEventHandler;
}

const DeleteRoleButton: FC<Props> = ({ disabled, onClick }) => (
  <Tooltip
    content="Удалить роль"
    position="bottom"
    isDisabled={disabled}
    modifiers={{ offset: 5 }}
  >
    <IconButton
      disabled={disabled}
      onClick={onClick}
      aria-label="settings"
      color="primary"
      sx={{
        cursor: "pointer"
      }}
    >
      <Delete
        sx={{
          width: "24px",
          height: "24px"
        }}
      />
    </IconButton>
  </Tooltip>
);

export default memo(DeleteRoleButton);
