import { useEffect, useState, useCallback } from "react";

import { httpRequest } from "../../ReactCore";
import { VipClientData } from "./Components/VipClient";

export interface SkillGroupData {
  label: string;
  value: number
}

export const useSkillGroups = () => {
  const [loading, setLoading] = useState(false);
  const [skillGroups, setSkillGroups] = useState<SkillGroupData[]>([]);

  useEffect(() => {
    setLoading(true);
    httpRequest("GET", `bff/dictionary/skillgroups`)
      .then((response) => {
        if (response?.success) {
          setSkillGroups(
            response.data?.map((skillgroup) => ({
              label: skillgroup.name,
              value: skillgroup.id
            }))
          );
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return { loading, skillGroups };
};

export const useVipClients = () => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<VipClientData[]>([]);

  const createClient = useCallback(
    async ({ login, skillGroupId }: VipClientData) => {
      setLoading(true);
      await httpRequest("POST", "bff/settings/vip-clients/create", {
        body: { login, skillGroupId }
      })
        .then(
          (client) =>
            client?.success &&
            setClients((prevClients) => prevClients.concat(client.data))
        )
        .finally(() => setLoading(false));
    },
    []
  );

  const editClient = useCallback((body: VipClientData) => {
    setLoading(true);
    httpRequest("POST", "bff/settings/vip-clients/edit", { body })
      .then(
        (client) =>
          client?.success &&
          setClients((prevClients) =>
            prevClients.map((c) => (c.id === client.data?.id ? client.data : c))
          )
      )
      .finally(() => setLoading(false));
  }, []);

  const deleteClient = useCallback((data: VipClientData) => {
    setLoading(true);
    httpRequest("DELETE", `bff/settings/vip-clients/delete/${data.id}`)
      .then(
        (res) =>
          res?.success &&
          setClients((prevClients) =>
            prevClients.filter((client) => client.id !== data.id)
          )
      )
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    httpRequest("GET", "bff/settings/vip-clients/list")
      .then((res) => res?.success && setClients(res.data?.content))
      .finally(() => setLoading(false));
  }, []);

  return { loading, clients, createClient, deleteClient, editClient };
};
