import { NAMESPACE, defaultFormData } from "../Consts";
import { MoveAppealModalInitialState } from "../MoveAppealModal.initialData";
import { createSlice } from "@reduxjs/toolkit";

/**
 * Редьюсер для МО Перемещения обращения.
 *
 * @param state Текущее состояние.
 * @param action Экшн.
 */
const MoveAppealModalSlice = createSlice({
  name: NAMESPACE,
  initialState: MoveAppealModalInitialState,
  reducers: {
    setMoveAppealModalFormData(state, action) {
      state.formData = action.payload;
    },
    cleanMoveAppealModalFormData(state) {
      state.formData = defaultFormData;
    }
  }
});

export const {
  setMoveAppealModalFormData,
  cleanMoveAppealModalFormData
} = MoveAppealModalSlice.actions;

export default MoveAppealModalSlice.reducer;
