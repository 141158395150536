import { isEmpty } from "@omnichat/arm_ui_kit";

export const getPreparedTransferSkillGroupList = (data: any): any => {
  if (isEmpty(data)) return false;
  const newArray = data.map(el => {
    return {
      value: "g" + el.id,
      label: el.name,
      online: el.onlineUsersCount ? true : false,
      count: el.onlineUsersCount,
      targetValue: {
        userId: null,
        groupId: el.id
      }
    };
  });
  return newArray;
};

export const getPreparedTransferUserList = (data: any): any => {
  if (isEmpty(data)) return false;
  let newArray = data.filter(el => {
    return el.user.id !== core.user.id;
  });
  newArray = newArray.map(el => {
    const label = `${el.user.secName} ${el.user.name}`;

    return {
      value: "u" + el.user.id + "g" + el.skillGroup.id,
      label,
      online: el.user.networkStatus === "ONLINE" ? true : false,
      group: el.skillGroup.name,
      avatar: !isEmpty(el.user.photo) ? `${FS_URL}/crop/${el.user.photo}` : "",
      targetValue: {
        userId: el.user.id,
        groupId: el.skillGroup.id
      }
    };
  });
  return newArray;
};
