import { createAction } from "@reduxjs/toolkit";
import {
  GET_THEMES,
  GET_THEMES_FIELDS,
  SET_THEMES_FIELDS,
  ADD_APPEAL_THEMES_TO_DIALOG,
  SET_SELECTED_FIELDS_VALUES,
  DELETE_THEME,
  EDIT_THEME,
  CANCEL_EDIT,
  SAVE_THEME,
  SET_REGION,
  SET_SERVICE,
  RESET_DATA,
  VALIDATE_RESULT,
  SET_SERVICE_ENABLED,
  GET_THEMES_FIELDS_LOADED
} from "../Consts";

/**
 * Получаем список полей для формирования тематик.
 */
export const getThemesFields = createAction<any>(GET_THEMES_FIELDS);

export const setThemesFieldsLoaded = createAction(GET_THEMES_FIELDS_LOADED);

/**
 * Устанавливаем список полей для формирования тематик.
 */
export const setThemesFields = createAction<any>(SET_THEMES_FIELDS);

export const setAppealThemes = createAction<any>(ADD_APPEAL_THEMES_TO_DIALOG);

export const setServiceTag = createAction<{ id: number }>(SET_SERVICE);

export const setRegion = createAction<{ id: number }>(SET_REGION);

export const setSelectedFieldsValues = createAction<any>(
  SET_SELECTED_FIELDS_VALUES
);

export const getThemes = createAction<{ appealId: number }>(GET_THEMES);

export const saveTheme = createAction(SAVE_THEME);

export const cancelEdit = createAction(CANCEL_EDIT);

export const editTheme = createAction<{ id: number }>(EDIT_THEME);

export const deleteTheme = createAction<{ id: number }>(DELETE_THEME);

export const validateResult = createAction(VALIDATE_RESULT);

export const resetData = createAction(RESET_DATA);

export const setServiceEnabled = createAction<boolean>(SET_SERVICE_ENABLED);
