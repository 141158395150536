import React, { FC, MouseEventHandler } from "react";
import c from "classnames";
import { SvgIconComponent } from "@mui/icons-material";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Typography,
  SxProps,
  Theme
} from "@mui/material";

import * as s from "./style.module.scss";

/**
 * Свойства компонента Button.
 */
export interface IButtonProps
  extends Pick<
    MuiButtonProps,
    "aria-haspopup" | "aria-controls" | "aria-expanded"
  > {
  /** Тип Button */
  type?: "bare" | "default" | "fill" | "underline" | "closeModal";
  /** Размер Button */
  size?: string;
  /** Текст Button */
  text?: string;
  /** Тема Button */
  theme?: "bare" | "green" | "red" | "blue" | "disabled" | "default";
  /** Блокировка Button */
  disabled?: boolean;
  /** индикатор загрузки */
  loading?: boolean;
  /** Button onClick function */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Button onMouseEnter function */
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  /** спец-иконка из @mui/icons-material */
  IconMUI?: SvgIconComponent;
  /** кастомная svg-иконка без пропсов */
  IconCustom?: FC;
  /** Дополнительный стиль Button */
  variant?: "text" | "outlined" | "contained" | "dropdown" | "manage";
  /** Дополнительный стиль Button */
  extraClass?: (string | Record<string, unknown>)[];
  /** Дополнительный SX-стили */
  extraSX?: {
    btnWrap?: SxProps<Theme>;
    text?: SxProps<Theme>;
  };
  /** Дополнительный цвет svg */
  iconColor?: string;
  /** Позиция иконки */
  iconPosition?: "left" | "right";
  /** Id элемента */
  id?: string;
}

/**
 * Компонент Button.
 */
const Button: FC<IButtonProps> = ({
  iconColor,
  type = "default",
  size = "default",
  text,
  theme = "default",
  disabled,
  loading,
  onClick,
  onMouseEnter,
  IconMUI,
  IconCustom,
  variant,
  extraClass = [],
  extraSX = { btnWrap: {}, text: {} },
  iconPosition = "left",
  id,
  ...props
}) => {
  /** Переменная, отвечающая за компоновку стилей */
  const cRoot = c(
    s["button"],
    s[`type__${type}`],
    s[`theme__${theme}`],
    s[`size__${size}`],
    {
      [s["disabled"]]: disabled,
      [s["withIcon"]]: IconMUI || IconCustom, // warning
      [s["withText"]]: text // warning
    },
    ...extraClass
  );

  return (
    <MuiButton
      onClick={loading ? () => {} : onClick}
      onMouseEnter={loading ? () => {} : onMouseEnter}
      className={cRoot}
      disabled={disabled}
      id={id}
      variant={variant || null}
      startIcon={IconMUI && iconPosition === "left" && <IconMUI />}
      endIcon={IconMUI && iconPosition === "right" && <IconMUI />}
      sx={extraSX?.btnWrap}
      {...props}
    >
      {IconCustom && iconPosition === "left" && <IconCustom />}
      {text && (
        <Typography className={s["content"]} sx={extraSX?.text}>
          {text}
        </Typography>
      )}
      {IconCustom && iconPosition === "right" && <IconCustom />}
    </MuiButton>
  );
};

export default Button;
