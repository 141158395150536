import { isEmpty } from "@omnichat/arm_ui_kit";

import { ISelectOption } from "../../Components/Select/Select.d";
import { sendDTO, getDTO, Category } from "./SkillGroupModal.d";
import { IFormData } from "./SkillGroupModal.schema";

export const mapDtoToForm = (data: getDTO): IFormData => {
  const {
    mainBot,
    mrfs,
    channels,
    regions,
    skillGroup,
    studiedBots,
    ...formData
  } = data || {};

  return (
    {
      ...formData,
      skillGroup: {
        ...skillGroup,
        delayReappealToBot:
          skillGroup.delayReappealToBot && skillGroup.delayReappealToBot / 60,
        appointToBot: Boolean(skillGroup?.appointToBot),
        autoStatusEnabled: Boolean(skillGroup.timeClose || skillGroup.timeWait),
        timeLimitEnabled: Boolean(skillGroup.timeLimit)
      },
      mrfs: mapCatalogToSelectOption(mrfs),
      regions: mapCatalogToSelectOption(regions),
      channels: mapCatalogToSelectOption(channels),
      studiedBots: mapCatalogToSelectOption(studiedBots),
      mainBot: mainBot ? [{ value: mainBot.id, label: mainBot.name }] : []
    } || null
  );
};

export const mapFormToDto = (formData: IFormData): sendDTO => {
  const {
    skillGroup,
    mainBot,
    channels,
    regions,
    mrfs,
    studiedBots,
    ...data
  } = formData;

  const { autoStatusEnabled, timeLimitEnabled, ...skillGroupData } = skillGroup;
  return {
    ...data,
    channels: mapSelectOptionToNumberArray(channels),
    regions: mapSelectOptionToNumberArray(regions),
    mrfs: mapSelectOptionToNumberArray(mrfs),
    studiedBotsId: mapSelectOptionToNumberArray(studiedBots),
    mainBotId:
      skillGroup.appointToBot && !isEmpty(mainBot) ? mainBot[0].value : null,
    skillGroup: {
      ...skillGroupData,
      timeWait: !autoStatusEnabled ? 0 : skillGroup.timeWait,
      timeClose: !autoStatusEnabled ? 0 : skillGroup.timeClose,
      timeLimit: !timeLimitEnabled ? 0 : skillGroup.timeLimit,
      delayReappealToBot:
        skillGroup.appointToBot && skillGroup.delayReappealToBot
          ? skillGroup.delayReappealToBot * 60
          : null,
      afterBotDisclaimer: skillGroup.appointToBot
        ? skillGroupData.afterBotDisclaimer
        : null,
      toBotPercent: skillGroup.appointToBot
        ? skillGroupData.toBotPercent
        : null,
      appointToBot: skillGroup.appointToBot ? 1 : 0
    }
  };
};

export const mapCatalogToSelectOption = (
  catalog: Category[]
): ISelectOption[] => {
  return catalog.map((category) => ({
    value: category.id,
    label: category.name
  }));
};

export const mapSelectOptionToNumberArray = (
  options: ISelectOption[]
): number[] => options.map((option) => Number(option.value)) || [];

export const getHoursValue = (time) => (time > 0 ? Math.floor(time / 60) : 0);

export const getMinutesValue = (time) => (time > 0 ? time % 60 : 0);

export const getChangedTimeInSumMinutes = (hours, minutes) =>
  hours * 60 + minutes;
