import { IWsNewMessage } from "../ReactFeatures/Dialog/Interfaces";
import { ChatTypes, IPreparedNewMessageData, IChatInitAppeals } from "./Chat";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { YOU } from "../ReactFeatures/Common/Consts/Text.consts";
import {
  getNewMessageCombutionTime,
  getChatInitCombutionTime
} from "../Adapters/CombutionTime";

import {
  addPrefixToMessage,
  addPrefixToEmailSubject,
  getMessageType
} from "../ReactFeatures/Common/Utils/Dialog.utils";

const getPreparedClientData = (clientData) => {
  let clientPoto = "";
  if (clientData.photo) {
    clientPoto = FS_URL + "/thumb/thumb/" + clientData.photo;
  }
  return {
    id: clientData.id,
    name: clientData.name,
    photo: clientPoto
  };
};

export const mapNewMessageData = (
  data: IWsNewMessage
): IPreparedNewMessageData => {
  let prefix =
    data.message.user && data.message.user.id !== core.user.id
      ? `${data.message.user.secName} ${data.message.user.name}: `
      : YOU;
  return {
    appealId: data.appealId,
    dialogId: data.dialogId,
    message: {
      type: getMessageType(data.message.mediaType),
      text: addPrefixToMessage(data.message, prefix),
      additionalData: {
        ...data.message?.additionalData,
        subject: addPrefixToEmailSubject(data.message, YOU)
      }
    },
    time: +data.message.date * 1000,
    unread: data.unread,
    withoutTheme: !!data.noThemes,
    combutionLine: getNewMessageCombutionTime(data),
    client: getPreparedClientData(data.clientProfile)
  };
};

export const mapDialogPreviewDataFromChatInit = (data: IChatInitAppeals) => {
  let newArray = [];

  if (data?.map && !isEmpty(data)) {
    newArray = data.map((el) => ({
      appealId: el.id,
      dialogId: el.dialogId,
      message: {
        type: getMessageType(el.lastMessage.mediaType),
        text: addPrefixToMessage(el.lastMessage, YOU),
        additionalData: {
          ...el.lastMessage?.additionalData,
          subject: addPrefixToEmailSubject(el.lastMessage, YOU)
        }
      },
      time: +el.lastMessage.date * 1000,
      unread: el.unreadCount,
      withoutTheme: !!el.noThemes,
      combutionLine: getChatInitCombutionTime(el),
      client: getPreparedClientData(el.client)
    }));
  }

  return newArray;
};

export const getUpdatedDialogListData = (
  currentData: ChatTypes.State.dialogs,
  newData: IPreparedNewMessageData[]
) => {
  let tempDialogs = [...currentData];

  newData.forEach((el) => {
    let foundValue = getIndexElementByKey(currentData, "dialogId", el.dialogId);
    if (tempDialogs.length && ~foundValue) {
      tempDialogs[foundValue] = {
        ...tempDialogs[foundValue],
        ...el
      };
    } else {
      tempDialogs.push(el);
    }
  });

  return tempDialogs;
};

export const setUnreadList = (dialogList: any[]) => {
  return dialogList?.reduce((acc, d) => {
    acc[d.dialogId] = d.unreadCount;
    return acc;
  }, {});
};

export const removeFromUnreadList = (unreadList, id: number) => {
  const newList = { ...unreadList };
  newList[id] = 0;
  return newList;
};

export const updateUnreadList = (unreadList, id: number, count: number) => {
  const newList = { ...unreadList };
  newList[id] = count;
  return newList;
};

export const getWithRemovedDialogPreview = (
  currentData: ChatTypes.State.dialogs,
  removingDialogs
) => {
  let tempDialogs = [...currentData];
  if (tempDialogs.length && removingDialogs.length) {
    removingDialogs.forEach((el) => {
      let foundValue = getIndexElementByKey(tempDialogs, "dialogId", el);
      if (~foundValue) {
        tempDialogs.splice(foundValue, 1);
      }
    });
  }
  return tempDialogs;
};

/**
 * Вернет массив диалогов, с проставленным флагом withoutTheme: true для
 * диалогов с идентификаторами из полученного массива.
 *
 * @param {ChatTypes.State.dialogs} currentData Текущие данные.
 * @param {any} newData Данные с бэка.
 */
export const getWithUpdatedIsWaitingOn = (
  currentData: ChatTypes.State.dialogs,
  newData
) => {
  let tempDialogs = [...currentData];

  if (!isEmpty(newData?.dialogIds)) {
    for (var i = 0; i < newData.dialogIds.length; i++) {
      let foundValue = getIndexElementByKey(
        currentData,
        "dialogId",
        newData.dialogIds[i]
      );
      if (foundValue > -1) {
        tempDialogs[foundValue] = {
          ...tempDialogs[foundValue],
          withoutTheme: true
        };
      }
    }
  }

  return tempDialogs;
};

export const getIndexElementByKey = (array, searchedKey, searchedValue) => {
  return array.findIndex((obj) => obj[searchedKey] === searchedValue);
};

export const hasAvalibleDialog = (dialogList, dialogId) => {
  return ~getIndexElementByKey(dialogList, "dialogId", dialogId);
};

export const getUrlParams = () => {
  const resDialogId = /dialog_id=(?<dialogId>[0-9]+)/gim.exec(location.search);
  const dialogId = +resDialogId?.groups?.dialogId || undefined;
  const resAppealId = /appeal_id=(?<appealId>[0-9]+)/gim.exec(location.search);
  const appealId =
    resAppealId && resAppealId.groups.appealId
      ? +resAppealId.groups.appealId
      : undefined;

  return {
    dialogId,
    appealId
  };
};

export const setDialogToUrl = (dialogId: number, appealId: number) => {
  core.nav.do({
    url: `/msg?sec=chat&project_id=1&dialog_id=${dialogId}&appeal_id=${appealId}`
  });
};

export const resetDialogFromUrl = () => {
  if (core.nav.url.sec !== "chat") {
    return;
  }
  core.nav.do({
    url: `/msg?sec=chat&project_id=1`
  });
};

/**
 * Обработчик отображения компонента для просмотра изображения.
 *
 * @param {string} imageSrc Ссылка на изображение.
 */
export const handleShowImageViewer = (
  imageSrc: string,
  background?: string
) => {
  window?.imageViewer?.handleShowImage(imageSrc, 2000, background);
};
