import { takeEvery, put, select } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { ThematicModalTypes } from "../Interfaces";

import { sendThemesData } from "../Actions/ThematicInfoPanel.action";
import { REGIONAL_SERVICE_ID } from "../Consts";
import { PayloadAction } from "@reduxjs/toolkit";

type ThemesPayload = {
  appealId: string;
  onClose: () => void;
};

/**
 * Сага закрепления тематик за обращением
 */
function* attachThemesData({ payload }: PayloadAction<ThemesPayload>) {
  const { appealId, onClose } = payload;
  const thematicModalState: ThematicModalTypes.State = yield select(
    ({ ThematicModal }) => ThematicModal
  );

  const {
    appealThemes,
    selectedRegion,
    activeService,
    serviceEnabled
  } = thematicModalState;

  let body = {
    appealId
  };

  if (serviceEnabled) {
    body["serviceTagId"] = activeService;
    if (selectedRegion?.value && activeService === REGIONAL_SERVICE_ID) {
      body["regionId"] = selectedRegion.value;
    }
  }

  const preparedThemes = appealThemes.map(({ fields, themeNumber }) => {
    return fields.map((theme) => ({
      themeNumber,
      fieldId: theme.fieldId,
      valueId: !["TEXTAREA", "INPUT"].includes(theme.fieldType)
        ? Number(theme.valueId)
        : undefined,
      textValue: ["TEXTAREA", "INPUT"].includes(theme.fieldType)
        ? theme.valueId
        : undefined
    }));
  });

  body["values"] = preparedThemes.length ? preparedThemes : null;

  const response = yield httpRequest("PUT", `bff/chat/thematics`, {
    body
  });

  if (response?.success) {
    onClose();
  }
}

export default function*() {
  yield takeEvery([sendThemesData], attachThemesData);
}
