import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";

import { sendAutorepliesSettings } from "../Actions/Autoreplies.actions";
import { setAutorepliesSettings } from "../Reducers/Autoreplies.reducer";

function* putAutorepliesSettingsSaga({ payload }) {
  const response = yield httpRequest("PUT", `bff/settings/autoreplies`, {
    body: payload
  });
  let textInformer;
  if (response?.success) {
    textInformer = "Изменения успешно сохранены";
    yield put(setAutorepliesSettings(response?.data || []));
  } else {
    textInformer = "Произошла ошибка. Попробуйте еще раз.";
  }
  const informer = new Informer(textInformer, 1500);
  informer.show();
}

export default function* () {
  yield takeEvery([sendAutorepliesSettings], putAutorepliesSettingsSaga);
}
