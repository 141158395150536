import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import {
  setClassifierFieldsList,
  getClassifierFields,
  GET_CLISSIFIER_FIELDS,
  startLoading,
  finishLoading
} from "../Store/Classifiers.reducer";

function* getClassifierFieldsSaga(action) {
  yield put(startLoading(GET_CLISSIFIER_FIELDS));
  const classifierId = action.payload;

  try {
    const response = yield httpRequest(
      "GET",
      `bff/settings/classifier/${classifierId}/fields`
    );

    if (response?.success) {
      yield put(setClassifierFieldsList(response.data));
    }
  } catch (e) {
    console.error("Saga: getCategoriesSaga error", e);
  } finally {
    yield put(finishLoading(GET_CLISSIFIER_FIELDS));
  }
}

export default function* () {
  yield takeEvery([getClassifierFields], getClassifierFieldsSaga);
}
