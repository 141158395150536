import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import {
  getClassifierFieldValues,
  GET_CLISSIFIER_FIELD_VALUES,
  setClassifierFieldValuesList,
  startLoading,
  finishLoading
} from "../Store/Classifiers.reducer";

function* getClassifierFieldValuesSaga(action) {
  yield put(startLoading(GET_CLISSIFIER_FIELD_VALUES));
  const fieldId = action.payload;
  try {
    const response = yield httpRequest(
      "GET",
      `bff/settings/classifier/fields/${fieldId}/values`
    );

    if (response?.success) {
      yield put(
        setClassifierFieldValuesList({ fieldId, values: response.data })
      );
    }
  } catch (e) {
    console.error("Saga: getClassifierFieldValuesSaga error", e);
  } finally {
    yield put(finishLoading(GET_CLISSIFIER_FIELD_VALUES));
  }
}

export default function* () {
  yield takeEvery([getClassifierFieldValues], getClassifierFieldValuesSaga);
}
