import { isEmpty } from "@omnichat/arm_ui_kit";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { put, takeEvery } from "redux-saga/effects";
import {
  getReplyTemplatesByText,
  setReplyTemplatesByTextError,
  setReplyTemplatesByTextFulfilled,
  setReplyTemplatesByTextPending
} from "../Actions/Dialog.actions";
import { store } from "../../../ReactCore";
import { getAsyncFoundReplyTemplates } from "../Dialog.selectors";
import { IPageableData } from "../../Common/Interfaces/LoadingData.interfaces";
import { RepliesTemplateEditModalTypes } from "../../RepliesTemplate/repliesEditModal";
import { nanoid } from "nanoid";

function* fullTextSearchOfReplyTemplatesSaga(action) {
  try {
    const { data: currentData } = yield getAsyncFoundReplyTemplates(
      store.getState()
    );

    if (
      (!currentData?.paging.last || action.payload.page === 0) &&
      !isEmpty(action.payload)
    ) {
      const { query, page, appealId, postAction } = action.payload;

      yield put(setReplyTemplatesByTextPending());

      const response = yield httpRequest(
        "GET",
        `bff/chat/dialog/0/replies/${appealId}/`,
        {
          query: {
            query: query,
            page: page,
            pageSize: CONTEXT_SEARCH_PAGE_SIZE,
            categoryId: 0
          }
        }
      );

      if (response?.success) {
        postAction && postAction();

        const content = !isEmpty(response.data?.content)
          ? response.data?.content?.map((item) => {
              item.id = nanoid(3);
              return item;
            })
          : [];
        delete response.data?.content;
        const pageableData: IPageableData<
          RepliesTemplateEditModalTypes.IReply[]
        > = {
          content: content,
          paging: response.data
        };

        if (page === 0 || isEmpty(currentData?.content)) {
          yield put(setReplyTemplatesByTextFulfilled(pageableData));
        } else if (!isEmpty(currentData?.content)) {
          pageableData.content = [...currentData.content, ...content];

          yield put(setReplyTemplatesByTextFulfilled(pageableData));
        }
      }
    }
  } catch (error) {
    yield put(setReplyTemplatesByTextError(error));
    console.log("searchReplyTemplatesByTextError", error);
  }
}

export default function* () {
  yield takeEvery(
    [getReplyTemplatesByText],
    fullTextSearchOfReplyTemplatesSaga
  );
}
