import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";
import { store } from "../../ReactCore";
import RepliesTemplate from "./Providers/RepliesTemplate.provider";
import ThemeProvider from "../../Components/ThemeProvider";

const REACT_ROOT_SELECTOR = "#settings_replies";

export default function initRepliesSettings() {
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  ReactDom.render(
    <ThemeProvider>
      <Provider store={store}>
        <RepliesTemplate />
      </Provider>
    </ThemeProvider>,
    selector
  );
}
