import React from "react";

import * as s from "./SectionMenuTitle.module.scss";

interface SectionMenuTitleProps {
  title: string;
}

/**
 * Заколовок для различных разделов Омничата
 */
const SectionMenuTitle = ({ title }: SectionMenuTitleProps): JSX.Element => (
  <div className={s["settingsSectionWrapper"]}>
    <div className={s["settingsSectionHeader"]}>{title}</div>
  </div>
);

export default SectionMenuTitle;
