import { takeEvery, put, select } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { RepliesListTypes } from "../../RepliesList/Interfaces";
import { CategoryForRepliesModalTypes } from "../Interfaces";

import {
  getCategories,
  getNextPage,
  setCategories,
  saveReplies
} from "../Actions/CategoryForRepliesModal.action";

import { getReplies } from "../../RepliesList/Actions/RepliesList.action";
import { getCategory } from "../../CategoriesList/CategoriesList.selectors";

function* getCategoriesData(action) {
  const { page }: CategoryForRepliesModalTypes.State = yield select(
    ({ CategoryForRepliesModal }) => CategoryForRepliesModal
  );

  const response = yield httpRequest("GET", `bff/settings/categories`, {
    query: {
      page
    }
  });

  if (response?.success) {
    yield put(setCategories(response.data?.content));
  }
}

function* saveRepliesData(action) {
  const { id, onSuccess, onError } = action.payload;
  const { all, selected }: RepliesListTypes.State = yield select(
    ({ RepliesList }) => RepliesList
  );
  const currentCategoryId = yield select(getCategory);
  const query = { categoryId: currentCategoryId };

  const response = yield httpRequest("PUT", `bff/settings/replies`, {
    query,
    body: {
      ids: selected,
      all,
      category: id
    }
  });

  if (response?.success) {
    yield put(getReplies());
    onSuccess();
  } else {
    onError();
  }
}

export const getCategoriesSaga = function* () {
  yield takeEvery([getCategories, getNextPage], getCategoriesData);
};

export const saveRepliesSaga = function* () {
  yield takeEvery([saveReplies], saveRepliesData);
};
