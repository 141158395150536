/**
 * Вернет высоту скролла контейнера потомков.
 *
 * @param {HTMLElement | null} element Элемент.
 */
export function getScrollHeight(element: HTMLElement | null) {
  return element ? element.scrollHeight + "px" : "";
}

/**
 * Прокинет максимальную высоту элементу.
 *
 * @param {string} height Высота.
 * @param {HTMLElement | null} element Элемент.
 */
export function setMaxHeight(
  height: string | null,
  element: HTMLElement | null
) {
  if (element) {
    element.style.maxHeight = height;
  }
}
