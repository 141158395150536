export const RESET_DATA = "RESET_DATA";

export const GET_THEMES = "GET_THEMES";
export const GET_THEMES_FIELDS = "GET_THEMES_FIELDS";
export const GET_THEMES_FIELDS_LOADED = "GET_THEMES_FIELDS_LOADED";
export const SET_THEMES_FIELDS = "SET_THEMES_FIELDS";

export const ADD_APPEAL_THEMES_TO_DIALOG = "ADD_APPEAL_THEMES_TO_DIALOG";
export const ADD_SERVICE_REGION_TO_DIALOG = "ADD_SERVICE_REGION_TO_DIALOG";

export const SET_SELECTED_FIELDS_VALUES = "SET_SELECTED_FIELDS_VALUES";

export const SET_SERVICE = "SET_SERVICE";
export const SET_REGION = "SET_REGION";

export const EDIT_THEME = "EDIT_THEME";
export const CANCEL_EDIT = "CANCEL_EDIT";
export const DELETE_THEME = "DELETE_THEME";
export const SAVE_THEME = "SAVE_THEME";

export const SEND_THEMES_DATA = "SEND_THEMES_DATA";

export const ADD_APPEAL_ORIGIN_THEMES = "ADD_APPEAL_ORIGIN_THEMES";
export const ADD_APPEAL_ORIGIN_SERVICES = "ADD_APPEAL_ORIGIN_SERVICES";

export const VALIDATE_RESULT = "VALIDATE_RESULT";

export const SET_SERVICE_ENABLED = "SET_SERVICE_ENABLED";
export const FEDERAL_SERVICE_ID = 1;
export const REGIONAL_SERVICE_ID = 2;
