import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/AgentCapacityModal.reducer";

export function* getAgentCapacityData({
  payload: { agentId }
}: PayloadAction<{
  agentId: number;
}>) {
  try {
    const res = yield httpRequest("GET", `bff/dashboard/agents/${agentId}`);
    if (res?.success) {
      yield put(
        actions.setAgentCapacityData({
          appealLimit: res.data?.appeals_limit || null,
          skillGroups: res.data?.skill_groups || null
        })
      );
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function*() {
  yield takeEvery([actions.getAgentData], getAgentCapacityData);
}
