import React, { FC } from "react";

import * as s from "./SectionTitle.module.scss";

export interface ISectionTitleProps {
  title: string;
}

/**
 * Заколовок для различных разделов Омничата
 */
const SectionTitle: FC<ISectionTitleProps> = ({ title }) => (
  <div>
    <div className={s["titleText"]}>{title}</div>
  </div>
);

export default SectionTitle;
