import * as React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../ReactCore/store/types";

import Modal from "../../../arm/Wrappers/Modal";
import Informer from "../../../arm/Wrappers/Informer.js";

import { CommonModal } from "@omnichat/arm_ui_kit";

import { CategoryForRepliesModal } from "../../CategoryForRepliesModal";

import { RepliesTemplateEditModal } from "../../RepliesTemplateEditModal";

import {
  getPreparedReplies,
  getQuery,
  getAllCheckboxValue
} from "../RepliesList.selectors";
import * as actions from "../Actions/RepliesList.action";

import RepliesList from "../Components/RepliesList/RepliesList";
/**
 * Модель собственных свойств компонента.
 */
interface IOwnProps {}

/**
 * Модель свойств, полученных из store.
 */
interface IStateProps {
  all: boolean;
  replies: any;
  query: string;
}

/**
 * Модель экшенов.
 */
interface IDispatchProps {
  getReplies: () => void;
  getNextPage: () => void;
  setAll: () => void;
  setQuery: (query: string) => void;
  setSelected: (id: number) => void;
  removeReplies: (onSuccess, onError) => void;
}

type RepliesListProviderProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * Провайдер фичи RepliesList.
 */
class RepliesListProvider extends React.PureComponent<
  RepliesListProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getReplies();
  }

  openChangeRepliesCategoryModal = () => {
    const modalId = `ChangeRepliesCategoryModal`;

    const modal = new Modal(modalId, 640, close => {
      const onSuccess = () => {
        const informer = new Informer("Данные успешно обновлены", 3000);
        informer.show();
        close();
      };
      const onError = () => {
        const informer = new Informer("Произошла ошибка", 3000);
        informer.show();
        close();
      };

      return (
        <CategoryForRepliesModal
          onSuccess={onSuccess}
          onError={onError}
          onCancel={close}
        />
      );
    });
    modal.open();
  };

  openRemoveRepliesModal = () => {
    const modalId = `RemoveRepliesModal`;

    const modal = new Modal(modalId, 640, close => {
      const onSuccess = () => {
        const informer = new Informer("Данные успешно обновлены", 3000);
        informer.show();
        close();
      };

      const onError = () => {
        const informer = new Informer("Произошла ошибка", 3000);
        informer.show();
        close();
      };

      const removeReplies = () => this.props.removeReplies(onSuccess, onError);

      return (
        <CommonModal
          onCancel={close}
          onConfirm={removeReplies}
          text="Выбранный шаблон будет удален. Хотите продолжить?"
          title="Подтверждение удаления"
        />
      );
    });
    modal.open();
  };

  editReply = (replyId?: number) => {
    const modalId = `RepliesTemplateEditModal`;

    const modal = new Modal(modalId, 600, close => {
      const onClose = () => {
        const informer = new Informer("Данные успешно обновлены", 3000);
        informer.show();
        close();
      };

      return (
        <RepliesTemplateEditModal closeModal={onClose} templateId={replyId} />
      );
    });
    modal.open();
  };

  addReply = () => {
    this.editReply();
  };

  render() {
    return (
      <>
        <RepliesList
          all={this.props.all}
          query={this.props.query}
          repliesList={this.props.replies}
          onSelect={this.props.setSelected}
          onSelectAll={this.props.setAll}
          onNextPage={this.props.getNextPage}
          onChangeCategory={this.openChangeRepliesCategoryModal}
          onRemove={this.openRemoveRepliesModal}
          onEdit={this.editReply}
          onAdd={this.addReply}
          onSearch={this.props.setQuery}
        />
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  all: getAllCheckboxValue(state),
  replies: getPreparedReplies(state),
  query: getQuery(state)
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
  getReplies: () => dispatch(actions.getReplies()),
  getNextPage: () => dispatch(actions.getNextPage()),
  setAll: () => dispatch(actions.setAll()),
  setQuery: query => dispatch(actions.setQuery(query)),
  setSelected: id => dispatch(actions.setSelected(id)),
  removeReplies: (onSuccess, onError) =>
    dispatch(actions.removeReplies({ onSuccess, onError }))
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepliesListProvider);

export default Connect;
