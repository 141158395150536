// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".users_profile_modal--OtApOxCU7RFhYP4yHBvc{width:550px}.users_profile_modal__buttons--Cqtppv7azXI0u_UENHOw{display:flex;justify-content:space-around}.users_profile_modal__required--huxrUPNlU6BI4i4OZpMV{color:var(--color-error-action-text)}.users_profile_modal__notification--EDYHDdlwqZANt8DR5Ex0{margin:15px 0 30px 0;color:var(--color-error-action-text)}.users_profile_modal--OtApOxCU7RFhYP4yHBvc .error--R3mXGL9PVVE2xQ_sqWQa{color:red}.users_profile_modal__yearOfBirth_Field--pbZShdgZE4tIS_pjD0XM{height:21px}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/UsersProfileModal/UsersProfileModal.module.scss"],"names":[],"mappings":"AAAA,2CACE,WAAA,CAEA,oDACE,YAAA,CACA,4BAAA,CAGF,qDACE,oCAAA,CAGF,yDACE,oBAAA,CACA,oCAAA,CAEF,wEACE,SAAA,CAGF,8DACE,WAAA","sourcesContent":[".users_profile_modal {\n  width: 550px;\n\n  &__buttons {\n    display: flex;\n    justify-content: space-around;\n  }\n\n  &__required {\n    color: var(--color-error-action-text);\n  }\n\n  &__notification {\n    margin: 15px 0 30px 0;\n    color: var(--color-error-action-text);\n  }\n  .error {\n    color: red;\n  }\n\n  &__yearOfBirth_Field {\n    height: 21px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var usersProfileModal = "users_profile_modal--OtApOxCU7RFhYP4yHBvc";
export var usersProfileModalButtons = "users_profile_modal__buttons--Cqtppv7azXI0u_UENHOw";
export var usersProfileModalRequired = "users_profile_modal__required--huxrUPNlU6BI4i4OZpMV";
export var usersProfileModalNotification = "users_profile_modal__notification--EDYHDdlwqZANt8DR5Ex0";
export var error = "error--R3mXGL9PVVE2xQ_sqWQa";
export var usersProfileModalYearOfBirthField = "users_profile_modal__yearOfBirth_Field--pbZShdgZE4tIS_pjD0XM";
export default ___CSS_LOADER_EXPORT___;
