import { Role, sendRoleDTO, rightsDTO } from "./Roles.d";

export const getItemById = (id: number, catalog: Role[]): Role =>
  catalog?.find?.((value) => id === value.id) ?? null;

export const getEditedRoleFromPath = (location: string): number => {
  const matched = location.match(/\/([0-9]+)\/?/)?.[1];
  if (matched) {
    return Number(matched);
  }
  return null;
};

export const mapEditRoleFormToDto = ({ id, name }: Role): sendRoleDTO => ({
  id: id || null,
  rolesName: name
});

export const mapEditRightsFormToDto = (rights: rightsDTO): rightsDTO => ({
  ...rights,
  monitoring: rights.systemDashboard ? rights.monitoring : false,
  skillLimit:
    rights.systemDashboard && rights.monitoring ? rights.skillLimit : false,
  agentLimit:
    rights.systemDashboard && rights.monitoring ? rights.agentLimit : false,
  userGroups:
    rights.systemDashboard && rights.monitoring ? rights.userGroups : false,
  appealClose: rights.systemDashboard ? rights.appealClose : false,
  appealTransfer: rights.systemDashboard ? rights.appealTransfer : false,
  appealReopen: rights.systemDashboard ? rights.appealReopen : false,
  excel: rights.systemDashboard ? rights.excel : false,
  reportAppeals: rights.systemDashboard ? rights.reportAppeals : false
});
