import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";

export const getClassirierState = (state: StoreState.State) => {
  return state.ClassifierForm;
};

export const getLoadingStatus = createSelector(getClassirierState, (state) => {
  return !isEmpty(state.loading);
});

export const channelsList = createSelector(getClassirierState, (state) => {
  return (
    state.channels?.map((c) => ({
      value: c.id,
      label: c.name
    })) || []
  );
});
export const mrfsList = createSelector(getClassirierState, (state) => {
  return (
    state.mrfs?.map((c) => ({
      value: c.id,
      label: c.name
    })) || []
  );
});
export const skillGroupList = createSelector(getClassirierState, (state) => {
  return (
    state.skillGroups
      ?.filter(
        (g) =>
          (state.classifierData?.id &&
            state.classifierData.skillGroupIds.includes(g.id)) ||
          state.availableSkillGroups.find((a) => a.id === g.id)
      )
      .map((c) => ({
        value: c.id,
        label: c.name
      })) || []
  );
});
