import React, { FC, memo } from "react";

import * as styles from "./SubTitle.module.scss";

interface ISubTitleProps {
  /** Текст заголовка */
  caption: string;
  htmlForId?: string;
  /** добавленный компонент к заголовку, например переключаталь */
  toggleComponent?: React.ReactNode;
}

/**
 * Подзаголовок
 * часто используется в МО для выделения групп настроек
 * @param param0
 */
const SubTitle: FC<ISubTitleProps> = ({
  htmlForId,
  caption,
  toggleComponent
}): JSX.Element => {
  return (
    <div className={styles.subTitle}>
      {toggleComponent ? (
        <>
          <label htmlFor={htmlForId}>
            <span>{caption}</span>
          </label>
          <div className={styles.subTitleToogle}>{toggleComponent}</div>
        </>
      ) : (
        <div>{caption && <span>{caption}</span>}</div>
      )}
    </div>
  );
};

export default memo(SubTitle);
