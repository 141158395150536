const domenNames =
  "(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|tech|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|ct|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|group|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|local|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa|moscow|tatar|psk|бг|xn--90ae|бел|xn--90ais|москва|қаз|xn--80ao21a|мон|срб|xn--90a3ac|рф|xn--p1ai|рус|xn--p1acf|укр|xn--j1amh)";

const regExpUrl = new RegExp(
  String.raw`(((https?|ftp):\/\/)?(www\.)?)?([^\s\/:;.,_!=+@*~#(|)\[\]{}@$%'"\`\\<>«»&?-](([\w\.~\|-]{0,256})|([а-яё\d._~\|-]{0,256}))[^\/:._-](\.${domenNames})(\/([\wа-яё:;%\|@()+\*.!\?\\\/,~#&=-]*[a-zа-яё\d)\/]+)?)?)`,
  "gi"
);

const regExpMail = new RegExp(
  String.raw`(mailto:)?([^\s\/:;.,_!=+@*~#(|)\[\]{}@$%'"\`\\<>«»&?-]([\w\.~\|-]{0,128}|[а-яё\d._~\|-]{0,128})\@[^-](([\w\.~\|-]{0,128})|[а-яё\d._~\|-]{0,128})(\.${domenNames}))`,
  "gi"
);

const regExpHtmlLink = new RegExp(
  String.raw`(&lt;|<|&amp;lt;)a\shref=(&#34;|"|&amp;#34;|&quot;|&amp;quot;)(.+?)(&#34;|"|&amp;#34;|&quot;|&amp;quot;)(&gt;|>|&amp;gt;)(.*?)(&lt;|<|&amp;lt;)\/a(&gt;|>|&amp;gt;)`,
  "gi"
);

const regExpPhone = new RegExp(
  String.raw`(callto:)?((8|\+7)(\s|-)?(\(\d{3}\)|\d{3})(\s|-)?((\d{3}(((\s|-)?\d{2}){2}))|(\d{4}(\s|-)?\d{3})))`,
  "g"
);

export const regExp = {
  HtmlLink: regExpHtmlLink,
  Mail: regExpMail,
  Url: regExpUrl,
  Phone: regExpPhone,
};
