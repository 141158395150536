import { CategoryModalTypes } from "./Interfaces";

const CategoriesList: CategoryModalTypes.State = {
  categoryId: null,
  name: "",
  all: true,
  selectedGroups: [],
  skillGroups: []
};

export default CategoriesList;
