import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createAction,
  createSlice,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import initialState from "../../../ReactCore/store/initialState";
import { SkillGroupData, UserData } from "../UserGroupsManager.d";
import { filteredDuplicateArray } from "../../../ReactFeatures/Common/Utils/CommonUtils";

const NAMESPACE = "users/";

export const GET_SKILLGROUPS = `${NAMESPACE}GET_SKILLGROUPS`;
export const GET_USERS = `${NAMESPACE}GET_USERS`;
export const SKILLGROUP_DELETE = `${NAMESPACE}SKILLGROUP_DELETE`;
export const USERS_TO_ARCHIVE = `${NAMESPACE}USERS_TO_ARCHIVE`;

export interface IActionDropdownMenuUsers {
  userIds: UserData["id"][];
  isAll: boolean;
  skillGroupId: SkillGroupData["id"];
  query: string;
}

interface IGetUsers {
  page?: number;
  size?: number;
  query?: string;
  skill_id?: string;
}

export const getUserListAction = createAction<IGetUsers>(GET_USERS);

export const getSkillGroupListAction = createAction<{
  page?: number;
}>(GET_SKILLGROUPS);

export const deleteSkillGroupAction = createAction<{
  skillGroupId: SkillGroupData["id"];
}>(SKILLGROUP_DELETE);

export const archiveUsersAction = createAction<IActionDropdownMenuUsers>(
  USERS_TO_ARCHIVE
);

const UsersSlice = createSlice({
  name: "users",
  initialState: initialState.Users,
  reducers: {
    setRestorePasswordHandling(state, action: PayloadAction<boolean>) {
      state.restorePasswordHandling = action.payload || false;
    },
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setUserList(state, action: PayloadAction<UserData[]>) {
      state.userList = action.payload || [];
    },
    addUsersIntoList(state, { payload }: PayloadAction<UserData[]>) {
      const filteredUserList = filteredDuplicateArray(state.userList, payload);

      state.userList = [...filteredUserList, ...payload];
    },
    changeUserPage(state, action: PayloadAction<number>) {
      state.currentUsersPage = action.payload;
    },
    resetUserPage(state) {
      state.currentUsersPage = initialState.Users.currentUsersPage;
    },
    setSkillGroupsList(state, action: PayloadAction<SkillGroupData[]>) {
      state.skillGroupList = action.payload
    },
    addSkillGroupsIntoList(
      state,
      { payload }: PayloadAction<SkillGroupData[]>
    ) {
      const filteredSkillGroupList = filteredDuplicateArray(
        state.skillGroupList,
        payload
      );

      state.skillGroupList = [...filteredSkillGroupList, ...payload];
    },
    changeSkillGroupPage(state, action: PayloadAction<number>) {
      state.currentSkillGroupPage = action.payload;
    },
    resetSkillGroupPage(state) {
      state.currentSkillGroupPage = initialState.Users.currentSkillGroupPage;
    },
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    setSelectedUser(state, { payload }: PayloadAction<UserData["id"]>) {
      const { checkedUsers, checkAllUsers: isAll, userList } = state;

      let newCheckedUsers = isAll
        ? userList.map((item: UserData) => item.id)
        : [...checkedUsers];

      const valueIndex = newCheckedUsers.findIndex((item) => item === payload);

      if (valueIndex === -1) {
        newCheckedUsers.push(payload);
      } else {
        state.checkAllUsers = false;
        newCheckedUsers.splice(valueIndex, 1);
      }
      state.checkedUsers = newCheckedUsers;
    },
    setSelectedAllUsers(state) {
      const { checkedUsers, userList } = state;
      const equal = checkedUsers.length === userList.length;

      if (equal && checkedUsers.length) {
        state.checkAllUsers = false;
        state.checkedUsers = [];
      } else {
        state.checkAllUsers = true;
        state.checkedUsers = userList.map((item: UserData) => item.id);
      }
    },
    resetCheckedUsers(state) {
      state.checkAllUsers = false;
      state.checkedUsers = [];
    },
    setUserEdited(state, action: PayloadAction<UserData["id"]>) {
      state.userData = action.payload;
    },
    cleanUserEdited(state) {
      state.userData = null;
    },
    archivedUsersSuccess(
      state,
      { payload: archivedUsersIds }: PayloadAction<UserData["id"][]>
    ) {
      const currentState = state.userList;
      state.userList = currentState.filter(
        ({ id }) => !archivedUsersIds.includes(id)
      );
    },
    setSkillGroupEdited(state, action: PayloadAction<SkillGroupData["id"]>) {
      state.skillGroupData = action.payload;
    },
    deletedSkillgroupSuccess(
      state,
      action: PayloadAction<SkillGroupData["id"]>
    ) {
      const deletedSkillGroupId = action.payload;
      const currentSkillGroupId = state.currentSkillGroupId;
      state.skillGroupList = state.skillGroupList.filter(
        ({ id }) => id !== deletedSkillGroupId
      );

      if (currentSkillGroupId === deletedSkillGroupId) {
        state.currentSkillGroupId = initialState.Users.currentSkillGroupId;
      }
    },
    cleanSkillGroupEdited(state) {
      state.skillGroupData = null;
    },
    setCurrentSkillGroup(state, action: PayloadAction<SkillGroupData["id"]>) {
      state.currentSkillGroupId = action.payload;
    },
    startUserManager(state) {
      state.userList = [...initialState.Users.userList];
      state.skillGroupList = [...initialState.Users.skillGroupList];
      state.currentSkillGroupId = null;
      state.userData = null;
      state.skillGroupData = null;
    }
  }
});

export const actionUsers = {
  ...UsersSlice.actions,
  getUserListAction,
  getSkillGroupListAction,
  deleteSkillGroupAction,
  archiveUsersAction
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionUsers, dispatch), [dispatch]);
};

export default UsersSlice.reducer;
