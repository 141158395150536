import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";

export const chartsState = (state: StoreState.State) => {
  return state.Charts;
};

export const charts = createSelector(chartsState, (state) => {
  return state.charts;
});

export const loading = createSelector(chartsState, (state) => {
  return !isEmpty(state.loading);
});
