import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  removeField,
  resetFieldData,
  REMOVE_FIELD
} from "../Store/ClassifierFieldForm.reducer";

export function* removeFieldSaga(action) {
  const { fieldId, cb } = action.payload;
  yield put(startLoading(REMOVE_FIELD));

  try {
    const res = yield httpRequest(
      "DELETE",
      `bff/settings/classifier/fields/${fieldId}`
    );

    if (res?.success) {
      yield put(resetFieldData());
      yield cb();
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(REMOVE_FIELD));
  }
}

export default function* () {
  yield takeEvery([removeField], removeFieldSaga);
}
