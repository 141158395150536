import React from "react";
import { Grid, Stack } from "@mui/material";
import { ScrollBox, Button } from "@omnichat/arm_ui_kit";

import SectionTitle from "../../ReactFeatures/Common/Components/SectionTitle/SectionTitle";
import { useSkillGroups } from "../VipClients/VipClients.hooks";
import {
  SystemSettingBoolean,
  SystemSettingInteger
} from "./Components/SystemSetting";
import * as s from "./SystemSettings.module.scss";
import { useSystemSettings } from "./SystemSettings.hooks";
import AutoLogoutSetting from "./Components/AutoLogoutSetting";

interface SystemSettingsProps {}

export enum SettingType {
  BOOLEAN = "BOOLEAN",
  INTEGER = "INTEGER"
}

export interface SytemSetting {
  defaultValue: string;
  id: number;
  ordering: number;
  settingDescription: string;
  settingKey: string;
  settingType: SettingType;
  settingValue: string;
}

const headerIntegers = [
  { label: "ПАРАМЕТРЫ", offset: 3 },
  { label: "ЗНАЧЕНИЕ", offset: 6 },
  { label: "ДЕЙСТВИЯ", offset: 3 }
];

const headerBooleans = [
  { label: "ВКЛЮЧИТЬ ОПЦИЮ", offset: 4 },
  { label: "СТАТУС", offset: 3 }
];

const AutoLogoutBooleans = [
  { label: "ПЕРИОД (МИН)", offset: 3 },
  { label: "ПРИЧИНА", offset: 6 },
  { label: "ДЕЙСТВИЯ", offset: 3 }
];

const SystemSettings: React.FC<SystemSettingsProps> = () => {
  const { loading, skillGroups } = useSkillGroups();
  const { settingsIntegers, settingsBooleans, handleSave } =
    useSystemSettings();

  if (loading) return null;

  return (
    <>
      <ScrollBox autoHeight hasScrollControlBackground>
        <Stack p={4} spacing={1.5}>
          <SectionTitle title="Настройки: Изменяемые параметры" />
          <Grid container spacing={1}>
            {headerIntegers.map((header) => (
              <Grid
                key={header.label}
                item
                xs={header.offset}
                p={1}
                borderBottom="1px solid #cbd7da"
              >
                <h4 className={s["label"]}>{header.label}</h4>
              </Grid>
            ))}
          </Grid>
          {settingsIntegers.map((systemSetting) => (
            <SystemSettingInteger
              key={systemSetting.id}
              systemSetting={systemSetting}
              skillGroups={skillGroups}
              onSave={handleSave}
            />
          ))}
          <Grid container>
            <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
              Кол-во попыток подключения после неудачного соединения с
              вебсокетом чата.
            </Grid>
            <Grid
              container
              alignItems="center"
              item
              xs={6}
              p={1}
              borderBottom="1px solid #cbd7da"
            >
              {WIDGET_STOMP_RECONNECT_ATTEMPTS || "Не задано"}
            </Grid>
            <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
              <Button
                text="Изменить"
                type="underline"
                disabled
                theme={"disabled"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
              Максимальное количество сессий.
            </Grid>
            <Grid
              container
              alignItems="center"
              item
              xs={6}
              p={1}
              borderBottom="1px solid #cbd7da"
            >
              {WEBCHAT_MAX_WS_CONNECTIONS || "Не задано"}
            </Grid>
            <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
              <Button
                text="Изменить"
                type="underline"
                disabled
                theme={"disabled"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            {headerBooleans.map((header) => (
              <Grid
                key={header.label}
                item
                xs={header.offset}
                p={1}
                borderBottom="1px solid #cbd7da"
              >
                <h4 className={s["label"]}>{header.label}</h4>
              </Grid>
            ))}
          </Grid>
          {settingsBooleans.map((systemSetting) => (
            <SystemSettingBoolean
              key={systemSetting.id}
              systemSetting={systemSetting}
              onSave={handleSave}
            />
          ))}
          <Grid container spacing={1}>
            {AutoLogoutBooleans.map((header) => (
              <Grid
                key={header.label}
                item
                xs={header.offset}
                p={1}
                borderBottom="1px solid #cbd7da"
              >
                <h4 className={s["label"]}>{header.label}</h4>
              </Grid>
            ))}
          </Grid>
          <AutoLogoutSetting />
        </Stack>
      </ScrollBox>
    </>
  );
};

export default SystemSettings;
