import { RepliesTemplateModalTypes } from "./Interfaces";

const repliesModal: RepliesTemplateModalTypes.State = {
  replies: [],
  categoryPage: 0,
  replyPage: 0,
  dialogReplyCategories: [],
  isLastReplyPage: false
};

export default repliesModal;
