import { RepliesListTypes } from "./Interfaces";

import { ALL } from "../CategoriesList/Consts";

const CategoriesList: RepliesListTypes.State = {
  all: false,
  categoryId: ALL,
  query: "",
  page: 0,
  repliesList: [],
  selected: []
};

export default CategoriesList;
