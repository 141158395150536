import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { getPaginatedMessages } from "../Actions/Dialog.actions";
import { pushMessagesIntoDialogTop } from "../Reducers/Dialog.reducer";

function* getPaginatedMessagesSaga(action) {
  const dialogId = action.payload.dialogId;
  const lastMessageId = action.payload.lastMessageId;

  const response = yield httpRequest(
    "GET",
    `bff/chat/dialog/${dialogId}/messages/${lastMessageId}`
  );

  if (response?.success) {
    yield put(pushMessagesIntoDialogTop(response.data));
    action.payload.cb(response.data);
  }
}

export default function* () {
  yield takeEvery([getPaginatedMessages], getPaginatedMessagesSaga);
}
