enum Colors {
  white = "#fff",
  lightGray = "#bec2c4",
  gray = "#6c8aa2",

  primaryBg = "#cee6f2",
  primaryHoverBg = "#e4f1f7",
  primaryText = "#73aecc",
  primaryAction = "#0090db",
  primaryDisabled = "#00000061",

  successAction = "#9fe49f",

  textHints = "#96a2a9", //'829199'
  textDefault = "#2b2b2b",
  textLabelDefault = "#525f67",

  tooltipBg = "#000000b3",
  tooltipArrow = "#fbfafab3",

  errorBg = "#f9ebea",
  errorText = "#d5afaa",
  errorAction = "#f6b5ac",
  errorActionText = "#fc2b32",

  modalBg = "rgba(0,0,0,0.6)"
}
export default Colors;
