import produce from "immer";

import * as ACTION from "../Consts";
import initialData from "../CategoriesList.initialData";

export default function CategoriesListReducer(
  state = initialData,
  { type, payload }
) {
  switch (type) {
    case ACTION.GET_CATEGORIES:
      return produce(state, draft => {
        draft.page = 0;
      });
    case ACTION.SET_CATEGORIES:
      return produce(state, draft => {
        draft.categories = payload;
      });
    case ACTION.ADD_CATEGORIES:
      return produce(state, draft => {
        draft.categories = [...draft.categories, ...payload];
      });
    case ACTION.SET_SKILLGROUPS:
      return produce(state, draft => {
        draft.skillGroups = payload;
      });
    case ACTION.SET_CATEGORY:
      return produce(state, draft => {
        draft.category = payload;
      });
    case ACTION.SET_SKILLGROUP:
      return produce(state, draft => {
        draft.page = 0;
        draft.skillGroup = payload;
      });
    case ACTION.GET_NEXT_PAGE:
      return produce(state, draft => {
        draft.page = draft.page + 1;
      });
    default:
      return state;
  }
}
