import { isEmpty } from "@omnichat/arm_ui_kit";
import { AppealThemeData, IRegion } from "../Interfaces";
import { REGIONAL_SERVICE_ID } from "../Consts";

export const getPreparedThemesList = (
  themeData: AppealThemeData[]
): string[] => {
  return themeData.map(t =>
    t.fields
      .map(f =>
        f.type === "CHECKBOX"
          ? `${f.parentName}(${f.textContent})`
          : f.textContent
      )
      .join(" - ")
  );
};

export const getPreparedServicesList = (serviceData: {
  tag: IRegion;
  value: IRegion;
}): string[] => {
  let result = [];
  if (isEmpty(serviceData)) return result;

  if (serviceData.tag?.name) {
    result.push(serviceData.tag.name);

    if (serviceData.tag.id === REGIONAL_SERVICE_ID && serviceData.value?.name) {
      result.push(serviceData.value.name);
    }
  }
  return result;
};
