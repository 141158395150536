import { httpRequest } from "../../ReactCore/shared/httpRequest";

export const removeOfflineTimerFromStorage = () => {
  core.storage.remove("msg_offline_timer");
};

export const getOfflineTimerFromStorage = () => {
  return core.storage.get("msg_offline_timer");
};

export const saveOfflineTimerToStorage = (substatus, startDate) => {
  const currentTimer = core.storage.get("msg_offline_timer");

  if (!currentTimer || currentTimer.substatus !== substatus) {
    core.storage.set("msg_offline_timer", { substatus, startDate });
  }
};

export const removeWorkspaceVariablesFromLocalStorage = () => {
  Object.keys(localStorage)
    .filter((item) => item.startsWith("msg_"))
    .forEach((item) => localStorage.removeItem(item));
};

export const logoutUser = async () => {
  removeOfflineTimerFromStorage();
  removeWorkspaceVariablesFromLocalStorage();
  try {
    await httpRequest("GET", `workspace?sec=logout`);
  } catch (e) {
    console.log("error", e);
  }
  location.reload();
};
