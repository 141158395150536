import React, { FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  REQUIRED_FIELD_ERROR,
  Preloader,
  Select
} from "@omnichat/arm_ui_kit";

import {
  FormFieldConnector,
  SubTitle,
  FormField
} from "../../../../Components";
import { Link } from "../../ClassifierLinkForm";

import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

import * as s from "./style.module.scss";

export interface ILinkProps {
  loading: boolean;
  name: string;
  fieldsList: {
    id: string;
    name: string;
    values?: { value: string; label: string }[];
  }[];
  onAddField: (fieldId: string) => void;
  onSave: (data: Link[]) => void;
}

const schema = Yup.object({
  link: Yup.array()
    .of(
      Yup.object().shape({
        field: Yup.string().required(REQUIRED_FIELD_ERROR),
        value: Yup.string().required(REQUIRED_FIELD_ERROR)
      })
    )
    .min(1)
    .required(REQUIRED_FIELD_ERROR)
});

export interface ILinkForm extends Yup.InferType<typeof schema> {}

const ClassifierLinkForm: FC<ILinkProps> = ({
  loading,
  name,
  fieldsList,
  onAddField,
  onSave
}): JSX.Element => {
  const methods = useForm<ILinkForm>({
    defaultValues: {
      link: []
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, setValue, watch } = methods;

  const link = watch("link");

  const handleSave = (data: ILinkForm) => {
    const result = data.link?.map((l) => ({
      fieldId: l.field,
      valueId: l.value,
      valueName:
        fieldsList
          .find((f) => f.id === l.field)
          ?.values.find((v) => v.value === l.value)?.label || "Без имени"
    }));
    onSave(result);
  };
  return (
    <div className={s["linkForm"]}>
      <FormProvider {...methods}>
        <SubTitle caption="Зависимость от значений предыдущих полей" />
        <Preloader size="mini" show={loading}>
          <>
            <FormField label="Поле">
              <div>{name}</div>
            </FormField>

            <FormField label="Выберите поле">
              <Select
                options={fieldsList
                  .filter((f) => !link.find((l) => l.field === f.id.toString()))
                  .map((f) => ({
                    label: f.name,
                    value: f.id
                  }))}
                selected={[]}
                onSelectOption={(o) => {
                  const fieldId = o?.[0];
                  onAddField(fieldId);
                  setValue("link", [
                    ...link,
                    {
                      field: fieldId,
                      value: ""
                    }
                  ]);
                }}
              />
            </FormField>

            {link?.map((l, i) => (
              <FormFieldConnector
                key={i}
                required
                label={fieldsList?.find((f) => f.id === l.field)?.name}
                name={`link.${i}.value`}
                Component={(p) => (
                  <Select
                    {...p}
                    options={
                      fieldsList?.find((f) => f.id === l.field)?.values || []
                    }
                    selected={p.value ? [p.value] : []}
                    onSelectOption={(e) => {
                      p.onChange(e[0]);
                    }}
                  />
                )}
              />
            ))}

            <div className={s["buttons"]}>
              <Button
                type="default"
                disabled={loading}
                theme={"green"}
                onClick={handleSubmit(
                  (data) => handleSave(data),
                  ReactHookFormErrorHandler
                )}
                text="Сохранить"
              />
            </div>
          </>
        </Preloader>
      </FormProvider>
    </div>
  );
};

export default ClassifierLinkForm;
