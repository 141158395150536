import newPrompterAnswers from "./newPrompterAnswers.action";
import newMessageAction from "./newMessage.action";
import appealRemoveAction from "./appealRemove.action";
import noThemesAction from "./noThemes.action";
import typingAction from "./typing.action";
import notForWaitingAction from "./notForWaiting.action";
import CsiRateOperatorInfoDtoAction from "./CsiRateOperatorInfoDto.action";

export const getChatAction = (type, payload) => {
  switch (type) {
    case "new_message":
      newMessageAction(payload);
      break;
    case "appeal_remove":
      appealRemoveAction(payload);
      break;
    case "typing":
      typingAction(payload);
      break;
    case "no_themes":
      noThemesAction(payload);
      break;
    case "not_for_waiting":
      notForWaitingAction(payload);
      break;
    case "client_online":
      break;
    case "client_offline":
      break;
    case "prompt":
      newPrompterAnswers(payload);
      break;
    case "CsiRateOperatorInfoDto":
      CsiRateOperatorInfoDtoAction(payload);
      break;
  }
};
