import { takeEvery, put, select } from "redux-saga/effects";
import { GET_REPLIES } from "../Сonsts";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import {
  setReplies,
  addReplies,
  changeRepliesPage
} from "../Actions/repliesEditModal.actions";
import { ISelect } from "../../Common/Interfaces/Store.interfaces";

function* getReplies(action) {
  const { currentPage, isLastReplyPage } = yield select<ISelect>(
    ({ RepliesTemplate }) => ({
      currentPage: RepliesTemplate.replyPage,
      isLastReplyPage: RepliesTemplate.isLastReplyPage
    })
  );

  if (isLastReplyPage) return;

  const { appealId, categoryId, query, page } = action.payload;

  const response = yield httpRequest(
    "GET",
    `bff/chat/dialog/0/replies/${appealId}/`,
    {
      query: {
        categoryId,
        query,
        page: page,
        pageSize: 20
      }
    }
  );

  if (response?.success) {
    if (page > 0 && currentPage !== page) {
      yield put(addReplies(response.data?.content));
    } else {
      yield put(setReplies(response.data?.content));
    }

    yield put(changeRepliesPage({ page, isLast: response.data.last }));
  }
}
export default function* () {
  yield takeEvery(GET_REPLIES, getReplies);
}
