import {
  PUSH_FILES,
  SEND_FILE,
  REMOVE_FILE,
  CLEAN_FILE_LIST,
  CANCEL_PENDING
} from "./consts";

export const pushFiles = files => ({
  type: PUSH_FILES,
  payload: files
});

export const sendFile = (uuid, onSuccess?, onError?) => ({
  type: SEND_FILE,
  payload: { uuid, onSuccess, onError }
});

export const removeFile = uuid => ({
  type: REMOVE_FILE,
  payload: uuid
});

export const cancelPending = uuid => ({
  type: CANCEL_PENDING,
  payload: uuid
});

export const cleanFileList = () => ({
  type: CLEAN_FILE_LIST
});
