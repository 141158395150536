import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getValueData,
  setValueData,
  setLinkData,
  GET_VALUE_DATA
} from "../Store/ClassifierFieldValueForm.reducer";

export function* getValueDataSaga(action) {
  const valueId = action.payload;
  yield put(startLoading(GET_VALUE_DATA));

  try {
    const res = yield httpRequest(
      "GET",
      `bff/settings/classifier/fields/values/${valueId}`
    );

    if (res?.success) {
      yield put(setValueData(res?.data));
      yield put(setLinkData(res?.data.links || []));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(GET_VALUE_DATA));
  }
}

export default function* () {
  yield takeEvery([getValueData], getValueDataSaga);
}
