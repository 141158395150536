// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--zlsAINbn5CwmjRdz5ikE{width:900px;padding:0}.modal__header_context-info--EwNxmjnzghO49poNm9i4{word-wrap:break-word;color:#829199;margin-top:5px;font-size:15px;list-style-type:none}.modal__content--zATAxDuBjYtbwj5wpemR{margin-top:20px;height:1px;min-height:555px;padding:0;border:none}.modal--zlsAINbn5CwmjRdz5ikE .margin__ScrollBox_Content--Rh0L9F2KCJQ3S9kS9ctt{margin-right:20px;height:100%;min-height:100%}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/DialogHeader/Components/DialogContextModal/DialogContextModal.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,SAAA,CAEA,kDACE,oBAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CACA,oBAAA,CAGF,sCACE,eAAA,CACA,UAAA,CACA,gBAAA,CACA,SAAA,CACA,WAAA,CAGF,8EACE,iBAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".modal {\n  width: 900px;\n  padding: 0;\n\n  &__header_context-info {\n    word-wrap: break-word;\n    color: #829199;\n    margin-top: 5px;\n    font-size: 15px;\n    list-style-type: none;\n  }\n\n  &__content {\n    margin-top: 20px;\n    height: 1px;\n    min-height: 555px;\n    padding: 0;\n    border: none;\n  }\n\n  .margin__ScrollBox_Content {\n    margin-right: 20px;\n    height: 100%;\n    min-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "modal--zlsAINbn5CwmjRdz5ikE";
export var modalHeaderContextInfo = "modal__header_context-info--EwNxmjnzghO49poNm9i4";
export var modalContent = "modal__content--zATAxDuBjYtbwj5wpemR";
export var marginScrollBoxContent = "margin__ScrollBox_Content--Rh0L9F2KCJQ3S9kS9ctt";
export default ___CSS_LOADER_EXPORT___;
