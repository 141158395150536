// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--7UicP8ZuIv7hTLT5s5Gp{width:550px}.header--INRVSTr70YDiZsdXnJTC{padding-bottom:20px;border-bottom:1px solid rgba(0,0,0,.15);color:#2a323f;font-size:18px;font-weight:700}.description--yiJbZZH49oXThPEMA2jm{padding:20px 0}.description--yiJbZZH49oXThPEMA2jm>p{font-size:16px;color:#000}.buttons--HGEo7KwrrygUOISLbuZM{display:flex;justify-content:center}.button--UjdmQaXVJgta1bU61nEc{margin:0 20px}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/Common/Components/ConfirmModal/ConfirmModal.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CAGF,8BACE,mBAAA,CACA,uCAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAGF,mCACE,cAAA,CAEA,qCACE,cAAA,CACA,UAAA,CAIJ,+BACE,YAAA,CACA,sBAAA,CAGF,8BACE,aAAA","sourcesContent":[".modal {\n  width: 550px;\n}\n\n.header {\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n  color: #2a323f;\n  font-size: 18px;\n  font-weight: 700;\n}\n\n.description {\n  padding: 20px 0;\n\n  > p {\n    font-size: 16px;\n    color: #000;\n  }\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n}\n\n.button {\n  margin: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "modal--7UicP8ZuIv7hTLT5s5Gp";
export var header = "header--INRVSTr70YDiZsdXnJTC";
export var description = "description--yiJbZZH49oXThPEMA2jm";
export var buttons = "buttons--HGEo7KwrrygUOISLbuZM";
export var button = "button--UjdmQaXVJgta1bU61nEc";
export default ___CSS_LOADER_EXPORT___;
