import React, { FC, useState } from "react";

import { Button, Tooltip } from "@omnichat/arm_ui_kit";
import TextTemplate, { ITextValidateData } from "./TextValidate";

import * as s from "./style.module.scss";

type ITextValidatesProps = {
  validates: ITextValidateData[];
  onSaveValidate: (data: ITextValidateData) => void;
  onDeleteValidate: (data: ITextValidateData) => void;
};

const TextValidates: FC<ITextValidatesProps> = ({
  validates,
  onDeleteValidate,
  onSaveValidate
}) => {
  const [newValidate, createNewValidate] = useState<ITextValidateData | null>(
    null
  );

  const handleAddNewTemplate = () => {
    createNewValidate({
      regexp: "",
      errorText: ""
    });
  };

  return (
    <div>
      <div>
        {(newValidate ? [...validates, newValidate] : validates)?.map(
          (t, i) => (
            <TextTemplate
              key={`${i}`}
              onDelete={onDeleteValidate}
              onSave={onSaveValidate}
              validateData={t}
              onCancelCreation={() => createNewValidate(null)}
            />
          )
        )}
      </div>
      <div className={s.add}>
        <Tooltip content="Добавить проверку" position="top">
          <Button
            type="bare"
            iconColor="blue"
            iconName="plus"
            onClick={handleAddNewTemplate}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TextValidates;
