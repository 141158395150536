import { takeLatest, fork, cancel, take, call } from "redux-saga/effects";

import { store } from "../../../ReactCore";
import { websocket } from "../../../Websocket/Websocket.saga";
import { actionAgents } from "../../Monitoring/Store/Monitoring.reducer";
import { RealTimeStatistics } from "../../Monitoring/Monitoring";

const onReceivedHandle = (wsEvent: {
  name: string;
  data: RealTimeStatistics;
}) => {
  const { name, data } = wsEvent;
  // Игноррируем все пакеты кроме real_time_stats
  if (!name || name !== "real_time_stats") return;

  store.dispatch(actionAgents.updateAgentMonitoringData(data.agents));
  store.dispatch(actionAgents.updateSkillGroupMonitoringData(data.groups));
  store.dispatch(actionAgents.updateOverallMonitoringData(data.overall));
};

export default function* () {
  while (true) {
    const { payload } = yield take(actionAgents.connectRealtimeWs);
    const instance = yield websocket().getInstance(payload, onReceivedHandle);

    yield fork(instance.open, instance);
    yield take(actionAgents.disconnectRealtimeWs);
    yield cancel(instance.secretId);

    yield call(instance.destroy);
  }
}
