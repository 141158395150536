import React, { FC, memo } from "react";
import { Box, Theme } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import Close from "@mui/icons-material/Close";

import Select from "../Select";

import { Option } from "./DragAndDropSelect";

interface IDraggableSelector {
  index: number;
  item: Option | null;
  options: Option[];
  draggableProvider: any;
  onOptionChange: (value: Option[]) => void;
  disabled?: boolean;
  remove: (index: number) => void;
  isError?: boolean;
}

const DraggableSelect: FC<IDraggableSelector> = ({
  index,
  item,
  options,
  draggableProvider,
  onOptionChange,
  disabled,
  remove,
  isError
}): JSX.Element => {
  const btnSx = ({ palette: { primary, error } }: Theme) => ({
    display: "flex",
    alignItems: "center",
    background: isError ? error.extraLight : primary.light
  });

  const iconSx = ({ palette: { primary, error } }: Theme) => ({
    color: isError ? error.main : primary.main,
    fontSize: "3rem"
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row"
      }}
      ref={draggableProvider.innerRef}
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}
    >
      <Box sx={[btnSx, { padding: "0 5px", marginRight: "2px" }]}>
        <AppsIcon
          width="24px"
          height="24px"
          sx={iconSx}
          name="dragButton"
          fontSize="large"
        />
      </Box>

      <Box sx={{ width: "calc(100% - 73px)" }}>
        <Select
          isError={isError}
          label=""
          options={options}
          selected={item !== null ? [item] : []}
          disabled={disabled}
          open={disabled ? false : null}
          onSelectOption={onOptionChange}
        />
      </Box>

      <Box
        sx={[
          btnSx,
          {
            position: "relative",
            justifyContent: "center",
            cursor: "default",
            width: "35px"
          }
        ]}
      >
        {!disabled && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              rigth: 0,
              bottom: 0,
              cursor: "pointer",
              display: "flex",
              alignItems: "center"
            }}
            onClick={() => remove(index)}
          >
            <Close
              width="24px"
              height="24px"
              name="delete"
              sx={iconSx}
              fontSize="large"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(DraggableSelect);
