import { fork } from 'redux-saga/effects';

import getUsers from './getUsers.saga';
import getSkillGroups from './getSkillGroups.saga';
import skillgroupDelete from './skillGroupDelete.saga';
import usersToArchive from './userToArchive.saga';

export default function* () {
  yield fork(getUsers);
  yield fork(getSkillGroups);
  yield fork(skillgroupDelete);
  yield fork(usersToArchive);
}
