import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import initialState from "../../../ReactCore/store/initialState";

export const FIELD_CREATED = "FIELD_CREATED";
export const GET_FIELD_DATA = "GET_FIELD_DATA";
export const SEND_FIELD_DATA = "SEND_FIELD_DATA";
export const REMOVE_FIELD = "REMOVE_FIELD";

export const GET_TEMPLATE_DATA = "GET_TEMPLATE_DATA";
export const SEND_TEMPLATE_DATA = "SEND_TEMPLATE_DATA";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";

export const GET_VALIDATE_DATA = "GET_VALIDATE_DATA";
export const SEND_VALIDATE_DATA = "SEND_VALIDATE_DATA";
export const REMOVE_VALIDATE = "REMOVE_VALIDATE";

export const fieldCreated = createAction(FIELD_CREATED);
export const getFieldData = createAction(GET_FIELD_DATA, (data) => ({
  payload: data
}));
export const sendFieldData = createAction(SEND_FIELD_DATA, (data) => ({
  payload: data
}));
export const removeField = createAction(REMOVE_FIELD, (data) => ({
  payload: data
}));

export const getTemplateData = createAction(GET_TEMPLATE_DATA, (data) => ({
  payload: data
}));
export const sendTemplateData = createAction(SEND_TEMPLATE_DATA, (data) => ({
  payload: data
}));
export const removeTemplate = createAction(REMOVE_TEMPLATE, (data) => ({
  payload: data
}));

export const getValidateData = createAction(GET_VALIDATE_DATA, (data) => ({
  payload: data
}));
export const sendValidateData = createAction(SEND_VALIDATE_DATA, (data) => ({
  payload: data
}));
export const removeValidate = createAction(REMOVE_VALIDATE, (data) => ({
  payload: data
}));

const fieldFormSlice = createSlice({
  name: "classifierFieldForm",
  initialState: initialState.ClassifierFieldForm,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setFieldData(state, action) {
      state.fieldData = action.payload;
    },
    setLinkData(state, action) {
      state.linksData = action.payload;
    },
    setTemplateData(state, action) {
      state.templateData = action.payload;
    },
    setValidateData(state, action) {
      state.validateData = action.payload;
    },
    resetFieldData(state) {
      state.loading = initialState.ClassifierFieldForm.loading;
      state.errorMessage = initialState.ClassifierFieldForm.errorMessage;
      state.fieldData = initialState.ClassifierFieldForm.fieldData;
      state.templateData = initialState.ClassifierFieldForm.templateData;
      state.validateData = initialState.ClassifierFieldForm.validateData;
    },
    setFieldResponseError(state, action) {
      state.errorMessage = action.payload;
    }
  }
});

export const {
  resetFieldData,
  setFieldData,
  setTemplateData,
  setValidateData,
  setLinkData,
  setFieldResponseError,
  startLoading,
  finishLoading
} = fieldFormSlice.actions;

export default fieldFormSlice.reducer;
