import { takeEvery, put, select } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { CategoriesListTypes } from "../Interfaces";

import { GET_NEXT_PAGE } from "../Consts";

import {
  getCategories,
  setSkillGroup,
  getSkillGroups,
  setCategories,
  setSkillGroups,
  getNextPage,
  addCategories
} from "../Actions/CategoriesList.action";

function* getSkillGroupsData(action) {
  const response = yield httpRequest("GET", `bff/dictionary/skillgroups`);

  if (response?.success) {
    yield put(setSkillGroups(response.data));
  }
}

function* getCategoriesData(action) {
  const { skillGroup, page }: CategoriesListTypes.State = yield select(
    ({ CategoriesList }) => CategoriesList
  );

  let query = {
    page
  };

  if (!!skillGroup) {
    query["skillGroup"] = skillGroup;
  }

  const response = yield httpRequest("GET", `bff/settings/categories`, {
    query
  });

  if (response?.success) {
    if (action.type === GET_NEXT_PAGE) {
      yield put(addCategories(response.data?.content));
    } else {
      yield put(setCategories(response.data?.content));
    }
  }
}

export const getSkillGroupsSaga = function* () {
  yield takeEvery([getSkillGroups], getSkillGroupsData);
};

export const getCategoriesSaga = function* () {
  yield takeEvery(
    [getCategories, setSkillGroup, getNextPage],
    getCategoriesData
  );
};
