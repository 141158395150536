import { takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { httpRequest } from '../../../ReactCore/shared/httpRequest';
import { actionUsers } from '../Store/Users.reducer';

type UsersQuery = {
  page?: number;
  size?: number;
  query?: string;
  skill_id?: string;
};

function* getUsersSettingsSaga({ type, payload }: PayloadAction<UsersQuery>) {
  yield put(actionUsers.startLoading(type));

  let query = { page: 0 };

  Object.entries(payload).forEach(([ queryEl, val ]) => {
    if (val) query[queryEl] = val;
  });

  try {
    const res = yield httpRequest(
      "GET",
      "bff/settings/profiles",
      { query }
    );

    if (res.success) {
      if (payload.page !== 0) {
        if (!!res.data?.content?.length) {
          yield put(actionUsers.addUsersIntoList(res.data.content));
        }
      } else {
        yield put(actionUsers.setUserList(res.data.content));
      }
    }

  } catch (error) {
    console.error(error)
  } finally {
    yield put(actionUsers.finishLoading(type));
  }
}

export default function* () {
  yield takeEvery(
    actionUsers.getUserListAction,
    getUsersSettingsSaga
  );
}
