import { Status } from "../../ReactCore/shared/consts";
import { AppealsData } from "./Appeals";

export const getStatusName = (status: number): string => {
  if (status === Status.Open || status === Status.Queue) return "Открыто";
  if (status === Status.Close) return "Закрыто";
  return "Ожидание";
};

export const getUserInfo = ({ usersFilterList, status, skillName }: AppealsData) => {
  const isEmpty = !usersFilterList || status === Status.Queue ? "В очереди" : null;
  const uniqGroupNames = usersFilterList
    ?.map((u) => u.userSkillGroupName)
    .filter((v, i, arr) => arr.indexOf(v) === i);
  const userNames = usersFilterList?.map((u) => `${u.userSecName} ${u.userName}`);
  const userPhotos = usersFilterList?.map((u) => u.userPhoto);

  return {
    label: isEmpty || getUserName(userNames),
    avatar: !isEmpty ? getUserPhoto(userPhotos) : null,
    value: getUserGroupName(uniqGroupNames) || skillName
  };
};

const getUserGroupName = (names?: string[]): string => {
  if (!names) return "";

  if (names.length > 1) {
    return "Более 1-й группы";
  } else {
    return names.pop();
  }
};

const getUserName = (names?: string[]) => {
  if (!names) return "";

  if (names.length > 1) {
    return "Более 1-го агента";
  } else {
    return names[0];
  }
};

const getUserPhoto = (photos?: string[]) => {
  if (!photos) return "";

  if (photos.length > 1 || !photos[0]) {
    return "";
  } else {
    return photos[0];
  }
};

export const getRating = (data: AppealsData): string => {
  return data.rating
    ? `${data.rating}`
    : data.status === Status.Close
    ? "-"
    : "";
};
