import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import {
  setClassifiers,
  getAllClassifiers,
  GET_ALL_CLASSIFIERS,
  startLoading,
  finishLoading
} from "../Store/Classifiers.reducer";

function* getClassifiersSaga() {
  yield put(startLoading(GET_ALL_CLASSIFIERS));
  try {
    const response = yield httpRequest("GET", "bff/settings/classifier");

    if (response?.success) {
      yield put(setClassifiers(response.data));
    }
  } catch (e) {
    console.error("Saga: getCategoriesSaga error", e);
  } finally {
    yield put(finishLoading(GET_ALL_CLASSIFIERS));
  }
}

export default function* () {
  yield takeEvery([getAllClassifiers], getClassifiersSaga);
}
