const NAMESPACE = "CategoriesList";

export const DELETED = `deleted`;
export const ALL = `all`;

export const GET_NEXT_PAGE = `${NAMESPACE}_GET_NEXT_PAGE`;

export const GET_CATEGORIES = `${NAMESPACE}_GET_CATEGORIES`;
export const GET_SKILLGROUPS = `${NAMESPACE}_GET_SKILLGROUPS`;

export const SET_SKILLGROUPS = `${NAMESPACE}_SET_SKILLGROUPS`;
export const SET_CATEGORIES = `${NAMESPACE}_SET_CATEGORIES`;
export const ADD_CATEGORIES = `${NAMESPACE}_ADD_CATEGORIES`;

export const SET_SKILLGROUP = `${NAMESPACE}_SET_SKILLGROUP`;
export const SET_CATEGORY = `${NAMESPACE}_SET_CATEGORY`;
