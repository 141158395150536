import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useQuery = <T>(): {
  search: string;
  query: { [key in keyof T]: string };
} => {
  const { search } = useLocation();

  const urlSearch = React.useMemo(() => new URLSearchParams(search), [search]);
  const query = React.useMemo(
    () => Object.fromEntries(urlSearch) as { [key in keyof T]: string },
    [urlSearch]
  );

  return {
    search,
    query
  };
};

export default useQuery;
