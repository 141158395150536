import { createAction } from "@reduxjs/toolkit";
import {
  GET_REGIONS,
  FETCH_RFS,
  FETCH_MRFS,
  GET_REPLIES_CATEGORIES,
  GET_TRANSFER_DICTIONARY,
  LOADED_TRANSFER_DICTIONARY,
  CLEAN_TRANSFER_DICTIONARY,
  GET_USERS_PROFILE_CATALOGS,
  SET_AREAS_LIST,
  SET_SKILL_GROUPS_LIST,
  SET_TERRITORIES_LIST,
  SET_ROLES_LIST,
  SET_ORGANIZATIONS_LIST
} from "../consts";

export const getRepliesCategories = (page) => ({
  type: GET_REPLIES_CATEGORIES,
  payload: page
});

export const getRegions = () => ({
  type: GET_REGIONS
});

export const getMrfs = () => ({
  type: FETCH_MRFS
});

export const getRfs = () => ({
  type: FETCH_RFS
});

/**
 * Создаст экшн для получения списка трансфера скилл-групп.
 */
export const getTransferDictionary = createAction<any | undefined>(
  GET_TRANSFER_DICTIONARY
);

export const loadedTransferDictionary = (data) => ({
  type: LOADED_TRANSFER_DICTIONARY,
  payload: { data }
});

export const cleanTransferDictionary = createAction<any | undefined>(
  CLEAN_TRANSFER_DICTIONARY
);

export const getUsersProfileCatalogs = (data) => ({
  type: GET_USERS_PROFILE_CATALOGS,
  payload: { data }
});

export const setAreasList = (data) => ({
  type: SET_AREAS_LIST,
  payload: { data }
});
export const setSkillGroupList = (data) => ({
  type: SET_SKILL_GROUPS_LIST,
  payload: { data }
});
export const setTerritoriesList = (data) => ({
  type: SET_TERRITORIES_LIST,
  payload: { data }
});
export const setRolesList = (data) => ({
  type: SET_ROLES_LIST,
  payload: { data }
});
export const setOrganizationsList = (data) => ({
  type: SET_ORGANIZATIONS_LIST,
  payload: { data }
});
