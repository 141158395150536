import React, { useState, useEffect } from "react";

import {
  SelectV2,
  Select,
  Button,
  Icon,
  AttachmentUpload,
  isEmpty,
  useStateWithCallback
} from "@omnichat/arm_ui_kit";

import {
  Input,
  FormField,
  Tooltip,
  DropdownButton,
  MenuOption,
  TextArea
} from "../../../../Components";
import EmojiPicker from "../../../Dialog/Components/EmojiPicker";
import {
  IValidationErrors,
  IValidationRule
} from "../../../Common/Interfaces/IValidation";
import { validate, validationRules } from "../../../Common/Utils/CommonUtils";
import { reservedKeyCodes } from "../../../Common/Consts/KeyCodes";
import { convertKeyCodes } from "../../../Common/Utils/Text.utils";

import KeyShortcutInput from "../KeyShortcutInput/KeyShortcutInput";
import Attachment from "./Attachment";
import { ReplyTemplateEditDictionary } from "./Consts";
import { ITemplateHotKey } from "./Models";
import * as s from "./ReplyTemplateEdit.module.scss";

interface Category {
  id: number;
  value: string | number;
  label: string;
}

interface Region {
  id: number;
  value: string | number;
  label: string;
}

/**
 * Модель данных шаблона ответа.
 *
 * @prop {string} title Название.
 * @prop {string} body Содержание.
 * @prop {number[]} category Категория.
 * @prop {number[]} regions Выбранные регионы.
 * @prop {string[]} attachments Добавленные приложения.
 * @prop {string | null} [keyShortcut] Описание сочетания горячих клавиш.
 * @prop {string | null} [hotKeyCodes] Кодовое представление сочетания горячих клавиш для быстрого использования шаблона.
 */
interface IReplyData {
  title: string;
  body: string;
  category: {
    value: number;
    label: string;
  } | null;
  regions: number[];
  attachments: string[];
  keyShortcut?: string | null;
  hotKeyCodes?: string | null;
}

interface INewAttachmentData {
  pending: boolean;
  progress: number;
  failed: boolean;
  sended: boolean;
  name: string;
  uuid: string;
  data: {
    uuid: string;
    originalName: string;
    mimeType: string;
  } | null;
}

/**
 * Модель собственных свойств компонента редактирования шаблона.
 *
 * @prop {Map<string,string>} [usedHotKeys] Map привязанных к шаблонам горячим клавишам.
 */
interface IReplyTemplateEdit {
  id?: number | string;
  categories: Category[];
  regions: Region[];
  replyData?: IReplyData;
  onLoadFile: any;
  newAttachments: INewAttachmentData[];
  removeNewAttachment: (uuid: string) => void;
  onSubmit: (data: IReplyData) => void;
  usedHotKeys?: Map<string, string>;
  onNextCategoryPage: () => void;
}

export interface IValidationState {
  name?: string | null;
  content?: string | null;
  category?: string | null;
  region?: string | null;
}

const ReplyTemplateEdit = ({
  id = null,
  categories = [],
  regions = [],
  replyData,
  newAttachments = [],
  onLoadFile,
  removeNewAttachment,
  onSubmit,
  usedHotKeys,
  onNextCategoryPage
}: IReplyTemplateEdit) => {
  let [nameValue, setName] = useState("");
  let [initContentValue, setInitContentValue] = useState("");
  let [contentValue, setContent] = useState("");
  let [categoryValue, setCategory] = useState<{
    value: number;
    label: string;
  } | null>(null);
  let [regionValue, setRegion] = useState<number[]>([]);
  let [attachments, setAttachments] = useState<string[]>([]);
  let [hotKey, setKeyCodes] = useStateWithCallback<ITemplateHotKey>({
    keyCodes: [],
    keyShortcutDescription: null
  });
  const [errors, setErrors] = useState<IValidationErrors>({});

  let body = null;

  const pasteTemplateLugature = (id: number) => {
    const ligatures = ["_ИМЯ_КЛИЕНТА_", "_ПСЕВДОНИМ_"];

    body.past(ligatures[id]);
  };

  const addEmoji = (emoji: any) => {
    body.past(emoji.native);
  };

  const dropdownSX = {
    button: {
      btnWrap: {
        height: "30px",
        backgroundColor: "#cee6f2",
        borderRadius: "3px"
      }
    },
    menu: () => ({
      ".MuiPaper-root": {
        marginTop: "4px"
      },
      ".MuiButtonBase-root.MuiMenuItem-root": {
        padding: "5px",
        backgroundColor: "#fff",

        "&:hover": {
          backgroundColor: "#e4f1f7"
        }
      },
      ".MuiPopover-paper.MuiMenu-paper": {
        borderRadius: "2px",
        boxShadow: "0 1px 5px rgba(0,0,0,.8)",
        minWidth: "150px"
      }
    })
  };

  const pastingActions = () => {
    return (
      <>
        <div className={s["attachFileContainer"]}>
          <Tooltip
            content="Прикрепить файл"
            position="top"
            modifiers={{ offset: 10 }}
          >
            <AttachmentUpload onChange={onLoadFile} />
          </Tooltip>
        </div>
        <div className={s["emojiContainer"]}>
          <EmojiPicker onAdd={addEmoji} multiple={false}>
            {(handleOpen) => (
              <div
                className={s["emojiButton"]}
                onClick={handleOpen}
                emoji-picker-opener="true"
              >
                <Icon color="blue" name="emoticon" width="24px" height="24px" />
              </div>
            )}
          </EmojiPicker>
        </div>
        <DropdownButton
          text="Вставка"
          disableIcon
          tooltipContent="Вставить имя или псевдоним"
          anchorTransformPosition="center"
          extraSX={dropdownSX}
          menuItems={[
            {
              id: "template_dropdown_paste_first_name",
              content: <MenuOption text="Имя клиента" />,
              onClick: () => pasteTemplateLugature(0)
            },
            {
              id: "template_dropdown_paste_nick_name",
              content: <MenuOption text="Псевдоним агента" />,
              onClick: () => pasteTemplateLugature(1)
            }
          ]}
        />
      </>
    );
  };

  const parseFileName = (fileData: string) => {
    const [uuid, name] = fileData.split("/");
    return {
      uuid,
      name
    };
  };

  const removeAttachment = (id: number) => {
    let editedAttachments = [...attachments];
    editedAttachments.splice(id, 1);
    setAttachments(editedAttachments);
  };

  const concatAttachments = () => {
    let result = [...attachments];

    newAttachments.map((attach) => {
      if (attach.sended && attach.data) {
        const { uuid, originalName } = attach.data;
        const concatString = `${uuid}/${originalName}`;
        result.push(concatString);
      }
    });

    return result;
  };

  const customValidationRules: IValidationRule[] = [
    {
      rule: validationRules.isNotEmpty,
      fieldName: "nameValue",
      isRequired: true,
      errorText: "Укажите название."
    },
    {
      rule: validationRules.isNotEmpty,
      fieldName: "contentValue",
      isRequired: true,
      errorText: "Укажите содержание."
    },
    {
      rule: (field: string) => [!!field && field.length < 4000],
      fieldName: "contentValue",
      errorText:
        "Превышено допустимое количество символов в поле (4000 символов)."
    },
    {
      rule: validationRules.isNotEmpty,
      fieldName: "categoryValue",
      isRequired: true,
      errorText: "Укажите категорию."
    },
    {
      rule: (field: string[]) => {
        let isFieldValid = true;
        let templateText = null;

        if (usedHotKeys) {
          isFieldValid = [...usedHotKeys.keys()].every(
            (hotKey: string) => hotKey !== field[0]
          );
        }

        if (!isFieldValid) templateText = usedHotKeys?.get(field[0]);

        return [isFieldValid, { templateText }];
      },
      fieldName: "hotKey",
      errorText: (template) =>
        `Данная комбинация клавиш уже используется в шаблоне "${template}"`
    },
    {
      rule: (field) => [
        [...reservedKeyCodes.values()].every(
          (reservedKeyCode: string) => reservedKeyCode !== field[0]
        )
      ],
      fieldName: "hotKey",
      errorText: "Данная комбинация клавиш зарезервирована ОС."
    }
  ];

  /**
   * Валидирует все указанные поля формы.
   * Возвращает результат в виде boolean.
   */
  const handleValidateFormFields = (): boolean => {
    const form = {
      nameValue,
      contentValue,
      categoryValue,
      hotKey: hotKey.keyCodes,
      regionValue
    };
    const { isValid, errors } = validate(form, customValidationRules);

    setErrors(errors);

    return isValid;
  };

  /**
   * Валидирует указанное поле и обновляет объект ошибок.
   *
   * @param {{[key: string]: any}} field Объект, содержащий поле для валидации.
   */
  const handleValidateField = (field: { [key: string]: any }) => {
    const { errors: newErrors } = validate(field, customValidationRules);

    setErrors((prevState) => ({ ...prevState, ...newErrors }));
  };

  /**
   * Обрабатывает сохранение данных при их валидности.
   *
   * @param {React.FormEvent<HTMLFormElement>} e Синтетическое событие.
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (handleValidateFormFields()) {
      let keyShortcut =
        !isEmpty(hotKey.keyCodes) && hotKey.keyShortcutDescription
          ? hotKey.keyShortcutDescription
          : null;

      onSubmit({
        title: nameValue,
        body: contentValue,
        category: categoryValue ? categoryValue : null,
        regions: regionValue,
        attachments: concatAttachments(),
        hotKeyCodes: hotKey.keyCodes?.[0] || null,
        keyShortcut
      });
    }
  };

  useEffect(() => {
    if (replyData) {
      !nameValue.length && setName(replyData.title);
      !contentValue.length && setInitContentValue(replyData.body);
      !contentValue.length && setContent(replyData.body);
      !categoryValue && setCategory(replyData.category);
      !regionValue.length && setRegion(replyData.regions);
      !attachments.length && setAttachments(replyData.attachments);
      !hotKey.keyCodes.length &&
        replyData.hotKeyCodes &&
        setKeyCodes({
          keyCodes: [replyData.hotKeyCodes],
          keyShortcutDescription: convertKeyCodes([replyData.hotKeyCodes])[0]
        });
    }
  }, [replyData]);

  /**
   * Обрабатывает изменение горячих клавиш.
   *
   * @param {string[]} newKeyCodes Кодовое представление горячих клавиш.
   * @param {string} currentKeyShortcut Описание горячих клавиш.
   */
  const handleChangeKeyCodes = (
    newKeyCodes: string[],
    currentKeyShortcut?: string
  ) => {
    let result: string[] = [];

    if (!isEmpty(newKeyCodes)) result = [...newKeyCodes];

    setKeyCodes(
      {
        keyCodes: result,
        keyShortcutDescription: currentKeyShortcut || null
      },
      () => handleValidateField({ hotKey: result })
    );
  };

  return (
    <div className={s["main"]}>
      <div className={s["title"]}>
        {id ? "Редактирование шаблона" : "Новый шаблон"}
      </div>
      <form className={s["form"]} onSubmit={handleSubmit}>
        <div className={s["formField"]}>
          <Input
            value={nameValue}
            isError={!!errors.nameValue}
            actionText={errors.nameValue ? errors.nameValue[0] : undefined}
            label="Название"
            name="template_name"
            placeholder="Не указано"
            onChange={(value) => setName(value)}
            required
          />
        </div>
        <div className={s["formField"]}>
          <TextArea
            ref={(inst) => (body = inst)}
            label="Содержание"
            focus="Введите текст шаблона"
            placeholder="Введите текст шаблона"
            actions={pastingActions}
            onChange={(e) => setContent(e.text)}
            isError={!!errors.contentValue}
            actionText={
              errors.contentValue ? errors.contentValue[0] : undefined
            }
            initialValue={initContentValue}
            required
          />

          <div className={s["infoBlock"]}>
            На место вставки, при отправке шаблона, будет автоматически
            подставлено соответствующее имя
          </div>
        </div>

        <div className={s["attachments"]}>
          <div className={s["subTitle"]}>Прикрепленные файлы:</div>
          <ul className={s["list"]}>
            {newAttachments.map((item, i) => (
              <Attachment
                key={i}
                sended={item.sended}
                name={item.name}
                uuid={item.uuid}
                progress={item.progress}
                loading={item.pending}
                failed={item.failed}
                removeAttachment={() => removeNewAttachment(item.uuid)}
              />
            ))}

            {attachments.map((item, i) => {
              const { name, uuid } = parseFileName(item);
              return (
                <Attachment
                  key={i}
                  sended={true}
                  name={name}
                  uuid={uuid}
                  removeAttachment={() => removeAttachment(i)}
                />
              );
            })}
          </ul>
        </div>

        <div className={s["formField"]}>
          <SelectV2
            options={categories}
            selected={categoryValue ? [categoryValue] : []}
            onSelectOption={(e) => setCategory(e[0])}
            label="Категория"
            required
            isSearchable
            isError={!!errors.categoryValue}
            actionText={
              errors.categoryValue ? errors.categoryValue[0] : undefined
            }
            onPaginationBottom={() => onNextCategoryPage()}
          />
        </div>
        <div className={s["formField"]}>
          <Select
            options={regions}
            selected={regionValue}
            onSelectOption={(e) => setRegion(e)}
            label="Регион"
            isMulti
            isSearchable
            actionText="Если регион для шаблона не указан, то шаблон будет отображаться для всех"
          />
        </div>

        <FormField
          label={ReplyTemplateEditDictionary.Modal.keyShortcut.label}
          errors={errors.hotKey}
        >
          <KeyShortcutInput
            values={hotKey.keyCodes}
            onChangeKeyShortcut={handleChangeKeyCodes}
            setOneKeyShortcutOnly
            texts={{
              inputPlaceholder:
                ReplyTemplateEditDictionary.Modal.keyShortcut.inputPlaceholder,
              textIfEmptyValues:
                ReplyTemplateEditDictionary.Modal.keyShortcut.emptyValues,
              defaultHintText:
                ReplyTemplateEditDictionary.Modal.keyShortcut.formFieldHint,
              hintWhenSettingShortcut:
                ReplyTemplateEditDictionary.Modal.keyShortcut.inputHint
            }}
            theme={errors.hotKey ? "Error" : "Default"}
          />
        </FormField>

        <div className={s["formSuccessField"]}>
          <Button
            htmlType="submit"
            type="default"
            theme="green"
            text="Сохранить"
          />
        </div>
      </form>
    </div>
  );
};

export default ReplyTemplateEdit;
