import React, { FC, MouseEvent, ReactNode, memo, useState } from "react";
import { Menu, Divider, MenuItem, SxProps, Theme } from "@mui/material";

import { SvgIconComponent } from "@mui/icons-material";

import Tooltip from "../Tooltip";
import MainButton from "./MainButton";

interface IDropdownOption {
  id?: string;
  content?: ReactNode;
  divider?: boolean;
  className?: string;
  hide?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export interface DropdownButtonProps {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  anchorTransformPosition?: "left" | "right" | "center";
  menuItems?: Array<IDropdownOption>;
  menu?: boolean;
  iconButton?: {
    position?: "left" | "right";
    CollapsedIcon?: SvgIconComponent;
    ExpandedIcon?: SvgIconComponent;
  };
  disableIcon?: boolean;
  tooltipContent?: string;
  hover?: boolean;
  children?: ({ close }: { close?: () => void }) => ReactNode;
  disableFocus?: boolean;
  extraSX?: {
    button?: {
      btnWrap?: SxProps<Theme>;
      text?: SxProps<Theme>;
    };
    menu?: SxProps<Theme>;
    divider?: SxProps<Theme>;
  };
}

const DropdownButton: FC<DropdownButtonProps> = ({
  text,
  loading,
  disabled,
  menu,
  iconButton,
  disableIcon = false,
  tooltipContent = "",
  hover,
  anchorTransformPosition = "left",
  children,
  disableFocus,
  menuItems,
  extraSX
}) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!hover) setAnchorEl(e.currentTarget);
  };

  const handleMouseEnter = (e: MouseEvent<HTMLButtonElement>) => {
    if (hover) setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const buttonProps = {
    text: text,
    disabled: disabled,
    loading: loading,
    disableIcon: disableIcon,
    iconButton: iconButton,
    extraSX: extraSX?.button,
    onClick: handleClick,
    onMouseEnter: handleMouseEnter
  };

  return (
    <>
      {tooltipContent ? (
        <Tooltip content={tooltipContent} enterDelay={500} followCursor>
          <MainButton {...buttonProps} anchorEl={anchorEl} />
        </Tooltip>
      ) : (
        <MainButton {...buttonProps} anchorEl={anchorEl} />
      )}

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: anchorTransformPosition
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: anchorTransformPosition
        }}
        MenuListProps={{ "aria-labelledby": "drop-down-button" }}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        sx={extraSX?.menu ?? {}}
      >
        {!!children && children?.({ close: handleCloseMenu })}
        {!children &&
          menuItems
            ?.filter((i) => !!i && !i.hide)
            /* removes double dividers */
            ?.filter((i, indx, arr) => !(i.divider && arr[indx + 1]?.divider))
            /* removes first and last dividers */
            ?.filter(
              (i, indx, arr) =>
                !(i.divider && (indx === 0 || indx === arr.length - 1))
            )
            .map((i, idx) =>
              i.divider ? (
                <Divider key={i.id || idx} sx={extraSX?.divider ?? {}} />
              ) : (
                <MenuItem
                  key={i.id || idx}
                  id={i.id}
                  onClick={() => {
                    handleCloseMenu();
                    i.onClick?.();
                  }}
                  disabled={i.disabled}
                >
                  {i.content}
                </MenuItem>
              )
            )}
      </Menu>
    </>
  );
};

export default memo(DropdownButton);
