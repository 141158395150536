import React, { FC, memo, useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Preloader, ScrollBox, isEmpty } from "@omnichat/arm_ui_kit";
import { Typography } from "@mui/material";

import { ModalWindow } from "../../Components";
import SettingsSection from "../../ReactFeatures/Common/Components/SettingsSection/SettingsSection";
import Modal from "../../arm/Wrappers/Modal";

import { useActions } from "./Store/Roles.reducer";
import * as selectors from "./Store/Roles.selector";
import {
  RolesList,
  RoleView,
  RoleEdit,
  RoleDeleteModal,
  AddRoleButton,
  ConfirmCancelEditRole
} from "./Components";
import { PATH_VIEW_ROLE, PATH_EDIT_ROLE, PATH_CREATE_ROLE } from "./const";
import { getItemById, getEditedRoleFromPath } from "./utils";

const Roles: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const actions = useActions();

  const [activeRole, setActiveRole] = useState(null);
  const [contentScrollRef, setContentScrollRef] = useState(null);
  const [openedConfirmDeleteModal, setOpenedConfirmDeleteModal] = useState(
    false
  );
  const [editedRoleForm, setEditedRoleForm] = useState<boolean>(false);

  const rolesList = useSelector(selectors.getRolesList);
  const rightsList = useSelector(selectors.getRightsList);
  const dataSendHandling = useSelector(selectors.getDataSendHandling);

  useEffect(() => {
    actions.getRoles();

    const roleId = getEditedRoleFromPath(pathname);
    if (roleId) {
      getRightsByRole(roleId);
      setActiveRole(roleId);
    }

    return () => {
      actions.clearRolesAndRights();
    };
  }, []);

  useEffect(() => {
    if (
      !isEmpty(rolesList) &&
      (!activeRole || !rolesList?.find(({ id }) => id === activeRole))
    ) {
      const roleId = rolesList[0].id;
      goto(`/${roleId}`);
      setActiveRole(roleId);
      getRightsByRole(roleId);
    }
  }, [rolesList]);

  const getContentSrcollRef = (ref) => {
    ref && setContentScrollRef(ref);
  };

  const getRightsByRole = (roleId) => {
    actions.getRights({ roleId });
  };

  const goto = (
    path: string,
    callback?: Function,
    _editedRoleForm?: boolean
  ): void => {
    if (_editedRoleForm ?? editedRoleForm) {
      renderModalCancelEditRole(path, callback);
      return;
    }
    navigate(path);
    callback?.();
  };

  const handleSelectRole = (id, _editedRoleForm?: boolean) => {
    goto(
      `/${id}`,
      () => {
        setActiveRole(id);
        getRightsByRole(id);
        contentScrollRef && contentScrollRef.scrollTop();
      },
      _editedRoleForm
    );
  };

  const handleAddNewRole = () => {
    goto(`/${PATH_CREATE_ROLE}`, () => {
      setActiveRole(null);
    });
  };

  const handleEditRole = () => {
    goto(`/${activeRole}/${PATH_EDIT_ROLE}`, () => {
      contentScrollRef && contentScrollRef.scrollTop();
    });
  };

  const renderModalCancelEditRole = (path, callback) => {
    const modalId = "ConfirmCancelEditRole";
    const modal = new Modal(modalId, 550, (close) => (
      <div style={{ padding: "25px" }}>
        <ConfirmCancelEditRole
          onConfirm={() => {
            navigate(path);
            callback?.();
            close();
            setEditedRoleForm(false);
          }}
          onCancel={() => {
            close();
          }}
        />
      </div>
    ));
    modal.open();
  };

  const handleCancelEditRole = (_editedRoleForm?: boolean) => {
    if (!activeRole) {
      const roleId = rolesList[0].id;
      goto(
        `/${roleId}`,
        () => {
          setActiveRole(roleId);
          contentScrollRef && contentScrollRef.scrollTop();
        },
        _editedRoleForm ?? editedRoleForm
      );
    } else {
      goto(
        `/${activeRole}`,
        () => {
          contentScrollRef && contentScrollRef.scrollTop();
        },
        _editedRoleForm ?? editedRoleForm
      );
    }
  };

  const handleSaveEditRole = (data) => {
    actions.sendEditedRole({
      roleId: data?.role?.id || activeRole,
      formData: data,
      callback: () => {
        actions.getRoles();
        handleCancelEditRole(false);
      }
    });
  };

  const handleSaveNewRole = (data) => {
    actions.sendNewRole({
      formData: data,
      callback: (idNewRole) => {
        handleSelectRole(idNewRole, false);
      }
    });
  };

  const openConfirmDelete = () => {
    setOpenedConfirmDeleteModal(true);
  };

  const closeConfirmDeleteModal = () => {
    setOpenedConfirmDeleteModal(false);
  };

  const handleSendDeleteRole = () => {
    actions.deleteRole({
      roleId: activeRole,
      callback: () => {
        closeConfirmDeleteModal();
        actions.getRoles();
      }
    });
  };

  return (
    <>
      <SettingsSection
        title="Управление ролями"
        menu={
          <ScrollBox autoHeight hasScrollControlBackground>
            <Preloader size="mini" show={isEmpty(rolesList)}>
              <div style={{ paddingRight: "15px", marginTop: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="h3">Роли</Typography>
                  <AddRoleButton
                    disabled={pathname === `/${PATH_CREATE_ROLE}`}
                    onClick={handleAddNewRole}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <RolesList
                    current={activeRole}
                    list={rolesList || []}
                    onSelect={handleSelectRole}
                  />
                </div>
              </div>
            </Preloader>
          </ScrollBox>
        }
        content={
          <ScrollBox
            autoHeight
            hasScrollControlBackground
            referens={getContentSrcollRef}
          >
            <div
              style={{
                paddingRight: "15px",
                marginTop: "5px",
                paddingBottom: "40px"
              }}
            >
              <Routes>
                <Route
                  key={PATH_VIEW_ROLE}
                  path={`/:roleId/*`}
                  element={
                    <Preloader
                      size="mini"
                      show={
                        isEmpty(rightsList) ||
                        !getItemById(activeRole, rolesList)
                      }
                    >
                      <RoleView
                        role={getItemById(activeRole, rolesList)}
                        rights={rightsList}
                        onEditRole={handleEditRole}
                        onDeleteRole={openConfirmDelete}
                      />
                    </Preloader>
                  }
                />
                <Route
                  key={PATH_EDIT_ROLE}
                  path={`/:roleId/${PATH_EDIT_ROLE}`}
                  element={
                    <Preloader
                      size="mini"
                      show={
                        isEmpty(rightsList) ||
                        !getItemById(activeRole, rolesList)
                      }
                    >
                      <RoleEdit
                        editMode={true}
                        formData={
                          activeRole && rolesList && rightsList
                            ? {
                                role: getItemById(activeRole, rolesList),
                                rights: rightsList
                              }
                            : null
                        }
                        onFormHasDataChanged={setEditedRoleForm}
                        onSave={handleSaveEditRole}
                        onCancel={handleCancelEditRole}
                      />
                    </Preloader>
                  }
                />
                <Route
                  key={PATH_CREATE_ROLE}
                  path={`/${PATH_CREATE_ROLE}`}
                  element={
                    <RoleEdit
                      onFormHasDataChanged={setEditedRoleForm}
                      onCancel={handleCancelEditRole}
                      onSave={handleSaveNewRole}
                    />
                  }
                />
              </Routes>
            </div>
          </ScrollBox>
        }
      />
      <ModalWindow
        open={openedConfirmDeleteModal}
        onCloseModal={closeConfirmDeleteModal}
        isButtonClose
      >
        <RoleDeleteModal
          handling={dataSendHandling}
          onConfirm={handleSendDeleteRole}
        />
      </ModalWindow>
    </>
  );
};

export default memo(Roles);
