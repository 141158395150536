import { chatInit, chatDestroy } from "./Constructors/ChatConstructor";

export default {
  draw: {
    tpls: function(data) {
      var tpls = {};

      if (data.urlCur.sec !== "chat") tpls.chat = "body";
      else tpls.chat_dialog = "chat_dialog";

      return tpls;
    }
  },

  nav: {
    init: function() {
      chatInit();
      $(window)
        .on("focus", function() {
          $(window).data("focused", true);
          core.notification.favicon.change("/www/img/favicon.ico");
        })
        .on("blur", function() {
          $(window).data("focused", false);
        });
      core.websocket.tab_id = core.websocket.tab_id || Math.random();
      core.storage.set("tab_id", core.websocket.tab_id);
    },

    destroy: function(data) {
      chatDestroy();
    },

    before: function(data) {},

    after: function(data) {
      core.notification.favicon.change("/www/img/favicon.ico");
    }
  }
};
