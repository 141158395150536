import * as Sentry from "@sentry/react";

import { store } from "../../ReactCore";

import { IWsNewMessage } from "../../ReactFeatures/Dialog/Interfaces";
import { IPreparedNewMessageData } from "../Chat";

import * as chatSelectors from "../store/Chat.selectors";
import * as chatActions from "../store/Chat.actionCreators";

import * as dialogSelectors from "../../ReactFeatures/Dialog/Dialog.selectors";
import {
  pushMessagesIntoDialogBottom,
  setOpenFromNotification
} from "../../ReactFeatures/Dialog/Reducers/Dialog.reducer";

import {
  getUpdatedDialogListData,
  mapNewMessageData,
  hasAvalibleDialog,
  updateUnreadList
} from "../Chat.service";

import {
  showNewMessageNotyfication,
  showNewDialogNotyfication
} from "../Chat.notifications";
import { isEmpty } from "@omnichat/arm_ui_kit";

const newMessageAction = (payload: { data: IWsNewMessage }) => {

  let dialogList = chatSelectors.getDialogList(store.getState());
  let unreadList = chatSelectors.getUnreadList(store.getState());
  let activeDialog = dialogSelectors.getActiveDialog(store.getState());

  if (activeDialog && payload.data && activeDialog === payload.data.dialogId) {
    store.dispatch(pushMessagesIntoDialogBottom([payload.data.message]));
    store.dispatch(setOpenFromNotification(false));
  }

  if (!isEmpty(payload.data)) {
    const newMessageData: IPreparedNewMessageData = mapNewMessageData(
      payload.data
    );

    const updatedDialogList = getUpdatedDialogListData(dialogList, [
      newMessageData
    ]);
    const updatedUnreadList = updateUnreadList(unreadList, newMessageData.dialogId, newMessageData.unread)
    store.dispatch(setOpenFromNotification(false));

    core.storage.set(
      "msg_appeal_count",
      updatedDialogList?.length ? updatedDialogList.length : 0
    );
    store.dispatch(chatActions.updateDialogList(updatedDialogList));
    store.dispatch(chatActions.updateUnreadList(updatedUnreadList));
  }

  // для работы еще актуальной старой логики блокировки перехода в другой раздел,
  // если оператор все еще онлайн и на нем есть активные обращения
  msg.websocket.new_message.count_delay++;

  if (NOTIFICATION_ACTIVE_WINDOW == "1" || !core.windowActive) {
    if (hasAvalibleDialog(dialogList, payload.data.dialogId)) {
      showNewMessageNotyfication(payload.data);
    } else {
      showNewDialogNotyfication(payload.data);
    }
  }

  // Логирование получения пакетов new_message от ядра
  if (SENTRY_DEBUG_GROUP_2 === "1") {
    Sentry.withScope((scope) => {
      scope.setExtra("MessageData", payload);
      Sentry.captureMessage(
        `newMessageRecived user=${core.user.id} dialog=${activeDialog}`,
        "debug"
      );
    });
  }
};

export default newMessageAction;
