import initialState from "frontend/src/ReactCore/store/initialState";
import produce from "immer";

import initialData from "../CategoryForRepliesModal.initialData";

import * as ACTION from "../Consts";

export default function CategoryForRepliesModalReducer(
  state = initialData,
  { type, payload }
) {
  switch (type) {
    case ACTION.GET_CATEGORIES:
      return produce(state, draft => {
        draft.categories = [];
        draft.page = 0;
      });
    case ACTION.SET_CATEGORIES:
      return produce(state, draft => {
        draft.categories = [...draft.categories, ...payload];
      });
    case ACTION.GET_NEXT_PAGE:
      return produce(state, draft => {
        draft.page = draft.page + 1;
      });
    case ACTION.RESET:
      return produce(state, draft => {
        (draft.categories = initialData.categories),
          (draft.page = initialData.page);
      });
    default:
      return state;
  }
}
