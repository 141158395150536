import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getFieldData,
  setFieldData,
  setLinkData,
  GET_FIELD_DATA
} from "../Store/ClassifierFieldForm.reducer";

export function* getFieldDataSaga(action) {
  const fieldId = action.payload;
  yield put(startLoading(GET_FIELD_DATA));

  try {
    const res = yield httpRequest(
      "GET",
      `bff/settings/classifier/fields/${fieldId}`
    );

    if (res.success) {
      yield put(setFieldData(res.data));
      yield put(setLinkData(res.data?.links || []));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(GET_FIELD_DATA));
  }
}

export default function* () {
  yield takeEvery([getFieldData], getFieldDataSaga);
}
