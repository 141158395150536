import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import initialState from "../../../ReactCore/store/initialState";
import { ChartsFilters } from "../../Charts/Charts";

const NAMESPACE = "dashboard/";

export const DOWNLOAD_STATISTICS_REPORT = `${NAMESPACE}DOWNLOAD_STATISTICS_REPORT`;
export const SENT_EMAIL_REPORT = `${NAMESPACE}SENT_EMAIL_REPORT`;

export const downloadStatisticsReport = createAction<{
  filters: string;
}>(DOWNLOAD_STATISTICS_REPORT);

export const sendEmailReport = createAction<{ filters: ChartsFilters }>(
  SENT_EMAIL_REPORT
);

const DashboardSectionSlice = createSlice({
  name: "dashboardSection",
  initialState: initialState.DashboardSection,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    }
  }
});

export const actions = {
  ...DashboardSectionSlice.actions,
  downloadStatisticsReport,
  sendEmailReport
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export default DashboardSectionSlice.reducer;
