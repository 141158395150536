// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmModal--zpWHwx2Zf4vZ7KIfBhiK{max-width:500px}.buttons--F0BdNTYUH48T4uk67KTT{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/ClassifierFieldForm/style.module.scss"],"names":[],"mappings":"AAAA,oCACE,eAAA,CAEF,+BACE,YAAA,CACA,6BAAA","sourcesContent":[".confirmModal {\n  max-width: 500px;\n}\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export var confirmModal = "confirmModal--zpWHwx2Zf4vZ7KIfBhiK";
export var buttons = "buttons--F0BdNTYUH48T4uk67KTT";
export default ___CSS_LOADER_EXPORT___;
