import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import throttle from "lodash.throttle";

import {
  healthCheck,
  initSystemStatisticStomp,
  sendKeydownEvent,
  sendMouseMoveEvent
} from "./Store/SystemStatistics.reducer";
import { disconnectStomp } from "../../Stomp/Stopm.actions";

type IProps = ConnectedProps<typeof connector>;

const interval =
  HEALTH_CHECK_TIME && +HEALTH_CHECK_TIME > 0
    ? +HEALTH_CHECK_TIME * (60 * 1000)
    : 60000;

const SystemStatistics: FC<IProps> = ({
  sendHealthCheck,
  initSystemStatistic,
  sendKeydown,
  sendMouseMove,
  disconnectStatistics,
  children
}) => {
  const [healthCheckCounter, setHealthCheckCounter] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      sendHealthCheck();
    }, interval);

    setHealthCheckCounter(id);
    return () => clearInterval(healthCheckCounter);
  }, []);

  useEffect(() => {
    initSystemStatistic();
    const sendKeydownThrottling = throttle(() => sendKeydown(), 5000);
    const sendMouseMoveThrottling = throttle(() => sendMouseMove(), 5000);

    window.addEventListener("keypress", sendKeydownThrottling);
    window.addEventListener("mousemove", sendMouseMoveThrottling);
    return () => {
      disconnectStatistics();
      window.removeEventListener("keypress", sendKeydownThrottling);
      window.removeEventListener("mousemove", sendMouseMoveThrottling);
    };
  }, []);

  return <>{children}</>;
};

const mapDispatchToProps = (dispatch) => ({
  sendHealthCheck: () => dispatch(healthCheck()),
  initSystemStatistic: () => dispatch(initSystemStatisticStomp()),
  sendKeydown: () => dispatch(sendKeydownEvent()),
  sendMouseMove: () => dispatch(sendMouseMoveEvent()),
  disconnectStatistics: () => dispatch(disconnectStomp())
});

const connector = connect(null, mapDispatchToProps);

export default connector(SystemStatistics);
