import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import {
  CONNECT_ACTION,
  DISCONNECT_ACTION
} from "../../../Stomp/Stopm.actions";

export const HEALTH_CHECK = "HEALTH_CHECK";

export const INIT_SYSTEM_STATISTIC_STOMP = "INIT_SYSTEM_STATISTIC_STOMP";
export const KEY_DOWN = "KEY_DOWN";
export const MOUSE_MOVE = "MOUSE_MOVE";

export const healthCheck = createAction(HEALTH_CHECK);

export const initSystemStatisticStomp = createAction(
  INIT_SYSTEM_STATISTIC_STOMP
);
export const sendKeydownEvent = createAction(KEY_DOWN);
export const sendMouseMoveEvent = createAction(MOUSE_MOVE);

const SystemStatisticsSlice = createSlice({
  name: "SystemStatistics",
  initialState: {
    stompConnected: false
  },
  reducers: {},
  extraReducers: {
    [CONNECT_ACTION]: (state, action: PayloadAction<string>) => {
      state.stompConnected = true;
    },
    [DISCONNECT_ACTION]: (state, action: PayloadAction<string>) => {
      state.stompConnected = false;
    }
  }
});

export const {} = SystemStatisticsSlice.actions;

export default SystemStatisticsSlice.reducer;
