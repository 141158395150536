import React, { FC, useState } from "react";
import ModalWindow from "../../../../Components/ModalWindow";
import EmailMessage, { IEmailMessageProps } from "./EmailMessage";
import * as s from "./EmailMessage.module.scss"

const EmailMessageComponent: FC<IEmailMessageProps> = (props) => {
  const [emailMessageModal, setEmailMessageModal] = useState(false);

  return (
    <>
      <EmailMessage {...props} onOpen={() => setEmailMessageModal(true)} />
      <ModalWindow
        open={emailMessageModal}
        onCloseModal={() => setEmailMessageModal(false)}
        isButtonClose
      >
        <div className={s["container"]}>
          <EmailMessage {...props} />
        </div>
      </ModalWindow>
    </>
  );
};

export default EmailMessageComponent;
