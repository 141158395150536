import { store } from "../../ReactCore";

import { IWsNewMessage } from "../../ReactFeatures/Dialog/Interfaces";

import * as dialogSelectors from "../../ReactFeatures/Dialog/Dialog.selectors";
import {
  showTyping,
  hideTyping
} from "../../ReactFeatures/Dialog/Reducers/Dialog.reducer";

const typingAction = (payload: IWsNewMessage) => {
  let typingTimeoutId;
  let activeDialog = dialogSelectors.getActiveDialog(store.getState());

  if (activeDialog && activeDialog === payload.data.data[0]) {
    store.dispatch(showTyping(payload.data.data));
    if (typingTimeoutId) {
      clearTimeout(typingTimeoutId);
    }
    typingTimeoutId = setTimeout(() => {
      store.dispatch(hideTyping());
    }, 1000);
  }
};

export default typingAction;
