import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDOWNLOAD_STATUS } from "../../Common/Enums/LoadingData.enums";
import {
  setFulfilledReducer,
  setPendingReducer,
  setRejectedReducer
} from "../../Common/Reducers/LoadingData.reducers";
import {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction
} from "../../Common/Utils/LoadingData.utils";
import { DialogTypes } from "../Interfaces";

export const dialogInitialState: DialogTypes.State = {
  status: {
    initialDataLoaded: false
  },
  dialogId: null,
  appealId: null,
  prompter: {
    status: EDOWNLOAD_STATUS.IDLE,
    answers: [],
    page: 0,
    totalAnswers: 0,
    isLast: false
  },
  messages: [],
  client: null,
  region: null,
  skillGroupName: null,
  timeLimit: null,
  attachments: [],
  externalMessage: null,
  templateText: null,
  isShowingTyping: false,
  operationTime: null,
  comment: "",
  foundByContextReplyTemplates: {
    status: EDOWNLOAD_STATUS.FULFILLED,
    data: null,
    error: null
  },
  context: null,
  fromNotification: false
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState: dialogInitialState,
  reducers: {
    setDialogData: {
      reducer: (state, { payload: { data }}) => {
        state.dialogId = data.dialogId;
        state.appealId = data.appealId;
        state.messages = data.messages;
        state.timeLimit = data.timeLimit;
        state.skillGroupName = data.skillGroupName;
      },
      prepare: (data) => ({ payload: { data }, error: null, meta: null })
    },
    setOpenFromNotification: (state, { payload }) => {
      state.fromNotification = payload;
    },
    setActiveDialog: {
      reducer: (state, { payload: { dialogId, appealId } }) => {
        state.dialogId = dialogId;
        state.appealId = appealId;
      },
      prepare: (data) => ({ payload: { ...data }, error: null, meta: null })
    },
    pushMessagesIntoDialogTop: {
      reducer: (state, { payload }) => {
        state.messages = [...payload.data, ...state.messages];
      },
      prepare: (data) => ({ payload: { data }, error: null, meta: null })
    },
    nextPrompterPage: (state) => {
      if (!state.prompter.isLast) {
        state.prompter.page += 1;
      }
    },
    setPrompterStatusPending: (state) => {
      state.prompter.status = EDOWNLOAD_STATUS.PENDING;
    },
    setPrompterStatusSuccess: (state, { payload: {
      answers, isLast, totalAnswers
    }}) => {
      state.prompter.status = EDOWNLOAD_STATUS.FULFILLED;
      state.prompter.answers.push(...answers);
      state.prompter.isLast = isLast;
      if (totalAnswers > state.prompter.totalAnswers) {
        state.prompter.totalAnswers = totalAnswers;
      }
    },
    setPrompterStatusFailure: (state) => {
      state.prompter.status = EDOWNLOAD_STATUS.REJECTED;
    },
    unshiftPrompterAnswers: (state, { payload: { answers } }) => {
      state.prompter.answers = [...answers, ...state.prompter.answers];
      state.prompter.totalAnswers += answers.length;
    },
    resetPrompterData: (state) => {
      state.prompter = dialogInitialState.prompter;
    },
    setRepliesGreeting: (state, { payload }) => {
      state.templateText = payload?.text;
      state.attachments = payload?.attachments;
    },
    pushMessagesIntoDialogBottom: {
      reducer: (state, { payload }) => {
        state.messages = [...state.messages, ...payload.data];
      },
      prepare: (data) => ({ payload: { data }, error: null, meta: null })
    },
    setStateDataLoaded: (state, { payload }) => {
      state.status.initialDataLoaded = payload;
    },
    resetDialogData: (state) => {
      state.messages = dialogInitialState.messages;
      state.status.initialDataLoaded =
        dialogInitialState.status.initialDataLoaded;
      state.client = dialogInitialState.client;
      state.dialogId = dialogInitialState.dialogId;
      state.appealId = dialogInitialState.appealId;
      state.comment = dialogInitialState.comment;
    },
    setCsiData: (state, { payload }) => {
      state.csi = { ...payload };
    },
    setClientData: (state, { payload }) => {
      state.client = { ...payload };
    },
    setRegionData: (state, { payload }) => {
      state.region = { ...payload };
    },
    setOperationTime: (state, { payload }) => {
      state.operationTime = payload;
    },
    addTemplateText: (state, { payload }) => {
      state.templateText = payload;
    },
    setExternalMessage: (state, { payload }) => {
      state.externalMessage = payload;
    },
    addAttachments: (state, { payload }) => {
      state.attachments = [...state.attachments, ...payload];
    },
    cleanAttachments: (state) => {
      state.attachments = dialogInitialState.attachments;
    },
    removeAttachment: (state, { payload }) => {
      let newAttachments = [...state.attachments];
      const uuid = payload;

      const fileIndex = newAttachments.findIndex(
        (attachment) => attachment.uuid === uuid
      );

      if (fileIndex === -1) {
        return state;
      }

      newAttachments.splice(fileIndex, 1);
      state.attachments = newAttachments;
    },
    showTyping: (state) => {
      state.isShowingTyping = true;
    },
    hideTyping: (state) => {
      state.isShowingTyping = false;
    },
    getDialogData: {
      reducer: (state, { payload: { dialogId, appealId } }) => {
        state.dialogId = dialogId;
        state.appealId = appealId;
        state.status.initialDataLoaded = false;
      },
      prepare: (data) => ({ payload: { ...data }, error: null, meta: null })
    },
    resetCsiData: (state) => {
      state.csi = {};
    },
    setAppealComment: (state, { payload }: PayloadAction<string>) => {
      state.comment = payload;
    },
    resetAppealComment: (state) => {
      state.comment = dialogInitialState.comment;
    },
    setAppealContext: (state, { payload }: PayloadAction<string>) => {
      state.context = payload;
    },
    resetContextData: (state) => {
      state.context = dialogInitialState.context;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, setPendingReducer)
      .addMatcher(isFulfilledAction, setFulfilledReducer)
      .addMatcher(isRejectedAction, setRejectedReducer);
  }
});

export const {
  setDialogData,
  setActiveDialog,
  setOpenFromNotification,
  pushMessagesIntoDialogTop,
  pushMessagesIntoDialogBottom,
  setRepliesGreeting,
  setStateDataLoaded,
  resetDialogData,
  nextPrompterPage,
  setPrompterStatusPending,
  setPrompterStatusSuccess,
  setPrompterStatusFailure,
  resetPrompterData,
  unshiftPrompterAnswers,
  setCsiData,
  setClientData,
  setRegionData,
  setOperationTime,
  addTemplateText,
  setExternalMessage,
  addAttachments,
  cleanAttachments,
  removeAttachment,
  showTyping,
  hideTyping,
  getDialogData,
  resetCsiData,
  setAppealComment,
  resetAppealComment,
  setAppealContext,
  resetContextData
} = dialogSlice.actions;

export default dialogSlice.reducer;
