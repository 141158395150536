export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;
export const SPACEBAR = 32;
export const PAGEUP = 33;
export const PAGEDOWN = 34;
export const END = 35;
export const HOME = 36;
export const ENTER = 13;
export const ESC = 27;

/**
 * Мапа для преобразования кодовой кракозябры в человеческий вид
 * для отображения пользователю.
 */
export const keyCodeToCodeMap = new Map<number, string>([
  [8, "backspace"], //  backspace
  [9, "tab"], //  tab
  [13, "enter"], //  enter
  [16, "shift"],
  [17, "ctrl"], //  ctrl
  [18, "alt"], //  alt
  [19, "pause/break"], //  pause/break
  [20, "caps lock"], //  caps lock
  [27, "escape"], //  escape
  [32, "space"],
  [33, "page up"], // page up, to avoid displaying alternate character and confusing people
  [34, "page down"], // page down
  [35, "end"], // end
  [36, "home"], // home
  [37, "left arrow"], // left arrow
  [38, "up arrow"], // up arrow
  [39, "right arrow"], // right arrow
  [40, "down arrow"], // down arrow
  [45, "insert"], // insert
  [46, "delete"], // delete
  [48, "0"],
  [49, "1"],
  [50, "2"],
  [51, "3"],
  [52, "4"],
  [53, "5"],
  [54, "6"],
  [55, "7"],
  [56, "8"],
  [57, "9"],
  [65, "A"],
  [66, "B"],
  [67, "C"],
  [68, "D"],
  [69, "E"],
  [70, "F"],
  [71, "G"],
  [72, "H"],
  [73, "I"],
  [74, "J"],
  [75, "K"],
  [76, "L"],
  [77, "M"],
  [78, "N"],
  [79, "O"],
  [80, "P"],
  [81, "Q"],
  [82, "R"],
  [83, "S"],
  [84, "T"],
  [85, "U"],
  [86, "V"],
  [87, "W"],
  [88, "X"],
  [89, "Y"],
  [90, "Z"],
  [59, ";"], // Semicolon
  [61, "="], // equal
  [91, "left window"], // left window
  [92, "right window"], // right window
  [93, "select key"], // select key
  [96, "numpad 0"], // numpad 0
  [97, "numpad 1"], // numpad 1
  [98, "numpad 2"], // numpad 2
  [99, "numpad 3"], // numpad 3
  [100, "numpad 4"], // numpad 4
  [101, "numpad 5"], // numpad 5
  [102, "numpad 6"], // numpad 6
  [103, "numpad 7"], // numpad 7
  [104, "numpad 8"], // numpad 8
  [105, "numpad 9"], // numpad 9
  [106, "multiply"], // multiply
  [107, "add"], // add
  [109, "subtract"], // subtract
  [110, "decimal point"], // decimal point
  [111, "divide"], // divide
  [112, "F1"], // F1
  [113, "F2"], // F2
  [114, "F3"], // F3
  [115, "F4"], // F4
  [116, "F5"], // F5
  [117, "F6"], // F6
  [118, "F7"], // F7
  [119, "F8"], // F8
  [120, "F9"], // F9
  [121, "F10"], // F10
  [122, "F11"], // F11
  [123, "F12"], // F12
  [144, "num lock"], // num lock
  [145, "scroll lock"], // scroll lock
  [173, "-"], // minus
  [186, ";"], // semi-colon
  [187, "="], // equal-sign
  [188, ","], // comma
  [189, "-"], // dash
  [190, "."],
  [191, "/"],
  [192, "`"], // grave accent
  [219, "["], // open bracket
  [220, "\\"], // back slash
  [221, "]"], // close bracket
  [222, "'"], // single quote
]);

/*
 * Map зарезервированных ОС, браузером горячих клавиш для валидации горячих клавиш, устанавливаемых на шаблонах.
 */
export const reservedKeyCodes = new Map([
  ["alt + tab", "18+9"],
  ["left window + tab", "91+9"],
  ["left window + up arrow", "91+38"],
  ["left window + left arrow", "91+37"],
  ["left window + right arrow", "91+39"],
  ["left window + down arrow", "91+40"],
  ["left window + shift + left arrow", "91+16+37"],
  ["left window + shift + right arrow", "91+16+39"],
  ["left window + home", "91+36"],
  ["left window + pause/break", "91+19"],
  ["left window + space", "91+32"],
  ["left window + B", "91+66"],
  ["left window + D", "91+68"],
  ["left window + E", "91+69"],
  ["left window + F", "91+70"],
  ["left window + ctrl + F", "91+17+70"],
  ["left window + F1", "91+112"],
  ["left window + G", "91+71"],
  ["left window + L", "91+76"],
  ["left window + M", "91+77"],
  ["left window + P", "91+80"],
  ["left window + R", "91+82"],
  ["left window + T", "91+84"],
  ["left window + U", "91+85"],
  ["left window + X", "91+88"],
  ["left window + 0", "91+48"],
  ["left window + 1", "91+49"],
  ["left window + 2", "91+50"],
  ["left window + 3", "91+51"],
  ["left window + 4", "91+52"],
  ["left window + 5", "91+53"],
  ["left window + 6", "91+54"],
  ["left window + 7", "91+55"],
  ["left window + 8", "91+56"],
  ["left window + 9", "91+57"],
  ["left window + =", "91+61"],
  ["left window + -", "91+173"],
  ["alt + P", "18+80"],
  ["ctrl + escape", "17+27"],
  ["ctrl + shift + escape", "17+16+27"],
  ["ctrl + alt + delete", "17+18+46"],
  ["ctrl + F", "17+70"],
  ["left window + shift + tab", "91+16+9"],
  ["ctrl + A", "17+65"],
  ["ctrl + B", "17+66"],
  ["ctrl + E", "17+69"],
  ["ctrl + C", "17+67"],
  ["ctrl + X", "17+88"],
  ["ctrl + V", "17+86"],
  ["ctrl + N", "17+78"],
  ["ctrl + S", "17+83"],
  ["ctrl + O", "17+79"],
  ["ctrl + P", "17+80"],
  ["ctrl + Z", "17+90"],
  ["alt + enter", "18+13"],
  ["ctrl + left arrow", "17+37"],
  ["ctrl + right arrow", "17+39"],
  ["shift + 0", "16+48"],
  ["shift + 1", "16+49"],
  ["shift + 2", "16+50"],
  ["shift + 3", "16+51"],
  ["shift + 4", "16+52"],
  ["shift + 5", "16+53"],
  ["shift + 6", "16+54"],
  ["shift + 7", "16+55"],
  ["shift + 8", "16+56"],
  ["shift + 9", "16+57"],
  ["shift + -", "16+189"],
  ["shift + =", "16+187"],
  ["shift + left arrow", "16+37"],
  ["shift + right arrow", "16+39"],
  ["shift + up arrow", "16+38"],
  ["shift + down arrow", "16+40"],
  ["shift + delete", "16+46"],
  ["ctrl + shift + left arrow", "17+16+37"],
  ["ctrl + shift + right arrow", "17+16+39"],
  ["ctrl + end", "17+35"],
  ["ctrl + home", "17+36"],
  ["shift + F10", "16+121"],
  ["alt + shift + tab", "18+16+9"],
  ["alt + escape", "18+27"],
  ["alt + shift + escape", "18+16+27"],
  ["alt + F6", "18+117"],
  ["alt + F4", "18+115"],
  ["alt + space", "18+32"],
  ["alt + -", "18+189"],
  ["ctrl + tab", "17+9"],
  ["shift + tab", "16+9"],
  ["ctrl + L", "17+76"],
  ["ctrl + I", "17+73"],
  ["ctrl + W", "17+87"],
  ["ctrl + R", "17+82"],
  ["ctrl + 0", "17+48"],
  ["ctrl + 1", "17+49"],
  ["ctrl + 2", "17+50"],
  ["ctrl + 3", "17+51"],
  ["ctrl + 4", "17+52"],
  ["ctrl + 5", "17+53"],
  ["ctrl + 6", "17+54"],
  ["ctrl + 7", "17+55"],
  ["ctrl + 8", "17+56"],
  ["ctrl + 9", "17+57"],
]);
