import { store } from "../../ReactCore";

import { unshiftPrompterAnswers } from "../../ReactFeatures/Dialog/Reducers/Dialog.reducer";
import { IWsNewPrompterAnswers } from "../../ReactFeatures/Dialog/Interfaces";
import { getActiveAppeal } from "../../ReactFeatures/Dialog/Dialog.selectors";

const newPrompterAnswers = (payload: { data: IWsNewPrompterAnswers }) => {
  const appealId = getActiveAppeal(store.getState());
  const { answers } = payload.data;

  if (answers.some(answer => answer.appealId !== appealId)) {
    return;
  }

  store.dispatch(unshiftPrompterAnswers({ answers }));
};

export default newPrompterAnswers;
