import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { CREATE_APPEAL_REPORT } from "./AppealReport.reducer";

export const modalState = (state: StoreState.State) => {
  return state.AppealReportModal;
};

export const loading = createSelector(modalState, (state) => {
  return !isEmpty(state.loading);
});

export const reportCreating = createSelector(modalState, (state) => {
  return state.loading.includes(CREATE_APPEAL_REPORT);
});

export const skillGroups = createSelector(modalState, (state) => {
  return (
    state.skillGroups?.map(({ id, name }) => ({ value: id, label: name })) || []
  );
});

export const modalData = createSelector(modalState, (state) => {
  return state.modalData
    ? {
        ...state.modalData,
        dateStart: state.modalData.dateStart * 1000,
        dateEnd: state.modalData.dateEnd * 1000
      }
    : null;
});

export const file = createSelector(modalState, (state) => {
  return state.reportMetadata;
});
