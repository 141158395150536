import React, { FC } from "react";
import c from "classnames";

import { Avatar } from "@omnichat/arm_ui_kit";

import * as s from "./DialogMessage.module.scss";

interface IDialogMessageProps {
  /** Сторона отправителя сообщения. */
  side: "right" | "left";
  /** Информационный текст о сообщении. */
  infoText: string;
  /** Ссылка на аватар отправителя. */
  avatar?: string;
  /** Дочерний элемент. */
  children: JSX.Element;
  /** Прикрепленный файл. */
  attachFile?: string;
}

const DialogMessage: FC<IDialogMessageProps> = ({
  side = "right",
  avatar,
  attachFile,
  infoText,
  children
}): JSX.Element => {
  return (
    <div className={c(s["dialogMessage"], s[side])}>
      <div className={s["dialogMessageContainer"]}>
        <div className={s["dialogMessageMessage"]}>
          <div className={c(s["dialogMessageTriangle"])}>▲</div>
          {attachFile ? (
            <a href={attachFile} download>
              Ссылка на файл
            </a>
          ) : (
            children
          )}
        </div>

        {infoText && (
          <span className={s["dialogMessageSubText"]}>{infoText}</span>
        )}
      </div>

      {typeof avatar === "string" && (
        <div className={s["dialogMessageSenderAvatar"]}>
          <Avatar size="normal" url={avatar} person="user" />
        </div>
      )}
    </div>
  );
};

export default DialogMessage;
