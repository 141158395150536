import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getValidateData,
  setValidateData,
  GET_VALIDATE_DATA
} from "../Store/ClassifierFieldForm.reducer";

export function* getValidateDataSaga(action) {
  const fieldId = action.payload;
  yield put(startLoading(GET_VALIDATE_DATA));

  try {
    const res = yield httpRequest(
      "GET",
      `bff/settings/classifier/fields/${fieldId}/validations`
    );

    if (res?.success) {
      yield put(setValidateData(res.data));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(GET_VALIDATE_DATA));
  }
}

export default function* () {
  yield takeEvery([getValidateData], getValidateDataSaga);
}
