import React from "react";

import SubmenuItem from "./SubmenuItem";
import { ISettingsSubmenuList, ISubmenuItemHandlers } from "./interface";

import { isEmpty } from "@omnichat/arm_ui_kit";
import * as styles from "./styles.module.scss";

/**
 * Компонент списка категорий
 */
const SettingsSubmenuList = ({
  list,
  onSelect,
  onEdit
}: ISettingsSubmenuList & ISubmenuItemHandlers): JSX.Element => {
  return (
    <div className="classifierListContentBlock">
      <div className="marginContentBlock"></div>
      {!isEmpty(list) &&
        list.map(({ id, name, current, editable, forAll }, i) => (
          <SubmenuItem
            key={i}
            id={id}
            forAll={forAll}
            name={name}
            current={current}
            editable={editable}
            onSelect={(id) => onSelect(id)}
            onEdit={(id) => onEdit(id)}
          />
        ))}
    </div>
  );
};

export default SettingsSubmenuList;
