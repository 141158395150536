import React from "react";

import { isEmpty } from "@omnichat/arm_ui_kit";

import * as s from "./CsiInfo.module.scss";

export interface ICsiCategory {
  id: number;
  title: string;
  requiredComment?: boolean;
}

export interface ICsiAppealProps {
  rate: number;
  categories: ICsiCategory[];
  comment: number | null;
}

const CsiInfo: React.FunctionComponent<ICsiAppealProps> = ({
  rate,
  categories,
  comment
}): JSX.Element => {
  const categoriesMapper = (
    category: ICsiCategory,
    index: number
  ): JSX.Element | void => {
    return (
      <>
        {index !== 0 && ", "}
        <span>{category.title}</span>
      </>
    );
  };

  return (
    <>
      {rate && (
        <div>
          <p className={s["csiInfoLabel"]}>Оценка: </p>
          <p className={s["csiInfoContent"]}>{rate}</p>
        </div>
      )}

      {!isEmpty(categories) && (
        <div>
          <p className={s["csiInfoLabel"]}> Категории оценки: </p>
          <p className={s["csiInfoContent"]}>
            {categories.map(categoriesMapper)}
          </p>
        </div>
      )}

      {comment && (
        <div>
          <p className={s["csiInfoLabel"]}>Комментарий: </p>
          <p className={s["csiInfoContent"]}>{comment}</p>
        </div>
      )}
    </>
  );
};

export default CsiInfo;
