// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--jfAmlynUlxd8RssJMRUg{width:600px;padding:25px 20px}.modal__item--MkVq1uWaOIDZqRWxm5wg{margin-bottom:15px}.modal__buttons--wibpIgaYZ4jSa7bkfS3I{display:flex;justify-content:space-around}.modal--jfAmlynUlxd8RssJMRUg p{color:var(--color-text-hints);margin-top:5px;font-size:15px}.header--xCPxvJCyildDW9f6ifDp{color:var(--color-text-default);font-size:var(--font-size-header);font-weight:bold;padding-bottom:20px;border-bottom:1px solid rgba(0,0,0,.15);margin-bottom:15px}.groups_container--FtjsWalqUgBNkoezhSKE{margin-bottom:20px;display:inline-grid}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/CategoryModal/Components/CategoryModal/CategoryModal.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,iBAAA,CAEA,mCACE,kBAAA,CAGF,sCACE,YAAA,CACA,4BAAA,CAGF,+BACE,6BAAA,CACA,cAAA,CACA,cAAA,CAIJ,8BACE,+BAAA,CACA,iCAAA,CACA,gBAAA,CACA,mBAAA,CACA,uCAAA,CACA,kBAAA,CAGF,wCACE,kBAAA,CACA,mBAAA","sourcesContent":[".modal {\n  width: 600px;\n  padding: 25px 20px;\n\n  &__item {\n    margin-bottom: 15px;\n  }\n\n  &__buttons {\n    display: flex;\n    justify-content: space-around;\n  }\n\n  p {\n    color: var(--color-text-hints);\n    margin-top: 5px;\n    font-size: 15px;\n  }\n}\n\n.header {\n  color: var(--color-text-default);\n  font-size: var(--font-size-header);\n  font-weight: bold;\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n  margin-bottom: 15px;\n}\n\n.groups_container {\n  margin-bottom: 20px;\n  display: inline-grid;\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "modal--jfAmlynUlxd8RssJMRUg";
export var modalItem = "modal__item--MkVq1uWaOIDZqRWxm5wg";
export var modalButtons = "modal__buttons--wibpIgaYZ4jSa7bkfS3I";
export var header = "header--xCPxvJCyildDW9f6ifDp";
export var groupsContainer = "groups_container--FtjsWalqUgBNkoezhSKE";
export default ___CSS_LOADER_EXPORT___;
