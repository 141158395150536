import {
  ThematicModalTypes,
  IComputedTheme,
  IComputedField,
  IComputedOption,
  AppealThemeData,
  IThemeField,
  ISelectedFieldsValue,
  IAppealTheme
} from "../Interfaces";

import { isEmpty } from "@omnichat/arm_ui_kit";

/**
 *
 * @param options значения полей прилетевших от api
 * @returns форматированое значение для передачи в компонент
 */
const formatedOption = (
  options: ThematicModalTypes.IValue[]
): IComputedOption[] => {
  return options.map(item => ({
    label: item.name,
    value: item.id
  }));
};

/**
 * @param field поле которое прилетело от api
 * @returns форматированое поле для передачи в компонент
 */
const formatedField = (field: ThematicModalTypes.IField): IComputedField => {
  return {
    ...field,
    options: formatedOption(field.options)
  };
};

/**
 * Отфильтрованные поля в зависимости от выбранных значений
 * @param themeFields Поля тематик со значениями
 * @returns
 */
export const computedFields = (
  themeFields: ThematicModalTypes.IField[],
  shouldFieldsAlwaysShow?: boolean
): IComputedField[] => {
  if (!themeFields) return [];

  const values = themeFields
    .filter(field => !!field.value)
    .map(field => field.value);

  return themeFields.reduce((acc, field) => {
    let $field = { ...field };

    if (isEmpty($field.depend) || canShowField(values, $field.depend)) {
      const filteredOptions = getFilteredFieldOptions(values, $field?.options);
      if (!isEmpty(filteredOptions)) {
        $field = { ...$field, options: filteredOptions };
        acc.push(formatedField($field));
      } else if (shouldFieldsAlwaysShow || $field.isAlwaysShow) {
        $field = { ...$field, options: [] };
        acc.push(formatedField($field));
      } else if (["TEXTAREA", "INPUT"].includes($field.fieldType)) {
        $field = { ...$field };
        acc.push(formatedField($field));
      }
    }

    return acc;
  }, []);
};

/**
 * Проверяем выбраны ли все поля, от которых зависит поле
 * @param selectedValues
 * @param dependencies
 * @returns
 */
const canShowField = (
  selectedValues: string[],
  dependencies: string[][]
): boolean => {
  return !isEmpty(
    dependencies?.filter(d => d.every(f => selectedValues.includes(f)))
  );
};

/**
 * Отфильтруем поля для которых условия отображения выполнены
 * @param selectedValues
 * @param options
 * @returns
 */
const getFilteredFieldOptions = (
  selectedValues: string[],
  options: ThematicModalTypes.IValue[]
): ThematicModalTypes.IValue[] => {
  return options?.filter(
    v =>
      isEmpty(v.depend) ||
      !isEmpty(v.depend.filter(f => f.every(c => selectedValues.includes(c))))
  );
};

/**
 * Подготавливает массив тем полученный от API
 * @param themes
 * @returns
 */
export const prepareThemes = (themes: AppealThemeData[]): IAppealTheme[] => {
  return themes.map((t, i) => ({
    themeNumber: i + 1,
    canEdit: t.editable,
    fields: t.fields.map(f => ({
      fieldId: f.fieldId,
      saved: true,
      content:
        f.type === "CHECKBOX"
          ? `${f.parentName}(${f.textContent})`
          : f.textContent || f.textValue,
      valueId: f.valueId || f.textValue,
      fieldType: f.type
    }))
  }));
};

/**
 * Добавляет в массив сохранненных тем еще одну тему
 * @param themes
 * @param themeFields
 * @returns
 */
export const prepareValue = (
  editIndex: number | null,
  themes: IAppealTheme[],
  themeFields: ThematicModalTypes.IField[]
): IAppealTheme[] => {
  const newThemes = [...themes];
  const themeNumber =
    editIndex !== null ? themes[editIndex]?.themeNumber : undefined;

  const newTheme: IAppealTheme = {
    themeNumber,
    canEdit: true,
    fields: themeFields
      .map(field => {
        if (field.value) {
          const option = field.options.find(
            option => option.id === field.value
          );
          return {
            fieldId: field.id,
            content:
              field.fieldType === "CHECKBOX"
                ? `${field.name || ""}(${option?.name})`
                : option?.name || `${field.value}`,
            saved: false,
            valueId: field.value,
            fieldType: field.fieldType
          };
        }
      })
      .filter(el => el)
  };

  if (editIndex !== null) {
    newThemes.splice(editIndex, 1, newTheme);
  } else {
    newThemes.push(newTheme);
  }

  return newThemes;
};

/**
 * Подготавливает массив полей полученных от API для составления тематик
 * @param themeFields
 * @returns
 */
export const prepareFields = (
  themeFields: IThemeField[]
): ThematicModalTypes.IField[] => {
  return themeFields.reduce((fields, field, i) => {
    fields.push({
      id: field.id,
      name: field.name,
      description: field.description,
      depend: field.links,
      value: null,
      error: false,
      isAlwaysShow: field.isAlwaysShow || false,
      options: field.classifierFieldValues.reduce((values, value) => {
        values.push({
          id: value.id,
          depend: value.links,
          name: value.name
        });
        return values;
      }, []),
      fieldType: field.type,
      templates: field.templates,
      validations: field.validations
    });
    return fields;
  }, []);
};

/**
 * Сброс значений полей следующих за измененным полем
 * @param changedOptionId
 * @param arr
 * @returns
 */
const resetDependentValues = (
  changedOptionId: string,
  arr: ThematicModalTypes.IField[]
): ThematicModalTypes.IField[] => {
  const tempArr = [...arr];
  const changedOptionIndex = tempArr.findIndex(a => a.id === changedOptionId);

  const result = tempArr.map((a, i) => {
    if (changedOptionIndex > i) return a;

    a.value = null;

    return a;
  });

  return result;
};

/**
 * Подготавливаем новый массив из выбранных значений
 * @param payload
 * @param state
 * @returns
 */
export const changedValues = (
  selectedValues: ISelectedFieldsValue[],
  themeFields: ThematicModalTypes.IField[]
): ThematicModalTypes.IField[] => {
  let fields = [...themeFields];

  selectedValues.forEach(selectedValue => {
    const { id, value } = selectedValue;
    const fieldId = fields.findIndex(item => item.id === id);
    if (fieldId !== -1) {
      if (!["INPUT", "TEXTAREA"].includes(fields[fieldId]?.fieldType)) {
        /**
         * Если у поля уже выбрано какое-то значение,
         * значит могли быть изменены зависимые от него поля
         */
        if (fields[fieldId].value) {
          fields = resetDependentValues(id, fields);
        }
      }

      fields[fieldId].value = value || null;
    }
  });

  return fields;
};

/**
 * Валидация поля регионов на пстое значение
 * @param themeFields
 * @returns
 */
export const validateRegion = (
  region: ThematicModalTypes.ISelectedRegion
): ThematicModalTypes.ISelectedRegion => {
  return region?.value ? region : { value: null, error: true };
};

/**
 * Проверка на наличие заполненных тематик
 * @param themes
 * @returns
 */
export const validateThemes = (themes: IAppealTheme[]): string | null => {
  return themes && themes.length
    ? null
    : "Небходимо добавить хотя бы одну тематику";
};

/**
 * Очистить все выбранные значение в полях
 * @param themeFields
 * @returns
 */
export const cleanValues = (
  themeFields: ThematicModalTypes.IField[]
): ThematicModalTypes.IField[] => {
  return [...themeFields].map((field, i) => {
    field.value = null;

    return field;
  });
};

/**
 * TODO
 * Удалить тему из списка
 * @param index
 * @param themes
 * @returns
 */
export const deleteTheme = (
  index: number,
  themes: IAppealTheme[]
): IAppealTheme[] => {
  const newThemes = [...themes];

  newThemes.splice(index, 1);

  return newThemes;
};

/**
 * TODO
 * Установить в поля значения редактируемой темы
 * @param index
 * @param themeFields
 * @param themes
 * @returns
 */
export const setFields = (
  index: number,
  themeFields: ThematicModalTypes.IField[],
  themes: IAppealTheme[]
): ThematicModalTypes.IField[] => {
  const editedTheme = themes[index];

  const values = editedTheme?.fields.map(t => ({
    id: t.fieldId,
    value: t.valueId
  }));
  return changedValues(values, themeFields);
};

/**
 * Переформатировать список сохранненных тем для web компонента
 * @param themes
 * @returns
 */
export const computedThemes = (themes: IAppealTheme[]): IComputedTheme[] => {
  if (!themes.length) return [];
  return themes.map((t, id) => ({
    id,
    text: t.fields.map(f => f.content).join(" - "),
    canEdit: t.canEdit,
    saved: t.fields?.[0].saved
  }));
};
