import { ClassifierTypes } from "./Interfaces";

const Thematics: ClassifierTypes.State = {
  loading: [],
  classifiers: [],
  fields: [],
  classifierFields: [],
  classifierFieldValuesList: {}
};

export default Thematics;
