import produce from "immer";

import initialState from "../../../ReactCore/store/initialState";
import {
  SET_REPLIES_CATEGORIES,
  ADD_REPLIES_CATEGORIES,
  LOADED_REGIONS,
  LOADED_MRFS,
  LOADED_RFS,
  GET_REGIONS,
  LOADED_TRANSFER_DICTIONARY,
  CLEAN_TRANSFER_DICTIONARY,
  SET_AREAS_LIST,
  SET_SKILL_GROUPS_LIST,
  SET_TERRITORIES_LIST,
  SET_ROLES_LIST,
  SET_ORGANIZATIONS_LIST
} from "../consts";

export default function dataReducer(
  state = initialState.Dictionaries,
  { type, payload }
) {
  switch (type) {
    case SET_REPLIES_CATEGORIES:
      return produce(state, (draft) => {
        draft.categories = payload;
      });

    case ADD_REPLIES_CATEGORIES:
      return produce(state, (draft) => {
        draft.categories = [...draft.categories, ...payload];
      });

    case LOADED_REGIONS:
      return produce(state, (draft) => {
        draft.regions = payload;
      });

    case LOADED_RFS:
      return produce(state, (draft) => {
        draft.rfs = payload;
      });

    case LOADED_MRFS:
      return produce(state, (draft) => {
        draft.mrfs = payload;
      });

    case GET_REGIONS:
      return produce(state, (draft) => {
        draft.mrfs = payload;
      });

    case LOADED_TRANSFER_DICTIONARY:
      return produce(state, (draft) => {
        draft.moveAppealList = [...draft.moveAppealList, ...payload];
      });

    case CLEAN_TRANSFER_DICTIONARY:
      return produce(state, (draft) => {
        draft.moveAppealList = [];
      });
    case SET_AREAS_LIST:
      return produce(state, (draft) => {
        draft.areas = payload.data;
      });
    case SET_ORGANIZATIONS_LIST:
      return produce(state, (draft) => {
        draft.organizations = payload.data || [];
      });
    case SET_SKILL_GROUPS_LIST:
      return produce(state, (draft) => {
        draft.skillGroups = payload.data || [];
      });
    case SET_TERRITORIES_LIST:
      return produce(state, (draft) => {
        draft.territories = payload.data || [];
      });
    case SET_ROLES_LIST:
      return produce(state, (draft) => {
        draft.roles = payload.data || [];
      });
    default:
      return state;
  }
}
