import { createSelector } from "reselect";
import { StoreState } from "../../../ReactCore/store/types";
import { DTO } from "../UsersMultiProfileModal.d";
import { getOriginSkillGroup } from "../../../Settings/Users/Store/Users.selector"

export const getErrorMessage = (state: StoreState.State): string =>
  state.UsersMultiProfileModal.errorMessage;

export const getFormData = (state: StoreState.State): DTO => null;

export const getDataSendHandling = (state: StoreState.State): boolean =>
  state.UsersMultiProfileModal.status.dataSendHandling;

export const userSkillGroups = createSelector(getOriginSkillGroup, (list) => {
  return list.map(l => l.id) || []
})
