import React, { FC, MouseEvent, memo } from "react";
import { IconButton, Icon, SxProps, Theme } from "@mui/material";

import { SvgIconComponent } from "@mui/icons-material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

import Button from "../Button";

export interface Props {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter: (e: MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  disableIcon?: boolean;
  iconButton?: {
    position?: "left" | "right";
    CollapsedIcon?: SvgIconComponent;
    ExpandedIcon?: SvgIconComponent;
  };
  extraSX?: {
    btnWrap?: SxProps<Theme>;
    text?: SxProps<Theme>;
  };
  anchorEl?: EventTarget & HTMLButtonElement;
}

export const MainButton: FC<Props> = ({
  onClick,
  onMouseEnter,
  text,
  loading,
  disabled,
  disableIcon,
  iconButton,
  extraSX,
  anchorEl
}) => {
  const buttonProps = {
    "aria-haspopup": true,
    "aria-controls": "drop-down-menu",
    "aria-expanded": !!anchorEl,
    onClick: onClick,
    onMouseEnter: onMouseEnter
  };

  const buttonIcons = anchorEl
    ? iconButton?.ExpandedIcon ?? KeyboardArrowUp
    : iconButton?.CollapsedIcon ?? KeyboardArrowDown;

  return (
    <>
      {text ? (
        <Button
          {...buttonProps}
          disabled={disabled}
          loading={loading}
          text={text}
          IconMUI={!disableIcon && buttonIcons}
          variant="dropdown"
          iconPosition={iconButton?.position}
          extraSX={extraSX ?? {}}
        />
      ) : (
        <IconButton
          {...buttonProps}
          disabled={loading || disabled}
          color="primary"
          sx={extraSX?.btnWrap ?? {}}
        >
          <Icon component={buttonIcons} />
        </IconButton>
      )}
    </>
  );
};

export default memo(MainButton);
