import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  removeValue,
  resetValueData,
  REMOVE_VALUE
} from "../Store/ClassifierFieldValueForm.reducer";

export function* getFieldDataSaga(action) {
  const { valueId, cb } = action.payload;
  yield put(startLoading(REMOVE_VALUE));

  try {
    const res = yield httpRequest(
      "DELETE",
      `bff/settings/classifier/fields/values/${valueId}`
    );

    if (res?.success) {
      yield put(resetValueData());
      yield cb();
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(REMOVE_VALUE));
  }
}

export default function* () {
  yield takeEvery([removeValue], getFieldDataSaga);
}
