import { put, takeEvery } from "redux-saga/effects";

import Informer from "../../../arm/Wrappers/Informer";
import { actions } from "../Store/DashboardSection.reducer";

function* downLoadStatisticsReportSaga({ payload, type }) {
  yield put(actions.startLoading(type));

  try {
    // TODO
    // При проксировании загрузки файла, файл прилетает битый
    // const res = yield httpRequest(
    //   "GET",
    //   `bff/dashboard/report/statistics`,
    //   {
    //     query: payload.filters
    //   },
    //   "blob",
    //   "report.xlsx"
    // );
    window.open(
      `/excel/appealGroup?${payload.filters}&user_id=${core.user.id}`,
      "_self"
    );
    const informer = new Informer("Началась загрузка отчета", 1500);
    informer.show();
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery(
    [actions.downloadStatisticsReport],
    downLoadStatisticsReportSaga
  );
}
