import produce from "immer";

import {} from "../Consts";

import initialState from "../../../ReactCore/store/initialState";

export default function dialogHeaderReducer(
  state = initialState.DialogHeader,
  { type, payload }
) {
  switch (type) {
    default:
      return state;
  }
}
