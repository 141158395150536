import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { ScrollBox } from "@omnichat/arm_ui_kit";
import { Button } from "../../../Components";

import ClassifierFieldTree from "./ClassifierFieldTree";

import * as s from "./style.module.scss";

type IProps = {
  fieldsList: any;
  onCollapseValues: (fieldId: string) => void;
  onCreateValue: (classifierId: string, fieldId: string) => void;
  onCreateField: (classifierId: string) => void;
  onEditValue: (classifierId: string, fieldId: string, valueId: string) => void;
  onEditField: (classifierId: string, fieldId: string) => void;
  onClose: () => void;
  updateFieldList: (id: string) => void;
  resetFieldList: () => void;
};

const FieldsList: FC<IProps> = ({
  fieldsList,
  onCreateValue,
  onCreateField,
  onEditValue,
  onEditField,
  onCollapseValues,
  onClose,
  updateFieldList,
  resetFieldList
}) => {
  const params = useParams<{
    classifierId: string;
    ["*"]: string;
  }>();

  const { classifierId, "*": splat } = params;

  const isNew = classifierId === "new";

  useEffect(() => {
    return () => resetFieldList();
  }, []);

  useEffect(() => {
    if (!isNew) {
      updateFieldList(classifierId);
    }
  }, [classifierId]);

  const fieldId = splat.split("/")?.[1];
  const valueId = splat.split("/")?.[3];

  const btnSx = {
    btnWrap: {
      "&.MuiButtonBase-root": {
        justifyContent: "flex-start",
        padding: "10px"
      }
    },
    text: {
      "&.MuiTypography-root.MuiTypography-body1": {
        lineHeight: "1.25"
      }
    }
  };

  return (
    <ScrollBox autoHeight hasScrollControlBackground>
      <div className={s.scrollContentWrap}>
        <Button
          id="goBackButton"
          text="Назад"
          type="bare"
          theme="blue"
          onClick={() => onClose()}
          IconCustom={NavigateBeforeIcon}
          variant="manage"
          extraSX={btnSx}
        />
        <div className={s.fieldBlockWrap}>
          <ClassifierFieldTree
            classifierId={!isNew ? classifierId : undefined}
            fields={fieldsList}
            activeField={fieldId}
            activeValue={valueId}
            onCollapseValues={onCollapseValues}
            onCreateField={() => onCreateField(classifierId)}
            onEditField={(fieldId) => onEditField(classifierId, fieldId)}
            onCreateValue={(fieldId) => onCreateValue(classifierId, fieldId)}
            onEditValue={(fieldId, valueId) =>
              onEditValue(classifierId, fieldId, valueId)
            }
          />
        </div>
      </div>
    </ScrollBox>
  );
};

export default FieldsList;
