import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";

import { saveOfflineTimerToStorage } from "../Utils";

import {
  setUserStatus,
  setUserStatusPending,
  sendUserOffline,
  setSubstatus
} from "../Store/OnlineToggle.reducer";

function resetAppealStatus() {
  msg.state.appeal_count = 0;
  core.storage.set("msg_appeal_count", 0);
}

function resetConnectStatus() {
  msg.state.appeal_connect = false;
  core.storage.set("msg_appeal_connect", false);
}

function* sendUserOfflineSaga({ payload }) {
  const { transfer = false, substatusId, cb = null, cbError = null } = payload;
  const body = { transfer, substatusId };
  const response = yield httpRequest("PUT", `bff/user/offline`, { body });
  const startDate = Date.now();

  let textInformer;
  if (response?.success) {
    yield put(setUserStatus("OFFLINE"));
    textInformer = "Получение новых обращений отключено";
    saveOfflineTimerToStorage(substatusId, startDate);
    yield put(setSubstatus({ startDate, substatus: substatusId }));
    resetAppealStatus();
    resetConnectStatus();
    if (cb) cb();
  } else {
    if (cbError) cbError();
    resetAppealStatus();
    resetConnectStatus();
    yield put(setUserStatusPending(false));
    textInformer = "Произошла ошибка. Попробуйте еще раз";
  }
  const informer = new Informer(textInformer, 1500);
  informer.show();
}

export default function* () {
  yield takeEvery([sendUserOffline], sendUserOfflineSaga);
}
