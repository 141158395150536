// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--UgEFLBEUTDB0VV4mjqvH{width:550px;min-height:850px}.perc--gISYKSE1iogvgQhcOZNA{color:#0090db;margin-left:10px}.avatar--z0upF2NqRcqN2o1vU5QQ{box-sizing:border-box;padding:15px;background-color:#fff;border:2px solid #0090db;height:100px;width:100px;border-radius:50%}.error--KBMoUzd3JFthy4gcOgTQ{color:red}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/Chatbots/Components/ChatbotModal/style.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,gBAAA,CAGF,4BACE,aAAA,CACA,gBAAA,CAGF,8BACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CAEA,YAAA,CACA,WAAA,CACA,iBAAA,CAGF,6BACE,SAAA","sourcesContent":[".body {\n  width: 550px;\n  min-height: 850px;\n}\n\n.perc {\n  color: #0090db;\n  margin-left: 10px;\n}\n\n.avatar {\n  box-sizing: border-box;\n  padding: 15px;\n  background-color: #fff;\n  border: 2px solid #0090db;\n\n  height: 100px;\n  width: 100px;\n  border-radius: 50%;\n}\n\n.error {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export var body = "body--UgEFLBEUTDB0VV4mjqvH";
export var perc = "perc--gISYKSE1iogvgQhcOZNA";
export var avatar = "avatar--z0upF2NqRcqN2o1vU5QQ";
export var error = "error--KBMoUzd3JFthy4gcOgTQ";
export default ___CSS_LOADER_EXPORT___;
