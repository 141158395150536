import React, { FC, useState, useEffect } from "react";
import {
  Tabs as TabsMui,
  Tab as TabMui,
  TabsProps,
  Typography
} from "@mui/material";

type Value = string | number;

type Tab = {
  id?: Value;
  label: string;
};

interface Props extends Omit<TabsProps, "onChange"> {
  data?: Tab[];
  onChange?: (v: Tab, e) => void;
  value?: number;
  enableExternalControl?: boolean;
}

const Tabs: FC<Props> = ({
  data,
  value,
  onChange,
  enableExternalControl,
  ...props
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = (e: any, v: number) => {
    onChange?.(data[v], e);
    setTabIndex(v);
  };

  useEffect(() => {
    if (enableExternalControl) {
      setTabIndex(value);
    }
  }, [value]);

  return (
    <TabsMui {...props} onChange={handleChange} value={tabIndex}>
      {data?.map((tab, id) => (
        <TabMui
          key={id}
          id={`simple-tab-${id}`}
          aria-controls={`simple-tabpanel-${id}`}
          label={<Typography variant="h2">{tab.label}</Typography>}
        />
      ))}
    </TabsMui>
  );
};

export default Tabs;
