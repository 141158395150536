import { ClassifierFieldFormTypes } from "./ClassifierFieldForm";

const ClassifierFieldForm: ClassifierFieldFormTypes.State = {
  loading: [],
  fieldData: null,
  templateData: null,
  validateData: null,
  linksData: null,
  errorMessage: null
};

export default ClassifierFieldForm;
