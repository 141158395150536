import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Box, Grid } from "@mui/material";

import { Tooltip, Button, ScrollBox, Preloader } from "@omnichat/arm_ui_kit";

import SectionTitle from "../../../ReactFeatures/Common/Components/SectionTitle/SectionTitle";
import VipClient, { VipClientData, VipClientProps } from "./VipClient";
import {
  Backdrop,
  PreloaderWrapper,
} from "./VipClients.styles";
import { SkillGroupData } from "../VipClients.hooks";

const DEFAULT_ID = "defaultId";

interface VipClientsProps {
  clients: VipClientData[];
  skillGroups: SkillGroupData[];
  loading: boolean;
  onEdit: VipClientProps["onEdit"];
  onCreate: VipClientProps["onCreate"];
  onDelete: VipClientProps["onDelete"];
}

const VipClients: React.FC<VipClientsProps> = ({
  clients: propClients,
  loading,
  skillGroups,
  onCreate,
  onEdit,
  onDelete,
}) => {
  const [newClient, setNewClient] = useState<VipClientData | null>(null);
  const [clients, setClients] = useState<VipClientData[]>(propClients);

  const loginsMap = useMemo(
    () =>
      clients.reduce<VipClientProps["loginsMap"]>(
        (logins, client) => ({ ...logins, [client.login]: client.login }),
        {}
      ),
    [clients]
  );

  const createClient = () =>
    setNewClient({
      id: DEFAULT_ID,
      login: "",
      skillGroupId: "",
    });

  const cancelCreation = useCallback(() => setNewClient(null), []);

  const handleSaveClient = useCallback(async (data: VipClientData) => {
    await onCreate(data);
    setNewClient(null);
  }, []);

  useEffect(() => {
    setClients(propClients);
  }, [propClients]);

  return (
    <Box position="relative" height="100%">
      <SectionTitle title="Управление VIP" />
      <ScrollBox autoHeight autoHeightMin="100%">
        <Grid container mt={2}>
          {(newClient ? [...clients, newClient] : clients).map((client) => (
            <Grid item xs={12} key={client.id} ml={1} mr={2} mb={2}>
              <VipClient
                clientData={client}
                onCreate={handleSaveClient}
                onEdit={onEdit}
                onDelete={onDelete}
                onCancelCreation={cancelCreation}
                loginsMap={loginsMap}
                skillGroups={skillGroups}
                initialMode={client.id === DEFAULT_ID ? "create" : null}
              />
            </Grid>
          ))}
          {!newClient && (
            <Grid container justifyContent="center" item xs={11}>
              <Tooltip content="Добавить клиента" position="top">
                <Button
                  type="bare"
                  iconColor="blue"
                  iconName="plus"
                  onClick={createClient}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </ScrollBox>

      {loading && (
        <Backdrop>
          <PreloaderWrapper>
            <Preloader show stretch color="blue" size="mini" />
          </PreloaderWrapper>
        </Backdrop>
      )}
    </Box>
  );
};

export default VipClients;
