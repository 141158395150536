import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/Appeals.reducer";

function* getChannelsSaga({ type }) {
  yield put(actions.startLoading(type));

  try {
    const response = yield httpRequest("GET", `bff/settings/channels`);

    if (response?.success) {
      let newData = response?.data ? response.data : [];

      yield put(actions.setChannels(newData));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery([actions.getChannels], getChannelsSaga);
}
