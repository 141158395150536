export const GET_REPLIES_CATEGORIES = "GET_REPLIES_CATEGORIES";
export const GET_REGIONS = "GET_REGIONS";

export const ADD_REPLIES_CATEGORIES = "ADD_REPLIES_CATEGORIES";
export const SET_REPLIES_CATEGORIES = "SET_REPLIES_CATEGORIES";
export const LOADED_REGIONS = "LOADED_REGIONS";
export const LOADED_TEXT_MARKERS = "LOADED_TEXT_MARKERS";

export const FETCH_REGIONS = "FETCH_REGIONS";
export const LOADED_RFS = "LOADED_RFS";
export const FETCH_RFS = "FETCH_RFS";
export const LOADED_MRFS = "LOADED_MRFS";
export const FETCH_MRFS = "FETCH_MRFS";

export const GET_TRANSFER_DICTIONARY = "GET_TRANSFER_DICTIONARY";
export const LOADED_TRANSFER_DICTIONARY = "LOADED_TRANSFER_DICTIONARY";
export const CLEAN_TRANSFER_DICTIONARY = "CLEAN_TRANSFER_DICTIONARY";

export const GET_USERS_PROFILE_CATALOGS = "GET_USERS_PROFILE_CATALOGS";

export const SET_ROLES_LIST = "SET_ROLES_LIST";
export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_TERRITORIES_LIST = "SET_TERRITORIES_LIST";
export const SET_AREAS_LIST = "SET_AREAS_LIST";
export const SET_SKILL_GROUPS_LIST = "SET_SKILL_GROUPS_LIST";

export const USERS_PROFILE_RATING = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" }
];
