import { ClassifierFieldValueFormTypes } from "./ClassifierFieldValueForm";

const ClassifierFieldForm: ClassifierFieldValueFormTypes.State = {
  loading: [],
  valueData: null,
  linksData: null,
  errorMessage: null
};

export default ClassifierFieldForm;
