import { takeLatest, put, cancelled } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/Appeals.reducer";

function* getAppealsSaga({ payload: { page, filters }, type }) {
  const controller = new AbortController();

  yield put(actions.startLoading(type));

  const query = { page, size: 20, ...filters };

  try {
    const res = yield httpRequest("GET", `bff/dashboard/appeals`, {
      query,
      signal: controller.signal
    });

    if (res.success) {
      if (page === 0) {
        yield put(actions.setAppeals(res.data?.content));
      } else {
        yield put(actions.addAppeals(res.data?.content));
      }

      if (res.data.last) {
        yield put(actions.setLastPage(res.data.last));
      }
      
      yield put(actions.setPage(page));
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
    yield put(actions.finishLoading(type));
  }
}

export default function*() {
  yield takeLatest([actions.getAppeals], getAppealsSaga);
}
