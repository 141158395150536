import * as React from "react";
import * as ReactDom from "react-dom";
import ImageViewerConstructor from "../Constructors/ImageViewerConstructor";

/**
 * Класс для работы с компонентом просмотра изображений.
 */
class ImageViewer {
  component: ImageViewerConstructor;

  constructor() {
    this.component = null;
    this.init.bind(this);
    this.handleCloseImageViewer.bind(this);
    this.handleShowImage.bind(this);
  }

  /**
   * Инициализация конструктора компонента. Рендер его в дом дерево, если его там нет.
   */
  init() {
    if (document.getElementById("image-viewer-container")) return;

    const body = document.getElementsByTagName("body");
    const viewerContainer: HTMLElement = document.createElement("div");

    viewerContainer.id = "image-viewer-container";
    viewerContainer.setAttribute("class", "image-viewer-container__hidden");
    body[0].appendChild(viewerContainer);

    this.component = ReactDom.render(
      React.createElement(ImageViewerConstructor, {
        onClose: this.handleCloseImageViewer,
        viewerContainer
      }),
      viewerContainer
    );
  }

  /**
   * Навесит класс для отображения контейнера и прокинет imgSrc в конструктор.
   *
   * @param {string} imgSrc Путь до изображения.
   * @param {number} [zIndex] zIndex для прокидывания в компонент просмотра изображений.
   */
  handleShowImage(imgSrc: string, zIndex?: number, background?: string) {
    const container: HTMLElement = document.getElementById(
      "image-viewer-container"
    );

    container.setAttribute("class", "image-viewer-container__shown");
    this.component.handleShowImageViewer(imgSrc, zIndex, background);
  }

  /**
   * Навесит класс для сокрытия контейнера.
   */
  handleCloseImageViewer() {
    const container = document.getElementById("image-viewer-container");

    container.setAttribute("class", "image-viewer-container__hidden");
  }
}

export default ImageViewer;
