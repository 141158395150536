import React, { useState } from "react";

import { Icon } from "@omnichat/arm_ui_kit";

import { ICsiAppealData } from "../../../Common/Interfaces/Message.interfaces";
import { MessagesTypes } from "../../../Common/Consts/MessagesTypes";
import CsiInfo from "../../../Common/Components/CsiInfo/CsiInfo";
import ExpandableBlock from "../ExpandableBlock/ExpandableBlock";

import * as s from "./SystemMessage.module.scss";

/**
 * Интерфейс собственных свойств системного сообщения.
 *
 * @prop {number} number Номер обращения.
 * @prop {number} action Действие с обращением:
 * 1 - создание нового обращения
 * 2 - закрытие обращения
 * 4 - автоматический перевод обращения
 * 5 - ручной перевод обращения
 * 6 - повторное открытие обращения
 * 9 - перевод обращения в ожидание
 * 10 - начало кобраузинга
 * 11 - завершение кобраузинга
 * @prop {string} info Информация о создании обращения.
 * @prop {string} sessionTime Длительность сессии.
 * @prop {string} uniqueId Идентификатор уникальный (любой).
 * @prop {string} [reason] Причина обращения.
 * @prop {string | void} [from] Кто передал.
 * @prop {string | void} [to] Кому передали.
 * @prop {string} [csi] Оценка клиента.
 * @prop {boolean} [isExpanded] Развернут ли блок по умолчанию.
 */
interface ISystemMessageProps {
  number: number;
  action: number;
  info: string;
  uniqueId: string;
  sessionTime?: string;
  reason?: string;
  from?: string | void;
  to?: string | void;
  csi?: ICsiAppealData;
  isExpanded?: boolean;
}

const SystemMessage: React.FunctionComponent<ISystemMessageProps> = ({
  sessionTime,
  number,
  action,
  reason,
  info,
  from,
  to,
  csi,
  isExpanded = false,
  uniqueId,
}): JSX.Element => {
  const [isManualTransferInfoShown, setIsManualTransferInfoShown] = useState(
    action === MessagesTypes.TransferByUser
  );

  let iconName = "";
  let claimAction = "";
  let clientAction = "";
  let iconWidth = "3rem";
  let iconHeight = "3rem";

  switch (action) {
    case MessagesTypes.CreateAppeal:
      claimAction = "создано";
      iconName = "plus";
      break;
    case MessagesTypes.CloseAppeal:
      claimAction = "закрыто";
      iconName = "cross";
      break;
    case MessagesTypes.TransferBySystem:
    case MessagesTypes.TransferByUser:
      claimAction = "передано";
      iconName = "repeat";
      iconWidth = "3.1rem";
      iconHeight = "3.1rem";
      break;
    case MessagesTypes.ReopenAppeal:
      claimAction = "переоткрыто";
      iconName = "replay";
      break;
    case MessagesTypes.Waiting:
      claimAction = "ожидает ответа";
      iconName = "schedule";
      break;
    case MessagesTypes.StartCobrowsing:
      claimAction = "кобраузинг";
      clientAction = "Клиент подтвердил запрос на доступ к браузеру";
      iconName = "cast";
      break;
    case MessagesTypes.EndCobrowsing:
      claimAction = "кобраузинг";
      clientAction = "Клиент завершил сессию кобраузинга";
      iconName = "cancel_presentation";
      break;
  }

  /**
   * Переключение отображения блока с информацией о ручном переводе.
   */
  function handleToggleManualTransferInfo(isExpanded: boolean) {
    setIsManualTransferInfoShown(!isExpanded);
  }

  /**
   * Отрисует информацию по ручному переводу обращения.
   */
  function renderManualTransferInfo() {
    return (
      action === MessagesTypes.TransferByUser &&
      isManualTransferInfoShown && (
        <>
          {!!reason && action === MessagesTypes.TransferByUser && (
            <div>
              причина: <span>{reason}</span>
            </div>
          )}

          <span className={s["systemMessageAnnouncement"]}>{info}</span>
        </>
      )
    );
  }

  /**
   * Отрисует видимый контекс (заголовок) сворачиваемого блока.
   */
  function renderVisibleContent() {
    return (
      <Icon
        name={iconName}
        color="gray"
        width={iconWidth}
        height={iconHeight}
      />
    );
  }

  return (
    <div className={s["systemMessage"]}>
      <ExpandableBlock
        visibleContent={renderVisibleContent()}
        keyString={uniqueId}
        arrowPosition="Right"
        isExpanded={!!isExpanded}
        onExpand={handleToggleManualTransferInfo}
      >
        <div className={s["systemMessageInfoContainer"]}>
          <span
            className={s["systemMessageHeader"]}
          >{`Обращение #${number} ${claimAction}`}</span>

          {!!clientAction && (
            <div>
              <span>{clientAction}</span>
            </div>
          )}

          {!!from && (
            <div>
              от: <span>{from}</span>
            </div>
          )}

          {!!to && (
            <div>
              кому: <span>{to}</span>
            </div>
          )}

          {!!reason && (
            <div>
              причина: <span>{reason}</span>
            </div>
          )}

          {!!sessionTime && (
            <span className={s["systemMessageAnnouncement"]}>
              Длительность: {sessionTime}
            </span>
          )}

          {!!csi && (
            <CsiInfo
              rate={csi.rate}
              comment={csi.comment}
              categories={csi.categories}
            />
          )}

          <span className={s["systemMessageAnnouncement"]}>{info}</span>
        </div>
      </ExpandableBlock>

      {renderManualTransferInfo()}
    </div>
  );
};

export default SystemMessage;
