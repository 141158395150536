import { takeEvery } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { closeAppeal } from "../Actions/CloseDialogModal.action";

/**
 * Закрытие обращения
 */
function* sendCloseDialogModal(action) {
  const {
    appealId,
    dialogId,
    themes,
    comment,
    closingFromChat,
    onConfirmCloseAppeal
  } = action.payload;

  const response = yield httpRequest(
    "PUT",
    `bff/chat/appeals/close/${appealId}`,
    {
      body: {
        dialogId,
        comment,
        closingFromChat,
        themes
      }
    }
  );

  if (response?.success) {
    onConfirmCloseAppeal();
  }
}

export default function*() {
  yield takeEvery([closeAppeal], sendCloseDialogModal);
}
