import React, { useEffect, useState } from "react";
import c from "classnames";

import { getScrollHeight, setMaxHeight } from "./utils";

import * as s from "./ExpandableBlock.module.scss";

interface ExpandableBlockProps {
  visibleContent: React.ReactNode;
  children: React.ReactNode;
  keyString: string;
  arrowPosition: "Right" | "Left";
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
}
/**
 * Компонент-контейнер с возможностью скрывать/показывать свое содержимое.
 */
const ExpandableBlock: React.FunctionComponent<ExpandableBlockProps> = ({
  visibleContent,
  children,
  keyString,
  arrowPosition = "Left",
  isExpanded = false,
  onExpand,
}) => {
  const [isChildrenContainerShown, setChildrenContainerShow] = useState<
    boolean
  >(isExpanded);

  /** Вернет контейнер потомков. */
  function getChildrenContainer() {
    return document.getElementById(
      `expandalble-children-container-${keyString}`
    );
  }

  useEffect(() => {
    isExpanded &&
      setMaxHeight(
        getScrollHeight(getChildrenContainer()),
        getChildrenContainer()
      ); // раскрывает блок по умолчанию
    onExpand?.(isExpanded);
  }, []);

  /**
   * Обработчик нажатия на видимый контент для раскрытия блока.
   *
   * @param {React.MouseEvent<HTMLInputElement>} event Событие нажатия.
   */
  const handleClickVisibleContent = function (
    event: React.MouseEvent<HTMLInputElement>
  ) {
    setChildrenContainerShow(!isChildrenContainerShown);

    !isChildrenContainerShown // это будущее значение состояния (новое)
      ? getChildrenContainer() &&
        setMaxHeight(
          getScrollHeight(getChildrenContainer()),
          getChildrenContainer()
        )
      : setMaxHeight(null, getChildrenContainer());

    onExpand?.(!isChildrenContainerShown);
  };

  /**
   * Вернет класс для отображаемого контейнера.
   */
  function getVisibleContainerClass() {
    return c(s["visibleContainer"], s[`visibleContainer${arrowPosition}`]);
  }

  return (
    <div className={s["expandableBlockContainer"]}>
      <label className={getVisibleContainerClass()}>
        <div className={s[`visibleContainerContent`]}>{visibleContent}</div>

        <input
          defaultChecked={isChildrenContainerShown}
          onClick={handleClickVisibleContent}
          id={`visible-content-${keyString}`}
          type="checkbox"
          className={s["visibleContainerCheckbox"]}
        />

        <span
          className={c(
            s["visibleContainerArrow"],
            s[`visibleContainerArrow${arrowPosition}`]
          )}
        />
      </label>

      <div
        id={`expandalble-children-container-${keyString}`}
        className={s["childrenContainer"]}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableBlock;
