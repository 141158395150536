import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

import initialState from "../../../ReactCore/store/initialState";
import { sendDTO, getDTO, Dictionaries } from "../SkillGroupModal.d";

export const GET_SKILL_GROUP_DICTIONARIES = "GET_SKILL_GROUP_DICTIONARIES";
export const GET_SKILLGROUP_DATA = "GET_SKILLGROUP_DATA";
export const SEND_SKILLGROUP_DATA = "SEND_SKILLGROUP_DATA";

export const getSkillGroupDictionaries = createAction(
  GET_SKILL_GROUP_DICTIONARIES
);

export const getSkillGroupData = createAction<{
  skillId: string;
}>(GET_SKILLGROUP_DATA);

export const sendSkillGroupData = createAction<{
  callback: Function;
  formData: sendDTO;
}>(SEND_SKILLGROUP_DATA);

const SkillGroupModalSlice = createSlice({
  name: "skillGroupModal",
  initialState: initialState.SkillGroupModal,
  reducers: {
    setSkillGroupData(state, action: PayloadAction<getDTO>) {
      state.formData = action.payload;
    },
    setSkillGroupDictionaries(state, action: PayloadAction<Dictionaries>) {
      state.dictionaries = action.payload;
    },
    resetSkillGroupData(state) {
      state.formData = initialState.SkillGroupModal.formData;
      state.dictionaries = initialState.SkillGroupModal.dictionaries;
      state.state = initialState.SkillGroupModal.state;
      state.errorMessage = initialState.SkillGroupModal.errorMessage;
    },
    setErrorMesssage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setSendDataHandling(state, action: PayloadAction<boolean>) {
      state.status.dataSendHandling = action.payload;
    }
  }
});

export const {
  setSkillGroupData,
  resetSkillGroupData,
  setSkillGroupDictionaries,
  setErrorMesssage,
  setSendDataHandling
} = SkillGroupModalSlice.actions;

export default SkillGroupModalSlice.reducer;
