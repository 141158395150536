// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scrollContentWrap--CHa6ydP8d0vj3iWE2IaT{padding-right:20px}.fieldBlockWrap--HlFlnRkOZL5VikQ5olK_{margin:20px 0 10px 0}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/Classifiers/Components/style.module.scss"],"names":[],"mappings":"AAAA,yCACE,kBAAA,CAGF,sCACE,oBAAA","sourcesContent":[".scrollContentWrap {\n  padding-right: 20px;\n}\n\n.fieldBlockWrap {\n  margin: 20px 0 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var scrollContentWrap = "scrollContentWrap--CHa6ydP8d0vj3iWE2IaT";
export var fieldBlockWrap = "fieldBlockWrap--HlFlnRkOZL5VikQ5olK_";
export default ___CSS_LOADER_EXPORT___;
