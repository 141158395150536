import React, { FC, useState } from "react";
import c from "classnames";

import { Avatar, ScrollBox, Icon } from "@omnichat/arm_ui_kit";
import Attachment from "./Attachment";

import * as s from "./EmailMessage.module.scss";

export type AttachmentType = {
  filename: string;
  type: string;
  mediaUrl: string;
};

export interface IEmailMessageProps {
  plainTextData: string;
  htmlData: string;
  client?: string;
  avatar?: string;
  subject: string;
  emailDate: string;
  sentFrom: string;
  sentTo: string;
  attachments?: AttachmentType[];
  onOpen?: () => void;
  filesUrl: string;
  onShowImageViewer: (imageSrc: string) => void;
}

const EmailMessage: FC<IEmailMessageProps> = ({
  client,
  subject,
  sentFrom,
  sentTo,
  emailDate,
  avatar,
  attachments,
  onOpen,
  plainTextData,
  htmlData,
  filesUrl,
  onShowImageViewer
}): JSX.Element => {
  // Компонент используется в диалоге или модалке
  const isBubble = !!onOpen;
  const imageExt = ["png", "jpg", "jpeg", "gif"];

  return (
    <>
      <div className={c(s["emailMessage"])}>
        <div className={c(s["emailMessageHeader"])}>
          {typeof avatar === "string" && (
            <div className={s["emailMessageSenderAvatar"]}>
              <Avatar size="normal" url={avatar} person="user" />
            </div>
          )}
          <div className={c(s["emailMessageInfo"])}>
            <div className={c(s["emailMessageFrom"])}>
              <span>От: </span>
              <span className={c(s["emailMessageFromName"])}>{`${client ||
                ""} `}</span>
              <span className={c(s["value"], s["lowercase"])}>{sentFrom}</span>
            </div>
            <div className={c(s["emailMessageTo"])}>
              Кому:{" "}
              <span className={c(s["value"], s["lowercase"])}>{sentTo}</span>
            </div>
            <div className={c(s["emailMessageSubject"])}>
              Тема: <span className={c(s["value"])}>{subject}</span>
            </div>
          </div>
          <div className={c(s["emailMessageDate"])}>{emailDate}</div>
        </div>
        <div className={c(s["emailMessageBody"])}>
          <ScrollBox
            autoHeight
            autoHeightMax={isBubble ? 263 : 400}
            customScrollControlStyles={{
              borderRadius: "2px",
              backgroundColor: "#f0f0f0"
            }}
          >
            <div
              className={c(s["emailMessageScrollContainer"])}
              dangerouslySetInnerHTML={{
                __html: isBubble ? plainTextData : htmlData
              }}
            ></div>
          </ScrollBox>
        </div>
        {isBubble && (
          <div className={c(s["emailMessageMore"])}>
            <div className={c(s["emailMessageMoreBtn"])} onClick={onOpen}>
              Раскрыть полностью
            </div>
          </div>
        )}
        <div className={c(s["emailMessageAttachments"])}>
          <ScrollBox
            autoHeight
            autoHeightMax={143}
            customScrollControlStyles={{ borderRadius: "2px" }}
          >
            <div className={c(s["emailMessageAttachmentsContainer"])}>
              {attachments?.map((a, i) => {
                const [name, ext] = a.filename?.split(".");
                const isImage = imageExt.includes(ext.toLocaleLowerCase());
                if (isImage) {
                  return (
                    <div
                      key={`${i}-${a.filename}`}
                      className={c(s["emailMessageAttachment"])}
                      onClick={() =>
                        onShowImageViewer(`${filesUrl}${a.mediaUrl}`)
                      }
                    >
                      <Attachment name={name} ext={ext} />
                    </div>
                  );
                } else {
                  return (
                    <a
                      className={c(s["emailMessageAttachment"])}
                      href={`${filesUrl}${a.mediaUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Attachment name={name} ext={ext} />
                    </a>
                  );
                }
              })}
            </div>
          </ScrollBox>
        </div>
      </div>
    </>
  );
};

export default EmailMessage;
