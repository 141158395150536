import { takeEvery, put, delay, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { isoIntervalToTimestamp } from "../../../ReactCore/shared/dataFormats";
import { getMappedContextMessages } from "../../../ReactCore/shared/mapContextMessages";
import {
  setDialogData,
  setStateDataLoaded,
  setClientData,
  setRegionData,
  setCsiData,
  setOperationTime,
  setAppealComment,
  getDialogData,
  setAppealContext,
  addTemplateText,
  addAttachments
} from "../Reducers/Dialog.reducer";
import { getRepliesGreetingData } from "../Actions/Dialog.actions";

import { ISelect } from "../../Common/Interfaces/Store.interfaces";

function* getDialogDataSaga(action) {
  const dialogId = action.payload.dialogId;
  const appealId = action.payload.appealId;
  const fromNotification = yield select<ISelect>(
    ({ Dialog }) => Dialog.fromNotification
  );

  const responseAppealData = yield httpRequest(
    "GET",
    `bff/chat/appeals/${appealId}/info`
  );

  if (responseAppealData.data?.client) {
    yield put(
      setClientData({
        ...responseAppealData.data.client
      })
    );
    yield put(setRegionData(responseAppealData.data.region));
  }

  if (responseAppealData.data?.csi) {
    yield put(setCsiData(responseAppealData.data.csi));
  }

  if (responseAppealData.data?.operationTime) {
    yield put(
      setOperationTime(
        isoIntervalToTimestamp(responseAppealData.data.operationTime)
      )
    );
  }

  if (responseAppealData.data?.comment) {
    yield put(setAppealComment(responseAppealData.data.comment));
  }

  if (responseAppealData.data?.context?.dialog) {
    const { pageUrl, serviceName, displayHeader } =
      responseAppealData.data.context;
    const clientRole =
      responseAppealData.data.context.dialog?.client?.userData?.role;
    const messages = getMappedContextMessages(
      responseAppealData.data.context.dialog,
      appealId
    );
    yield put(
      setAppealContext({
        pageUrl,
        serviceName,
        displayHeader,
        clientRole,
        messages
      })
    );
  }

  const responseMessage = yield httpRequest(
    "GET",
    `bff/chat/dialog/${dialogId}/messages/`
  );

  if (responseMessage?.success && responseMessage.data) {
    yield put(
      setDialogData({
        messages: responseMessage.data,
        skillGroupName: responseAppealData.data?.skillGroup.name,
        timeLimit: responseAppealData.data?.skillGroup.timeLimit,
        dialogId,
        appealId
      })
    );

    action?.payload?.cb && action.payload.cb();

    if (
      ENABLE_GREETING_BY_NOTIFICATION == "1" &&
      responseAppealData.data?.operatorAnswerInResponseTime === null &&
      fromNotification != false
    ) {
      yield put(getRepliesGreetingData({ appealId }));
    }

    yield delay(100);
    yield put(setStateDataLoaded(true));
  }
}

export default function* () {
  yield takeEvery([getDialogData], getDialogDataSaga);
}
