import * as Yup from "yup";
import { REQUIRED_FIELD_ERROR } from "@omnichat/arm_ui_kit";

import { getMaxStringLengthErrorText } from "../../../../Utils/getMaxStringLengthErrorText";

const NAME_MAX_STRING_LENGTH = 50;

export const schema = Yup.object({
  role: Yup.object({
    id: Yup.number().notRequired(),
    name: Yup.string()
      .trim()
      .max(
        NAME_MAX_STRING_LENGTH,
        getMaxStringLengthErrorText(NAME_MAX_STRING_LENGTH)
      )
      .required(REQUIRED_FIELD_ERROR)
      .matches(
        /^[\s\wа-яё-]+$/iu,
        "Название может содержать только буквы, цифры, пробелы, подчеркивание и тире"
      )
  }),

  rights: Yup.object({
    roleId: Yup.number(),
    rightId: Yup.number(), // на данный момент оставлен как рудимент можно отправлять как 0 или не отправлять вообще
    adSync: Yup.boolean(),
    agent: Yup.boolean(),
    agentLimit: Yup.boolean(),
    appealClose: Yup.boolean(),
    appealReopen: Yup.boolean(),
    appealTransfer: Yup.boolean(),
    autoreplies: Yup.boolean(),
    bots: Yup.boolean(),
    channels: Yup.boolean(),
    classifier: Yup.boolean(),
    createUsers: Yup.boolean(),
    excel: Yup.boolean(),
    log: Yup.boolean(),
    monitoring: Yup.boolean(),
    personDashboard: Yup.boolean(),
    reportAppeals: Yup.boolean(),
    roles: Yup.boolean(),
    settings: Yup.boolean(),
    skillLimit: Yup.boolean(),
    skills: Yup.boolean(),
    systemDashboard: Yup.boolean(),
    templates: Yup.boolean(),
    userGroups: Yup.boolean(),
    wfm: Yup.boolean(),
    vip: Yup.boolean(),
    systemSettings: Yup.boolean()
  })
});

export const defaultValues = {
  role: {
    id: null,
    name: ""
  },
  rights: {
    adSync: false,
    agent: false,
    agentLimit: false,
    appealClose: false,
    appealReopen: false,
    appealTransfer: false,
    autoreplies: false,
    bots: false,
    channels: false,
    classifier: false,
    createUsers: false,
    excel: false,
    log: false,
    monitoring: false,
    personDashboard: false,
    reportAppeals: false,
    roles: false,
    settings: false,
    skillLimit: false,
    skills: false,
    systemDashboard: false,
    templates: false,
    userGroups: false,
    wfm: false,
    vip: false,
    systemSettings: false
  }
};

export interface IFormData extends Yup.InferType<typeof schema> {}
