import { StoreState } from "frontend/src/ReactCore/store/types";
import { createSelector } from "reselect";

import { IAttachment } from "./Interfaces";

export const getMessages = (state: StoreState.State) => {
  return state.Dialog.messages;
};

export const getPrompter = (state: StoreState.State) => {
  return state.Dialog.prompter;
};

export const getActiveDialog = (state) => {
  return state.Dialog.dialogId;
};

export const getActiveAppeal = (state) => {
  return state.Dialog.appealId;
};

export const getOperationTime = (state): number => {
  return state.Dialog.operationTime;
};

export const getInitialDataLoaded = (state): boolean => {
  return state.Dialog.status.initialDataLoaded;
};

export const getClientData = (state: StoreState.State) => {
  return state.Dialog.client;
};

export const getOpenFromNotification = (state) => {
  return state.Dialog.fromNotification;
};

export const getCsi = (state) => {
  return state.Dialog.csi;
};
export const getRegion = (state) => {
  return state.Dialog.region;
};
export const getSkillGroupName = (state) => {
  return state.Dialog.skillGroupName;
};
export const getTypingState = (state) => {
  return state.Dialog.isShowingTyping;
};
export const getTimeLimit = (state): number => {
  return state.Dialog.timeLimit;
};

export const getChanneProjectId = createSelector(getClientData, (client) => {
  return client?.channel?.projectId;
});

export const getChannelName = createSelector(getClientData, (client) => {
  return client?.channel?.name ?? "";
});

export const getChannelType = createSelector(getClientData, (client) => {
  return client?.channel?.name ? client.channel.channelTypeId : null;
});

export const getChannelId = createSelector(getClientData, (client) => {
  return client?.channel?.id ?? null;
});

export const getClientName = createSelector(getClientData, (client) => {
  return client?.name;
});

export const getClientId = createSelector(getClientData, (client) => {
  return client?.id;
});

export const getClientAvatar = createSelector(getClientData, (client) => {
  return client?.photo;
});

export const getClientRemarkFio = createSelector(getClientData, (client) => {
  return client?.remarkFio;
});

export const getClientRemarkPhone = createSelector(getClientData, (client) => {
  return client?.remarkPhone;
});

export const getClientBirthDay = createSelector(getClientData, (client) => {
  return client?.birthDay;
});

export const getAggregatedAttachments = (state: StoreState.State) => {
  const currentAttachments = [...state.Dialog.attachments];
  let pendingAttachments: IAttachment[] = [];

  if (state.FileStorage.files.length) {
    pendingAttachments = state.FileStorage.files.map(
      (item, i): IAttachment => {
        const name = item.name?.toLowerCase();
        const ext = item.name
          ?.split(".")
          .pop()
          .toLowerCase();
        const mimeType = item.data?.mimeType;

        return {
          uuid: item.uuid,
          name,
          ext,
          mimeType,
          loaded: item.sended,
          failed: item.failed,
          pending: item.pending,
          progress: item.progress
        };
      }
    );
  }

  return [...currentAttachments, ...pendingAttachments];
};

/**
 * Селектор комментария по обращению.
 *
 * @param state Текущий redux-state.
 */
export const getAppealComment = (state: StoreState.State) =>
  state.Dialog.comment;

/**
 * Селектор шаблонов, найденных по контекстному поиску.
 *
 * @param state Текущий redux-state.
 */
export const getAsyncFoundReplyTemplates = (state: StoreState.State) =>
  state.Dialog.foundByContextReplyTemplates;

export const getContextData = (state: StoreState.State) => {
  return state.Dialog.context || null;
};
