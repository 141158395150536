import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import { Data, Item, ReportMetaData } from "../AppealReport";
import initialState from "../../../ReactCore/store/initialState";

export const GET_APPEAL_REPORT = "GET_APPEAL_REPORT";
export const CREATE_APPEAL_REPORT = "CREATE_APPEAL_REPORT";
export const DOWNLOAD_APPEAL_REPORT = "DOWNLOAD_APPEAL_REPORT";
export const GET_SKILL_GROUP = "GET_SKILL_GROUP";
export const DOWNLOAD_STATISTICS_REPORT = "DOWNLOAD_STATISTICS_REPORT";
export const SENT_EMAIL_REPORT = "SENT_EMAIL_REPORT";

export const getAppealReport = createAction(GET_APPEAL_REPORT);
export const createAppealReport = createAction<Data>(CREATE_APPEAL_REPORT);
export const downloadAppealReport = createAction(DOWNLOAD_APPEAL_REPORT);
export const getSkillGroups = createAction(GET_SKILL_GROUP);
export const downloadStatisticsReport = createAction(
  DOWNLOAD_STATISTICS_REPORT
);
export const sendEmailReport = createAction(SENT_EMAIL_REPORT);

const AppealReportSlice = createSlice({
  name: "appealReportModal",
  initialState: initialState.AppealReportModal,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    setReportFail(state, action: PayloadAction<boolean>) {
      state.reportFailed = action.payload;
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setFilter(state, action: PayloadAction<Data>) {
      state.modalData = action.payload;
    },
    setSkillGroups(state, action: PayloadAction<Item[]>) {
      state.skillGroups = action.payload;
    },
    setFileData(state, action: PayloadAction<ReportMetaData>) {
      state.reportMetadata = action.payload;
    }
  }
});

export const actions = {
  ...AppealReportSlice.actions,
  getAppealReport,
  createAppealReport,
  downloadAppealReport,
  getSkillGroups,
  downloadStatisticsReport,
  sendEmailReport
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export default AppealReportSlice.reducer;
