import React from "react";

import c from "classnames";
import * as styles from "./style.module.scss";
import { Box } from "@mui/material";

import Select from "../Select";
import ActionText from "../ActionText";

const getPreparedValue = (value, catalog) =>
  catalog.filter((item) => item.value === value) || catalog[0];

interface ITimeInput {
  initialValue?: string;
  separator?: string;
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
  hoursValue?: number;
  minutesValue?: number;
  secondsValue?: number;
  isError?: boolean;
  onChangeHours?: (val: number | string) => void;
  onChangeMinutes?: (val: number | string) => void;
  onChangeSeconds?: (val: number | string) => void;
  actionText?: string;
}

const range = (N: number) =>
  Array.from({ length: N }, (v, timeValue) => {
    const timeLabel = String(
      typeof timeValue === "number" && timeValue < 10
        ? "0" + timeValue
        : timeValue
    );
    return { value: timeValue, label: timeLabel };
  });

const TimeInput = ({
  hours = false,
  minutes = false,
  seconds = false,
  separator = ":",
  hoursValue = 0,
  minutesValue = 0,
  secondsValue = 0,
  isError = false,
  onChangeHours,
  onChangeMinutes,
  onChangeSeconds,
  actionText
}: ITimeInput) => {
  const hoursOptions = range(25);
  const minutesOptions = range(60);
  const secondsOptions = range(60);

  const preparedHoursValue = getPreparedValue(hoursValue, hoursOptions);
  const preparedMinutesValue = getPreparedValue(minutesValue, minutesOptions);
  const preparedSecondsValue = getPreparedValue(secondsValue, secondsOptions);

  const onChangeHandler = (val, func) => {
    func && func(val[0].value);
  };

  return (
    <div className={c(styles.timeInput)}>
      {hours && (
        <div>
          <Select
            options={hoursOptions}
            selected={preparedHoursValue}
            onSelectOption={(val) => onChangeHandler(val, onChangeHours)}
            isError={isError}
            placeholder="00"
          />
        </div>
      )}
      {hours && (minutes || seconds) && <div>{separator}</div>}
      {minutes && (
        <Select
          options={minutesOptions}
          selected={preparedMinutesValue}
          onSelectOption={(val) => onChangeHandler(val, onChangeMinutes)}
          isError={isError}
          placeholder="00"
        />
      )}
      {(hours || minutes) && seconds && <div>{separator}</div>}
      {seconds && (
        <Select
          options={secondsOptions}
          selected={preparedSecondsValue}
          onSelectOption={(val) => onChangeHandler(val, onChangeSeconds)}
          isError={isError}
          placeholder="00"
        />
      )}

      {actionText && (
        <Box>
          <ActionText actionText={actionText} />
        </Box>
      )}
    </div>
  );
};
export default TimeInput;
