import React, { FC, useState, useEffect } from "react";
import { useNavigate, useParams, Outlet as AppealInfo } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import c from "classnames";

import {
  ModalWindow,
  DropdownButton,
  Tabs,
  MenuOption
} from "../../Components";
import Monitoring from "../Monitoring";
import Charts from "../Charts";
import Appeals from "../Appeals";
import DashboardFilters from "../DashboardFilters";
import AppealsReportModal from "../../ReactFeatures/AppealReportModal";

import {
  getFiltersSelectedData,
  prepareQueryParams
} from "../DashboardFilters/utils";
import hasUserRight from "../../../src/Utils/hasUserRight";
import {
  parseParamsToUrl,
  parseQueryToChartsFilter,
  parseQueryToAppealFilter
} from "./utils";

import useQuery from "../../ReactFeatures/Common/Hooks/useQuery";
import { useActions } from "./Store/DashboardSection.reducer";

import { useActions as useAppealsActions } from "../Appeals/Store/Appeals.reducer";

import { DashboardQueryParams } from "./DashboardSection";

import * as styles from "./style.module.scss";

export enum Dashboard_Tabs {
  "APPEALS" = "appeals",
  "CHARTS" = "charts"
}

enum Modals {
  AppealsReportModal = 1
}

const dashboardTabs = [
  {
    id: Dashboard_Tabs.APPEALS,
    label: "Обращения"
  },
  {
    id: Dashboard_Tabs.CHARTS,
    label: "Статистика"
  }
];

const DashboardSection: FC = () => {
  const navigate = useNavigate();
  const { query } = useQuery<DashboardQueryParams>();
  const { currentTab, appealId } = useParams<{
    currentTab: Dashboard_Tabs;
    appealId?: string;
  }>();

  useEffect(() => {
    if (!currentTab) {
      navigate({
        search: parseParamsToUrl(query),
        pathname: `/${Dashboard_Tabs.APPEALS}`
      });
    }
  }, []);

  useEffect(() => {
    if (![Dashboard_Tabs.APPEALS, Dashboard_Tabs.CHARTS].includes(currentTab)) {
      navigate({
        search: parseParamsToUrl(query),
        pathname: `/${Dashboard_Tabs.APPEALS}`
      });
    }
  }, [query]);

  const [modalOpened, setModalOpened] = useState<Modals | null>(null);

  const { sendEmailReport, downloadStatisticsReport } = useActions();

  const { getAppeals } = useAppealsActions();

  const menuItems = () => {
    const reportAppeals = hasUserRight("report_appeals")
      ? [
          {
            id: "appealsReport",
            content: <MenuOption text="Обращения" />,
            onClick: () => setModalOpened(Modals.AppealsReportModal)
          }
        ]
      : [];

    const reportExcel = hasUserRight("excel")
      ? [
          reportAppeals.length && { divider: true },
          {
            id: "exportStatistics",
            content: <MenuOption text="Статистика" />,
            onClick: () =>
              downloadStatisticsReport({
                filters: parseParamsToUrl(parseQueryToChartsFilter(query))
              })
          },
          { divider: true },
          {
            id: "сonsolidatedReport",
            content: <MenuOption text="Сводный отчет" />,
            onClick: () =>
              sendEmailReport({
                filters: parseQueryToChartsFilter(query)
              })
          }
        ]
      : [];

    return [...reportAppeals, ...reportExcel];
  };

  const dropdownSX = {
    button: {
      btnWrap: {
        height: "26px",
        width: "100px",
        padding: "7px"
      },
      text: ({ typography: { h2 } }) => ({
        fontSize: `${h2.fontSize}px`,
        fontWeight: h2.fontWeight
      })
    },
    menu: ({ typography: { h2 } }) => ({
      ".MuiTypography-root.MuiListItemText-primary": {
        fontSize: `${h2.fontSize}px`,
        fontWeight: h2.fontWeight,
        whiteSpace: "nowrap",
        padding: "0 5px"
      }
    })
  };

  return (
    <>
      {currentTab &&
        [Dashboard_Tabs.APPEALS, Dashboard_Tabs.CHARTS].includes(
          currentTab
        ) && (
          <div className={styles["dashboard"]}>
            <div className={styles["dashboardLeft"]}>
              {hasUserRight("monitoring") && hasUserRight("system_dashboard") && (
                <Monitoring
                  onUserSelect={(id) => {
                    navigate({
                      search: parseParamsToUrl({
                        ...query,
                        agent: `${id}`
                      }),
                      pathname: `/${currentTab}`
                    });
                  }}
                  onGroupSelect={(id) => {
                    delete query.agent;
                    delete query.skill;
                    navigate({
                      search: parseParamsToUrl({
                        ...query,
                        ...(id ? { skill: `${id}` } : {})
                      }),
                      pathname: `/${currentTab}`
                    });
                  }}
                />
              )}
            </div>
            <div className={styles["dashboardRight"]}>
              <div className={c(styles["dashboardHead"])}>
                <div className={styles["dashboardTabs"]}>
                  <Tabs
                    enableExternalControl
                    data={dashboardTabs}
                    onChange={(v) => {
                      navigate({
                        pathname: `/${v.id}`,
                        search: parseParamsToUrl({ ...query })
                      });
                    }}
                    value={dashboardTabs.findIndex((d) => d.id === currentTab)}
                  />
                </div>
                {hasUserRight("system_dashboard") &&
                  (hasUserRight("excel") || hasUserRight("report_appeals")) && (
                    <div
                      className={c(styles["dashboardExport"], {
                        [styles["hide"]]: appealId
                      })}
                    >
                      <DropdownButton
                        text="Экспорт"
                        anchorTransformPosition="right"
                        iconButton={{
                          position: "right",
                          CollapsedIcon: LaunchIcon,
                          ExpandedIcon: LaunchIcon
                        }}
                        extraSX={dropdownSX}
                        menuItems={menuItems()}
                      />
                    </div>
                  )}
              </div>
              <div className={c(styles["dashboardFilters"])}>
                <DashboardFilters
                  preSelectedData={getFiltersSelectedData(query)}
                  onAppealChange={(appealId: number) => {
                    navigate({
                      search: parseParamsToUrl({
                        ...query
                      }),
                      pathname: `/${currentTab}/${appealId}`
                    });
                  }}
                  onChange={(formData) => {
                    // Почему-то без задержки, в react-router не попадает первый инициализационный onChange
                    setTimeout(() => {
                      navigate({
                        pathname: `/${currentTab}`,
                        search: parseParamsToUrl({
                          ...query,
                          ...prepareQueryParams(formData)
                        })
                      });
                    }, 500);
                  }}
                />
              </div>
              <div
                className={c(styles["dashboardItems"], {
                  [styles["hide"]]: appealId
                })}
              >
                {currentTab === Dashboard_Tabs.APPEALS && (
                  <Appeals
                    onOpenAppeal={(appealId) =>
                      navigate({
                        search: parseParamsToUrl({
                          ...query
                        }),
                        pathname: `/${currentTab}/${appealId}`
                      })
                    }
                  />
                )}
                {currentTab === Dashboard_Tabs.CHARTS && <Charts />}
              </div>
              <AppealInfo
                context={{
                  resetAppeals: () =>
                    getAppeals({
                      page: 0,
                      filters: parseQueryToAppealFilter(query)
                    })
                }}
              />
            </div>

            <ModalWindow
              open={!!modalOpened}
              onCloseModal={() => setModalOpened(null)}
              isButtonClose
            >
              {modalOpened === Modals.AppealsReportModal && (
                <AppealsReportModal />
              )}
            </ModalWindow>
          </div>
        )}
    </>
  );
};

export default DashboardSection;
