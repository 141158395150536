import { createAction } from "@reduxjs/toolkit";
import * as ACTION from "../Consts";

import { CategoryModalTypes } from "../Interfaces";

export const getCategory = createAction<number>(ACTION.GET_CATEGORY);
export const getSkillgroups = createAction(ACTION.GET_SKILLGROUPS);
export const setCategory = createAction<any>(ACTION.SET_CATEGORY);
export const setSkillgroups = createAction<CategoryModalTypes.SkillGroup[]>(
  ACTION.SET_SKILLGROUPS
);
export const setSelectedSkillgroups = createAction<number[]>(
  ACTION.SET_SELECTED_SKILLGROUPS
);
export const setAll = createAction(ACTION.SET_ALL);
export const setName = createAction<string>(ACTION.SET_NAME);
export const saveCategory = createAction<any>(ACTION.SAVE_CATEGORY);
export const removeCategory = createAction<any>(ACTION.REMOVE_CATEGORY);
export const resetModalData = createAction(ACTION.RESET);
