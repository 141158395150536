import { IFormData } from "./UsersMultiProfileModal.schema";
import { DTO } from "./UsersMultiProfileModal.d";

export const mapFormDataToDto = ({ file, ...data }): DTO => {
  let preparedData = {} as IFormData;
  for (let key in data) {
    preparedData[key] =
      typeof data[key] === "string" && data[key].length === 0
        ? null
        : data[key];
  }

  return {
    ...preparedData,
    skillGroupIds: preparedData.skillGroupIds.map((group) => group.value)
  };
};
