import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionRoles } from "../Store/Roles.reducer";
import Informer from "../../../arm/Wrappers/Informer";
import { ERROR_MESSAGE_WHILE_GET_DATA } from "../const";

function* getRolesSaga() {
  try {
    const res = yield httpRequest("GET", `bff/settings/roles`);
    if (res.success && res.data) {
      yield put(actionRoles.setRoles(res.data));
    }
  } catch (error) {
    const info = new Informer(ERROR_MESSAGE_WHILE_GET_DATA);
    info.show();
  } finally {
  }
}

export default function*() {
  yield takeEvery([actionRoles.getRoles], getRolesSaga);
}
