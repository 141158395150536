import React, { memo, useState, useReducer } from "react";
import { Button } from "@omnichat/arm_ui_kit";
import { Grid } from "@mui/material";
import Select from "../../../../Components/Select";

import * as s from "./SystemSetting.module.scss";
import {
  SystemSettingItem,
  UpdateSystemSetting
} from "../../SystemSettings.hooks";
import { useDidUpdate } from "../../../../ReactFeatures/Common/Hooks/useDidUpdate";

interface SystemSettingProps {
  systemSetting: SystemSettingItem;
  skillGroups: { label: string; value: number }[];
  onSave: (setting: UpdateSystemSetting) => Promise<void>;
}

export const SystemSettingsInteger: React.FC<SystemSettingProps> = ({
  systemSetting,
  skillGroups,
  onSave
}) => {
  const [value, setValue] = useState([
    { value: +systemSetting.settingValue, label: systemSetting.settingValue }
  ]);
  const [mode, toggleMode] = useReducer(
    (prevMode) => (prevMode === "edit" ? "view" : "edit"),
    "view"
  );

  const handleSelectOption = (nextValue) => setValue(nextValue);

  const handleCancel = () => {
    setValue([
      { value: +systemSetting.settingValue, label: systemSetting.settingValue }
    ]);
    toggleMode();
  };

  const handleChangeToDefault = () =>
    setValue([
      { value: +systemSetting.settingValue, label: systemSetting.settingValue }
    ]);

  const handleSave = () =>
    onSave({ id: systemSetting.id, settingValue: String(value[0].value) }).then(
      toggleMode
    );

  useDidUpdate(() => {
    if (systemSetting.disabled && mode !== "view") {
      toggleMode();
      handleChangeToDefault();
    }
  }, [systemSetting.disabled]);

  if (mode === "view" || systemSetting.disabled) {
    return (
      <Grid container>
        <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
          {systemSetting.settingDescription}
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={6}
          p={1}
          borderBottom="1px solid #cbd7da"
        >
          {
            skillGroups.find(
              (skillGroup) => skillGroup.value === +systemSetting.settingValue
            )?.label
          }
        </Grid>
        <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
          <Button
            text="Изменить"
            type="underline"
            disabled={systemSetting.disabled}
            theme={systemSetting.disabled ? "disabled" : "default"}
            onClick={toggleMode}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={3} p={1} borderBottom="1px solid #cbd7da">
        {systemSetting.settingDescription}
      </Grid>
      <Grid
        container
        alignItems="center"
        item
        xs={6}
        p={1}
        borderBottom="1px solid #cbd7da"
      >
        <div className={s["select"]}>
          <Select
            id={systemSetting.settingKey}
            name={systemSetting.settingKey}
            options={skillGroups}
            selected={value}
            onSelectOption={handleSelectOption}
          />
        </div>
        <Button
          text="По Умолчанию"
          type="underline"
          onClick={handleChangeToDefault}
          iconName="check"
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        item
        xs={3}
        p={1}
        borderBottom="1px solid #cbd7da"
      >
        <Button
          text="Сохранить"
          type="underline"
          onClick={handleSave}
          iconName="check"
        />
        <Button text="Отменить" type="underline" onClick={handleCancel} />
      </Grid>
    </Grid>
  );
};

export default memo(SystemSettingsInteger);
