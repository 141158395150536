import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionUsersMultiProfileModal as actions } from "../Store/UsersMultiProfileModal.reducer";
import Informer from "../../../arm/Wrappers/Informer";

export function* sendUsersMultiProfileSaga(action) {
  yield put(actions.setSendDataHandling(true));
  const { formData, close } = action.payload;

  try {
    const res = yield httpRequest(
      "PUT",
      "bff/settings/profiles/multi/update-common-params",
      { body: formData }
    );

    if (res?.success) {
      const informer = new Informer("Профиль успешно отредактирован");
      informer.show();
    } else {
      const informer = new Informer("Не удалось отредактировать профиль");
      informer.show();
      console.error(res?.error);
    }
    close();
  } catch ($error) {
    const informer = new Informer("Не удалось отредактировать профиль");
    informer.show();
    console.error($error);
  } finally {
    yield put(actions.setSendDataHandling(false));
  }
}

export default function*() {
  yield takeEvery(
    [actions.sendUsersMultiProfileData],
    sendUsersMultiProfileSaga
  );
}
