import React, { FC, useState, memo } from "react";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import { Box } from "@mui/material";
import DraggableSelector from "./DraggableSelect";

import * as styles from "./style.module.scss";

export interface Option {
  value: number;
  label: string;
  disabled?: boolean;
}

interface IDragAndDropSelectProps {
  label?: string;
  iconName?: string;
  selected: Option[];
  options: Option[];
  addText: string;
  required?: boolean;
  onChange: (option: Option[]) => void;
  isError?: boolean;
  actionText?: string;
}

const DragAndDropSelect: FC<IDragAndDropSelectProps> = ({
  actionText = "",
  isError = false,
  label,
  iconName = "apps",
  options = [],
  selected = [],
  addText = "Добавить пункт",
  required = false,
  onChange,
  ...props
}) => {
  const [isShowNewSelector, toggleNewSelector] = useState(false);
  const [disableSelector, setDisableSelector] = useState(false);

  const onOptionChange = (index: number, newValue: Option[]) => {
    const result = [...selected];
    result.splice(index, 1, newValue[0]);
    toggleNewSelector(false);
    onChange(result);
  };

  const onPositionChange = ({ destination, source }: DropResult) => {
    setDisableSelector(false);
    const newIndex = destination?.index;
    const oldIndex = source?.index;
    const result = [...selected];
    const value = result.splice(oldIndex, 1)[0];

    if (typeof newIndex !== "number" || value === undefined) return;

    result.splice(newIndex, 0, value);
    onChange(result);
  };

  const onDragStart = () => setDisableSelector(true);

  const onRemove = (index: number) => {
    const result = [...selected];
    result.splice(index, 1);
    onChange(result);
  };

  const excludeSelectedOptions = (selectedOption: Option | null) =>
    options?.filter(
      ({ value }) =>
        !selected.find(
          (item) => value === item.value && value !== selectedOption?.value
        )
    );

  const stillHaveOptions = (() => {
    const lastOptions = excludeSelectedOptions(null).filter(
      ({ disabled }) => !disabled
    );
    return lastOptions.length !== 0;
  })();

  const isHaveNoSelectedOptions = selected.length === 0;

  return (
    <Box
      sx={{
        fontSize: "20px",
        minHeight: "24px"
      }}
      {...props}
    >
      <div>
        <DragDropContext onDragEnd={onPositionChange} onDragStart={onDragStart}>
          <Droppable droppableId="droppable">
            {(droppableProvider) => (
              <Box
                sx={{
                  marginTop: "3px",
                  borderRadius: "2px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  overflow: "hidden"
                }}
                ref={droppableProvider.innerRef}
              >
                {selected.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={`id-${index}`}
                    index={index}
                  >
                    {(draggableProvider) => (
                      <DraggableSelector
                        isError={isError}
                        draggableProvider={draggableProvider}
                        options={excludeSelectedOptions(item)}
                        index={index}
                        item={item}
                        disabled={disableSelector || item.disabled}
                        onOptionChange={(value) => onOptionChange(index, value)}
                        remove={onRemove}
                      />
                    )}
                  </Draggable>
                ))}

                {(isHaveNoSelectedOptions ||
                  (isShowNewSelector && stillHaveOptions)) && (
                  <Draggable
                    key={selected.length}
                    draggableId={`id-${selected.length}`}
                    index={selected.length}
                  >
                    {(draggableProvider) => (
                      <DraggableSelector
                        isError={isError}
                        draggableProvider={draggableProvider}
                        options={excludeSelectedOptions(null)}
                        index={selected.length}
                        disabled={disableSelector}
                        item={null}
                        onOptionChange={(value) =>
                          onOptionChange(selected.length, value)
                        }
                        remove={() => toggleNewSelector(false)}
                      />
                    )}
                  </Draggable>
                )}
                {droppableProvider.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {!isShowNewSelector && !isHaveNoSelectedOptions && stillHaveOptions && (
        <div
          onClick={() => toggleNewSelector(true)}
          className={styles.dndHandlerAddContainer}
        >
          <div className={styles.dndHandlerAdd}>{addText}</div>
        </div>
      )}
    </Box>
  );
};

export default memo(DragAndDropSelect);
