import { takeEvery, put } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { sendOperatorsTyping } from "../Actions/Dialog.actions";

function* sendOperatorsTypingSaga(action) {
  const dialogId = action.payload;

  yield httpRequest("POST", `bff/chat/dialog/${dialogId}/typing`);
}

export default function*() {
  yield takeEvery([sendOperatorsTyping], sendOperatorsTypingSaga);
}
