import * as React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../ReactCore/store/types";
import { Preloader } from "@omnichat/arm_ui_kit";

import {
  getTemplateModalCatalogs,
  sendTemplateModalData,
  getTemplateData,
  resetTemplateData,
  changeTemplateModalCategoriesPage
} from "./Store/RepliesTemplateEditModal.action";

import {
  pushFiles,
  sendFile,
  removeFile,
  cleanFileList
} from "../FileStorage/fileStorage.actions";
import { isEmpty } from "@omnichat/arm_ui_kit";
import ReplyTemplateEditModal from "./Components/ReplyTemplateEdit/ReplyTemplateEdit";
import { IHotkey } from "../Common/Interfaces/HotKeys.interfaces";
import { isPending } from "../Common/Utils/LoadingData.utils";
import { getAvailableHotKeysData } from "../AvailableHotKeys/Actions/AvailableHotKeysModal.actionCreators";

/**
 * Модель собственных свойств компонента.
 */
interface IOwnProps {
  templateId?: number | string;
  closeModal: () => void;
}

/**
 * Модель свойств, полученных из store.
 */
interface IStateProps {
  files: any;
  replyData: any;
  categories: any;
  regions: any;
  asyncHotKeysListData: any;
}

/**
 * Модель экшенов.
 */
interface IDispatchProps {
  getTemplateModalCatalogs: any;
  getTemplateData: any;
  sendTemplateModalData: any;
  pushFiles: any;
  sendFile: any;
  removeFile: any;
  cleanFileList: any;
  resetTemplateData: any;
  getAvailableHotKeysData: any;
  changeTemplateModalCategoriesPage: any;
}

type RepliesTemplateEditModalProviderProps = IOwnProps &
  IStateProps &
  IDispatchProps;

class RepliesTemplateEditModalProvider extends React.PureComponent<
  RepliesTemplateEditModalProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      getTemplateData,
      templateId,
      getTemplateModalCatalogs,
      replyData,
      getAvailableHotKeysData
    } = this.props;

    getAvailableHotKeysData();
    getTemplateModalCatalogs();

    if (templateId && templateId != 0 && !replyData) {
      getTemplateData(templateId);
    }
  }

  componentDidUpdate() {
    this.props.files.map((file) => {
      if (file.failed) {
        this.props.removeFile(file.uuid);
      }
      !file.sended &&
        !file.failed &&
        !file.pending &&
        this.props.sendFile(file.uuid);
    });
  }

  componentWillUnmount() {
    this.props.cleanFileList();
    this.props.resetTemplateData();
  }

  handleSubmit = (values) => {
    this.props.sendTemplateModalData(this.props.templateId, values, () =>
      this.props.closeModal()
    );
  };

  mapReplyData = (data) => ({
    title: data.title,
    body: data.text,
    category: data.category
      ? {
          value: data.category.id,
          label: data.category.name
        }
      : null,
    regions: data.regionsIds,
    attachments: data.attachmentsUrl,
    hotKeyCodes: data.hotkeyCode,
    keyShortcut: data.hotkeyValue
  });

  pushFiles = (e, filesArr) => {
    this.props.pushFiles(filesArr);
  };

  /**
   * Вернет мап [кодовое представление сочетания клавиш, наименование шаблона].
   *
   * @param {IHotkey[]} data Данные.
   */
  mapHotKeyListData = (data: IHotkey[]) => {
    const { templateId } = this.props;
    let result = null;

    if (!isEmpty(data)) {
      result = new Map();
      const filteredData = data.filter(
        (hotKey: IHotkey) => hotKey.replyTemplateId !== templateId
      );

      filteredData.forEach((hotKey: IHotkey) => {
        result.set(hotKey.keyCode, hotKey.replyTemplateTitle);
      });
    }

    return result;
  };

  mapNewAttachments = (files) => {
    return files.map((file) => ({
      uuid: file.uuid,
      name: file.name,
      progress: file.progress,
      pending: file.pending,
      sended: file.sended,
      failed: file.failed,
      data: file.data
    }));
  };

  render() {
    return (
      <Preloader
        size="mini"
        show={
          !this.props.categories.length &&
          !this.props.regions.length &&
          isPending(this.props.asyncHotKeysListData, true)
        }
      >
        <ReplyTemplateEditModal
          id={this.props.templateId}
          replyData={
            this.props.replyData
              ? this.mapReplyData(this.props.replyData)
              : null
          }
          categories={this.props.categories}
          regions={this.props.regions}
          newAttachments={this.mapNewAttachments(this.props.files)}
          onLoadFile={this.pushFiles}
          onSubmit={this.handleSubmit}
          removeNewAttachment={this.props.removeFile}
          usedHotKeys={this.mapHotKeyListData(
            this.props.asyncHotKeysListData?.data
          )}
          onNextCategoryPage={() =>
            this.props.changeTemplateModalCategoriesPage()
          }
        />
      </Preloader>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  files: state.FileStorage.files,
  replyData: state.RepliesTemplateEditModal.replyData,
  categories: state.Dictionaries.categories,
  regions: state.Dictionaries.regions,
  asyncHotKeysListData: state.AvailableHotKeys.availableHotKeysData
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
  getTemplateModalCatalogs: () => dispatch(getTemplateModalCatalogs()),
  getTemplateData: (id) => dispatch(getTemplateData(id)),
  sendTemplateModalData: (id, data, callback) =>
    dispatch(sendTemplateModalData({ id, data, close: callback })),
  pushFiles: (files) => dispatch(pushFiles(files)),
  sendFile: (uuid) => dispatch(sendFile(uuid)),
  removeFile: (uuid) => dispatch(removeFile(uuid)),
  cleanFileList: () => dispatch(cleanFileList()),
  resetTemplateData: () => dispatch(resetTemplateData()),
  getAvailableHotKeysData: (data) => dispatch(getAvailableHotKeysData(data)),
  changeTemplateModalCategoriesPage: () =>
    dispatch(changeTemplateModalCategoriesPage())
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepliesTemplateEditModalProvider);

export default Connect;
