import { put, takeLatest, delay } from "redux-saga/effects";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { LOADED_TRANSFER_DICTIONARY, GET_TRANSFER_DICTIONARY } from "../consts";
import {
  getPreparedTransferSkillGroupList,
  getPreparedTransferUserList
} from "../Dictionaries.service";

export function* getTransferListSaga(action) {
  yield delay(1000);

  try {
    const { target, query = null, page = 0, appealId, cb } = action.payload;
    let response, resultData;
    if (target === "agent") {
      response = yield httpRequest(
        "GET",
        `bff/dictionary/users-for-moving-appeals`,
        {
          query: {
            page: page,
            query: query,
            appealId: appealId
          }
        }
      );
      resultData = getPreparedTransferUserList(response?.data?.content);
    } else if (target === "group") {
      response = yield httpRequest(
        "GET",
        `bff/dictionary/skill-groups-for-moving-appeals`,
        {
          query: {
            page: page,
            query: query
          }
        }
      );
      resultData = getPreparedTransferSkillGroupList(response?.data?.content);
    }

    if (!isEmpty(resultData)) {
      yield put({
        type: LOADED_TRANSFER_DICTIONARY,
        payload: resultData
      });
    }
    cb && cb();
  } catch (e) {
    console.error("Saga: getRegionsSaga error", e);
  }
}

export default function*() {
  yield takeLatest(GET_TRANSFER_DICTIONARY, getTransferListSaga);
}
