import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import initialState from "../../../ReactCore/store/initialState";

export const VALUE_CREATED = "VALUE_CREATED";
export const GET_VALUE_DATA = "GET_VALUE_DATA";
export const SEND_VALUE_DATA = "SEND_VALUE_DATA";
export const REMOVE_VALUE = "REMOVE_VALUE";

export const valueCreated = createAction(VALUE_CREATED);
export const getValueData = createAction(GET_VALUE_DATA, data => ({
  payload: data
}));
export const sendValueData = createAction(SEND_VALUE_DATA, data => ({
  payload: data
}));
export const removeValue = createAction(REMOVE_VALUE, data => ({
  payload: data
}));

const valueFormSlice = createSlice({
  name: "classifierFieldValueForm",
  initialState: initialState.ClassifierFieldValueForm,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setValueData(state, action) {
      state.valueData = action.payload;
    },
    setLinkData(state, action) {
      state.linksData = action.payload;
    },
    resetValueData(state) {
      state.loading = initialState.ClassifierFieldValueForm.loading;
      state.errorMessage = initialState.ClassifierFieldValueForm.errorMessage;
      state.valueData = initialState.ClassifierFieldValueForm.valueData;
    },
    setValueResponseError(state, action) {
      state.errorMessage = action.payload;
    }
  }
});

export const {
  resetValueData,
  setValueData,
  setLinkData,
  setValueResponseError,
  startLoading,
  finishLoading
} = valueFormSlice.actions;

export default valueFormSlice.reducer;
