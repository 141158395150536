export const secondsInWeek = 60 * 60 * 24 * 7 * 1000;

// 23 hours 59 min 59 sec
export const secondsInDay = (60 * 60 * 23 + 60 * 59 + 59) * 1000;

export const validatePeriodLessOneWeek = (startDate, endDate) => {
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();

  return secondsInWeek > endTime - startTime;
};

export const setEndOfTheDay = (date) => {
  const seconds = date.getTime();

  return new Date(date.getTime() + secondsInDay);
};
