import * as React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../ReactCore/store/types";

import { Preloader } from "@omnichat/arm_ui_kit";

import CategoryForRepliesModal from '../Components/CategoryForRepliesModal/CategoryForRepliesModal';
import * as actions from "../Actions/CategoryForRepliesModal.action";

import { getCategoriesList } from "../CategoryForRepliesModal.selectors";

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  onSuccess: () => void;
  onError: () => void;
  onCancel: () => void;
}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {
  categories: {
    value: number;
    label: string;
  }[];
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  resetData: () => void;
  getCategories: () => void;
  getNextPage: () => void;
  saveReplies: (id: number, onSuccess: () => void, onError: () => void) => void;
}

type CategoryForRepliesModalProviderProps = IOwnProps &
  IStateProps &
  IDispatchProps;

/**
 * Провайдер фичи CategoryForRepliesModal.
 */
class CategoryForRepliesModalProvider extends React.PureComponent<
  CategoryForRepliesModalProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.categories.length) return;

    this.props.getCategories();
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  render() {
    return (
      <>
        <Preloader size="mini" show={!this.props.categories.length}>
          <CategoryForRepliesModal
            categories={this.props.categories}
            onConfirm={id =>
              this.props.saveReplies(
                id,
                this.props.onSuccess,
                this.props.onError
              )
            }
            onNextPage={() => this.props.getNextPage()}
            onCancel={() => this.props.onCancel()}
          />
        </Preloader>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  categories: getCategoriesList(state)
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
  resetData: () => dispatch(actions.resetData()),
  getCategories: () => dispatch(actions.getCategories()),
  getNextPage: () => dispatch(actions.getNextPage()),
  saveReplies: (id, onSuccess, onError) =>
    dispatch(actions.saveReplies({ id, onSuccess, onError }))
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryForRepliesModalProvider);

export default Connect;
