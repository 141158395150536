import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import {
  getSkillGroupData,
  setSkillGroupData
} from "../Store/SkillGroupModal.reducer";

export function* getSkillGroupDataSaga({ payload }) {
  const { skillId } = payload;

  try {
    const response = yield httpRequest(
      "GET",
      `bff/settings/skill-groups/${skillId}/get`
    );

    if (response.success) {
      yield put(setSkillGroupData(response.data));
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function*() {
  yield takeEvery([getSkillGroupData], getSkillGroupDataSaga);
}
