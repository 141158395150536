import { ThematicModalTypes } from "./Interfaces";

const thematicModal: ThematicModalTypes.State = {
  errorMessage: null,
  activeService: null,
  serviceEnabled: false,
  selectedRegion: null,
  editTheme: null,
  themeFields: null,
  appealThemes: null,
  originAppealThemes: [],
  originAppealService: null,
  isLoading: false
};

export default thematicModal;
