import { useCallback, useEffect, useState } from 'react';

interface IScrollReturn {
  scrollCondition: boolean,
  deps: unknown
};

export const useRefScrollReturn = ({
  scrollCondition,
  deps
}: IScrollReturn) => {
  const [scroll, setScroll] = useState(null);

  const scrollReferenses = useCallback(
    (ref) => setScroll(ref),
    [scroll]
  );

  useEffect(() => {
    if (scrollCondition) scroll?.scrollTop(0);
  }, [deps])

  return scrollReferenses;
};
