import React, { useState, useEffect } from "react";
import { isEmpty, Preloader } from "@omnichat/arm_ui_kit";

import styled from "styled-components";

import { fileExtensions } from "../consts";
import ModalWindow from "../../../Components/ModalWindow";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import ChannelSettingsModal from "./ChannelSettingsModal";
import { TReducerState } from "./ChannelSettingsModalReducer";
import ConforimModal from "../../../ReactFeatures/Common/Components/ConfirmModal/ConfirmModal";
import Informer from "../../../arm/Wrappers/Informer";
import { getAllowedExtension } from "../Channels.helpers";

const EditChannelModalRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export function EditChannel({ open, channel, handleClose }) {
  const [state, updateState] = useState<{
    name: string;
    settings: TReducerState;
  }>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    httpRequest("GET", `bff/settings/channels/${channel}`).then(
      (response: any) => {
        if (response?.success) {
          const newState = {
            name: response.data?.name || "",
            settings: {}
          };

          if (response.data?.channelSettings?.behaviour) {
            newState.settings = response.data.channelSettings.behaviour;
          } else if (!isEmpty(response.data.channelSettings)) {
            newState.settings = {
              ...response.data?.channelSettings,
              backlogEnabled: response.data?.backlogEnabled
            };
          }

          let responseExtensions =
            response.data.channelSettings?.files?.allowedTypes;
          if (responseExtensions?.length) {
            let validatedExtensions = getAllowedExtension(responseExtensions);
            if (responseExtensions.length !== validatedExtensions.length) {
              newState.settings.files.allowedTypes = validatedExtensions;
              const info = new Informer(
                `Изменились настройки допустимых расширений. Требуется пересохранить настройки канала! `,
                4500
              );
              info.show();
            }
          }

          updateState(newState);
        }
      }
    );
  }, [channel]);

  const handleDelete = () => setOpenConfirmModal(true);

  const handleConfirmDelete = () =>
    httpRequest("DELETE", `bff/settings/channels/${channel}`).then((res) => {
      if (res?.success) {
      } else {
        console.error(res?.error);
      }
      handleClose?.();
      setOpenConfirmModal(false);
    });

  const handleCancelDelete = () => setOpenConfirmModal(false);

  const handleSave = (name, settings) => {
    httpRequest("PUT", `bff/settings/channels/${channel}`, {
      body: { name, settings }
    }).then((res) => {
      if (res?.success) {
      } else {
        console.error(res?.error);
      }
      handleClose?.();
    });
  };

  if (openConfirmModal) {
    return (
      <ConforimModal
        open
        title="Подтверждение удаления"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        description={
          <>
            Выбранный канал будет удален <br /> Хотите продолжить?
          </>
        }
      />
    );
  }

  return (
    <ModalWindow open={open} onCloseModal={handleClose} isButtonClose>
      <EditChannelModalRoot>
        <Preloader show={state === null}>
          <EditChannelModalRoot>
            {!!state && (
              <ChannelSettingsModal
                channelId={channel}
                defaultState={state.settings}
                fileExtensions={fileExtensions}
                name={state.name}
                onSave={handleSave}
                onDelete={handleDelete}
              />
            )}
          </EditChannelModalRoot>
        </Preloader>
      </EditChannelModalRoot>
    </ModalWindow>
  );
}
