const skillGroupCapacityModal = {
  status: {
    skillGroupsDataLoaded: false
  },
  skillGroupsData: {
    skillId: null,
    queue: false,
    queueLimit: null,
    appealLimit: null
  }
};

export default skillGroupCapacityModal;
