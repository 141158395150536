import React, { FC } from "react";

import { Typography } from "@mui/material";
import * as styles from "./style.module.scss";

const ModalHeader: FC = ({ children }): JSX.Element => {
  return (
    <header className={styles.modalHeader}>
      <Typography variant="h1">{children}</Typography>
    </header>
  );
};

export default ModalHeader;
