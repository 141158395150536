import { store } from "../../ReactCore";

import * as chatSelectors from "../store/Chat.selectors";
import * as chatActions from "../store/Chat.actionCreators";

import { getUpdatedDialogListData } from "../Chat.service";

const notForWaitingAction = (payload) => {
  let dialogList = chatSelectors.getDialogList(store.getState());

  if (!dialogList.length) return;

  const targetDialog = dialogList.find(
    (obj) => obj.dialogId === +payload.data.dialogId
  );

  if (!targetDialog?.withoutTheme) return;

  store.dispatch(
    chatActions.updateDialogList(
      getUpdatedDialogListData(dialogList, [
        {
          dialogId: payload.data.dialogId,
          withoutTheme: false
        }
      ])
    )
  );
};

export default notForWaitingAction;
