/**
 * Коллекция статусов, в которых может находится загрузка данных.
 *
 * @prop {number} IDLE Бездействие - начальное состояние данных в store.
 * @prop {number} PENDING В ожидании - загрузка данных началась.
 * @prop {number} FULFILLED Успешное завершение - данные получены.
 * @prop {number} REJECTED Ошибка - запрос отклонен, данные не получены.
 */
export enum EDOWNLOAD_STATUS {
  IDLE = 0,
  PENDING = 1,
  FULFILLED = 2,
  REJECTED = 3
}

/**
 * Коллекция статусов загрузки данных через XMLHttpRequest.
 *
 * @prop {number} UNSENT Исходное состояние.
 * @prop {number} OPENED Вызван метод open.
 * @prop {number} HEADERS_RECEIVED Получены заголовки ответа
 * @prop {number} LOADING Ответ в процессе передачи (данные частично получены).
 * @prop {number} DONE Передача завершена.
 */
export enum EXHR_READY_STATE {
  UNSENT = 0,
  OPENED = 1,
  HEADERS_RECEIVED = 2,
  LOADING = 3,
  DONE = 4
}
