const NAMESPACE = "RepliesTemplate";

export const DIALOG_TEMPLATE_MODAL_CATEGORIES_ADD = `${NAMESPACE}_DIALOG_TEMPLATE_MODAL_CATEGORIES_ADD`;
export const DIALOG_TEMPLATE_MODAL_CATEGORIES_LOADING = `${NAMESPACE}_DIALOG_TEMPLATE_MODAL_CATEGORIES_LOADING`;
export const DIALOG_TEMPLATE_MODAL_LOADED = `${NAMESPACE}_DIALOG_TEMPLATE_MODAL_LOADED`;
export const SET_DIALOG_TEMPLATE_MODAL_CATEGORIES = `${NAMESPACE}_SET_DIALOG_TEMPLATE_MODAL_CATEGORIES`;
export const CHANGE_CATEGORIES_PAGE = `${NAMESPACE}_CHANGE_CATEGORIES_PAGE`;

export const GET_REPLIES = `${NAMESPACE}_GET_REPLIES`;
export const SET_REPLIES = `${NAMESPACE}_SET_REPLIES`;
export const ADD_REPLIES = `${NAMESPACE}_ADD_REPLIES`;
export const CHANGE_REPLIES_PAGE = `${NAMESPACE}_CHANGE_REPLIES_PAGE`;
export const RESET_REPLIES_DATA = `${NAMESPACE}_RESET_REPLIES_DATA`;
