import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Preloader } from "@omnichat/arm_ui_kit";

import ThematicModal from "../../../ReactFeatures/ThematicModal/Providers/ThematicModal.provider";

import { StoreState } from "../../../ReactCore/store/types";

import CloseModal from "../Components/CloseAppealByAgentWithoutClassifierModal/CloseAppealByAgentWithoutClassifierModal";
import { closeAppeal } from "../Actions/CloseDialogModal.action";

import {
  getCanEditThemes,
  getServiceEnabled
} from "../../ThematicModal/ThematicModal.selectors";

const CloseFromDashboard = ({
  comment,
  withThemes,
  appealId,
  onCancel,
  onConfirm,
  onAppealClose
}) =>
  comment && withThemes ? (
    <ThematicModal
      onConfirm={() => onAppealClose({ comment, closingFromChat: false })}
      appealId={appealId}
    />
  ) : (
    <CloseModal
      comment={true}
      onCancelAction={() => onCancel()}
      onConfirmAction={(comment) => onConfirm(comment)}
    />
  );

const CloseFromChat = ({ appealId, withThemes, onCancel, onAppealClose }) =>
  withThemes ? (
    <ThematicModal
      onConfirm={() => onAppealClose({ comment: "", closingFromChat: true })}
      appealId={appealId}
    />
  ) : (
    <CloseModal
      onCancelAction={() => onCancel()}
      onConfirmAction={() =>
        onAppealClose({ comment: "", closingFromChat: true })
      }
    />
  );

class CloseDialogModalProvider extends PureComponent<
  {
    isChat;
    onCancel;
    appealId;
    activeDialog;
    onConfirmCloseAppeal;
    withThemes;
    closeAppeal;
    appealThemes;
    selectedRegion;
    activeService;
    serviceEnabled;
  },
  {
    comment: string | null;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      comment: null
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onAppealClose = ({ comment, closingFromChat }) => {
    const {
      appealId,
      activeDialog,
      closeAppeal,
      onConfirmCloseAppeal,
      withThemes
    } = this.props;

    closeAppeal({
      appealId,
      dialogId: activeDialog,
      onConfirmCloseAppeal,
      comment,
      closingFromChat,
      themes: withThemes ? this.getThemesData() : {}
    });
  };

  getThemesData = () => {
    const {
      appealId,
      activeService,
      selectedRegion,
      appealThemes,
      serviceEnabled
    } = this.props;

    let themes = { appealId };

    if (serviceEnabled && selectedRegion?.value) {
      themes["serviceTagId"] = activeService;
      themes["regionId"] = selectedRegion.value;
    }

    const preparedThemes = appealThemes.map(({ fields }) => {
      return fields.map((theme) => ({
        fieldId: theme.fieldId,
        valueId: !["TEXTAREA", "INPUT"].includes(theme.fieldType)
          ? Number(theme.valueId)
          : undefined,
        textValue: ["TEXTAREA", "INPUT"].includes(theme.fieldType)
          ? theme.valueId
          : undefined
      }));
    });

    themes["values"] = preparedThemes.length ? preparedThemes : null;

    return themes;
  };

  setCommentAndClose = (text) => {
    const { withThemes } = this.props;
    this.setState({ comment: text });

    if (!withThemes) {
      this.onAppealClose({ comment: text, closingFromChat: false });
    }
  };

  render() {
    return (
      <>
        <Preloader size="mini" show={false}>
          {this.props.isChat ? (
            <CloseFromChat
              withThemes={this.props.withThemes}
              appealId={this.props.appealId}
              onAppealClose={this.onAppealClose}
              onCancel={this.props.onCancel}
            />
          ) : (
            <CloseFromDashboard
              withThemes={this.props.withThemes}
              appealId={this.props.appealId}
              comment={this.state.comment}
              onAppealClose={this.onAppealClose}
              onCancel={this.props.onCancel}
              onConfirm={(comment) => this.setCommentAndClose(comment)}
            />
          )}
        </Preloader>
      </>
    );
  }
}

export default connect(
  (state: StoreState.State) => ({
    withThemes: getCanEditThemes(state),
    appealThemes: state.ThematicModal.appealThemes,
    selectedRegion: state.ThematicModal.selectedRegion,
    activeService: state.ThematicModal.activeService,
    serviceEnabled: getServiceEnabled(state)
  }),
  (dispatch) => ({
    closeAppeal: (data) => dispatch(closeAppeal(data))
  })
)(CloseDialogModalProvider);
