import styled from "styled-components";

export const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const PreloaderWrapper = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
`;
