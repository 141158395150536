import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { RepliesTemplateModalTypes } from "../Interfaces";

import {
  DIALOG_TEMPLATE_MODAL_CATEGORIES_LOADING,
  DIALOG_TEMPLATE_MODAL_LOADED,
  CHANGE_CATEGORIES_PAGE
} from "../Сonsts";

import {
  setDialogTemplateModalCatalogs,
  addDialogTemplateModalCatalogs
} from "../Actions/repliesEditModal.actions";

function* getCategoriesSaga(action) {
  const { categoryPage }: RepliesTemplateModalTypes.State = yield select(
    ({ RepliesTemplate }) => RepliesTemplate
  );

  try {
    const response = yield httpRequest("GET", "bff/settings/categories", {
      query: {
        page: categoryPage,
        filterBy: "skillgroup"
      }
    });

    if (response?.success) {
      const catalogs = response.data?.content?.map((el) => ({
        id: el.id,
        value: el.id,
        label: el.name
      }));

      if (action.type === CHANGE_CATEGORIES_PAGE) {
        yield put(addDialogTemplateModalCatalogs(catalogs));
      } else {
        yield put(setDialogTemplateModalCatalogs(catalogs));
      }

      yield put({
        type: DIALOG_TEMPLATE_MODAL_LOADED
      });
    }
  } catch (e) {
    console.error("Saga: getCategoriesSaga error", e);
  }
}

export default function* () {
  yield takeEvery(
    [DIALOG_TEMPLATE_MODAL_CATEGORIES_LOADING, CHANGE_CATEGORIES_PAGE],
    getCategoriesSaga
  );
}
