import * as Yup from "yup";
import { REQUIRED_FIELD_ERROR } from "@omnichat/arm_ui_kit";
import { getMaxStringLengthErrorText } from "../../Utils/getMaxStringLengthErrorText";

const MAX_STRING_LENGTH = 128;
const NAME_MAX_STRING_LENGTH = 64;

export const schema = Yup.object({
  skillGroup: Yup.object({
    name: Yup.string()
      .trim()
      .max(
        NAME_MAX_STRING_LENGTH,
        getMaxStringLengthErrorText(NAME_MAX_STRING_LENGTH)
      )
      .required(REQUIRED_FIELD_ERROR),
    disclaimer: Yup.string()
      .trim()
      .max(MAX_STRING_LENGTH, getMaxStringLengthErrorText(MAX_STRING_LENGTH))
      .nullable(),
    aliasDisclaimer: Yup.string()
      .trim()
      .max(MAX_STRING_LENGTH, getMaxStringLengthErrorText(MAX_STRING_LENGTH))
      .nullable(),
    alias: Yup.string()
      .trim()
      .max(MAX_STRING_LENGTH, getMaxStringLengthErrorText(MAX_STRING_LENGTH))
      .required(REQUIRED_FIELD_ERROR),
    afterBotDisclaimer: Yup.string()
      .trim()
      .max(MAX_STRING_LENGTH, getMaxStringLengthErrorText(MAX_STRING_LENGTH))
      .nullable(),
    toBotPercent: Yup.number().nullable(),
    delayReappealToBot: Yup.number().nullable(),
    appointToBot: Yup.boolean(),
    autoStatusEnabled: Yup.boolean(), // temp field, not to send
    timeClose: Yup.number().when("autoStatusEnabled", {
      is: true,
      then: (schema) =>
        schema.min(1, "Время до закрытия должно быть больше 0."),
      otherwise: (schema) => schema.nullable()
    }),
    timeWait: Yup.number().when(["autoStatusEnabled"], {
      is: (val) => val === true,
      then: (schema) => schema.min(1, "Время ожидания должно быть больше 0."),
      otherwise: (schema) => schema.nullable()
    }),
    timeLimitEnabled: Yup.boolean(), // temp field, not to send
    timeLimit: Yup.number().when(["timeLimitEnabled"], {
      is: true,
      then: (schema) =>
        schema.min(
          1,
          "Время Информирования агента о бездействии должно быть больше 0."
        ),
      otherwise: (schema) => schema.nullable()
    }),
    vip: Yup.boolean(),
    excludeGroupFromVipsRedistribute: Yup.boolean(),
    returnFromWaiting: Yup.boolean(),
    backlogEnabled: Yup.boolean(),
    excludeGroupFromCountingBacklogAppeals: Yup.boolean()
  }),
  mrfs: Yup.array(),
  regions: Yup.array(),
  channels: Yup.array(),
  mainBot: Yup.array().when(["skillGroup.appointToBot"], {
    is: true,
    then: (schema) => schema.min(1, REQUIRED_FIELD_ERROR),
    otherwise: (schema) => schema.nullable()
  }),
  studiedBots: Yup.array()
});

export const defaultValues = {
  skillGroup: {
    name: "",
    disclaimer: "",
    aliasDisclaimer: "",
    alias: "",
    afterBotDisclaimer: "",
    toBotPercent: 0,
    delayReappealToBot: 0,
    timeClose: 0,
    timeWait: 0,
    timeLimit: 0,
    vip: false,
    excludeGroupFromVipsRedistribute: false,
    returnFromWaiting: false,
    backlogEnabled: false,
    excludeGroupFromCountingBacklogAppeals: false,
    autoStatusEnabled: false,
    timeLimitEnabled: false
  },
  mrfs: [],
  regions: [],
  channels: [],
  studiedBots: [],
  mainBotId: null
};

export interface IFormData extends Yup.InferType<typeof schema> {}
