import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionRoles } from "../Store/Roles.reducer";
import Informer from "../../../arm/Wrappers/Informer";
import {
  ERROR_MESSAGE_WHILE_SAVE_DATA,
  SUCCESS_MESSAGE_SAVE_DATA,
  ERROR_ROLES_NAME_ALREADY_EXISTS
} from "../const";

function* sendEditedRoleSaga({ payload }) {
  actionRoles.setSendDataHandling(true);

  const { formData, callback, roleId } = payload;

  try {
    const res = yield httpRequest("PATCH", `bff/settings/roles/${roleId}`, {
      body: formData
    });

    if (
      ((formData.role && res?.resRole?.success) ||
        (!formData.role && !res?.resRole)) &&
      res?.resRights?.success &&
      res?.resRights?.data
    ) {
      yield put(actionRoles.setRights(res?.resRights?.data));
      callback?.();
      const info = new Informer(SUCCESS_MESSAGE_SAVE_DATA);
      info.show();
    } else {
      const roleError = res?.resRole?.error;
      if (roleError?.status === 409) {
        const info = new Informer(ERROR_ROLES_NAME_ALREADY_EXISTS);
        info.show();
        yield put(actionRoles.setErrorMessage(ERROR_ROLES_NAME_ALREADY_EXISTS));
      } else {
        const info = new Informer(ERROR_MESSAGE_WHILE_SAVE_DATA);
        info.show();
      }
    }
  } catch (error) {
    const info = new Informer(ERROR_MESSAGE_WHILE_SAVE_DATA);
    info.show();
  } finally {
    yield put(actionRoles.setSendDataHandling(false));
  }
}

export default function*() {
  yield takeEvery([actionRoles.sendEditedRole], sendEditedRoleSaga);
}
