import React, { FC, useMemo } from "react";

import { textItemsCount } from "../../../../ReactFeatures/Common/Utils/Text.utils";

interface SubscriptionProps {
  id: string;
  appealsCount: number;
  agentsCount?: number;
}

const Subscription: FC<SubscriptionProps> = ({
  id,
  appealsCount,
  agentsCount
}) => {
  const appealsItemsCount = useMemo(
    () =>
      textItemsCount({
        digit: appealsCount,
        wordForms: ["обращение", "обращения", "обращений"],
        emptyMessage: "нет обращений"
      }),
    [appealsCount]
  );

  const agentsItemsCount = useMemo(
    () =>
      textItemsCount({
        digit: agentsCount,
        wordForms: ["агент", "агента", "агентов"],
        emptyMessage: "нет агентов"
      }),
    [agentsCount]
  );

  return (
    <>
      {typeof agentsCount === "number" && (
        <span id={`agents-count-${id}`}>{agentsItemsCount}</span>
      )}
      <span id={`appeals-count-${id}`}>{appealsItemsCount}</span>
    </>
  );
};

export default Subscription;
