import { fork } from "redux-saga/effects";

import getChatbot from "./getChatbot.saga";
import getChatbots from "./getChatbots.saga";
import removeChatbot from "./removeChatbot.saga";
import sendChatbot from "./sendChatbot.saga";

export default function* () {
  yield fork(getChatbot);
  yield fork(getChatbots);
  yield fork(removeChatbot);
  yield fork(sendChatbot);
}
