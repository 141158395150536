import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import initialState from "../../../ReactCore/store/initialState";

export const GET_CLASSIFIER_CATALOGS = "GET_CLASSIFIER_CATALOGS";
export const CLASSIFIER_CREATED = "CLASSIFIER_CREATED";
export const GET_CLASSIFIER_DATA = "GET_CLASSIFIER_DATA";
export const SEND_CLASSIFIER_DATA = "SEND_CLASSIFIER_DATA";
export const REMOVE_CLASSIFIER = "REMOVE_CLASSIFIER";

export const getClassifierCatalogs = createAction(GET_CLASSIFIER_CATALOGS);
export const classifierCreated = createAction(CLASSIFIER_CREATED);
export const getClassifierData = createAction(GET_CLASSIFIER_DATA, (data) => ({
  payload: data
}));
export const sendClassifierData = createAction(
  SEND_CLASSIFIER_DATA,
  (data) => ({
    payload: data
  })
);
export const removeClassifier = createAction(REMOVE_CLASSIFIER, (data) => ({
  payload: data
}));

const ClassifierFormSlice = createSlice({
  name: "classifierForm",
  initialState: initialState.ClassifierForm,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setMrfList(state, action) {
      state.mrfs = action.payload;
    },
    setSkillGroupList(state, action) {
      state.skillGroups = action.payload;
    },
    setAvailableSkillGroupList(state, action) {
      state.availableSkillGroups = action.payload;
    },
    setChannelsList(state, action) {
      state.channels = action.payload;
    },
    setClassifierData(state, action) {
      state.classifierData = action.payload;
    },
    resetClassifierData(state) {
      state.loading = initialState.ClassifierForm.loading;
      state.errorMessage = initialState.ClassifierForm.errorMessage;
      state.classifierData = initialState.ClassifierForm.classifierData;
    },
    setClassifierResponseError(state, action) {
      state.errorMessage = action.payload;
    }
  }
});

export const {
  setClassifierData,
  resetClassifierData,
  setChannelsList,
  setClassifierResponseError,
  setMrfList,
  setSkillGroupList,
  setAvailableSkillGroupList,
  startLoading,
  finishLoading
} = ClassifierFormSlice.actions;

export default ClassifierFormSlice.reducer;
