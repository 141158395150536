import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import initialState from "../../../ReactCore/store/initialState";

export const SET_USER_STATUS = "SET_USER_STATUS";
export const SEND_USER_OFFLINE = "SEND_USER_OFFLINE";
export const SEND_USER_ONLINE = "SEND_USER_ONLINE";
export const SEND_USER_SUBSTATUS = "SEND_USER_SUBSTATUS";
export const GET_USER_SUBSTATUS = "GET_USER_SUBSTATUS";
export const SEND_USER_LOGOFF = "SEND_USER_LOGOFF";
export const SET_USER_STATUS_PENDING = "SET_USER_STATUS_PENDING";
export const GET_OFFLINE_SUBSTATUSES = "GET_OFFLINE_SUBSTATUSES";
export const TRANSFER_ALL_APPEALS = "TRANSFER_ALL_APPEALS";

export const sendUserOffline = createAction(SEND_USER_OFFLINE);

export const sendUserOnline = createAction(SEND_USER_ONLINE);

export const sendUserSubstatus = createAction(SEND_USER_SUBSTATUS);
export const getUserSubstatus = createAction(GET_USER_SUBSTATUS);

export const sendUserlogoff = createAction(SEND_USER_LOGOFF);

export const getOfflineSubstatuses = createAction(GET_OFFLINE_SUBSTATUSES);

/**
 * Передаст все обращения текущего пользователя.
 */
export const transferAllAppeals = createAction<{
  postAction?: () => void;
  onError?: () => void;
}>(TRANSFER_ALL_APPEALS);

const OnlineToggleSlice = createSlice({
  name: "OnlineToggle",
  initialState: initialState.OnlineToggle,
  reducers: {
    setUserStatus: (state, action: PayloadAction<string>) => {
      state.status.online = action.payload === "ONLINE" ? true : false;
      state.status.isPending = false;
    },
    setUserStatusPending: (state, action: PayloadAction<boolean>) => {
      state.status.isPending = action.payload || false;
    },
    setOfflineReasonList: (state, action: PayloadAction<any>) => {
      state.offlineReasonList = action.payload;
    },
    setSubstatus: (
      state,
      action: PayloadAction<{
        substatus: number;
        startDate: number;
      }>
    ) => {
      state.status.substatusId = action.payload?.substatus;
      state.status.startStatusDate = action.payload?.startDate;
    }
  }
});

export const {
  setSubstatus,
  setUserStatus,
  setUserStatusPending,
  setOfflineReasonList
} = OnlineToggleSlice.actions;

export default OnlineToggleSlice.reducer;
