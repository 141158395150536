import { useMemo } from "react";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import initialState from "../../../ReactCore/store/initialState";
import {
  DTO,
  DataActionTargetUsers,
  ErrorFields
} from "../UsersProfileModal.d";

const NAMESPACE = "usersProfileModal";
export const GET_USERS_PROFILE_DATA = `${NAMESPACE}/GET_USERS_PROFILE_DATA`;
export const RESTORE_USERS_PASS = `${NAMESPACE}/RESTORE_USERS_PASS`;
export const SEND_USERS_PROFILE_DATA = `${NAMESPACE}/SEND_USERS_PROFILE_DATA`;

export const getUsersProfileData = createAction<{
  profileId: string;
  forEdit?: boolean;
}>(GET_USERS_PROFILE_DATA);

export const sendUsersProfileData = createAction<{
  close: Function;
  formData: DTO;
}>(SEND_USERS_PROFILE_DATA);

export const restoreUsersPass = createAction<{
  targetUsersInfo: DataActionTargetUsers;
  close: Function;
}>(RESTORE_USERS_PASS);

const UsersProfileModalSlice = createSlice({
  name: "usersProfileModal",
  initialState: initialState.UsersProfileModal,
  reducers: {
    setPhoto(state, action: PayloadAction<string>) {
      state.photo = action.payload ? action.payload : undefined;
    },
    setUsersProfileData(state, action: PayloadAction<DTO>) {
      state.formData = action.payload;
    },
    setSendDataHandling(state, action: PayloadAction<boolean>) {
      state.status.dataSendHandling = action.payload;
    },
    setLoadDataHandling(state, action: PayloadAction<boolean>) {
      state.status.dataLoadHandling = action.payload;
    },
    setErrorMesssage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setErrorFields(state, action: PayloadAction<ErrorFields>) {
      state.errorFields = action.payload;
    },
    resetUsersProfileData(state) {
      state.photo = null;
      state.status = initialState.UsersProfileModal.status;
      state.errorMessage = initialState.UsersProfileModal.errorMessage;
      state.formData = initialState.UsersProfileModal.formData;
      state.errorFields = null;
    }
  }
});

export const actionUsersProfileModal = {
  ...UsersProfileModalSlice.actions,
  getUsersProfileData,
  sendUsersProfileData,
  restoreUsersPass
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionUsersProfileModal, dispatch), [
    dispatch
  ]);
};

export default UsersProfileModalSlice.reducer;
