import { takeEvery, put, delay } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { FEDERAL_SERVICE_ID } from "../Consts";

import {
  getThemes,
  setAppealThemes,
  setServiceTag,
  setServiceEnabled,
  setRegion
} from "../Actions/ThematicModal.action";

import {
  setOriginAppealThemes,
  setOriginAppealService
} from "../Actions/ThematicInfoPanel.action";

import { prepareThemes } from "../Services/ThematicModal.service";

/**
 * Сага получения тем
 */
function* getThemesData(action) {
  const appealId = action.payload;

  if (appealId) {
    const responseAppealData = yield httpRequest(
      "GET",
      `bff/chat/thematics/${appealId}`
    );

    if (responseAppealData?.data?.appealThemes) {
      yield put(
        setAppealThemes(prepareThemes(responseAppealData.data.appealThemes))
      );
      yield put(setOriginAppealThemes(responseAppealData.data.appealThemes));
    }

    if (responseAppealData?.data?.serviceTagAndRegionEnabled) {
      yield put(setServiceEnabled(true));

      if (responseAppealData?.data?.serviceTag?.id) {
        yield put(setServiceTag(responseAppealData.data.serviceTag.id));
      } else {
        yield put(setServiceTag(FEDERAL_SERVICE_ID));
      }
    }

    if (responseAppealData?.data?.serviceTag?.id) {
      yield put(
        setOriginAppealService({
          tag: responseAppealData.data.serviceTag,
          value: responseAppealData.data.serviceRegion
        })
      );
    }

    if (responseAppealData?.data?.serviceRegion?.id) {
      yield put(setRegion(responseAppealData.data.serviceRegion.id));
    } else if (responseAppealData?.data?.region?.id) {
      yield put(setRegion(responseAppealData.data.region.id));
    }
  }
}

export default function*() {
  yield takeEvery([getThemes], getThemesData);
}
