export const TOGGLE_FILTER_PANEL = "TOGGLE_FILTER_PANEL";
export const CHANGE_FILTER_PANEL_DATA = "CHANGE_FILTER_PANEL_DATA";
export const FILL_FILTERS_DICTIONARIES = "FILL_FILTERS_DICTIONARIES";

export const DASHBOARD_FILTER_DICTIONARIES_LOADING =
  "DASHBOARD_FILTER_DICTIONARIES_LOADING";
export const DASHBOARD_FILTER_DICTIONARIES_LOADED =
  "DASHBOARD_FILTER_DICTIONARIES_LOADED";

export const DASHBOARD_SET_CONFIRM_IN_PROGRESS =
  "DASHBOARD_SET_CONFIRM_IN_PROGRESS";

export const RESET_DASHBOARD_FILTER_FORM = "RESET_DASHBOARD_FILTER_FORM";

export const SET_RECENT_APPEALS = "SET_RECENT_APPEALS";

// select options

export const STATUS_OPTIONS = [
  { label: "Любой", value: "any" },
  { label: "Закрыто", value: "0" },
  { label: "Открыто", value: "1" },
  { label: "В ожидании", value: "2" }
];

// Кем обработано обращение
export const WHO_PROCESSED_OPTIONS = [
  { label: "Не указан", value: "any" },
  { label: "Только оператор", value: "1" },
  { label: "Только чат-бот", value: "2" },
  { label: "Чат-бот и оператор", value: "3" },
  { label: "Чат-бот и оператор + Оператор", value: "4" }
];

export const SL_OPTIONS = [
  { label: "Любой", value: "any" },
  { label: "Не выполнен", value: "0" },
  { label: "Выполнен", value: "1" }
];

export const CSI_OPTIONS = [
  { label: "Любая", value: "any" },
  { label: "Без оценки", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" }
];

export const COMMON_OPTIONS = [
  { label: "Все", value: "any" },
  { label: "Не определен", value: "none" }
];

export const CHANNEL_DEFAULT_OPTIONS = [{ label: "Все", value: "any" }];

export const INITIAL_FORM_DATA = {
  date_start: null,
  date_end: null,
  client: "",
  appeal: "",
  channel: "any",
  who_processed: "any",
  region_id: "any",
  status: "any",
  region: "any",
  mrf: "any",
  rf: "any",
  sl: "any",
  csi: "any",
  remark_fio: "",
  remark_phone: "",
  remark_birthday: "",
  without_themes: Number(WAITING_CONTROL_FILTER) ? "0" : undefined
};

export const DEFAULT_UPPER_TIME_LIMIT = "00:00:00";

export const DEFAULT_LOWER_TIME_LIMIT = "23:59:59";
