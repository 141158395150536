import React from "react";

import { Icon, Tooltip } from "@omnichat/arm_ui_kit";

import { getFormatedTime } from "./AppealTimer.helpers";
import * as s from "./AppealTimer.module.scss";
import c from "classnames";

interface IAppealTimerProps {
  time: number;
  tooltipText: string;
}

const AppealTimer: React.FunctionComponent<IAppealTimerProps> = ({
  time,
  tooltipText,
}): JSX.Element => {
  return (
    <Tooltip content={tooltipText} position="bottom" modifiers={{ offset: 5 }}>
      <div className={c(s[`middleAlign`])}>
        <Icon color="gray" name="clock" width="25px" height="25px" />
        <div className={c(s["timerTime"])}>{getFormatedTime(time)}</div>
      </div>
    </Tooltip>
  );
};

export default AppealTimer;
