import { AnyAction } from "redux";
import { PENDING, FULFILLED, REJECTED } from "../Consts/LoadingData.consts";
import { EDOWNLOAD_STATUS } from "../Enums/LoadingData.enums";
import { IAsyncData } from "../Interfaces/Store.interfaces";

/**
 * Проверит, находятся ли данные в статусе загрузки.
 * Если указан флаг, то проверит находятся ли данные в статусе ожидания.
 *
 * @param {IAsyncData<any>} asyncDataObject контейнер с данными, с возможностью отслеживать статус загрузки.
 * @param {boolean} isIdleIncluded Включить в результат проверку статуса "Ожидание" или нет.
 */
export function isPending(
  asyncDataObject: IAsyncData<any>,
  isIdleIncluded?: boolean
): boolean {
  let result = false;

  if (asyncDataObject) {
    result =
      (isIdleIncluded && asyncDataObject.status === EDOWNLOAD_STATUS.IDLE) ||
      asyncDataObject.status === EDOWNLOAD_STATUS.PENDING;
  }

  return result;
}

/**
 * Проверяет, что экшн установит статус PENDING у асинхронного объекта.
 *
 * @param {AnyAction} action Любой экшн.
 */
export function isPendingAction(action: AnyAction) {
  return action.type.endsWith(`/${PENDING}`);
}

/**
 * Проверяет, что экшн установит статус FULFILLED у асинхронного объекта.
 *
 * @param {AnyAction} action Любой экшн.
 */
export function isFulfilledAction(action: AnyAction) {
  return action.type.endsWith(`/${FULFILLED}`);
}

/**
 * Проверяет, что экшн установит статус REJECTED у асинхронного объекта.
 *
 * @param {AnyAction} action Любой экшн.
 */
export function isRejectedAction(action: AnyAction) {
  return action.type.endsWith(`/${REJECTED}`);
}
