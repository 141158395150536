// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--F1_Cvyypu4ewwJbQulwI{width:550px}.header--yLl9gz03Acq9aWIQlSol{color:var(--color-text-default);font-size:var(--font-size-header);font-weight:bold;padding-bottom:20px;border-bottom:1px solid rgba(0,0,0,.15);margin-bottom:15px}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/Users/Components/ConfirmModal/styles.module.scss"],"names":[],"mappings":"AAEA,6BACE,WAAA,CAGF,8BACE,+BAAA,CACA,iCAAA,CACA,gBAAA,CACA,mBAAA,CACA,uCAAA,CACA,kBAAA","sourcesContent":["@import \"../../../../../src/styles/variables.module.scss\";\n\n.modal {\n  width: 550px;\n}\n\n.header {\n  color: var(--color-text-default);\n  font-size: var(--font-size-header);\n  font-weight: bold;\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(0,0,0,0.15);\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "modal--F1_Cvyypu4ewwJbQulwI";
export var header = "header--yLl9gz03Acq9aWIQlSol";
export default ___CSS_LOADER_EXPORT___;
