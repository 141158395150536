import produce from "immer";

import {
  FETCH_SKILLGROUP_CAPACITY,
  SET_SKILLGROUP_CAPACITY_TO_FORM,
  RESET_SKILLGROUP_CAPACITY
} from "./consts";

import initialState from "../../ReactCore/store/initialState";

export default function SkillGroupCapacityModalReducer(
  state = initialState.SkillGroupCapacityModal,
  { type, payload }
) {
  switch (type) {
    case FETCH_SKILLGROUP_CAPACITY:
      return produce(state, draft => {
        draft.status.skillGroupsDataLoaded = true;
      });
    case SET_SKILLGROUP_CAPACITY_TO_FORM:
      return produce(state, draft => {
        draft.skillGroupsData = payload.data;
      });
    case RESET_SKILLGROUP_CAPACITY:
      return produce(state, draft => {
        draft.status.skillGroupsDataLoaded = false;
      });
    default:
      return state;
  }
}
