import { isEmpty } from "@omnichat/arm_ui_kit";
import { createSelector } from "reselect";

import { Dictionaries } from "../SkillGroupModal.d";
import { mapDtoToForm, mapCatalogToSelectOption } from "../utils";
import { StoreState } from "../../../ReactCore/store/types";
import { IFormData } from "../SkillGroupModal.schema";

export const getFormData = (state: StoreState.State): IFormData => {
  const formData = state.SkillGroupModal.formData || {};
  if (!isEmpty(formData)) {
    return mapDtoToForm(formData);
  }
  return null;
};

export const getDictionariesLoaded = (state: StoreState.State): boolean =>
  !isEmpty(state.SkillGroupModal.dictionaries);

export const getDataSendHandling = (state: StoreState.State): boolean =>
  state.SkillGroupModal.status.dataSendHandling;

export const getErrorMessage = (state: StoreState.State): string =>
  state.SkillGroupModal.errorMessage;

export const getDictionaries = (state: StoreState.State): Dictionaries =>
  state.SkillGroupModal.dictionaries || {};

export const getDictionariesToForm = createSelector(
  getDictionaries,
  (dictionaries: Dictionaries) => {
    if (isEmpty(dictionaries)) {
      return {};
    }

    return {
      mrfs: mapCatalogToSelectOption(dictionaries.mrfs),
      channels: mapCatalogToSelectOption(dictionaries.channels),
      botProfiles: mapCatalogToSelectOption(dictionaries.botProfiles),
      regions: dictionaries.regions.map((region) => ({
        value: region.id,
        label: region.region
      }))
    };
  }
);
