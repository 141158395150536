import * as React from "react";
import { connect } from "react-redux";

import AdditionalClientDataFields from "./Components/AdditionalClientDataFields/AdditionalClientDataFields";
import { StoreState } from "../../ReactCore/store/types";

import { sendAdditionalFieldData } from "./additionalFields.actions";

import { CHANNELTYPENAMES } from "../../ReactCore/shared/consts";

class AdditionalFieldsProvider extends React.PureComponent<{
  remarkFio;
  remarkPhone;
  birthDay;
  clientId;
  channelId;
  channelType;
  sendAdditionalFieldData;
}> {
  saveAdditionalData = (type, value) => {
    const {
      channelId,
      clientId,
      channelType,
      sendAdditionalFieldData,
    } = this.props;

    var dataClient = {
      client_id: clientId,
      channel_id: channelId,
      channel_type: channelType,
    };

    dataClient[type] = value;

    sendAdditionalFieldData(dataClient);
  };

  showAdditionalFields = (channelType) => {
    let showAdditionalClientDataFields = false;

    var channelName = Object.keys(CHANNELTYPENAMES).find((el) => {
      if (CHANNELTYPENAMES[el] == channelType) {
        return el;
      }
    });

    var availableChannelTypes = ADDITIONAL_CLIENTS_PROPS_FOR_CHANNEL.split(",");

    availableChannelTypes.forEach((el) => {
      if (el.trim() == channelName) {
        showAdditionalClientDataFields = true;
        return false;
      }
    });

    return showAdditionalClientDataFields;
  };

  render() {
    return (
      <>
        {this.props.channelType &&
          this.showAdditionalFields(this.props.channelType) && (
            <AdditionalClientDataFields
              nameValue={this.props.remarkFio}
              phoneValue={this.props.remarkPhone}
              dateValue={this.props.birthDay}
              saveAdditionalClientProps={this.saveAdditionalData}
            />
          )}
      </>
    );
  }
}

export default connect(
  (state: StoreState.State) => ({}),
  (dispatch) => ({
    sendAdditionalFieldData: (data) => dispatch(sendAdditionalFieldData(data)),
  })
)(AdditionalFieldsProvider);
