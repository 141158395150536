import React, { useState, memo } from "react";

import { Tooltip, Button } from "@omnichat/arm_ui_kit";
import { Grid } from "@mui/material";

import Select from "../../../Components/Select";
import { Input, FormField } from "../../../Components";

import { SkillGroupData } from "../VipClients.hooks";
import { getInitialClientData, validateBeforeSubmit } from "./helpers";
import * as s from "./VipClient.module.scss";

export interface VipClientData {
  id: string | number;
  login: string | number;
  skillGroupId: string | number;
};

export type ValidationErrors = Partial<Record<keyof VipClientData, string>>;

export type ModeVariants = "view" | "edit" | "create";

export interface VipClientProps {
  initialMode?: ModeVariants | null;
  skillGroups: SkillGroupData[];
  loginsMap: Record<string, unknown>;
  clientData: Partial<VipClientData>;
  onEdit: (data: VipClientData) => void;
  onCreate: (data: VipClientData) => void;
  onDelete: (data: VipClientData) => void;
  onCancelCreation: () => void;
}

const VipClient: React.FC<VipClientProps> = ({
  initialMode,
  skillGroups,
  loginsMap,
  clientData,
  onCreate,
  onEdit,
  onDelete,
  onCancelCreation
}) => {
  const [data, setData] = useState<VipClientData>(() =>
    getInitialClientData(clientData)
  );
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [mode, setMode] = useState<ModeVariants>(initialMode || "view");

  const changeLogin = (value: string) => setData({ ...data, login: value });

  const changeGroup = (nextGroup: VipClientProps["skillGroups"]) =>
    nextGroup[0] && setData({ ...data, skillGroupId: nextGroup[0].value });

  const cancelCreation = () => {
    const { login, skillGroupId } = getInitialClientData(clientData);
    setData({
      ...data,
      login,
      skillGroupId
    });
    setMode("view");
    setErrors({});
  };

  const createClient = () => {
    const errors = validateBeforeSubmit({ ...data, loginsMap });

    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      onCreate(data);
      setMode("view");
      setErrors({});
    }
  };

  const editClient = () => {
    delete loginsMap[String(clientData.login)];
    const errors = validateBeforeSubmit({ ...data, loginsMap });

    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      onEdit(data);
      setMode("view");
      setErrors({});
    }
  };

  const deleteClient = () => onDelete(data);

  const skillGroupValue = skillGroups.find(
    (sg) => sg.value === data.skillGroupId
  );

  return (
    <Grid
      container
      alignItems="stretch"
      border="1px solid #61a5dd"
      borderRadius="6px"
      spacing={1}
      mt={0}
      ml={0}
      pb={1}
    >
      <Grid item xs={5}>
        {mode === "view" ? (
          <>
            <h6 className={s["title"]}>Логин:</h6>
            <p className={s["label"]}>{data.login}</p>
          </>
        ) : (
          <FormField label="Логин" required labelPosition="relative">
            <Input
              id="login"
              name="login"
              placeholder="Не указан"
              value={data.login.toString()}
              onChange={changeLogin}
              actionText={errors.login}
              isError={!!errors.login}
            />
          </FormField>
        )}
      </Grid>
      <Grid item xs={5}>
        {mode === "view" ? (
          <>
            <h6 className={s["title"]}>Группа:</h6>
            <p className={s["label"]}>{skillGroupValue?.label}</p>
          </>
        ) : (
          <FormField label="Группа" required labelPosition="relative">
            <Select
              options={skillGroups}
              selected={skillGroupValue ? [skillGroupValue] : []}
              onSelectOption={changeGroup}
              isError={!!errors.skillGroupId}
              actionText={errors.skillGroupId}
              extraSX={{ pt: "0" }}
            />
          </FormField>
        )}
      </Grid>
      <Grid item xs={2} container alignItems="center">
        {mode === "edit" && (
          <>
            <Tooltip content="Сохранить" position="top">
              <Button
                type="bare"
                iconName="check"
                iconColor="green"
                onClick={editClient}
              />
            </Tooltip>
            <Tooltip content="Отмена" position="top">
              <Button type="bare" iconName="cross" onClick={cancelCreation} />
            </Tooltip>
          </>
        )}

        {mode === "create" && (
          <>
            <Tooltip content="Создать" position="top">
              <Button
                type="bare"
                iconName="check"
                iconColor="green"
                onClick={createClient}
              />
            </Tooltip>
            <Tooltip content="Отмена" position="top">
              <Button type="bare" iconName="cross" onClick={onCancelCreation} />
            </Tooltip>
          </>
        )}

        {mode === "view" && (
          <>
            <Tooltip content="Редактировать" position="top">
              <Button
                type="bare"
                iconName="edit"
                iconColor="blue"
                onClick={() => setMode("edit")}
              />
            </Tooltip>
            <Tooltip content="Удалить" position="top">
              <Button
                type="bare"
                iconName="cross"
                iconColor="red"
                onClick={deleteClient}
              />
            </Tooltip>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(VipClient);
