import { buffers, eventChannel, END } from "redux-saga";

import { FileStorageTypes } from "./fileStorage";
import Informer from "../../arm/Wrappers/Informer";
import { nanoid } from "nanoid";
import { EXHR_READY_STATE } from "../Common/Enums/LoadingData.enums";

/**
 * Отправить файл в FileStorage
 * @param file
 */
export const createUploadFileChannel = (file: File) => {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const form = new FormData();
    form.append("file", file);

    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = Math.floor((e.loaded / e.total) * 100);
        emitter({ progress });
      }
    };

    const showErrorInformer = (res) => {
      let errorText = "Возникла ошибка при передаче файла";
      if (res.title && /Not allowed mime-type/.test(res.title)) {
        errorText = "Недопустимый формат файла";
      } else if (
        res.detail &&
        /Maximum upload size exceeded/.test(res.detail)
      ) {
        errorText = "Размер файла превышает допустимый";
      }
      const info = new Informer(errorText, 3500);
      info.show();
    };

    const onFailure = (e?: ProgressEvent) => {
      emitter(new Error("Upload failed"));
      e && showErrorInformer(e);
      emitter(END);
    };

    /**
     * Выбросит ошибку перед перезагрузкой страницы.
     */
    const emitErrorBeforeUnload = () => {
      onFailure();
      // Это позволит выпилить из store незагруженный полностью файл и после перезагрузки загрузить его снова
    };

    window.addEventListener("beforeunload", emitErrorBeforeUnload);

    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === EXHR_READY_STATE.DONE) {
        if (status === 201) {
          emitter({ success: true, response: JSON.parse(xhr.response) });
          emitter(END);
        } else if (status === 502) {
          onFailure(xhr.response);
        } else {
          onFailure(JSON.parse(xhr.response));
        }
      }
    };

    if (!FS_URL) {
      console.error("Undefined FS_URL .env");
      emitter(END);
      return;
    }
    xhr.open("POST", FS_URL);

    xhr.setRequestHeader("accept", "application/problem+json;charset=UTF-8");
    xhr.setRequestHeader(
      "x-xsrf-token",
      "b915c594-9e4c-4a96-84da-d452ccde3d60"
    );

    xhr.send(form);

    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      window.removeEventListener("beforeunload", emitErrorBeforeUnload);
      xhr.abort();
    };
  }, buffers.sliding(2));
};

/**
 * Замена пробелов в имени файла на "_"
 * По сути копирование файла с новым измененным именем
 * @param file {File}
 */
const getFileWithChangedNameSpaceToSnake = (file: File) => {
  var blob = file.slice(0, file.size, file.type);
  const newFile = new File([blob], file.name.replace(/\s/g, "_"), {
    type: file.type
  });
  return newFile;
};

/**
 * Подготавливаем структуру данных для хранения файла в store
 * @param files
 */
export const prepareFileData = (files) => {
  let preparedFilesData: FileStorageTypes.IFile[] = [];
  for (let i = 0; i < files.length; i++) {
    let preparedFile;
    if (/\s/.test(files[i].name)) {
      preparedFile = getFileWithChangedNameSpaceToSnake(files[i]);
    } else {
      preparedFile = files[i];
    }
    preparedFilesData.push({
      uuid: nanoid(10),
      file: preparedFile,
      name: preparedFile.name,
      progress: 0,
      pending: false,
      sended: false,
      data: null,
      failed: false,
      cancel: null
    });
  }

  return preparedFilesData;
};

export const updateFileData = (
  files: FileStorageTypes.IFile[],
  uuid: string,
  newData
) => {
  let newFiles = [...files];

  const fileIndex = newFiles.findIndex((file) => file.uuid === uuid);

  const updatedFile = { ...newFiles[fileIndex], ...newData };

  newFiles.splice(fileIndex, 1, updatedFile);

  return newFiles;
};
export const cancelPanding = (
  files: FileStorageTypes.IFile[],
  uuid: string
) => {
  let newFiles = [...files];

  const fileIndex = newFiles.findIndex((file) => file.uuid === uuid);

  if (
    fileIndex !== -1 &&
    newFiles[fileIndex] &&
    typeof newFiles[fileIndex].cancel === "function"
  ) {
    newFiles[fileIndex].cancel();
  }
};

export const removeFileByUuid = (
  files: FileStorageTypes.IFile[],
  uuid: string
) => {
  let newFiles = [...files];

  const fileIndex = newFiles.findIndex((file) => file.uuid === uuid);

  newFiles.splice(fileIndex, 1);

  return newFiles;
};
