import React, { FC, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import c from "classnames";

import {
  Button,
  TextareaDump,
  REQUIRED_FIELD_ERROR,
  Tooltip
} from "@omnichat/arm_ui_kit";
import { Input } from "../../../../Components";

import FormFieldConnector from "../../../../Components/FormFieldConnector";
import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

import * as s from "./style.module.scss";

export type ITextTemplateData = ITextTemplateForm & {
  id?: string;
  order: number;
};

type ITextTemplateForm = {
  name?: string;
  content?: string;
};

export type ModeVariants = "view" | "edit" | "create";

type ITextTemplateProps = {
  templateData: ITextTemplateData;
  onSave: (data: ITextTemplateData) => void;
  onDelete: (data: ITextTemplateData) => void;
  onCancelCreation: () => void;
};

const schema = Yup.object({
  name: Yup.string().required(REQUIRED_FIELD_ERROR),
  content: Yup.string().required(REQUIRED_FIELD_ERROR)
});

const defaultValues = {
  name: "",
  content: ""
};

const TextTemplate: FC<ITextTemplateProps> = ({
  templateData,
  onSave,
  onDelete,
  onCancelCreation
}) => {
  const [mode, setMode] = useState<ModeVariants>(
    templateData?.id ? "view" : "create"
  );
  const methods = useForm<ITextTemplateForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (templateData) {
      reset({
        name: templateData.name || "",
        content: templateData.content || ""
      });
    } else {
      reset(defaultValues);
    }
  }, [templateData]);

  const handleSave = (form: ITextTemplateForm) => {
    onSave({
      ...templateData,
      ...form
    });
  };

  const handleOnEdit = () => {
    if (templateData) {
      reset({
        name: templateData.name || "",
        content: templateData.content || ""
      });
    } else {
      reset(defaultValues);
    }
    setMode("edit");
  };

  return (
    <div className={s.textTemplate}>
      <FormProvider {...methods}>
        <div className={c(s.head, { [s.headEdit]: mode !== "view" })}>
          <div className={s.name}>
            {mode === "view" && (
              <div className={s.nameText}>{templateData?.name}</div>
            )}
            {(mode === "edit" || mode === "create") && (
              <FormFieldConnector
                required
                showErrorText={false}
                name="name"
                Component={(p, s) => (
                  <Input placeholder="Наименование" isError={s.error} {...p} />
                )}
                extraClass={[s.nameInput]}
              />
            )}
          </div>
          <div className={s.buttons}>
            {mode === "view" && (
              <>
                <Tooltip content="Редактировать" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="blue"
                    iconName="edit"
                    onClick={() => handleOnEdit()}
                  />
                </Tooltip>
                <Tooltip content="Удалить" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="red"
                    iconName="cross"
                    onClick={() => onDelete(templateData)}
                  />
                </Tooltip>
              </>
            )}
            {(mode === "edit" || mode === "create") && (
              <>
                <Tooltip content="Сохранить" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="green"
                    iconName="check"
                    onClick={handleSubmit(
                      handleSave,
                      ReactHookFormErrorHandler
                    )}
                  />
                </Tooltip>
                <Tooltip content="Отменить" position="top">
                  <Button
                    type="bare"
                    width="18px"
                    height="18px"
                    iconColor="black"
                    iconName="cross"
                    onClick={() =>
                      mode === "create" ? onCancelCreation() : setMode("view")
                    }
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>

        {(mode === "edit" || mode === "create") && (
          <div>
            <FormFieldConnector
              required
              showErrorText={false}
              name="content"
              Component={(p, s) => (
                <TextareaDump
                  placeholder="Содержимое"
                  theme={s.error ? "error" : "default"}
                  {...p}
                />
              )}
            />
          </div>
        )}
      </FormProvider>
    </div>
  );
};

export default TextTemplate;
