import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getChatbotData,
  setChatbot
} from "../Store/Chatbots.reducer";

function* getChatbotSaga({ payload, type }) {
  yield put(startLoading(type));

  try {
    const res = yield httpRequest("GET", `bff/settings/chatbots/${payload}`);

    if (res.success) {
      yield put(setChatbot(res.data));
    }
  } catch (error) {
  } finally {
    yield put(finishLoading(type));
  }
}

export default function* () {
  yield takeEvery([getChatbotData], getChatbotSaga);
}
