import { takeLatest, take, fork, cancel, call } from "redux-saga/effects";

import { websocket } from "../../Websocket/Websocket.saga";
import { getChatAction } from "../WS/ws.actions";
import { connectChatWs, disconnectChatWs } from "../store/Chat.actionCreators";

const onReceivedHandle = (wsEvent, sendMessageRead) => {
  // Игноррируем пакеты с real_time_stats, т.к. пока что в чате они не нужны
  if (!wsEvent.name || wsEvent.name === "real_time_stats") return;
  getChatAction(wsEvent.name, wsEvent);
  sendMessageRead();
};

export default function* () {
  while (true) {
    const { payload } = yield take(connectChatWs);
    const instance = yield websocket().getInstance(payload, onReceivedHandle);

    yield fork(instance.open, instance);
    yield take(disconnectChatWs);
    yield cancel(instance.secretId);

    yield call(instance.destroy);
  }
}
