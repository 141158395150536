import { put, takeEvery } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/DashboardSection.reducer";

import Informer from "../../../arm/Wrappers/Informer";

function* sendEmailReportSaga({ payload, type }) {
  yield put(actions.startLoading(type));

  try {
    const res = yield httpRequest("GET", `bff/dashboard/report/send-email`, {
      query: payload.filters
    });

    if (res.success) {
      const info = new Informer(
        res.data.success === 1
          ? "Отчет будет сформирован, результат будет выслан на email"
          : res.data.inprocess === 1
          ? "Отчет уже формируется, результат будет выслан на email"
          : "Что-то пошло не так, повторите позднее",
        3500
      );
      info.show();
    }
  } catch (error) {
    let errorMessage = error
      ? JSON.parse(error.message)
      : `Не удалось отправить отчет`;

    if (
      typeof JSON.parse(error?.message) === "object" &&
      JSON.parse(error?.message)?.body === null
    ) {
      errorMessage = "Сервис выгрузки excel недоступен, попробуйте позже";
    }

    const info = new Informer(errorMessage, 3500);
    info.show();
    console.log(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery([actions.sendEmailReport], sendEmailReportSaga);
}
