import produce from "immer";

import initialData from "../RepliesList.initialData";

import * as ACTION from "../Consts";

export default function RepliesListReducer(
  state = initialData,
  { type, payload }
) {
  switch (type) {
    case ACTION.SET_ALL_SELECTED:
      return produce(state, (draft) => {
        draft.all = !draft.all;
        if (draft.all) {
          draft.selected = draft.repliesList.map((item) => item.id);
        } else {
          draft.selected = initialData.selected;
        }
      });
    case ACTION.RESET_SELECTED:
      return produce(state, (draft) => {
        draft.all = false;
        draft.selected = initialData.selected;
      });
    case ACTION.SET_QUERY:
      return produce(state, (draft) => {
        draft.page = 0;
        draft.query = payload;
      });
    case ACTION.SET_CATEGORY:
      return produce(state, (draft) => {
        draft.categoryId = payload;
      });
    case ACTION.GET_NEXT_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page + 1;
      });
    case ACTION.SET_REPLIES:
      return produce(state, (draft) => {
        draft.repliesList = [...payload];
      });
    case ACTION.GET_REPLIES:
      return produce(state, (draft) => {
        draft.page = 0;
      });
    case ACTION.ADD_REPLIES:
      return produce(state, (draft) => {
        draft.repliesList = [...draft.repliesList, ...payload];
      });
    case ACTION.SET_SELECTED_REPLIES:
      return produce(state, (draft) => {
        const valueIndex = draft.selected.findIndex((item) => item === payload);

        if (valueIndex === -1) {
          draft.selected.push(payload);
        } else {
          draft.all = false;
          draft.selected.splice(valueIndex, 1);
        }
      });
    case ACTION.RESET_PAGE:
      return produce(state, (draft) => {
        draft.page = 0;
      });
    default:
      return state;
  }
}
