import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";
import { restoreUsersPass } from "../Store/UsersProfileModal.reducer";
import { actionUsers } from "../../../Settings/Users/Store/Users.reducer";

export function* restoreUsersPassSaga({ payload: { targetUsersInfo, close } }) {
  yield put(actionUsers.setRestorePasswordHandling(true));

  const { userIds, isAll, skillGroupId, query } = targetUsersInfo;

  const body = {
    userIds: isAll ? [] : userIds.map((id) => +id),
    isAll: isAll,
    skillGroupId: skillGroupId,
    query: query
  };

  try {
    const res = yield httpRequest(
      "PUT",
      `bff/settings/profiles/multi/reset-password`,
      { body }
    );
    if (res?.success) {
      const info = new Informer(
        `Новый пароль был отправлен на почту пользователя(ей)`
      );
      info.show();
      close();
    } else {
      const info = new Informer(`Не удалось восстановить пароль`);
      info.show();
    }
  } catch ($error) {
    console.error($error);
    const info = new Informer(`Не удалось восстановить пароль`);
    info.show();
  } finally {
    yield put(actionUsers.setRestorePasswordHandling(false));
  }
}

export default function*() {
  yield takeEvery([restoreUsersPass], restoreUsersPassSaga);
}
