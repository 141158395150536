import React from "react";

interface IUploadFileLinkProps {
  title?: string;
  fileUrl: string;
}

const UploadFileLink: React.FunctionComponent<IUploadFileLinkProps> = ({
  title = "Ссылка на файл",
  fileUrl,
}): JSX.Element => {
  return (
    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  );
};

export default UploadFileLink;
