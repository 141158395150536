import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getFieldsList,
  setFieldsList,
  GET_FIELDS_DATA
} from "../Store/ClassifierLinkForm.reducer";

export function* getFieldsSaga(action) {
  const classifierId = action.payload;
  yield put(startLoading(GET_FIELDS_DATA));

  try {
    const res = yield httpRequest(
      "GET",
      `bff/settings/classifier/${classifierId}/fields`
    );

    if (res?.success) {
      yield put(setFieldsList(res.data));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(GET_FIELDS_DATA));
  }
}

export default function* () {
  yield takeEvery([getFieldsList], getFieldsSaga);
}
