import React, { forwardRef, memo } from "react";
import { Slider as SliderMui, SliderProps } from "@mui/material";

type Props = SliderProps;

const Slider = forwardRef<HTMLSpanElement, Props>((props, ref) => {
  return <SliderMui size="small" ref={ref} {...props} />;
});

export default memo(Slider);
