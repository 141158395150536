import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Button, ScrollBox, Preloader, isEmpty } from "@omnichat/arm_ui_kit";

import SectionMenuTitle from "../../../ReactFeatures/Common/Components/SectionMenuTitle/SectionMenuTitle";
import SettingsSubmenuList from "../../Thematics/Components/SettingsSubmenuList/SettingsSubmenuList";

import * as s from "./style.module.scss";

type ClassifiersListProps = {
  loading: boolean;
  classifiersList: any;
  onCreate: () => void;
  onEdit: (id: string) => void;
  onSelect: (param: string) => void;
};

const ClassifiersList: React.FC<ClassifiersListProps> = ({
  loading,
  classifiersList,
  onSelect,
  onCreate,
  onEdit
}) => {
  const { classifierId } = useParams<{
    classifierId: string;
  }>();

  useEffect(() => {
    if (core.nav.url.params.classifier && !classifierId) {
      onSelect(core.nav.url.params.classifier);
    }
  }, []);

  useEffect(() => {
    if (
      !isEmpty(classifiersList) &&
      !classifierId &&
      !core.nav.url.params.classifier
    ) {
      onSelect(classifiersList[0].id);
    }
  }, [classifiersList, classifierId]);

  return (
    <ScrollBox autoHeight hasScrollControlBackground>
      <Preloader show={loading}>
        <div className={s["scrollContentWrap"]}>
          <Button
            type="bare"
            theme="block"
            iconName="plus_bold"
            iconPosition="right"
            iconColor="light-gray"
            onClick={onCreate}
            text="Добавить класcификатор"
          />
          <div className={s["fieldBlockWrap"]}>
            <SectionMenuTitle title="КЛАССИФИКАТОРЫ" />
            <SettingsSubmenuList
              list={classifiersList}
              onSelect={onSelect}
              onEdit={onEdit}
            />
          </div>
        </div>
      </Preloader>
    </ScrollBox>
  );
};

export default ClassifiersList;
