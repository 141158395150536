import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionUsers } from "../Store/Users.reducer";

function* getSkillGroupsSettingsSaga({
  type,
  payload
}: PayloadAction<{ page?: number }>) {
  yield put(actionUsers.startLoading(type));

  const query = { page: 0 };

  try {
    const res = yield httpRequest("GET", "bff/settings/skill-groups", {
      query
    });

    if (res.success) {
      // В данный момент page всегда = 0
      // if (payload.page !== 0) {
      //   if (!!res.data?.content?.length) {
      //     yield put(actionUsers.changeSkillGroupPage());
      //     yield put(actionUsers.addSkillGroupsIntoList(res.data.content));
      //   }
      // } else {
      yield put(actionUsers.setSkillGroupsList(res.data.content));
      // }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(actionUsers.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery(
    actionUsers.getSkillGroupListAction,
    getSkillGroupsSettingsSaga
  );
}
