import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionRoles } from "../Store/Roles.reducer";
import Informer from "../../../arm/Wrappers/Informer";
import {
  ERROR_MESSAGE_WHILE_SAVE_DATA,
  SUCCESS_MESSAGE_SAVE_DATA,
  ERROR_ROLES_NAME_ALREADY_EXISTS
} from "../const";

function* sendNewRoleSaga({ payload }) {
  actionRoles.setSendDataHandling(true);

  const { formData, callback } = payload;

  try {
    const resNewRole = yield httpRequest("POST", `bff/settings/roles`, {
      body: formData
    });

    if (resNewRole?.resRole?.success && resNewRole?.resRights?.success) {
      const info = new Informer(SUCCESS_MESSAGE_SAVE_DATA);
      info.show();

      yield put(actionRoles.getRoles());
      callback?.(resNewRole?.resRole?.data?.id);
    } else {
      const roleError = resNewRole?.resRole?.error;
      if (
        roleError?.status === 409 &&
        /already exists/.test(roleError?.message)
      ) {
        const info = new Informer(ERROR_ROLES_NAME_ALREADY_EXISTS);
        info.show();
        yield put(actionRoles.setErrorMessage(ERROR_ROLES_NAME_ALREADY_EXISTS));
      } else {
        const info = new Informer(ERROR_MESSAGE_WHILE_SAVE_DATA);
        info.show();
      }
    }
  } catch (error) {
    const info = new Informer(ERROR_MESSAGE_WHILE_SAVE_DATA);
    info.show();
  } finally {
    yield put(actionRoles.setSendDataHandling(false));
  }
}

export default function*() {
  yield takeEvery([actionRoles.sendNewRole], sendNewRoleSaga);
}
