import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  sendValueData,
  setValueData,
  setValueResponseError,
  SEND_VALUE_DATA
} from "../Store/ClassifierFieldValueForm.reducer";
import {
  ClassifierFieldValueFormTypes,
  Data,
  Link
} from "../ClassifierFieldValueForm";

import Informer from "../../../arm/Wrappers/Informer";
import { ISelect } from "../../Common/Interfaces/Store.interfaces";

export function* saveFieldSaga(action) {
  yield put(startLoading(SEND_VALUE_DATA));

  const valueData: Data = yield select<ISelect>(
    ({ ClassifierFieldValueForm }) => ClassifierFieldValueForm.valueData
  );

  const linksData: Link[][] = yield select<ISelect>(
    ({ ClassifierFieldValueForm }) => ClassifierFieldValueForm.linksData
  );

  const { fieldId, formData, cb } = action.payload;

  const preparedValueData = ((data: ClassifierFieldValueFormTypes.DTO) => ({
    name: data.name,
    isAlwaysShow: data.isAlwaysShow,
    links: data.isAlwaysShow
      ? undefined
      : linksData?.map((l) => l.map((v) => String(v.valueId)))
  }))(formData);

  try {
    const method = valueData?.id ? "PUT" : "POST";
    const id = valueData?.id || "";
    const path = id ? `fields/values/${id}` : `fields/${fieldId}/values`;

    const res = yield httpRequest(method, `bff/settings/classifier/${path}`, {
      body: preparedValueData
    });

    if (res?.success) {
      if (id) {
        yield put(setValueData(res.data));
      }

      let actionName = id ? "отредактировано" : "создано";

      yield cb(res.data.id);

      const info = new Informer(
        `Поле «${res.data.name}» было ${actionName}`,
        4500
      );
      info.show();
    } else {
      if (res.error) {
        console.error(res.error);
        yield put(setValueResponseError("Ошибка сервера"));
        return;
      }
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(SEND_VALUE_DATA));
  }
}

export default function* () {
  yield takeEvery([sendValueData], saveFieldSaga);
}
