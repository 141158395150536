import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box } from "@mui/material";
import { Preloader, Button, isEmpty } from "@omnichat/arm_ui_kit";

import { ReactHookFormErrorHandler } from "../../Utils/HookForm.helper";
import {
  FormFieldConnector,
  Slider,
  Select,
  ModalHeader,
  DragAndDropSelect
} from "../../Components";
import * as disctionaryActions from "../Dictionaries/Store/Dictionaries.actions";
import * as disctionarySelectors from "../Dictionaries/Store/Dictionaries.selector";
import { ITargetGroupUsersAction } from "../../Settings/Users/UserGroupsManager.d";

import { useActions } from "./Store/UsersMultiProfileModal.reducer";
import * as selectors from "./Store/UsersMultiProfileModal.selector";
import {
  schema,
  defaultValues,
  IFormData
} from "./UsersMultiProfileModal.schema";
import * as styles from "./UsersMultiProfileModal.module.scss";
import { mapFormDataToDto } from "./utils";

type Props = {
  targetUsersAction: ITargetGroupUsersAction;
  close: () => void;
};

const UsersMultiProfileModal: FC<Props> = ({ targetUsersAction, close }) => {
  const dispatch = useDispatch();
  const { ...actions } = useActions();

  const errorMessage = useSelector(selectors.getErrorMessage);
  const formData = useSelector(selectors.getFormData);
  const dataSendHandling = useSelector(selectors.getDataSendHandling);
  const isDictionariesLoaded = useSelector(
    disctionarySelectors.getIsUsersDictionariesLoaded
  );
  const organizations = useSelector(
    disctionarySelectors.getOrganizationsToForm
  );
  const areas = useSelector(disctionarySelectors.getAreasToForm);
  const territories = useSelector(disctionarySelectors.getTerritoriesToForm);
  const dictionarySkillGroups = useSelector(disctionarySelectors.getSkillGroupsToForm);

  const availableSkillsForAgent = useSelector(selectors.userSkillGroups);

  const methods = useForm<IFormData, any, IFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch } = methods;

  const appealLimit = watch("appealLimit");

  useEffect(() => {
    dispatch(disctionaryActions.getUsersProfileCatalogs(core.user.id));

    return () => {
      actions.resetUsersMultiProfileData();
    };
  }, []);

  useEffect(() => {
    if (!dataSendHandling) {
      reset(formData || defaultValues);
    }
  }, [formData]);

  const handleSave = (data) => {
    actions.sendUsersMultiProfileData({
      formData: { ...mapFormDataToDto(data), targetUsersAction },
      close
    });
  };

  const availableSkills = (allSkills) =>
    allSkills?.map((i) => ({
      ...i,
      disabled: !availableSkillsForAgent.includes(i.value)
    }));

  const skillsOptions = availableSkills(dictionarySkillGroups);

  return (
    <Preloader size="mini" show={!isDictionariesLoaded}>
      <div
        id="UsersMultiProfileEditModal"
        className={styles["parametersEmployeesModalContainer"]}
      >
        <ModalHeader>Параметры сотрудников</ModalHeader>

        <FormProvider {...methods}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="appealLimit"
                label={"Количество обращений операторов"}
                actionText="Установленный лимит обращений будет применён ко всем выбранным операторам"
                Component={(p) => <Slider {...p} />}
                customTitle={
                  <div className={styles["appealLimitValue"]}>
                    {appealLimit && appealLimit > 0
                      ? appealLimit
                      : "Нет ограничений"}
                  </div>
                }
              />
            </Grid>

            {!isEmpty(organizations) && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="userOrganizationId"
                  label="Признак организации"
                  Component={(p, { error }) => {
                    const selected = [{ value: p.value, label: p.label }];
                    return (
                      <Select
                        {...p}
                        placeholder="Не указан"
                        isError={!isEmpty(error)}
                        options={organizations}
                        selected={selected}
                        onSelectOption={(selected) =>
                          p.onChange(selected[0].value)
                        }
                        actionText={
                          !isEmpty(error)
                            ? ""
                            : "Может быть использован для формировании отчетности во внешних системах(BI)"
                        }
                      />
                    );
                  }}
                />
              </Grid>
            )}

            {!isEmpty(areas) && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="userAreaId"
                  label="Площадка"
                  Component={(p, { error }) => (
                    <Select
                      {...p}
                      isError={!isEmpty(error)}
                      options={areas}
                      placeholder="Не указан"
                      selected={[{ value: p.value, label: p.label }]}
                      onSelectOption={(selected) =>
                        p.onChange(selected[0].value)
                      }
                    />
                  )}
                />
              </Grid>
            )}

            {!isEmpty(territories) && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="userTerritoryId"
                  label="Территория"
                  Component={(p, { error }) => (
                    <Select
                      {...p}
                      placeholder="Не указана"
                      isError={!isEmpty(error)}
                      options={territories}
                      selected={[{ value: p.value, label: p.label }]}
                      onSelectOption={(selected) =>
                        p.onChange(selected[0].value)
                      }
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroupIds"
                label="скилл-группы операторов"
                required
                Component={(p, { error }) => (
                  <DragAndDropSelect
                    addText="Добавить группу"
                    required
                    isError={error}
                    selected={availableSkills(p.value)}
                    options={!isEmpty(skillsOptions) ? skillsOptions : []}
                    onChange={p.onChange}
                  />
                )}
              />
            </Grid>

            {errorMessage && (
              <Grid
                container
                justifyContent="center"
                item
                xs={12}
                paddingBottom={2}
              >
                <div className={styles["error"]}>{errorMessage}</div>
              </Grid>
            )}

            <Grid item alignItems="center" xs={12}>
              <p>
                Установленные параметры будут применены ко всем выбранным
                операторам
              </p>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  type="default"
                  disabled={dataSendHandling}
                  theme={dataSendHandling ? "disabled" : "green"}
                  onClick={handleSubmit(
                    (data) => handleSave({ ...(formData || {}), ...data }),
                    ReactHookFormErrorHandler
                  )}
                  text="Cохранить"
                />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
    </Preloader>
  );
};

export default UsersMultiProfileModal;
