import { takeEvery } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { setActiveDialog } from "../Reducers/Dialog.reducer";

function* setActiveDialogSaga(action) {
  const { appealId, dialogId } = action.payload;
  yield httpRequest("PUT", `bff/chat/active-appeal/${appealId || ""}`);

  if (dialogId) {
    yield httpRequest("PUT", `bff/chat/dialog/${dialogId}/unread/0`);
  }
}

export default function*() {
  yield takeEvery([setActiveDialog], setActiveDialogSaga);
}
