import { createAction } from "@reduxjs/toolkit";

import { RepliesListTypes } from "../Interfaces";

import * as ACTION from "../Consts";

export const getReplies = createAction(ACTION.GET_REPLIES);

export const setReplies = createAction<RepliesListTypes.Reply[]>(
  ACTION.SET_REPLIES
);

export const addReplies = createAction<RepliesListTypes.Reply[]>(
  ACTION.ADD_REPLIES
);

export const getNextPage = createAction(ACTION.GET_NEXT_PAGE);

export const setAll = createAction(ACTION.SET_ALL_SELECTED);

export const resetSelect = createAction(ACTION.RESET_SELECTED);

export const setCategory = createAction<number | string>(ACTION.SET_CATEGORY);

export const setQuery = createAction<string>(ACTION.SET_QUERY);

export const setSelected = createAction<number>(ACTION.SET_SELECTED_REPLIES);

export const resetPage = createAction<number>(ACTION.RESET_PAGE);

export const removeReplies = createAction<{
  onSuccess: () => void;
  onError: () => void;
}>(ACTION.REMOVE_REPLIES);
