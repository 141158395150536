import React, { FunctionComponent } from "react";
import { PatternFormat } from "react-number-format";

import { Tooltip } from "@omnichat/arm_ui_kit";
import * as s from "./PhoneField.module.scss";

import { preparePhoneToMask } from "./utils";

interface IPhoneField {
  value: number;
}

const PhoneField: FunctionComponent<IPhoneField> = ({ value }): JSX.Element => {
  const copyToClipboard = (value: string): void => {
    let textField = document.createElement("textarea");
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const preparedPhone = preparePhoneToMask(value);

  return (
    <>
      {preparedPhone && (
        <Tooltip
          content="Нажмите, чтобы скопировать"
          position="top"
          modifiers={{ offset: 5 }}
        >
          <p
            className={s["phoneNumber"]}
            onClick={() => copyToClipboard(`8${preparedPhone}`)}
          >
            <PatternFormat
              format="+7 (###) ###-####"
              displayType={"text"}
              allowEmptyFormatting
              value={preparedPhone}
            />
          </p>
        </Tooltip>
      )}
    </>
  );
};

export default PhoneField;
