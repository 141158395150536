import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import { ChartData, ChartsFilters } from "../Charts";
import initialState from "../../../ReactCore/store/initialState";

const NAMESPACE = "dashboardCharts/";

export const GET_CHART = `${NAMESPACE}GET_CHART`;

export const getChart = createAction<{ name: string; filters: ChartsFilters }>(
  GET_CHART
);

const ChartsSlice = createSlice({
  name: "charts",
  initialState: initialState.Charts,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setChart(state, action: PayloadAction<{ [key: string]: ChartData }>) {
      state.charts = { ...state.charts, ...action.payload };
    },
    resetCharts(state) {
      state.charts = {};
    }
  }
});

export const actions = { ...ChartsSlice.actions, getChart };

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export default ChartsSlice.reducer;
