import React, { FunctionComponent } from "react";

import { Button, isEmpty } from "@omnichat/arm_ui_kit";

import * as s from "./ThematicsInfo.module.scss";

interface IThematicsInfoProps {
  thematics?: string[];
  services?: any[];
  canEdit?: boolean;
  onEditHandler: () => void;
}

/**
 * Плашка с информацией о проставленных у обращения тематик и услуг.
 */
const ThematicsInfo: FunctionComponent<IThematicsInfoProps> = ({
  thematics,
  services,
  canEdit,
  onEditHandler
}): JSX.Element => {
  const Service = (data: string[], key: number) => {
    return (
      <h4 className={s["service"]} key={"service_" + key}>
        {data}
      </h4>
    );
  };

  const ServicesList = ({ services }) => {
    return <>{services?.map(Service)}</>;
  };

  const Thematic = (data: string, key: number) => {
    return (
      <div className={s["theme"]} key={"theme_" + key}>
        {key + 1}. {data}
      </div>
    );
  };

  const ThematicsList = ({ thematics }) => {
    return <>{thematics.map(Thematic)}</>;
  };

  return !isEmpty(thematics) || !isEmpty(services) || canEdit ? (
    <div className={s["appealThemes"]}>
      <h3>Тематики обращения</h3>

      {!isEmpty(services) && <ServicesList services={services} />}

      {!isEmpty(thematics) && <ThematicsList thematics={thematics} />}

      {canEdit && (
        <div className={s["themeEditButton"]}>
          <Button
            type="underline"
            text={
              !isEmpty(thematics) || !isEmpty(services)
                ? "Редактировать"
                : "Добавить"
            }
            onClick={onEditHandler}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default ThematicsInfo;
