import React, { FC } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isEmpty, FieldTitle } from "@omnichat/arm_ui_kit";
import c from "classnames";

import ActionText from "../ActionText";

import * as styles from "./style.module.scss";

interface IFormFieldConnectorProps {
  label?: JSX.Element | string;
  name: string;
  actionText?: string;
  showErrorText?: boolean;
  required?: boolean;
  Component: (field, state) => JSX.Element;
  customTitle?: JSX.Element;
  extraClass?: (string | object)[];
}

/**
 * Обертка для поля react-hook-form
 */
const FormFieldConnector: FC<IFormFieldConnectorProps> = ({
  label,
  name,
  actionText,
  showErrorText = true,
  required,
  Component,
  customTitle,
  extraClass = []
}): JSX.Element => {
  const { control } = useFormContext();
  const { errors } = useFormState();

  return (
    <div className={c(styles.fieldWrapper, ...extraClass)}>
      {label && (
        <FieldTitle label={label} required={required}>
          <div className={styles.customTitle}>{customTitle}</div>
        </FieldTitle>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            {Component(field, fieldState)}
            {!!actionText && isEmpty(fieldState.error) && (
              <div className={styles.fieldActionText}>
                <div className={styles.text}>{actionText}</div>
              </div>
            )}
          </>
        )}
      />

      {showErrorText && (
        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => (
            <ActionText isError={true} actionText={message} />
          )}
        />
      )}
    </div>
  );
};

export default FormFieldConnector;
