export const INFORMER_DELAY = 2500;

class Informer {
  constructor(text, timeout = INFORMER_DELAY) {
    this.text = text;
    this.timeout = timeout;
    this.informer = core.informer;
  }

  show() {
    const { informer, text, timeout } = this;
    informer.show(text, timeout);
  }

  hide() {
    this.informer.hide();
  }
}

export default Informer;
