/**
 * Вывод Времени из timestamp в форматированную строку
 * @param time в timestamp
 * @returns (HH:)MM:SS
 */
export const getFormatedTime = (time: number): string => {
  if (typeof time !== "number" || Number.isNaN(time) || !time) {
    return "00:00";
  }

  let hour: string | number = Math.floor(time / 3600);
  if (hour && hour < 10) {
    hour = "0" + hour;
  }
  let min: string | number = (time % 3600) / 60;
  min = Math.floor(min);
  if (min < 10) {
    min = "0" + min;
  }
  let sec: string | number = time % 60;
  if (sec < 10) {
    sec = "0" + sec;
  }
  return `${hour ? hour + ":" : ""}${min}:${sec}`;
};
