export const DELETED = `DELETED`;

export const ALL = `ALL`;

export const ALL_SKILLGROUP = {
  id: ALL,
  name: `Все сотрудники`,
  current: true,
  editable: false
};

export const DELETED_SKILLGROUP = {
  id: DELETED,
  name: `Архив`,
  current: false,
  editable: false
};
