import { useEffect, useRef, useState } from "react";

export const useSetOffsetTop = (
  minElementOffsetTop: number = 0
) => {
  const [elemOffsetTop, setElemOffsetTop] = useState(
    minElementOffsetTop
  );

  const elemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elemRef?.current?.clientHeight) {
      setElemOffsetTop(
        Math.max(
          minElementOffsetTop,
          elemRef.current.clientHeight
        )
      );
    }
  });

  return { el: elemOffsetTop, ref: elemRef }
};
