import { createAction } from "@reduxjs/toolkit";
import { AppealThemeData } from "../Interfaces";
import {
  ADD_APPEAL_ORIGIN_THEMES,
  ADD_APPEAL_ORIGIN_SERVICES,
  SEND_THEMES_DATA
} from "../Consts";

/**
 * Устанавливаем список полей для формирования тематик.
 */
export const setOriginAppealThemes = createAction<AppealThemeData[]>(
  ADD_APPEAL_ORIGIN_THEMES
);
export const setOriginServiceTag = createAction<any>(
  ADD_APPEAL_ORIGIN_SERVICES
);
export const setOriginAppealService = createAction<any>(
  ADD_APPEAL_ORIGIN_SERVICES
);

export const sendThemesData = createAction<any>(SEND_THEMES_DATA);
