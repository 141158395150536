import React, { FC, memo, MouseEventHandler } from "react";
import { Tooltip } from "@omnichat/arm_ui_kit";
import IconButton from "@mui/material/IconButton";
import Add from "@mui/icons-material/Add";

interface Props {
  disabled: boolean;
  onClick: MouseEventHandler;
}

const AddRoleButton: FC<Props> = ({ disabled, onClick }) => (
  <Tooltip
    isDisabled={disabled}
    content="Добавить роль"
    position="bottom"
    modifiers={{ offset: 5 }}
  >
    <IconButton
      disabled={disabled}
      onClick={onClick}
      aria-label="settings"
      color="primary"
      sx={{
        cursor: "pointer"
      }}
    >
      <Add
        viewBox="3 3 17 17"
        sx={{
          width: "22px",
          height: "22px"
        }}
      />
    </IconButton>
  </Tooltip>
);

export default memo(AddRoleButton);

{
  /* <Tooltip
disabled={disabled}
content="Добавить роль"
position="bottom"
modifiers={{ offset: 5 }}
>
<IconButton
  disabled={disabled}
  onClick={handleAddNewRole}
  aria-label="settings"
  color="primary"
  sx={{
    cursor: "pointer"
  }}
>
  <Add
    viewBox="3 3 17 17"
    sx={{
      width: "22px",
      height: "22px"
    }}
  />
</IconButton>
</Tooltip> */
}
