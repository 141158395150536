import { fork } from "redux-saga/effects";

import GetThemeFields from "./GetThemeFields.saga";
import SaveThemes from "./SaveThemes.saga";
import GetThemes from "./GetThemes.saga";

export default function*() {
  yield fork(GetThemeFields);
  yield fork(SaveThemes);
  yield fork(GetThemes);
}
