// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".exportFileBlock--zlYmJ7Kz4y5rOdaXAtk7{display:flex;flex-direction:column}.loaderWraper--xdMNZc7Xz6OY0EuT1PcS{position:relative;display:flex;color:#0090db;font-size:1em}.loader--4YTDehLnx1oqbDQAN1bX{position:relative;width:24px;height:24px;margin-right:5px}.loaderText--lSeXTiLVrOSFnK1eLIAt{margin-top:5px;font-size:1em;color:#88969e;line-height:130%}.loaderError--xGVL5LImU_qbFAol5tyz{color:red}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/AppealReportModal/style.module.scss"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,qBAAA,CAEF,oCACE,iBAAA,CACA,YAAA,CACA,aAAA,CACA,aAAA,CAGF,8BACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CAEF,kCACE,cAAA,CACA,aAAA,CACA,aAAA,CACA,gBAAA,CAEF,mCACE,SAAA","sourcesContent":[".exportFileBlock {\n  display: flex;\n  flex-direction: column;\n}\n.loaderWraper {\n  position: relative;\n  display: flex;\n  color: #0090db;\n  font-size: 1em;\n}\n\n.loader {\n  position: relative;\n  width: 24px;\n  height: 24px;\n  margin-right: 5px;\n}\n.loaderText {\n  margin-top: 5px;\n  font-size: 1em;\n  color: #88969e;\n  line-height: 130%;\n}\n.loaderError {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export var exportFileBlock = "exportFileBlock--zlYmJ7Kz4y5rOdaXAtk7";
export var loaderWraper = "loaderWraper--xdMNZc7Xz6OY0EuT1PcS";
export var loader = "loader--4YTDehLnx1oqbDQAN1bX";
export var loaderText = "loaderText--lSeXTiLVrOSFnK1eLIAt";
export var loaderError = "loaderError--xGVL5LImU_qbFAol5tyz";
export default ___CSS_LOADER_EXPORT___;
