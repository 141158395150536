import React, { BaseSyntheticEvent, useRef } from "react";

import c from "classnames";
import {
  CheckboxProps,
  FormControlLabel as MuiFormControlLabel
} from "@mui/material";
import {
  Checkbox as MuiCheckbox,
  Switch as MuiSwitch,
  Box
} from "@mui/material";
import ActionText from "../ActionText";

/**
 * Свойства компонента Checkbox.
 */
export interface ICheckboxProps extends CheckboxProps {
  /** Тип Checkbox */
  type?: "square" | "slider";
  disabled?: boolean;
  onChange?: (e?: BaseSyntheticEvent) => void;
  extraClass?: (string | Record<string, unknown>)[];
  checked?: boolean;
  caption?: string;
  captionPosition?: "bottom" | "end" | "start" | "top";
  actionText?: string;
  id?: string;
  labelSx?: Record<string, unknown>;
}

/**
 * Компонент Checkbox.
 */
const Checkbox = ({
  type = "square",
  disabled,
  extraClass = [],
  onChange = () => {},
  checked,
  caption,
  captionPosition = "start",
  actionText,
  labelSx = {},
  ...props
}: ICheckboxProps) => {
  const ref = useRef(null);
  const CheckboxComponent = () => (
    <>
      {type === "square" ? (
        <MuiCheckbox
          ref={ref}
          className={c(...extraClass)}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: "24px"
            }
          }}
          {...props}
        />
      ) : (
        <MuiSwitch
          ref={ref}
          className={c(...extraClass)}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
      )}
    </>
  );

  return (
    <>
      <Box>
        {caption ? (
          <MuiFormControlLabel
            label={caption}
            labelPlacement={captionPosition}
            disabled={disabled}
            sx={labelSx}
            control={
              <Box
                ml={captionPosition === "start" ? 1 : 0}
                mr={captionPosition === "end" ? 1 : 0}
                {...(type === "square" && {
                  display: "inline-flex",
                  alignItems: "center"
                })}
              >
                <CheckboxComponent />
              </Box>
            }
          />
        ) : (
          <CheckboxComponent />
        )}
      </Box>
      {actionText && (
        <Box>
          <ActionText disabled={disabled} actionText={actionText} />
        </Box>
      )}
    </>
  );
};

export default Checkbox;
