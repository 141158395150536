import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactHookFormErrorHandler } from "../../../Utils/HookForm.helper";
import FormFieldConnector from "../../../Components/FormFieldConnector";
import Informer from "../../../arm/Wrappers/Informer";
import ConforimModal from "../../../ReactFeatures/Common/Components/ConfirmModal/ConfirmModal";


import {
  Button,
  isEmpty,
  TextareaDump,
  ModalWindow,
  REQUIRED_FIELD_ERROR
} from "@omnichat/arm_ui_kit";

import { Input, ModalHeader } from "../../../Components";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

const ModalContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
`;

const ActionButtons = styled.div`
  margin-top: 10px;
  justify-content: center;
  display: flex;
  & > button {
    margin-right: 10px;
  }
`;

const schema = Yup.object({
  name: Yup.string()
    .trim()
    .required(REQUIRED_FIELD_ERROR),
  email: Yup.string().trim(),
  signature: Yup.string().trim()
});

const defaultValues = {
  name: "",
  email: "",
  signature: ""
};

export interface IChannelForm extends Yup.InferType<typeof schema> {}

export function EditChannelEmail({ open, channel, handleClose }) {
  const [data, updateData] = useState<{
    name: string;
    account: string;
    signature: string;
  }>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const methods = useForm<IChannelForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if(data) {
      reset({
        name: data.name,
        email: data.account,
        signature: data.signature
      })
    } else {
      reset(defaultValues);
    }
  }, [data])

  useEffect(() => {
    httpRequest("GET", `bff/settings/channels/${channel}`).then(
      (response: any) => {
        if (response?.success) {
          const newState = {
            name: response.data?.name || "",
            account: response.data?.account || "",
            signature: response.data?.channelSettings?.signature || ""
          };

          updateData(newState);
        }
      }
    );
  }, [channel]);

  const handleConfirmDelete = () =>
    httpRequest("DELETE", `bff/settings/channels/${channel}`).then((res) => {
      if (res?.success) {
        const info = new Informer(
          "Канал успешно удален",
          3500
        );
        info.show();
      } else {
        console.error(res?.error);
      }
      handleClose?.();
      setOpenConfirmModal(false);
    });

  const handleCancelDelete = () => setOpenConfirmModal(false);

  const handleSave = (form) => {
    httpRequest("PUT", `bff/settings/channels/${channel}`, {
      body: {
        name: form.name,
        account: form.email,
        settings: { signature: form.signature }
      }
    }).then((res) => {
      if (res?.success) {
        const info = new Informer(
          "Канал успешно отредактирован",
          3500
        );
        info.show();
      } else {
        console.error(res?.error);
      }
      handleClose?.();
    });
  };

  const handleDelete = () => setOpenConfirmModal(true);

  if (openConfirmModal) {
    return (
      <ConforimModal
        open
        title="Подтверждение удаления"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        description={
          <>
            Выбранный канал будет удален <br /> Хотите продолжить?
          </>
        }
      />
    );
  }

  return (
    <ModalWindow open={open} onCloseModal={handleClose} isButtonClose>
      <ModalContainer id="EmailChannelModal">
        <FormProvider {...methods}>
          <ModalHeader>Редактирование канала</ModalHeader>

          <FormFieldConnector
            name="name"
            label="Название"
            required
            Component={(p) => (
              <Input {...p} onChange={p.onChange} value={p.value} />
            )}
          />

          <FormFieldConnector
            name="email"
            label="Почта"
            Component={(p) => <Input {...p} disabled value={p.value} />}
          />

          <FormFieldConnector
            name="signature"
            label="Подпись"
            Component={(p, formState) => (
              <TextareaDump
                {...p}
                placeholder="Укажите подпись"
                theme={!isEmpty(formState.error) ? "error" : undefined}
                value={p.value}
                onChange={p.onChange}
                rows={4}
              />
            )}
          />

          <ActionButtons>
            <Button
              text="Сохранить"
              theme="green"
              type="default"
              onClick={handleSubmit(handleSave, ReactHookFormErrorHandler)}
            />

            <Button
              text="Удалить"
              theme="red"
              type="default"
              onClick={handleDelete}
            />
          </ActionButtons>
        </FormProvider>
      </ModalContainer>
    </ModalWindow>
  );
}
