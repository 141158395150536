import React, { FC } from "react";
import c from "classnames";

import { Icon } from "@omnichat/arm_ui_kit";

import * as s from "./EmailMessage.module.scss";

export type AttachmentType = {
  name: string;
  ext: string;
};

const Attachment: FC<AttachmentType> = ({ ext, name }) => {
  return (
    <>
      <Icon color="blue" name="attach_file" width="20px" height="20px" />
      <div className={c(s["emailMessageAttachmentNameContainer"])}>
        <span className={c(s["emailMessageAttachmentName"])}>{name}</span>
        <span className={c(s["emailMessageAttachmentExt"])}>{`.${ext}`}</span>
      </div>
    </>
  );
};

export default Attachment;
