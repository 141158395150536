import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionUsersProfileModal as actions } from "../Store/UsersProfileModal.reducer";

export function* getUsersProfileDataSaga({ payload }) {
  const { profileId, forEdit = false } = payload;
  yield put(actions.setLoadDataHandling(true));

  try {
    const res = yield httpRequest("GET", `bff/settings/profiles/${profileId}/`);

    if (res.success) {
      if (forEdit) {
        yield put(actions.setPhoto(res.data?.photo));
      }
      yield put(actions.setUsersProfileData(res?.data));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(actions.setLoadDataHandling(false));
  }
}

export default function*() {
  yield takeEvery([actions.getUsersProfileData], getUsersProfileDataSaga);
}
