export const PATH_VIEW_ROLE = "view-role";
export const PATH_EDIT_ROLE = "edit-role";
export const PATH_CREATE_ROLE = "create-role";

export const ADMIN_ROLE_ID = 1;

export const SUCCESS_MESSAGE_SAVE_DATA = "Данные успешно сохранены";
export const SUCCESS_MESSAGE_DELETE_ROLE = "Роль успешно удалена";

export const ERROR_MESSAGE_WHILE_SAVE_DATA =
  "Произошла ошибка при сохранении данных";
export const ERROR_MESSAGE_WHILE_GET_DATA =
  "Произошла ошибка при получении данных";
export const ERROR_MESSAGE_WHILE_DELETE_DATA =
  "Произошла ошибка при удалении данных";

export const ERROR_ROLES_NAME_ALREADY_EXISTS =
  "Роль с таким названием уже существует";
export const ERROR_CANT_DELETE_ROLE_HAS_USERS =
  "Удалите или замените роль у участников и повторите попытку";
