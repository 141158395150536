import { isoToTimestamp } from "./dataFormats";

import { MessagesTypes } from "../../ReactFeatures/Common/Consts/MessagesTypes";
import { IMessage } from "../../ReactFeatures/Common/Interfaces/Message.interfaces";

enum MediaTypes {
  System,
  Text,
  Image,
  Video,
  Location,
  File,
  Sticker,
}

interface IRawContext {
  users: IRawContextUser[];
  messages: IRawContextMessage[];
  client: IRawContextClient;
}

interface IRawContextMessage {
  id: number;
  userId: number;
  clientId: number;
  channelName: string;
  date: string;
  latitude: number;
  longitude: number;
  mediaThumb: string;
  mediaType: string;
  mediaUrl: string;
  text: string;
  type?: number;
}

interface IRawContextUser {
  id: number;
  name: string;
  penName: string;
  photo: string;
}

interface IRawContextClient {
  id: number;
  login: string;
  name: string;
  photo: string;
  userData?: {
    role: string;
  };
}

export const getMappedContextMessages = (
  rawContext: IRawContext,
  appealId: number
): IMessage[] => {
  return rawContext.messages.reduce((accumulator, currentValue): IMessage => {
    const user = currentValue.userId && rawContext.users[currentValue.userId];
    let text = currentValue.text;
    // Если в сообщении находится изображение в теге <img>
    // и текст, например в <p>, берем текст из параграфа и отправляем отдельно
    if (hasTextImages(text)) {
      addImageMessages(text, (link) => {
        accumulator.push(getImageMessage(currentValue, appealId, user, link));
      });
      text = getClearedText(text);
    }
    if (text) {
      accumulator.push(getDefaultMessage(currentValue, appealId, user, text));
    }
    return accumulator;
  }, []);
};

//Возращает объект для сообщения с текстом
const getDefaultMessage = (data, appealId, user, text) => {
  return {
    id: data.id,
    channel: {
      id: 0,
      name: data.channelName,
    },
    appealId: appealId,
    type: data?.type || MessagesTypes.Default,
    clientId: data.clientId,
    date: isoToTimestamp(data.date),
    latitude: data.latitude,
    longitude: data.longitude,
    mediaThumb: data.mediaThumb,
    mediaType: data.mediaType,
    mediaUrl: data.mediaUrl,
    text: text,
    user: user && {
      id: user.id,
      name: user.name ?? "",
      secName: user.penName ?? "",
      photo: user.photo,
    },
  };
};

//Возращает объект для сообщения с изображением
const getImageMessage = (data, appealId, user, image) => {
  return {
    id: data.id,
    channel: {
      id: 0,
      name: data.channelName,
    },
    appealId: appealId,
    type: MessagesTypes.Default,
    clientId: data.clientId,
    date: isoToTimestamp(data.date),
    latitude: data.latitude,
    longitude: data.longitude,
    mediaThumb: image,
    mediaType: MediaTypes.Image,
    mediaUrl: image,
    text: "",
    user: user && {
      id: user.id,
      name: user.name ?? "",
      secName: user.penName ?? "",
      photo: user.photo,
    },
  };
};

// Регулярка для нахождения <img> в сообщении
const imgRegexp = /(<img [а-яёА-ЯЁ\-\w="'\s]*src=[\\]?["|']([\w=\s:\/.#\-\\&?\.%]*)["|'][а-яёА-ЯЁ\-\w="'\s]*>)/g;

// Регулярка для взятия адреса изображения из src тега <img>
let reg = /(https?:\/\/.*\.(?:png|jpe?g|svg|gif))/gi;

//Проверяем есть ли в сообщении изображение
const hasTextImages = (text) => imgRegexp.test(text);

// Если в сообщении находится изображение в теге <img> и текст, например в <p>, берем только текст
const getClearedText = (text) => text.replaceAll(imgRegexp, "").trim();

const addImageMessages = (text, callback) => {
  const imageSrc = text.match(reg);
  callback(imageSrc[0] ?? text);
};
