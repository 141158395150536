import { takeEvery, put } from "redux-saga/effects";

import { isEmpty } from "@omnichat/arm_ui_kit";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { GREETING_BY_NOTIFICATION_STRATEGY_VARS } from "../Consts";

import { getRepliesGreetingData, sendMessage } from "../Actions/Dialog.actions";
import { setRepliesGreeting } from "../Reducers/Dialog.reducer";

function* getRepliesGreetingSaga(action) {
  let userId, repliesGreeting;
  const appealId = action.payload.appealId;
  try {
    repliesGreeting = yield httpRequest(
      "GET",
      `bff/chat/dialog/replies/greeting`,
      {
        query: {
          userId,
          appealId
        }
      }
    );
    if (
      GREETING_BY_NOTIFICATION_STRATEGY ===
        GREETING_BY_NOTIFICATION_STRATEGY_VARS.SEND &&
      repliesGreeting?.success
    ) {
      const dialogId = core.nav.url.params.dialog_id;
      const messages = [];
      if (repliesGreeting.data?.text && repliesGreeting.data?.text !== "") {
        messages.push({
          text: repliesGreeting.data?.text,
          mediaThumb: null,
          mediaUrl: null,
          dialogId: +dialogId
        });
      }
      if (repliesGreeting.data?.attachments.length) {
        repliesGreeting.data.attachments.map((fileName) => {
          const attachment = {
            dialogId: +dialogId,
            hint_uid: null,
            mediaThumb: `thumb/${fileName.split("/")[0]}.${
              fileName.split(".")[1]
            }/${fileName.split("/")[1]}`,
            mediaUrl: `${fileName.split("/")[0]}.${fileName.split(".")[1]}/${
              fileName.split("/")[1]
            }`,
            text: ""
          };
          messages.push(attachment);
        });
      }
      yield put(sendMessage(messages));
    } else if (
      GREETING_BY_NOTIFICATION_STRATEGY ===
        GREETING_BY_NOTIFICATION_STRATEGY_VARS.FILL &&
      !isEmpty(repliesGreeting) &&
      repliesGreeting.data.error !== true
    ) {
      const templateMessage = {
        text: repliesGreeting?.data?.text || "",
        title: repliesGreeting?.data?.title || "",
        attachments: []
      };
      if (repliesGreeting.data.attachments.length) {
        repliesGreeting.data.attachments.map((fileName) => {
          const attachment = {
            ext: fileName.split(".")[1],
            failed: false,
            loaded: true,
            name: fileName.split("/")[1],
            pending: false,
            progress: 100,
            uuid: fileName.split("/")[0],
            text: ""
          };
          templateMessage.attachments.push(attachment);
        });
      }
      yield put(setRepliesGreeting(templateMessage));
    } else {
      return;
    }
  } catch (e) {
    console.error("Saga: getRepliesGreetingData error", e);
  }
}

export default function* () {
  yield takeEvery(getRepliesGreetingData, getRepliesGreetingSaga);
}
