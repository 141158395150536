import React, { FC, useEffect, useRef } from "react";
import c from "classnames";
import { Modal } from "@mui/material";
import { Button } from "@omnichat/arm_ui_kit";

import * as s from "./style.module.scss";

export interface IModalWindowProps {
  /** Контент окна */
  children: JSX.Element | JSX.Element[];
  /** Состояние открытости окна */
  open: boolean;
  /** Варианты оформления окна */
  type?: "message" | "image";
  /** Признак наличия кнопки закрытия */
  isButtonClose?: boolean;
  /** Функция обработчик закрытия окна */
  onCloseModal?: () => void;
  /** Кастомные стили SX */
  extraSX?: { [key: string]: string | { [key: string]: string } };
}

const ModalWindow: FC<IModalWindowProps> = ({
  children,
  open,
  type = "message",
  isButtonClose = false,
  onCloseModal = () => {},
  extraSX
}): JSX.Element | null => {
  const isImageType = type === "image";

  const modalElement = useRef<HTMLDivElement>(null);

  useEffect((): (() => void) => {
    const closeModalOnKeyDown = (event: KeyboardEvent): void => {
      if ((isButtonClose || isImageType) && modalElement.current) {
        if (event.keyCode === 27) {
          onCloseModal();
        }
      }
    };

    document.addEventListener("keydown", closeModalOnKeyDown);
    return () => {
      document.removeEventListener("keydown", closeModalOnKeyDown);
    };
  }, []);

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      closeAfterTransition
      sx={extraSX ?? {}}
    >
      <div
        className={c(s["overlay"], { [s["overlayDark"]]: isImageType })}
        onMouseDown={onCloseModal}
      >
        {isImageType && (
          <div onClick={onCloseModal} className={s["overlayButtonClose"]} />
        )}
        <div
          ref={modalElement}
          className={s["overlayLightbox"]}
          onMouseDown={stopPropagation}
        >
          {isButtonClose && (
            <Button
              id="closeModalButton"
              type="closeModal"
              extraClass={[s["intersectionPosition"]]}
              onClick={onCloseModal}
            />
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ModalWindow;
