const NAMESPACE = "RepliesTemplateEditModal";

export const GET_CATALOGS = `${NAMESPACE}_GET_CATALOGS`;

export const CHANGE_CATEGORIES_PAGE = `${NAMESPACE}_CHANGE_CATEGORIES_PAGE`;

export const GET_REPLIES_DATA = `${NAMESPACE}_GET_REPLIES_DATA`;
export const SET_REPLIES_DATA = `${NAMESPACE}_SET_REPLIES_DATA`;
export const SEND_DATA = `${NAMESPACE}_SEND_DATA`;

export const RESET_DATA = `${NAMESPACE}_RESET_DATA`;
