import React, { FunctionComponent } from "react";

import * as s from "./InfoField.module.scss";

interface IInfoFieldProps {
  label: JSX.Element | string;
  children: JSX.Element | JSX.Element[];
}

const InfoField: FunctionComponent<IInfoFieldProps> = ({
  label,
  children
}): JSX.Element => (
  <div className={s["wrapper"]}>
    <div className={s["label"]}>{label}</div>
    <div>{children}</div>
  </div>
);

export default InfoField;
