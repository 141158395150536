import { put, takeEvery } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/AppealReport.reducer";

import Informer from "../../../arm/Wrappers/Informer";

function* getAppealReportSaga({ type }) {
  yield put(actions.startLoading(type));

  try {
    const res = yield httpRequest("GET", `bff/dashboard/report/appeals`);

    if (res.success) {
      const { filters, status, ...file } = res.data?.data?.reportMetadata;
      if (status !== "NEW" && status !== "ERROR") {
        yield put(actions.setFilter(filters));
      }
      yield put(actions.setFileData(file));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

function* createAppealReportSaga({ payload, type }) {
  yield put(actions.startLoading(type));
  yield put(actions.setReportFail(false));

  try {
    const res = yield httpRequest("POST", `bff/dashboard/report/appeals`, {
      body: payload
    });

    if (res.success) {
      yield put(actions.getAppealReport());

      const info = new Informer(`Отчет успешно создан`, 3500);
      info.show();
    }
  } catch (error) {
    const info = new Informer(`Не удалось создать отчет`, 3500);
    info.show();

    yield put(actions.setReportFail(true));
    console.log(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

function* downloadAppealReportSaga({ type }) {
  yield put(actions.startLoading(type));

  try {
    // TODO
    // При проксировании загрузки файла, файл прилетает битый
    // const res = yield httpRequest(
    //   "GET",
    //   `bff/dashboard/report/appeals/download`
    // );

    // if (res.success) {
    //   console.log(res.data);
    // }
    window.open(`/excel/appeal-reports/${core.user.id}`, "_self");
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery([actions.getAppealReport], getAppealReportSaga);
  yield takeEvery([actions.createAppealReport], createAppealReportSaga);
  yield takeEvery([actions.downloadAppealReport], downloadAppealReportSaga);
}
