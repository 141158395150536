import { put, takeEvery } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/AppealReport.reducer";

function* getSkillGroupSaga({ type }) {
  yield put(actions.startLoading(type));

  const query = { size: 100500 };

  try {
    const res = yield httpRequest("GET", "bff/settings/skill-groups", {
      query
    });

    if (res.success) {
      yield put(actions.setSkillGroups(res.data.content));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

export default function* () {
  yield takeEvery([actions.getSkillGroups], getSkillGroupSaga);
}
