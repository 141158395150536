import * as React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import ThemeProvider from "../../Components/ThemeProvider";
import { store } from "../../ReactCore";

class Modal {
  constructor(modalId, modalWidth = 0, component, onClose = () => {}) {
    this.modalId = modalId;
    this.component = component;
    this.onClose = onClose;
    this.modalWidth = modalWidth;
  }

  open() {
    const { modalId, component, onClose, modalWidth, close } = this;
    this.renderModalForReact(
      modalId,
      modalWidth,
      (root) => {
        ReactDom.render(
          <ThemeProvider>
            <Provider store={store}>{component(close.bind(this))}</Provider>
          </ThemeProvider>,
          root
        );
      },
      onClose
    );
  }

  close() {
    const { modalId } = this;
    $(`#reactRoot_${modalId}`)
      .parent()
      .modal("close");
  }

  renderModalForReact(modalId, modalWidth, callback, callbackClose = () => {}) {
    let reactElement;

    let width = "auto";
    if (typeof modalWidth === "number" && modalWidth > 0) {
      width = `${modalWidth}px`;
    }
    core.draw.window({
      id: modalId,
      body: `
        <div class="wsModal" style="width: ${width}">
          <div id="reactRoot_${modalId}" class="reactRootModal"></div>
        </div>
        `,
      callback: {
        afterOpen() {
          reactElement = document.getElementById(`reactRoot_${modalId}`);
          $(".modal-container_i2").off();
          callback(reactElement);
        },
        afterClose() {
          callbackClose();
          ReactDom.unmountComponentAtNode(reactElement);
        }
      }
    });
  }
}

export default Modal;
