import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getChatbotslist,
  setChatbots
} from "../Store/Chatbots.reducer";

function* getChatbotsSaga({ type }) {
  yield put(startLoading(type));

  try {
    const res = yield httpRequest("GET", "bff/settings/chatbots");

    if (res.success) {
      yield put(setChatbots(res.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(finishLoading(type));
  }
}

export default function* () {
  yield takeEvery([getChatbotslist], getChatbotsSaga);
}
