import React, { useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import data from "@emoji-mart/data/sets/13/apple.json";
import Picker from "@emoji-mart/react";

interface IEmojiPicker {
  /** render prop */
  children: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void
  ) => JSX.Element;
  /** handler */
  onAdd: (emoji) => void;
  /** flag of multiply choice */
  multiple: boolean;
}

const BlockView = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const PickerWrapper = styled.div`
  z-index: 9999;
`;

const useFixedPosition = (
  elementH: number
): [React.CSSProperties, (node: EventTarget) => void] => {
  const [position, setPosition] = useState<React.CSSProperties>({});

  const getPosition = (node: HTMLElement): React.CSSProperties => {
    const { top, left } = node.getBoundingClientRect();
    const h = window.innerHeight;
    let pos: React.CSSProperties = { top: top + 20, left: left - 10 };

    if (h < top + elementH) pos = { bottom: h - top + 20, left: left - 10 };

    return pos;
  };

  const updatePosition = (node: HTMLElement): void => {
    setPosition({ ...getPosition(node) });
  };

  return [{ ...position, position: "fixed" }, updatePosition];
};

const EmojiPicker: React.FunctionComponent<IEmojiPicker> = ({
  children,
  onAdd,
  multiple = false
}): JSX.Element => {
  const [open, handleOpen] = useState(false);
  const [position, updatePosition] = useFixedPosition(300);

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    handleOpen(!open);
  };

  const onOpen = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    updatePosition(event.target);
    handleOpen(!open);
  };

  const handleAdd = (e): void => {
    if (onAdd) onAdd(e);
    if (!multiple) {
      handleOpen(false);
    }
  };

  return (
    <>
      {children(onOpen)}
      {open &&
        createPortal(
          <BlockView onClick={handleClose}>
            <PickerWrapper
              style={position}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Picker
                data={data}
                onEmojiSelect={handleAdd}
                showPreview={false}
                set="apple"
                emojiVersion="13"
                locale="ru"
                theme="ligth"
                emojiButtonSize="40"
                emojiSize="30"
                emojiButtonColors={["rgba(153, 151, 153, .2)"]}
                icons="solid"
                maxFrequentRows={0}
                previewPosition="none"
                skin="1"
                skinTonePosition="none"
                autoFocus={true}
              />
            </PickerWrapper>
          </BlockView>,
          document.body
        )}
    </>
  );
};

export default EmojiPicker;
