import React from "react";
import c from "classnames";

import { Icon } from "@omnichat/arm_ui_kit";
import { Tooltip, Badge } from "../../../../Components";
import { ISubmenuItem, ISubmenuItemHandlers } from "./interface";
import * as styles from "./styles.module.scss";

/**
 * Одна категория
 */
const SubmenuItem = ({
  id,
  name,
  current,
  editable,
  onSelect,
  onEdit,
  forAll
}: ISubmenuItem & ISubmenuItemHandlers): JSX.Element => {
  const stringId = id.toString();

  const edit = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onEdit(stringId);
  };

  const handleClick = () => onSelect(stringId);

  return (
    <>
      <Badge
        id={`badge_${stringId}`}
        badgeContent={
          <Tooltip
            content={`Отображается для не занятых групп `}
            position="top"
          >
            <div className={styles.badgeToolTip}></div>
          </Tooltip>
        }
        color="success"
        classes={{
          root: c(styles.classifier, { [styles.current]: current }),
          badge: styles.badge
        }}
        invisible={!forAll}
        onClick={handleClick}
      >
        <div
          id={`classifier_name_${stringId}`}
          className={styles.classifierName}
        >
          {name}
        </div>
        {editable && (
          <div onClick={(e) => edit(e)} className={styles.classifierIcon}>
            <Icon
              id={`icon_badge_${stringId}`}
              color={current ? "white" : "blue"}
              name="edit"
              width="18px"
              height="18px"
            />
          </div>
        )}
      </Badge>
    </>
  );
};

export default SubmenuItem;
