import * as React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../ReactCore/store/types";

import CategoryList from '../Components/CategoryList/CategoryList';
import * as actions from "../Actions/CategoriesList.action";
import {
  resetPage,
  resetSelect,
} from "../../RepliesList/Actions/RepliesList.action";

import {
  getCategories,
  getSkillGroup,
  getSkillGroups,
} from "../CategoriesList.selectors";

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  openEditCategoryModal: (id?) => void;
}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {
  categories: {
    id: number | string;
    name: string;
    current: boolean;
    editable: boolean;
  }[];
  skillGroups: {
    value: number | string;
    label: string;
  }[];
  skillGroup: number;
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  getNextPage: () => void;
  getCategories: (id) => void;
  getSkillGroups: () => void;
  onSelectSkillgroup: (id) => void;
  setCategory: (id) => void;
  resetSelectAll: () => void;
  resetRepliesPage: () => void;
}

type CategoriesListProviderProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * Провайдер фичи CategoriesList.
 */
class CategoriesListProvider extends React.PureComponent<
  CategoriesListProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { skillGroup, getCategories, getSkillGroups } = this.props;

    getCategories(skillGroup);
    getSkillGroups();
  }

  onEditCategory = (id) => this.props.openEditCategoryModal(id);

  onAddCategory = () => this.props.openEditCategoryModal();

  onSelectCategory = (id) => {
    this.props.resetRepliesPage();
    this.props.setCategory(id);
    this.props.resetSelectAll();
  };

  render() {
    return (
      <>
        <CategoryList
          skillGroups={this.props.skillGroups}
          skillGroup={this.props.skillGroup}
          categories={this.props.categories}
          onSelectCategory={this.onSelectCategory}
          onSelectSkillgroup={this.props.onSelectSkillgroup}
          onEditCategory={this.onEditCategory}
          onNextPage={this.props.getNextPage}
          onAdd={this.onAddCategory}
        />
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  skillGroup: getSkillGroup(state),
  skillGroups: getSkillGroups(state),
  categories: getCategories(state),
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
  getNextPage: () => dispatch(actions.getNextPage()),
  getCategories: (id) => dispatch(actions.getCategories(id)),
  getSkillGroups: () => dispatch(actions.getSkillGroups()),
  onSelectSkillgroup: (id) => dispatch(actions.setSkillGroup(id)),
  setCategory: (id) => dispatch(actions.setCategory(id)),
  resetSelectAll: () => dispatch(resetSelect()),
  resetRepliesPage: () => dispatch(resetPage()),
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesListProvider);

export default Connect;
