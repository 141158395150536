import { FieldErrors } from "react-hook-form";
import Informer, { INFORMER_DELAY } from "../arm/Wrappers/Informer";

export const ReactHookFormErrorHandler = (e: FieldErrors) => {
  console.error(`Submit error`, e);
  const informer = new Informer(
    "Проверьте правильность заполнения полей.",
    INFORMER_DELAY
  );
  informer.show();
};
