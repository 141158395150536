import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { getActiveAppeal, getPrompter } from "../Dialog.selectors";
import {
  setPrompterStatusPending,
  setPrompterStatusSuccess,
  setPrompterStatusFailure
} from "../Reducers/Dialog.reducer";
import { getPrompterAnswers } from "../Actions/Dialog.actions";

function* getPrompterAnswersSaga() {
  const appealId = yield select(getActiveAppeal);
  const { page } = yield select(getPrompter);

  yield put(setPrompterStatusPending());
  try {
    const { success, data } = yield httpRequest(
      "GET",
      `bff/chat/dialog/prompter/${appealId}`,
      { query: { page } }
    );
    if (success) {
      yield put(
        setPrompterStatusSuccess({
          isLast: data.last,
          answers: data.content,
          totalAnswers: data.totalElements
        })
      );
    }
  } catch (e) {
    yield put(setPrompterStatusFailure());
  }
}

export default function* () {
  yield takeEvery(getPrompterAnswers, getPrompterAnswersSaga);
}
