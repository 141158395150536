class Notification {
  constructor() {
    this.sound = "/www/notification.mp3";
    this.changeFavicon = "/www/img/favicon_notice.ico";
  }

  onClick() {
    window.focus();
    core.notification["instance_" + core.notification.count].close();
    // core.notification[
    //   "instance_" + core.notification.count - 1
    // ].close();
  }

  show({
    title,
    text,
    icon,
    titleMarqueeText,
    tag,
    onClick = () => {},
    onClose = () => {}
  }) {
    const { sound } = this;

    document.title = titleMarqueeText;
    core.notification.send({
      sound: sound,
      title: title,
      text: text,
      icon: icon,
      change_favicon: this.changeFavicon,
      tag: tag,
      on: {
        click: () => {
          this.onClick();
          onClick();
        },
        close: onClose
      }
    });
  }

  hide() {}
}

export default Notification;
