import React from "react";

import { Preloader, isEmpty, ScrollBox } from "@omnichat/arm_ui_kit";

import ThematicsPicker from "../../../ReactFeatures/ThematicModal/Components/ThematickModal/ThematicsPicker";

import * as s from "./style.module.scss";

type ThematicsPickerDemoProps = {
  loading: boolean;
  themesFields: any;
  onChange: any;
};

const ThematicsPickerDemo: React.FC<ThematicsPickerDemoProps> = ({
  loading,
  themesFields,
  onChange
}) => {
  return (
    <ScrollBox autoHeight hasScrollControlBackground>
      <div className={s.scrollContentWrap}>
        {isEmpty(themesFields) && !loading ? (
          <div className={s.fieldBlockWrap}>Нет полей у классификатора</div>
        ) : (
          <Preloader show={loading}>
            <div className={s.fieldBlockWrap}>
              Данная форма создана для определения темы обращения. Агент
              заполняет ее после завершения чата с посетителем.
            </div>
            <div className={s.fieldBlockWrap}>
              <ThematicsPicker
                themesFields={themesFields || []}
                onChange={onChange}
              />
            </div>
            <div className={s.fieldBlockWrap}>
              <span className="wsForm_fieldName imp"></span> — Обязательные поля
              при заполнении формы агентом.
            </div>
          </Preloader>
        )}
      </div>
    </ScrollBox>
  );
};

export default ThematicsPickerDemo;
