import { MonitoringTypes } from "../Monitoring.d";

const Agents: MonitoringTypes.State = {
  currentAgentsPage: 0,
  editableAgents: true,
  editableSkills: true,
  query: "",
  loading: [],
  skillGroupList: [],
  skillGroupData: null,
  agentList: [],
  agentData: null,
  monitoringAgents: null,
  monitoringGroups: null,
  monitoringOverall: null
};

export default Agents;
