import React, { PureComponent } from "react";
import c from "classnames";

import { isEmpty, ScrollBox, Icon } from "@omnichat/arm_ui_kit";

import { captureKeyCodesStuff } from "../../ReactFeatures/Common/Utils/Text.utils";
import * as s from "./TextArea.module.scss";

interface ILastStateOfEditor {
  node: Node | null;
  position: number;
  scrollTopPosition: number;
}

function findLastTextNode(node: Node): Node | null {
  if (node.nodeType === Node.TEXT_NODE) return node;
  let children = node.childNodes;
  for (let i = children.length - 1; i >= 0; i--) {
    let textNode = findLastTextNode(children[i]);
    if (textNode !== null) return textNode;
  }
  return null;
}

function normalizeHtml(str: string): string {
  return str?.replace(/&nbsp;|\u202F|\u00A0|\r/g, " ");
}

/**
 * Свойства комнотента TextArea.
 *
 * @prop {string} initialValue Начальное значение текстовой области.
 * @prop {Function} onChange Колбэк на изменение значения в store.
 * @prop {string} [customClasses] Кастомные классы для компонента.
 * @prop {string} [placeholder] placeholder текстового поля.
 * @prop {string} [focus] Сочетание клавиш для отправки сообщения.
 * @prop {string} [label] Наименование поля.
 * @prop {boolean} [required] Флаг, показывающий, обязательное ли поле.
 * @prop {string} [actionText] Текст подсказки, расположенной ниже компонента.
 * @prop {boolean} [isError] Флаг, который показывает, допущена ли ошибка.
 * @prop {Function} [actions] Действия.
 * @prop {string} [debug] Флаг, который показывает, нужен ли debug.
 * @prop {Function} [onKeyShortcut] Колбэк на нажатие комбинации клавиш.
 * @prop {boolean} [isKeyCodeCaptureEnabled] Активна ли возможность определения зажатых горячих клавиш.
 * @prop {Function} [onBlur] Колбэк на действие снятия фокуса с элемента.
 * @prop {Function} [onPaste] Колбэк на вставку данных в поле ввода.
 * @prop {Function} [onKeyDown] Колбэк на нажатие клавиши.
 * @prop {string} [id] Id элемента
 */
interface ITextAreaProps {
  initialValue: string;
  onChange: (value: { html: string; text: string }) => void;
  customClasses?: string;
  placeholder?: string;
  focus?: string;
  label?: string;
  required?: boolean;
  actionText?: string;
  isError?: boolean;
  actions?: () => JSX.Element;
  debug?: boolean;
  onKeyShortcut?: (keyCode: string) => void;
  isKeyCodeCaptureEnabled?: boolean;
  onBlur?: () => void;
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  id?: string;
}

/**
 * Модель состояния компонента.
 *
 * @prop {Set<number> | null} pressedCodes Коллекция кодов, зажатых в данный момент клавиш.
 */
interface IState {
  pressedCodes: Set<number> | null;
}

export default class TextArea extends PureComponent<ITextAreaProps, IState> {
  static defaultProps = {
    customClasses: {},
    placeholder: "Текст сообщения",
    focus: "Ctrl+Enter - отправить",
    required: false,
    debug: false
  };

  constructor(props: ITextAreaProps) {
    super(props);

    this.state = {
      pressedCodes: new Set()
    };
  }

  textAreaElement = React.createRef<HTMLDivElement>();
  textFieldElement = React.createRef<HTMLDivElement>();

  private html: string | null = null;
  private text = "";
  private lastStateOfEditor: ILastStateOfEditor = {
    node: null,
    position: 0,
    scrollTopPosition: 0
  };

  private ScrollBox: unknown;

  componentDidUpdate() {
    if (this.props.debug) {
      console.info("TextArea.componentDidUpdate", { ...this });
    }
  }

  updateLastStateOfEditor = () => {
    const selection = document.getSelection();

    if (selection) {
      const { anchorNode, anchorOffset, focusOffset } = selection;
      this.lastStateOfEditor.node = anchorNode;
      this.lastStateOfEditor.position =
        anchorOffset > focusOffset ? anchorOffset : focusOffset;
    }

    if (this.ScrollBox) {
      // @ts-ignore
      this.lastStateOfEditor.scrollTopPosition = this.ScrollBox.getScrollTop();
    }

    if (this.props.debug) {
      console.info("TextArea.updateLastStateOfEditor", {
        ...this.lastStateOfEditor
      });
    }
  };

  /**
   * Вернуть форму в начальное состояние.
   */
  handleSetComponentInitialState = () => {
    this.setState({
      pressedCodes: new Set()
    });
  };

  private handleFocusOnMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    if (this.textFieldElement.current) {
      this.textFieldElement.current.focus();
      this.updateLastStateOfEditor();
    }
  };

  private handleToggleFocusTextArea = (flag: boolean = false): void => {
    const action = flag ? "add" : "remove";
    if (this.textAreaElement.current) {
      this.textAreaElement.current.classList[action](
        s["textareaContainerFocused"]
      );
    }
  };

  /**
   * Сэтит значения в локальные переменные и вызывает колбэк на изменение.
   * (Сохранение изменений после удаления приветствия)
   *
   * @param {string} html innerHTML.
   * @param {string} text innerText.
   */
  handleSetAndChange = (html: string, text: string) => {
    this.html = html;
    this.text = text;
    this.props.onChange({ html: this.html, text: this.text });
  };

  private handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    if (this.textFieldElement && this.props.onChange) {
      if (event.target !== this.textFieldElement.current) return;

      let html = normalizeHtml(this.textFieldElement.current.innerHTML);
      let text = this.textFieldElement.current.innerText || "";

      if (text === "\n") {
        html = "";
      }

      if (text && /\n$/.test(text)) {
        text = text.replace(/\n$/, "");
      }

      if (this.html !== html) {
        this.handleSetAndChange(html, text);
      }

      this.updateLastStateOfEditor();
    }
  };

  public focus() {
    this.textFieldElement.current?.focus();
  }

  public past(text: string) {
    if (this.textFieldElement.current) {
      this.focus();

      if (!this.lastStateOfEditor.node) {
        this.updateLastStateOfEditor();
      }

      if (this.lastStateOfEditor.node) {
        const range = new Range();
        const selection = document.getSelection();

        const pastingNode = new Text(normalizeHtml(text));

        range.selectNode(this.lastStateOfEditor.node);
        range.setStart(
          this.lastStateOfEditor.node,
          this.lastStateOfEditor.position
        );
        range.insertNode(pastingNode);
        if (selection && pastingNode.textContent) {
          selection.deleteFromDocument();
          selection.collapse(pastingNode, pastingNode.textContent.length);
        }
      }

      this.handleSetAndChange(
        normalizeHtml(this.textFieldElement.current.innerHTML),
        this.textFieldElement.current.innerText || ""
      );
    }
    if (this.ScrollBox) {
      // @ts-ignore
      this.ScrollBox.scrollTop(this.lastStateOfEditor.scrollTopPosition);
    }
  }

  public moveCaretToEnd() {
    if (this.textFieldElement.current) {
      const target = this.textFieldElement.current;
      const html = target.innerHTML;

      if (html.length === 0) return;

      if (target === document.activeElement) {
        const selection = window.getSelection();
        if (selection) {
          const lastTextNode = findLastTextNode(target);
          const range = document.createRange();
          if (lastTextNode !== null && lastTextNode.nodeValue) {
            range.setStart(lastTextNode, lastTextNode.nodeValue.length);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }
      }
    }
  }

  public cleanTextfield() {
    if (!this.textFieldElement.current) return;

    this.textFieldElement.current.innerHTML = "";
    this.props.onChange({ html: "", text: "" });
    this.lastStateOfEditor.node = null;
    this.lastStateOfEditor.position = 0;
    this.lastStateOfEditor.scrollTopPosition = 0;
  }

  /**
   * Обрабатывает события по нажатию клавиш.
   *
   * @param {React.KeyboardEvent<HTMLDivElement>} event Синтетическое событие.
   */
  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { onKeyShortcut, isKeyCodeCaptureEnabled, onKeyDown } = this.props;

    onKeyDown?.(event);

    if (isKeyCodeCaptureEnabled) {
      let result = captureKeyCodesStuff(event, this.state.pressedCodes);

      result?.hotKeyCodes && onKeyShortcut?.(result.hotKeyCodes);

      this.setState((prevState: IState) => ({
        pressedCodes: result?.pressedCodes ?? prevState.pressedCodes
      }));
    }
  };

  /**
   * Обрабатывает события при отжатии клавиш.
   *
   * @param {React.KeyboardEvent<HTMLDivElement>} event Синтетическое событие.
   */
  handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const newPressedCodes = new Set([...this.state.pressedCodes]);

    event.preventDefault();
    event.stopPropagation();

    if (newPressedCodes.has(event.keyCode)) {
      newPressedCodes.delete(event.keyCode);

      this.setState({ pressedCodes: newPressedCodes });
    }

    this.handleInput(event);
  };

  /**
   * Обрабатывает события вставки в поле ввода.
   *
   * @param {React.ClipboardEvent<HTMLDivElement>} event Событие при вставке в поле ввода.
   */
  handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (!event.clipboardData || isEmpty(event.clipboardData.items)) return;

    this.props.onPaste?.(event);
  };

  handlePasteText = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData("text/plain");

    if (document?.queryCommandSupported("insertHTML")) {
      document?.execCommand("insertHTML", false, text);

      if (this.textFieldElement.current) {
        this.handleSetAndChange(
          normalizeHtml(this.textFieldElement.current.innerHTML),
          this.textFieldElement.current.innerText || ""
        );
      }
    } else {
      this.past(text);
    }
  };

  render() {
    if (this.props.debug) {
      console.info("TextArea.render");
    }

    return (
      <div
        onMouseDown={this.handleFocusOnMouseDown}
        onMouseUp={this.updateLastStateOfEditor}
        onFocus={() => this.handleToggleFocusTextArea(true)}
        onBlur={() => this.handleToggleFocusTextArea()}
        onPaste={this.handlePasteText}
        className={c(s["textarea"], { [s["error"]]: this.props.isError })}
      >
        {this.props.label && !this.props.actions && (
          <label>
            {this.props.label}:&nbsp;
            {this.props.required && <span className={s["required"]}>*</span>}
          </label>
        )}

        {this.props.label && this.props.actions && (
          <div className={s["labelWithActions"]}>
            <label>
              {this.props.label}:&nbsp;
              {this.props.required && <span className={s["required"]}>*</span>}
            </label>
            <div className={s["actions"]}>{this.props.actions()}</div>
          </div>
        )}

        <div
          className={c(s["textareaContainer"], {
            [s["error"]]: this.props.isError
          })}
          ref={this.textAreaElement}
        >
          <ScrollBox
            referens={(r) => (this.ScrollBox = r)}
            size="mini"
            autoHeightMax={215}
            autoHeight
            autoHide
          >
            <div
              onMouseDown={(event) => event.stopPropagation()}
              ref={this.textFieldElement}
              contentEditable
              placeholder={this.props.placeholder}
              data-text={this.props.focus}
              className={c(s["textareaTextField"], this.props.customClasses)}
              onInput={this.handleInput}
              onKeyUp={this.handleKeyUp}
              onKeyDown={this.handleKeyDown}
              dangerouslySetInnerHTML={{ __html: this.props.initialValue }}
              onBlur={this.props.onBlur}
              onPaste={this.handlePaste}
              id={this.props.id}
            />
          </ScrollBox>
        </div>

        {this.props.actionText && (
          <div className={s["actionText"]}>
            {this.props.isError && (
              <Icon width="24px" height="28px" color="red" name="error" />
            )}
            <span>{this.props.actionText}</span>
          </div>
        )}
      </div>
    );
  }
}
