import { takeEvery, put } from "redux-saga/effects";
import { GET_REPLIES_DATA } from "../consts";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { setTemplateData } from "../Store/RepliesTemplateEditModal.action";

interface IReplyData {
  title: string;
  body: string;
  category: number[];
  regions: number[];
  attachments: string[];
}

function* getReplyData(action) {
  const id = action.payload;

  const response = yield httpRequest("GET", `bff/settings/replies/${id}`);

  if (response?.success) {
    yield put(setTemplateData(response.data));
  }
}

export default function*() {
  yield takeEvery(GET_REPLIES_DATA, getReplyData);
}
