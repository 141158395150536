import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { isoIntervalToTimestamp } from "../../../ReactCore/shared/dataFormats";
import Informer from "../../../arm/Wrappers/Informer";
import { getAppealData } from "../Actions/AppealInfo.actionCreators";
import { setAppealData } from "../Reducers/AppealInfo.reducer";
import { getMappedContextMessages } from "../../../ReactCore/shared/mapContextMessages";
import { setRecentAppeals, getRecentAppeals } from "../utils";
import { setrecentAppeals } from "../../../Dashboard/DashboardFilters/Store/DashboardFilters.action";

interface AppealData {
  appealId: number;
  onError?: () => void;
}

function* getAppealDataSaga({
  payload: { appealId, onError }
}: PayloadAction<AppealData>) {
  try {
    const response = yield httpRequest(
      "GET",
      `bff/chat/appeals/${appealId}/info`,
      { query: { messages: true } }
    );

    if (response?.success) {
      const { data } = response;

      data.operationTime = isoIntervalToTimestamp(data.operationTime);

      if (typeof data?.client?.phone === "string") {
        data.client.phone = Number(data.client.phone.replace(/[^0-9]*/g, ""));
      }

      if (data?.context?.dialog) {
        data.context.clientRole = data.context.dialog?.client?.userData?.role;
        data.context.messages = getMappedContextMessages(
          data.context.dialog,
          appealId
        );
      }

      yield put(setAppealData(data));

      setRecentAppeals(`${appealId}`);
      yield put(setrecentAppeals(getRecentAppeals()));
    }
  } catch (error) {
    const parsedError = JSON.parse(error.message);
    const textMessage = parsedError?.message || "Неизвестная ошибка";

    const informer = new Informer(textMessage.replace("Appeal", "Обращение"));
    informer.show();

    onError?.();
    console.error("*** GetAppealDataError ***", error);
  }
}

export default function*() {
  yield takeEvery([getAppealData], getAppealDataSaga);
}
