import { AppealReportTypes } from "../AppealReport";

const AppealReport: AppealReportTypes.State = {
  loading: [],
  skillGroups: [],
  modalData: null,
  reportMetadata: null,
  reportFailed: false
};

export default AppealReport;
