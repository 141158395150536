import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  sendFieldData,
  setFieldData,
  setFieldResponseError,
  SEND_FIELD_DATA
} from "../Store/ClassifierFieldForm.reducer";
import { ClassifierFieldFormTypes, Link, Data } from "../ClassifierFieldForm";

import Informer from "../../../arm/Wrappers/Informer";
import { ISelect } from "../../Common/Interfaces/Store.interfaces";

export function* saveFieldSaga(action) {
  yield put(startLoading(SEND_FIELD_DATA));

  const fieldData: Data = yield select<ISelect>(
    ({ ClassifierFieldForm }) => ClassifierFieldForm.fieldData
  );

  const linksData: Link[][] = yield select<ISelect>(
    ({ ClassifierFieldForm }) => ClassifierFieldForm.linksData
  );

  const { classifierId, formData, cb } = action.payload;

  const preparedData = ((data: ClassifierFieldFormTypes.DTO) => ({
    description: data.description,
    type: data.type,
    isAlwaysShow: data.isAlwaysShow,
    name: data.name,
    links: data.isAlwaysShow
      ? undefined
      : linksData?.map((l) => l.map((v) => String(v.valueId)))
  }))(formData);

  try {
    const method = fieldData?.id ? "PUT" : "POST";
    const id = fieldData?.id || "";
    const path = id ? `fields/${id}` : `${classifierId}/fields`;

    const res = yield httpRequest(method, `bff/settings/classifier/${path}`, {
      body: preparedData
    });

    if (res?.success) {
      if (id) {
        yield put(setFieldData(res.data));
      }

      let actionName = id ? "отредактировано" : "создано";

      cb(res.data.id);

      const info = new Informer(
        `Поле «${res.data.name}» было ${actionName}`,
        4500
      );
      info.show();
    } else {
      if (res.error) {
        console.error(res.error);
        yield put(setFieldResponseError("Ошибка сервера"));
        return;
      }
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(SEND_FIELD_DATA));
  }
}

export default function* () {
  yield takeEvery([sendFieldData], saveFieldSaga);
}
