// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textValidate--aMpHZLdhnGqMDiRMOMhP{margin-bottom:10px}.head--n_GtBrae30EIfiA_gNIW{display:flex;justify-content:flex-start;align-items:center;justify-content:space-between;height:43px;margin-bottom:4px;background-color:#cee6f2}.name--hXvyFc_8rR0e2G1UT_oW{flex:1;display:flex;overflow:hidden}.nameText--cdU7lhBKcOCPcYO4DLY1{flex:1;padding-left:10px;font-size:18px;color:#0090db;cursor:pointer;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.buttons--IHyAfABBXvpLwjoiENjq{display:flex;margin-left:auto;padding:0 10px}.nameInput--vbGL4lqPDJO0dQpVVSmU{flex:1;margin-bottom:0}.add--hKm7E8VgGqSucZMycKap{display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/ClassifierFieldForm/Components/TextValidates/style.module.scss"],"names":[],"mappings":"AAAA,oCACE,kBAAA,CAEF,4BACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,iBAAA,CACA,wBAAA,CAEF,4BACE,MAAA,CACA,YAAA,CACA,eAAA,CAEF,gCACE,MAAA,CACA,iBAAA,CACA,cAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAEF,+BACE,YAAA,CACA,gBAAA,CACA,cAAA,CAEF,iCACE,MAAA,CACA,eAAA,CAEF,2BACE,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".textValidate {\n  margin-bottom: 10px;\n}\n.head {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  justify-content: space-between;\n  height: 43px;\n  margin-bottom: 4px;\n  background-color: #cee6f2;\n}\n.name {\n  flex: 1;\n  display: flex;\n  overflow: hidden;\n}\n.nameText {\n  flex: 1;\n  padding-left: 10px;\n  font-size: 18px;\n  color: #0090db;\n  cursor: pointer;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.buttons {\n  display: flex;\n  margin-left: auto;\n  padding: 0 10px;\n}\n.nameInput {\n  flex: 1;\n  margin-bottom: 0;\n}\n.add {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var textValidate = "textValidate--aMpHZLdhnGqMDiRMOMhP";
export var head = "head--n_GtBrae30EIfiA_gNIW";
export var name = "name--hXvyFc_8rR0e2G1UT_oW";
export var nameText = "nameText--cdU7lhBKcOCPcYO4DLY1";
export var buttons = "buttons--IHyAfABBXvpLwjoiENjq";
export var nameInput = "nameInput--vbGL4lqPDJO0dQpVVSmU";
export var add = "add--hKm7E8VgGqSucZMycKap";
export default ___CSS_LOADER_EXPORT___;
