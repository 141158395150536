// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fieldWrapper--VgDyxUQ7vS7Lyk8xpUzz{display:flex;flex-flow:column nowrap;margin-bottom:10px}.fieldActionText--g3iivhkfo12lkO6Gt8Ns{line-height:18px;color:#829199;margin-top:3px;font-size:16px;display:flex}.customTitle--OvnF5f8tHV4Ncv55X2Yw{margin-left:10px;font-size:16px;color:var(--color-primary-action);text-transform:lowercase;font-weight:bold}.fieldError--h3Z3WFfv2DbGpp7Ra8GA{display:flex;align-items:center;color:#fc2b32}.text--Cf52_zChJToT1FCvoMDm{margin-left:5px}", "",{"version":3,"sources":["webpack://./frontend/src/Components/FormFieldConnector/style.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,uBAAA,CACA,kBAAA,CAGF,uCACE,gBAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CAGF,mCACE,gBAAA,CACA,cAAA,CACA,iCAAA,CACA,wBAAA,CACA,gBAAA,CAGF,kCACE,YAAA,CACA,kBAAA,CACA,aAAA,CAGF,4BACE,eAAA","sourcesContent":[".fieldWrapper {\n  display: flex;\n  flex-flow: column nowrap;\n  margin-bottom: 10px;\n}\n\n.fieldActionText {\n  line-height: 18px;\n  color: #829199;\n  margin-top: 3px;\n  font-size: 16px;\n  display: flex;\n}\n\n.customTitle {\n  margin-left: 10px;\n  font-size: 16px;\n  color: var(--color-primary-action);\n  text-transform: lowercase;\n  font-weight: bold;\n}\n\n.fieldError {\n  display: flex;\n  align-items: center;\n  color: #fc2b32;\n}\n\n.text {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export var fieldWrapper = "fieldWrapper--VgDyxUQ7vS7Lyk8xpUzz";
export var fieldActionText = "fieldActionText--g3iivhkfo12lkO6Gt8Ns";
export var customTitle = "customTitle--OvnF5f8tHV4Ncv55X2Yw";
export var fieldError = "fieldError--h3Z3WFfv2DbGpp7Ra8GA";
export var text = "text--Cf52_zChJToT1FCvoMDm";
export default ___CSS_LOADER_EXPORT___;
