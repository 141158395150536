import React, { FC, ReactNode } from "react";
import { ListItemIcon, ListItemText, Icon as MuiIcon } from "@mui/material";

/**
 * Модель свойств элемента списка выпадающего меню.
 */
export interface MenuOptionProps {
  text: ReactNode;
  muiIconParams?: {
    Icon: typeof MuiIcon;
    fontSize?: "small" | "inherit" | "large" | "medium";
    iconPosition?: "start" | "end";
  };
}

export const MenuOption: FC<MenuOptionProps> = ({
  text,
  muiIconParams = null
}) => {
  if (muiIconParams === null) return <ListItemText primary={text} />;

  const { Icon, fontSize = "large", iconPosition = "start" } = muiIconParams;

  return (
    <>
      {iconPosition === "start" && (
        <ListItemIcon>
          <Icon fontSize={fontSize} />
        </ListItemIcon>
      )}
      <ListItemText primary={text} />
      {iconPosition === "end" && (
        <ListItemIcon>
          <Icon fontSize={fontSize} />
        </ListItemIcon>
      )}
    </>
  );
};

export default MenuOption;
