import React from "react";

import { SwitcherContainer } from "@omnichat/arm_ui_kit";
import { Input, Checkbox, FormField as MuiFormField } from "../../../Components";

const prechatFieldRoot = {
  flexDirection: "column",
  justifyContent: "space-between",
  mt: "5px",
  mb: "10px",
};

const CheckBoxField = SwitcherContainer(Checkbox);

const PrechatOption = ({
  fieldLabel,
  groupsValue,
  enabledOnChange,
  requiredOnChange,
  labelOnChange,
}) => {
  return (
    <MuiFormField label={fieldLabel} labelPosition="relative">
      <CheckBoxField
        caption="Включено"
        type="slider"
        checked={groupsValue?.enabled}
        onChange={enabledOnChange}
      />

      {!!groupsValue?.enabled && (
        <>
          <CheckBoxField
            type="slider"
            caption="Обязательно"
            checked={groupsValue?.required}
            onChange={requiredOnChange}
          />

          <Input
            placeholder="Лейбл"
            value={groupsValue?.label}
            onChange={labelOnChange}
            extraSX={{ inputBox: prechatFieldRoot }}
          />
        </>
      )}
    </MuiFormField>
  );
};

export default PrechatOption;
