const dictionaries = {
  categories: [],
  regions: [],
  mrfs: [],
  rfs: [],
  moveAppealList: [],
  areas: null,
  organizations: null,
  skillGroups: null,
  territories: null
};

export default dictionaries;
