import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getTemplateData,
  setTemplateData,
  GET_TEMPLATE_DATA
} from "../Store/ClassifierFieldForm.reducer";

export function* getTemplateDataSaga(action) {
  const fieldId = action.payload;
  yield put(startLoading(GET_TEMPLATE_DATA));

  try {
    const res = yield httpRequest(
      "GET",
      `bff/settings/classifier/fields/${fieldId}/templates`
    );

    if (res?.success) {
      yield put(setTemplateData(res.data));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(GET_TEMPLATE_DATA));
  }
}

export default function* () {
  yield takeEvery([getTemplateData], getTemplateDataSaga);
}
