export enum NETWORKSTATUS {
  LOGOUT = 0,
  ONLINE = 1,
  OFFLINE = 2
}

export enum CORERESPONSESTATUS {
  SUCCESS = 1,
  FAILURE = 0
}

export enum MESSAGESTYPE {
  MESSAGE = 0
}

export enum CHANNELTYPENAMES {
  SYSTEM = 0,
  WHATSAPP = 1,
  VIBER = 2,
  TELEGRAM = 3,
  VK = 4,
  FACEBOOK = 5,
  ODNOKLASSNIKI = 6,
  WEB_CHAT_CHANNEL = 9,
  MOBILE_APP_CHANNEL = 10,
  EMAIL_CHANNEL = 11,
  MBBIZ = 14
}

export enum Status {
  Close,
  Open,
  Waiting,
  Queue
}
