import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { getClassifiers } from "../../../Settings/Classifiers/Store/Classifiers.selectors";

export const ClassifierFieldValueForm = (state: StoreState.State) => {
  return state.ClassifierFieldValueForm;
};

export const getValueList = createSelector(getClassifiers, state => {
  return state.fields;
});
export const getLinksList = createSelector(ClassifierFieldValueForm, state => {
  return state.linksData || [];
});
export const getLoadingStatus = createSelector(
  ClassifierFieldValueForm,
  state => {
    return !isEmpty(state.loading);
  }
);

export const getResponseError = createSelector(
  ClassifierFieldValueForm,
  state => {
    return state.errorMessage;
  }
);

export const getValueData = createSelector(ClassifierFieldValueForm, state => {
  return state.valueData || null;
});

export const getReadableLinks = createSelector(
  ClassifierFieldValueForm,
  state => {
    return state.linksData?.map(d => d.map(l => l.valueName).join(" > "));
  }
);
