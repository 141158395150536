import { string } from "prop-types";
import { AppealsTypes } from "../Appeals";

const Appeals: AppealsTypes.State = {
  loading: [],
  appeals: [],
  page: 0,
  lastPage: null,
  channels: []
};

export default Appeals;
