// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".parameters-employees-modal-container--YJ4rKwtw6KUVK5WFgitP{width:550px}.parameters-employees-modal-container--YJ4rKwtw6KUVK5WFgitP .appealLimitValue--dziCLx16ADsWKJ8kmchq{margin-left:10px;text-transform:lowercase;color:var(--color-primary-action)}.parameters-employees-modal-container--YJ4rKwtw6KUVK5WFgitP .error--NQWNBbCXiHfmIMf_egID{color:var(--color-error-action-text)}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/UsersMultiProfileModal/UsersMultiProfileModal.module.scss"],"names":[],"mappings":"AAAA,4DACE,WAAA,CAEA,oGACE,gBAAA,CACA,wBAAA,CACA,iCAAA,CAGF,yFACE,oCAAA","sourcesContent":[".parameters-employees-modal-container {\n  width: 550px;\n\n  .appealLimitValue {\n    margin-left: 10px;\n    text-transform: lowercase;\n    color: var(--color-primary-action);\n  }\n\n  .error {\n    color: var(--color-error-action-text);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var parametersEmployeesModalContainer = "parameters-employees-modal-container--YJ4rKwtw6KUVK5WFgitP";
export var appealLimitValue = "appealLimitValue--dziCLx16ADsWKJ8kmchq";
export var error = "error--NQWNBbCXiHfmIMf_egID";
export default ___CSS_LOADER_EXPORT___;
