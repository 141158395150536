import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";

import {
  setSubstatus,
  setUserStatusPending,
  sendUserSubstatus
} from "../Store/OnlineToggle.reducer";

import { saveOfflineTimerToStorage } from "../Utils";

function* sendUserSubstatusSaga({ payload }) {
  const { substatusId } = payload;
  yield put(setUserStatusPending(true));

  try {
    const response = yield httpRequest(
      "PUT",
      `bff/user/offline-substatus/${substatusId}`
    );

    if (response?.success) {
      const startDate = Date.now();
      yield saveOfflineTimerToStorage(substatusId, startDate);
      yield put(setSubstatus({ startDate, substatus: substatusId }));

      const informer = new Informer("Подстатус изменён", 1500);
      informer.show();
    }
  } catch (error) {
    const informer = new Informer("Произошла ошибка. Попробуйте еще раз", 1500);
    informer.show();
  } finally {
    yield put(setUserStatusPending(false));
  }
}

export default function* () {
  yield takeEvery([sendUserSubstatus], sendUserSubstatusSaga);
}
