// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--e0RVYSF6hr6aR0Jn2Yx7{width:600px;padding:25px 20px}.modal__description--DLdLePGNenBSS87bchhu{margin-bottom:20px}.modal__button-group--TNDX0knjavRTIDQJK_E6{display:flex;flex-direction:row;margin-top:20px;justify-content:space-around}.header--DPFUpuw1aKc1Xb48rMLg{color:var(--color-text-default);font-size:var(--font-size-header);font-weight:bold;padding-bottom:20px;border-bottom:1px solid rgba(0,0,0,.15);margin-bottom:15px}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/CategoryForRepliesModal/Components/CategoryForRepliesModal/CategoryForRepliesModal.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,iBAAA,CAEA,0CACE,kBAAA,CAGF,2CACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,4BAAA,CAIJ,8BACE,+BAAA,CACA,iCAAA,CACA,gBAAA,CACA,mBAAA,CACA,uCAAA,CACA,kBAAA","sourcesContent":[".modal {\n  width: 600px;\n  padding: 25px 20px;\n\n  &__description {\n    margin-bottom: 20px;\n  }\n\n  &__button-group {\n    display: flex;\n    flex-direction: row;\n    margin-top: 20px;\n    justify-content: space-around;\n  }\n}\n\n.header {\n  color: var(--color-text-default);\n  font-size: var(--font-size-header);\n  font-weight: bold;\n  padding-bottom: 20px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "modal--e0RVYSF6hr6aR0Jn2Yx7";
export var modalDescription = "modal__description--DLdLePGNenBSS87bchhu";
export var modalButtonGroup = "modal__button-group--TNDX0knjavRTIDQJK_E6";
export var header = "header--DPFUpuw1aKc1Xb48rMLg";
export default ___CSS_LOADER_EXPORT___;
