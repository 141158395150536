import React, { FC } from "react";
import {
  InputLabel as MuiInputLabel,
  FormControlProps as MuiFormControlProps,
  SxProps,
  Theme
} from "@mui/material";

import ActionText from "../ActionText";

export type labelCaseType = "none" | "capitalize" | "uppercase" | "lowercase";
type labelPositionType =
  | "static"
  | "relative"
  | "absolute"
  | "sticky"
  | "fixed";

/**
 * Модель свойств компонента обертки.
 *
 * @prop {JSX.Element | string} [label] Label компонента FormField.
 * @prop {boolean} [labelCaseStyle] Переключатель регистра текста label.
 * @prop {boolean} [labelPosition] Переключатель позиционирования текста label.
 * @prop {boolean} [required] Признак необходимости заполнения.
 * @prop {boolean} [disabled] Признак доступности компонента для редактирования.
 * @prop {string} [actionText] Подсказка.
 * @prop {boolean} [isError] Признак наличия ошибки.
 * @prop {string} [inputId] id привязки к компоненту-потомку.
 * @prop {JSX.Element[]} children Дочерние компоненты.
 * @prop {object} [extraSX] Кастомные стили на обертку.
 */
interface IFormFieldProps extends MuiFormControlProps {
  label?: JSX.Element | string;
  labelCaseStyle?: labelCaseType;
  labelPosition?: labelPositionType;
  required?: boolean;
  disabled?: boolean;
  actionText?: string;
  isError?: boolean;
  inputId?: string;
  children: JSX.Element | JSX.Element[];
  extraSX?: SxProps<Theme>;
}

/**
 * Обертка для поля с наименованием.
 */
const FormField: FC<IFormFieldProps> = ({
  label,
  labelCaseStyle = "none",
  labelPosition = "relative",
  required = false,
  disabled,
  actionText,
  isError = false,
  inputId,
  children,
  extraSX = {},
  ...props
}): JSX.Element => {
  return (
    <>
      {label && typeof label === "string" && (
        // todo: параметр shrink={true} - костыль для обхода директивы отключения placeholder у Input,
        // являющегося потомком FormControl ["label[data-shrink=false] + .MuiInputBase-formControl &"]
        <MuiInputLabel
          htmlFor={inputId ? `input-${inputId}` : null}
          required={required}
          disableAnimation
          shrink={true}
          sx={[
            { position: labelPosition, display: "block" },
            labelCaseStyle !== "none" && { textTransform: labelCaseStyle }
          ]}
        >
          {`${label}:`}
        </MuiInputLabel>
      )}
      {typeof label !== "string" && label}

      <>{children}</>

      {actionText && (
        <ActionText
          id={inputId ? `action-text-${inputId}` : null}
          isError={isError}
          actionText={actionText}
        />
      )}
    </>
  );
};

export default FormField;
