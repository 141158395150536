import { createAction } from "@reduxjs/toolkit";
import { CHANNELS_DATA_STATE_KEY } from "../consts";
import {
  setFulfilled,
  setPending,
  setRejected
} from "../../../ReactFeatures/Common/ActionCreators/LoadingData.actionCreators";

export const getChannelsData = createAction("GET_CHANNEL_DATA");
export const channelsDataSetPending = setPending(
  "SET_CHANNEL_DATA",
  CHANNELS_DATA_STATE_KEY
);
export const channelsDataSetFullfilled = setFulfilled(
  "SET_CHANNEL_DATA",
  CHANNELS_DATA_STATE_KEY
);
export const channelsDataSetRejected = setRejected(
  "SET_CHANNEL_DATA",
  CHANNELS_DATA_STATE_KEY
);
