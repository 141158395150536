import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";

import { computedFields } from "../../../ReactFeatures/ThematicModal/Services/ThematicModal.service";

export const getClassifiers = (state: StoreState.State) => state.Classifiers;
export const getClassifiersList = (state) => state.Classifiers.classifiers;

export const getThemesFields = (state) => state.Classifiers.fields || [];

export const getIsFieldLoading = createSelector(getClassifiers, (state) => {
  return !isEmpty(state.loading);
});

export const getComputedFields = createSelector(
  getThemesFields,
  (themeFields) =>
    !isEmpty(themeFields) ? computedFields(themeFields, true) : null
);

export const getClassifierFields = createSelector(getClassifiers, (state) => {
  return state.classifierFields.map((f) => {
    return {
      ...f,
      values: state.classifierFieldValuesList?.[f.id] || null
    };
  });
});
