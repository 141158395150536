/**
 * Преобразование времени из формата ISO-8601 в timestamp
 * @param {string} isoTime интервал в iso
 * @returns {number} интервал в timestamp
 */
export function isoIntervalToTimestamp(isoTime: string): number {
  let resultTimestamp = 0;
  if (!isoTime) {
    return resultTimestamp;
  }
  const matches = isoTime.match(
    /PT((?<d>[0-9]+)D)?((?<h>[0-9]+)H)?((?<m>[0-9]+)M)?((?<s>[0-9]+)S)?/i
  );
  if (matches?.groups?.d)
    resultTimestamp += Number(matches.groups.d) * 24 * 60 * 60;
  if (matches?.groups?.h) resultTimestamp += Number(matches.groups.h) * 60 * 60;
  if (matches?.groups?.m) resultTimestamp += Number(matches.groups.m) * 60;
  if (matches?.groups?.s) resultTimestamp += Number(matches.groups.s);
  return resultTimestamp;
}

/**
 * Преобразование даты и времени из формата ISO в timestamp
 * @param {string} isoTime время в iso
 * @returns {number} время в timestamp
 */
export function isoToTimestamp(isoTime: string): number {
  if (
    !isoTime ||
    !/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(
      isoTime
    )
  ) {
    return 0;
  }
  const dateFormate = new Date(isoTime.replace(/\[.*\]/, "")).toString();
  const resultTimestamp = Date.parse(dateFormate);

  return resultTimestamp / 1000;
}
