import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";

const linkTypes = ["DROPDOWN", "CHECKBOX", "RADIO"];

export const ClassifierLinkForm = (state: StoreState.State) => {
  return state.ClassifierLinkForm;
};

export const getLoadingStatus = createSelector(ClassifierLinkForm, (state) => {
  return !isEmpty(state.loading);
});

const getFieldId = (state, fieldId) => fieldId;

export const fieldValuesList = createSelector(
  [ClassifierLinkForm, getFieldId],
  (state, fieldId) => {
    const fields = [...state.fieldValuesList];
    const indexOfTargetField = fields.findIndex(
      (f) => f.id.toString() === fieldId
    );

    fields.splice(indexOfTargetField);

    return (
      fields
        .filter((f) => linkTypes.includes(f.type))
        .map((f) => ({
          ...f,
          id: f.id.toString(),
          values:
            f.values?.map((v) => ({
              value: v.id.toString(),
              label: v.name
            })) || []
        })) || []
    );
  }
);
