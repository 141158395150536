import { RolesTypes } from "../Roles.d";

const Roles: RolesTypes.State = {
  roles: null,
  rights: null,
  errorMessage: null,
  status: {
    dataSendHandling: false
  }
};

export default Roles;
