export const setRecentAppeals = (id: string) => {
  const recentAppeals = window.localStorage.getItem("recentAppeals");

  if (recentAppeals) {
    const result: string[] = JSON.parse(recentAppeals);
    const i = result.indexOf(id);

    if (i > -1) {
      result.splice(i, 1);
    }

    result.unshift(id);

    if (result.length > 10) {
      result.splice(10, result.length - 10);
    }

    window.localStorage.setItem("recentAppeals", JSON.stringify(result));
  } else {
    window.localStorage.setItem("recentAppeals", JSON.stringify([id]));
  }
};

export const getRecentAppeals = (): string[] => {
  const recentAppeals = window.localStorage.getItem("recentAppeals");
  return recentAppeals ? JSON.parse(recentAppeals) : [];
};
