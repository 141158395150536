import React, { FC } from "react";

import { Button } from "@omnichat/arm_ui_kit";

import AutoLogoutSetting from "./AutoLogoutSetting";
import { useAutoLogoutSettings } from "./AutoLogoutSetting.hooks";

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {}

type AutoLogoutSettingsProps = IOwnProps;

const AutoLogoutSettings: FC<AutoLogoutSettingsProps> = () => {
  const {
    loading,
    newSetting,
    handleNewSetting,
    substatuses,
    settingsList,
    handleSave,
    handleDel
  } = useAutoLogoutSettings();

  return (
    <>
      {(newSetting ? [...settingsList, newSetting] : settingsList).map(
        (item, i) => (
          <AutoLogoutSetting
            key={i}
            loading={loading}
            setting={item}
            substatuses={substatuses}
            handleSave={handleSave}
            handleDel={handleDel}
          />
        )
      )}

      {!newSetting && (
        <Button
          disabled={loading}
          type="bare"
          width="18px"
          height="18px"
          text="Добавить настройку автоматического выхода из учётной записи"
          onClick={() => handleNewSetting()}
        />
      )}
    </>
  );
};

export default AutoLogoutSettings;
