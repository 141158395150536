import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { takeEvery } from "redux-saga/effects";
import Informer from "../../../arm/Wrappers/Informer";
import { transferAllAppeals } from "../Store/OnlineToggle.reducer";
import { isEmpty } from "@omnichat/arm_ui_kit";

function* transferAppealsSaga({ payload }) {
  const errorText = "Произошла ошибка при передаче всех обращений.";

  try {
    const result = yield httpRequest(
      "DELETE",
      "bff/user/transfer-all-appeals/"
    );

    if (result?.success) {
      payload.postAction && payload.postAction();
      core.storage.set("msg_appeal_count", 0);
    } else {
      payload.onError && payload.onError();
      console.log("*** Ошибка при передаче всех обращений ***", result.error);
      const informer = new Informer(errorText, 1500);
      informer.show();
    }
  } catch (error) {
    payload.onError && payload.onError();
    console.log("*** Ошибка при передаче всех обращений ***", error);
  }
}

export default function* () {
  yield takeEvery([transferAllAppeals], transferAppealsSaga);
}
