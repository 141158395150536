import React, { FC } from "react";

import { isEmpty, ScrollBox } from "@omnichat/arm_ui_kit";
import GroupsIcon from "@mui/icons-material/Groups";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import CloseIcon from "@mui/icons-material/Close";

import hasUserRight from "../../../../Utils/hasUserRight";
import {
  Input,
  Checkbox,
  DropdownButton,
  MenuOption
} from "../../../../Components";
import { SkillGroupData, UserData } from "../../UserGroupsManager.d";
import UserItem from "./UserItem";
import { useRefScrollReturn } from "../../../../ReactFeatures/Common/Hooks/useRefScrollReturn";
import { ALL, DELETED } from "../../consts";

import * as s from "./styles.module.scss";

interface UserListProps {
  all: boolean;
  currentSkillGroup: SkillGroupData;
  currentUserPage: number;
  users?: UserData[];
  onSelectUser: (id?: string) => void;
  searchText: string;
  onSearch: (query?: string) => void;
  onEditCommonParams: () => void;
  onPassRestore: () => void;
  onArchive: () => void;
  onEdit: (id?: string) => void;
  onNextPage: () => void;
}

const UserList: FC<UserListProps> = ({
  all,
  currentSkillGroup,
  currentUserPage,
  users = [],
  onSelectUser,
  searchText,
  onSearch,
  onEditCommonParams,
  onPassRestore,
  onArchive,
  onEdit,
  onNextPage
}) => {
  const scrollReferenses = useRefScrollReturn({
    scrollCondition: currentUserPage === 0,
    deps: currentUserPage
  });

  const usersCheckedCount = users?.filter((i) => i.checked).length;

  const captionAllCheckbox = () => {
    if (all) return "Выбрано всё";
    if (!usersCheckedCount) return "Выбрать всех";
    return `Выбрано: ${usersCheckedCount}`;
  };

  const handleSelectAll = () => onSelectUser();

  const handleNextPage = () => onNextPage();

  let usersInArhive = currentSkillGroup?.id === DELETED;
  let usersInCommonGroup = currentSkillGroup?.id === ALL;

  const menuItems = () => {
    const itemsBase = [
      {
        id: "editCommonParams",
        content: (
          <MenuOption
            text="Параметры сотрудников"
            muiIconParams={{ Icon: GroupsIcon }}
          />
        ),
        onClick: onEditCommonParams
      },
      { divider: true },
      {
        id: "passwordRestore",
        content: (
          <MenuOption
            text="Восстановить пароль"
            muiIconParams={{ Icon: VpnKeyIcon }}
          />
        ),
        onClick: onPassRestore
      }
    ];

    const itemToArchive = [
      { divider: true },
      {
        id: "moveToArchive",
        content: (
          <MenuOption
            text="Перенести в архив"
            muiIconParams={{ Icon: CloseIcon }}
          />
        ),
        onClick: onArchive
      }
    ];

    return usersInArhive || (all && usersInCommonGroup)
      ? itemsBase
      : [...itemsBase, ...itemToArchive];
  };

  return (
    <div className={s["usersWrap"]}>
      <div className={s["usersHeader"]}>
        <div>
          <h4 className={s["usersTitle"]}>{currentSkillGroup?.name ?? ""}</h4>
        </div>

        <div>
          <Input placeholder="Поиск" onChange={onSearch} value={searchText} />
        </div>
        {hasUserRight("create_users") && (
          <div className={s["usersControl"]}>
            {SHOW_ALL_BUTTON === "1" ? (
              <Checkbox
                caption={captionAllCheckbox()}
                captionPosition="end"
                checked={all}
                onChange={handleSelectAll}
                disabled={!users?.length}
              />
            ) : (
              <div></div>
            )}
            <DropdownButton
              disabled={!(usersCheckedCount && hasUserRight("create_users"))}
              text="Действия"
              anchorTransformPosition="right"
              menuItems={menuItems()}
              extraSX={{
                button: {
                  btnWrap: {
                    height: "26px",
                    width: "102px"
                  }
                }
              }}
            />
          </div>
        )}
      </div>

      {isEmpty(users) && (
        <div className={s["usersEmpty"]}>{"Нет совпадений"}</div>
      )}

      {!isEmpty(users) && (
        <div className={s["usersList"]}>
          <ScrollBox
            referens={scrollReferenses}
            autoHeight
            hasScrollControlBackground
            onPaginationBottom={handleNextPage}
          >
            <div
              className={s["usersListContentBlock"]}
              style={{
                marginTop: hasUserRight("create_users") ? "135px" : "95px"
              }}
            >
              {users.map((user, i) => (
                <UserItem
                  key={`${i}_${user.id}`}
                  user={user}
                  onEdit={onEdit}
                  onSelect={onSelectUser}
                  isArhive={usersInArhive}
                />
              ))}
            </div>
          </ScrollBox>
        </div>
      )}
    </div>
  );
};

export default UserList;
