import { useMemo } from "react";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import initialState from "../../../ReactCore/store/initialState";
import { rightsDTO, sendRoleDTO, Role } from "../Roles";

const NAMESPACE = "roles";
export const GET_ROLES = `${NAMESPACE}/GET_ROLES`;
export const SEND_EDITED_ROLE = `${NAMESPACE}/SEND_EDITED_ROLE`;
export const SEND_NEW_ROLE = `${NAMESPACE}/SEND_NEW_ROLE`;
export const DELETE_ROLE = `${NAMESPACE}/DELETE_ROLE`;
export const GET_RIGHTS = `${NAMESPACE}/GET_RIGHTS`;

export const getRoles = createAction(GET_ROLES);

export const sendEditedRole = createAction<{
  roleId: number;
  formData: { role?: sendRoleDTO; rights: rightsDTO };
  callback: Function;
}>(SEND_EDITED_ROLE);

export const sendNewRole = createAction<{
  formData: { role: sendRoleDTO; rights: rightsDTO };
  callback: Function;
}>(SEND_NEW_ROLE);

export const deleteRole = createAction<{
  roleId: number;
  callback: Function;
}>(DELETE_ROLE);

export const getRights = createAction<{
  roleId: number;
}>(GET_RIGHTS);

const RolesSlice = createSlice({
  name: "roles",
  initialState: initialState.Roles,
  reducers: {
    setRoles(state, { payload }: PayloadAction<Role[]>) {
      state.roles = payload ?? null;
    },
    setRights(state, { payload }: PayloadAction<rightsDTO>) {
      state.rights = payload ?? null;
    },
    clearRolesAndRights(state) {
      state = { ...initialState.Roles };
    },
    setSendDataHandling(state, { payload }: PayloadAction<boolean>) {
      state.status.dataSendHandling = payload;
    },
    setErrorMessage(state, { payload }: PayloadAction<string>) {
      state.errorMessage = payload;
    }
  }
});

export const actionRoles = {
  ...RolesSlice.actions,
  getRoles,
  sendEditedRole,
  sendNewRole,
  getRights,
  deleteRole
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionRoles, dispatch), [dispatch]);
};

export default RolesSlice.reducer;
