const skillGroupModal = {
  status: {
    dataSendHandling: false,
    dictionaryLoading: false
  },
  errorMessage: "",

  dictionaries: null,
  formData: null
};

export default skillGroupModal;
