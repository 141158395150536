import React, { PureComponent } from "react";
import { DEFAULT_IMAGE_VIEWER_Z_INDEX } from "./Consts";
import styled from "styled-components";

import ImageViewer from "../../Components/ImageViewer";

export const ImageViewerWrap = styled.div<{
  background?: string;
}>`
  & .react-viewer-icon {
    vertical-align: middle;
    line-height: 1.5;
    font-size: 15px;
  }
  & .react-viewer-mask {
    background-color: ${({ background }) =>
      background ? background : "rgba(255, 255, 255, 0.6)"};
  }
`;

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {HTMLElement} viewerContainer Контэйнер, для монтирования компонента просмотра изображения.
 * @prop {HTMLElement} onClose Колбэк для вызова при закрытии компонента просмотра изображения.
 */
interface IImageViewerConstructorProps {
  viewerContainer: HTMLElement;
  onClose: () => void;
}

/**
 * Интерфейс состояния компонента.
 *
 * @prop {boolean} isImageViewerShown Флаг, который показывает, отображается ли компонент для просмотра изображений.
 * @prop {string} imageSource Ссылка на изображение для передачи в компонент просмотра изображений.
 * @prop {number} zIndex Для прокидывания в компонент просмотра.
 */
interface IState {
  isImageViewerShown: boolean;
  imageSource: string;
  zIndex: number;
  imageViewerKey: number;
}

/**
 * Консруктор, содержащий в себе компнонент для просмотра изображения.
 */
class ImageViewerConstructor extends PureComponent<
  IImageViewerConstructorProps,
  IState
> {
  constructor(props) {
    super(props);

    this.state = {
      isImageViewerShown: false,
      imageSource: null,
      zIndex: DEFAULT_IMAGE_VIEWER_Z_INDEX,
      imageViewerKey: 1
    };
  }

  /**
   * Обработчик отображения компонента для просмотра изображения.
   *
   * @param {string} imageSrc Ссылка на изображение.
   * @param {number} zIndex zIndex для прокидывания в компонент просмотра изображений.
   */
  handleShowImageViewer = (
    imageSrc: string,
    zIndex: number = DEFAULT_IMAGE_VIEWER_Z_INDEX,
    background: string
  ) => {
    imageSrc &&
      this.setState({
        imageSource: imageSrc,
        isImageViewerShown: true,
        zIndex
      });
  };

  /**
   * Обработчик закрытия компонента для просмотра изображения.
   */
  handleCloseImageViewer = () => {
    const { onClose } = this.props;

    this.setState(
      {
        isImageViewerShown: false,
        imageSource: null
      },
      onClose
    );
  };

  rerenderImageViewer = () => {
    this.setState({ imageViewerKey: this.state.imageViewerKey + 1 });
  };

  handleMouseUp = () => {
    const { viewerContainer } = this.props;

    const image = viewerContainer.querySelector(".react-viewer-image");
    if (!image) return;

    const { top, height, width, right } = image.getBoundingClientRect();

    const souldRerenderImageViewer =
      top + height < 0 ||
      viewerContainer.clientHeight < top ||
      viewerContainer.clientWidth < right - width ||
      right < 0;

    if (souldRerenderImageViewer) {
      this.rerenderImageViewer();
    }
  };

  componentDidMount() {
    document.addEventListener("mouseup", this.handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleMouseUp);
  }

  render() {
    const { viewerContainer } = this.props;
    const {
      imageSource,
      imageViewerKey,
      isImageViewerShown,
      zIndex
    } = this.state;

    return (
      <ImageViewer
        key={imageViewerKey}
        imageSrc={imageSource}
        onClose={this.handleCloseImageViewer}
        isViewerShown={isImageViewerShown}
        zIndex={zIndex}
        container={viewerContainer}
      />
    );
  }
}

export default ImageViewerConstructor;
