import { isEmpty } from "@omnichat/arm_ui_kit";

import { hash53b } from "../../Utils/hash53b.helper";

import { ISelectOption, ISelectValue } from "./Select.d";

export const getPreparedValue = (
  isMulti: boolean,
  selected: ISelectOption[]
): ISelectValue[] | ISelectValue =>
  isMulti ? selected.map(({ value }) => value) : selected[0]?.value;

export const getPreparedOptions = (
  isMulti: boolean,
  options: ISelectOption[]
) =>
  isMulti
    ? options.filter((option) => !selectedValue2SelectedOption[option.value])
    : options;

export const getPreparedValueToOptions = (
  options: ISelectOption[]
): Record<ISelectOption["value"], ISelectOption> =>
  options.reduce(
    (values, option) => ({ ...values, [option.value]: option }),
    {}
  );

const selectedValue2SelectedOption = (
  selected: ISelectOption[]
): Record<ISelectOption["value"], ISelectOption> => {
  return selected.reduce(
    (values, selected) => ({ ...values, [selected.value]: selected }),
    {}
  );
};

export const getHashId = ({ actionText, label, placeholder }) => {
  return hash53b(`${actionText || ""} + ${label || ""} + ${placeholder || ""}`);
};

export const hasValue = (value) => {
  return (typeof value === "number" && value >= 0) || !isEmpty(value);
};
