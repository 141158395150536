import React from "react";

import { Button } from "@omnichat/arm_ui_kit";

import ModalWindow from "../../../../Components/ModalWindow";
import * as s from "./ConfirmModal.module.scss";

interface ConforimModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description: string | JSX.Element;
}

const ConforimModal: React.FC<ConforimModalProps> = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
}) => {
  return (
    <ModalWindow open={open} onClose={onCancel}>
      <div className={s["modal"]}>
        <header className={s["header"]}>
          <h6>{title}</h6>
        </header>
        <div className={s["description"]}>
          <p>{description}</p>
        </div>
        <div className={s["buttons"]}>
          <Button
            type="default"
            iconColor="white"
            extraClass={[s["button"]]}
            iconName="cross"
            theme="red"
            onClick={onCancel}
            text="Нет, отменить"
          />
          <Button
            type="default"
            iconColor="white"
            className={[s["button"]]}
            iconName="check"
            theme="green"
            onClick={onConfirm}
            text="Да, продолжить"
          />
        </div>
      </div>
    </ModalWindow>
  );
};

export default ConforimModal;
