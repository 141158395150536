import * as Yup from "yup";

export const schema = Yup.object({
  appealLimit: Yup.number().nullable(),
  userAreaId: Yup.number().nullable(),
  userOrganizationId: Yup.number().nullable(),
  userTerritoryId: Yup.number().nullable(),
  skillGroupIds: Yup.array().nullable()
});

export const defaultValues = {
  appealLimit: null,
  userAreaId: null,
  userOrganizationId: null,
  userTerritoryId: null,
  skillGroupIds: []
};

export interface IFormData extends Yup.InferType<typeof schema> {}
