import * as React from "react";
import * as ReactDom from "react-dom";
import { MemoryRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "../../ReactCore";
import Classifiers from "./Classifiers.provider";
import ThemeProvider from "../../Components/ThemeProvider";

const REACT_ROOT_SELECTOR = "#settings_classifiers";

export default function initClassiersSettings() {
  // @ts-ignore
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  ReactDom.render(
    <ThemeProvider>
      <MemoryRouter>
        <Provider store={store}>
          <Classifiers />
        </Provider>
      </MemoryRouter>
    </ThemeProvider>,
    selector
  );
}
