import { takeEvery } from "redux-saga/effects";
import { SEND_DATA } from "../consts";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

interface IReplyData {
  title: string;
  body: string;
  category: {
    value: number;
    label: string;
  };
  regions: number[];
  attachments: string[];
  keyShortcut: string | null;
  hotKeyCodes: string | null;
}

function* sendRepliesData(action) {
  const data: IReplyData = action.payload.data;
  const id = action.payload.id;
  const close = action.payload.close;

  const response = yield httpRequest("POST", "bff/settings/replies", {
    body: {
      id: id,
      attachmentsUrl: data.attachments,
      category: {
        id: data.category.value
      },
      regionsIds: data.regions,
      text: data.body,
      title: data.title,
      hotkeyCode: data.hotKeyCodes,
      hotkeyValue: data.keyShortcut
    }
  });

  if (response?.success) {
    close();
    location.reload();
  }
}

export default function*() {
  yield takeEvery(SEND_DATA, sendRepliesData);
}
