import {
  EAppearanceControllerShape,
  EAppearanceChatShape,
  EAppearanceThemes,
  TReducerState
} from "./Components/ChannelSettingsModalReducer";

export const NAMESPACE = "Channels";
export const CHANNELS_DATA_STATE_KEY = "channels";

export const fileExtensions = EXTENSIONS_ALLOWED_BY_STORAGE.map((v) => ({
  value: v,
  label: v
}));

export const timeZones = [
  { value: "default", label: "Не указано" },
  { value: "item1", label: "Facebook" },
  { value: "item2", label: "Telegram" },
  { value: "item3", label: "Viber" },
  { value: "item4", label: "Веб-чат #1" },
  { value: "item5", label: "Веб-чат #2" },
  { value: "item6", label: "Вконтакте" },
  { value: "item7", label: "Мобильное приложение Android" },
  { value: "item8", label: "Мобильное приложение iOS" },
  { value: "item9", label: "Тестовый канал веб-чат" }
];

export const channelTypes = [
  { id: 1, label: "Веб-чат" },
  { id: 2, label: "Мобильное приложение" }
];
export const channels = [
  { id: 1, label: "Веб-чат 1" },
  { id: 2, label: "Веб-чат 2" },
  { id: 3, label: "Веб-чат 3" },
  { id: 4, label: "Веб-чат 4" },
  { id: 5, label: "Веб-чат 5" },
  { id: 6, label: "Веб-чат 6" },
  { id: 7, label: "Веб-чат 7" },
  { id: 8, label: "Веб-чат 8" }
];

export enum ChannelTypesIds {
  WebChat = 9,
  Mobile = 10,
  Email = 11
}

/**
 * Настройки по умолчанию для канала, если не придут с бэка.
 */
export const defaultSettings: TReducerState = {
  enabled: true,
  fallbackOperatorAvatar: null,
  bar: {
    triggerMessage: {
      enabled: false,
      delay: 0,
      text: undefined
    },
    icon: null,
    text: {
      enabled: false,
      value: undefined
    }
  },

  appearance: {
    theme: EAppearanceThemes.white, //black
    controllerShape: EAppearanceControllerShape.rounded, //geometric, circular
    chatShape: EAppearanceChatShape.rounded, // square
    colors: {
      bubble: null,
      chat: null,
      messageInput: null,
      font: null,
      triggerButton: null
    }
  },

  files: {
    limit: 0, //0=unlimited
    size: 5242880, //5 mb
    allowedTypes: [
      "txt",
      "gif",
      "jpg",
      "jpeg",
      "png",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "pdf"
    ]
  },

  prechats: {
    enabled: true,
    fullName: {
      enabled: true,
      required: false,
      label: "Имя"
    },
    phone: {
      enabled: true,
      required: false,
      label: "Номер телефона"
    },
    message: {
      enabled: true,
      required: true
    },
    skillGroup: {
      enabled: false,
      label: "Отдел",
      required: false
    }
  },

  schedule: {
    from: 0,
    to: 24,
    timeZone: 1,
    showWidgetOnlyIfAgentsOnline: false
  }
};

export const timeZonesOptions = [
  { value: 10, label: "Владивосток (GMT+10)" },
  { value: 8, label: "Иркутск (GMT+8)" },
  { value: 2, label: "Калининград (GMT+2)" },
  { value: 12, label: "Камчатский край (GMT+12)" },
  { value: 11, label: "Магадан (GMT+11)" },
  { value: 3, label: "Москва (GMT+3)" },
  { value: 7, label: "Новосибирск (GMT+7)" },
  { value: 4, label: "Самара (GMT+4)" },
  { value: 11, label: "Сахалин (GMT+11)" },
  { value: 9, label: "Якутск (GMT+9)" }
];

export const colorsByThemeDefault = {
  black: {
    bubble: "#505765",
    chat: "#3d4454",
    font: "#f4f4f5",
    font2: "#b5b7c0",
    messageInput: "#3c434f",
    triggerButton: "#0090db"
  },
  white: {
    bubble: "#cee6f2",
    chat: "#ffffff",
    font: "#000000",
    font2: "#afb2b7",
    messageInput: "#f4f4f5",
    triggerButton: "#0090db"
  }
};
