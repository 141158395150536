const usersProfileModal = {
  photo: undefined,

  status: {
    dataSendHandling: false,
    dataLoadHandling: false
  },

  formData: null,

  errorMessage: null
};

export default usersProfileModal;
