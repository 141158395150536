import { fork } from "redux-saga/effects";

import getClassifierCatalogs from "./getClassifierCatalogs.saga";
import sendClassifierSaga from "./sendClassifier.saga";
import getClassifierDataSaga from "./getClassifierData.saga";
import removeClassifierSaga from "./removeClassifier.saga";

export default function*() {
  yield fork(getClassifierCatalogs);
  yield fork(sendClassifierSaga);
  yield fork(getClassifierDataSaga);
  yield fork(removeClassifierSaga);
}
