import React, { FC } from "react";

import { Grid } from "@mui/material";
import { Button, SwitcherContainer, FieldTitle } from "@omnichat/arm_ui_kit";

import { Radio, Slider, SubTitle, FormField } from "../../../../Components";

import * as commonStyles from "../../../../styles/common.module.scss";
import * as styles from "./SkillGroupCapacityModal.module.scss";

/**
 * Состояние формы
 * @prop {number} skillId Id редактируемой группы
 * @prop {boolean} queue наличие очереди обращений на группе
 * @prop {number} queueLimit настройка колличества обращений в очереди на группе
 * @prop {number} appealLimit настройка количества обращений поступающих на каждого оператора группы
 */
interface IFormData {
  skillId: number;
  queue?: boolean;
  queueLimit?: number;
  appealLimit?: number;
}

/**
 * Свойства компонента SkillGroupCapacityModal.
 * @prop {string} skillGroupName имя редактируемой группы
 * @prop {object} formData изначальные состояние настроек на группе
 * @prop {Function} onConfirmHandler обработчик на подтверждение внесеных настроек
 * @prop {Function} onChange обработчик на изменние каждой настройки
 */
interface ISkillGroupCapacityModalProps {
  skillGroupName?: string;
  formData: IFormData;
  onConfirmHandler: () => void;
  onChange: ({}) => void;
}

const SkillGroupCapacityModal: FC<ISkillGroupCapacityModalProps> = ({
  skillGroupName,
  formData,
  onConfirmHandler = () => {},
  onChange = () => {}
}) => {
  const { queue = false, queueLimit = 0, appealLimit = 0 } = formData;

  const RadioGroup = SwitcherContainer(Radio);

  const radioGroupProps = {
    name: "queue",
    extraSX: {
      alignSelf: "flex-start",
      mt: "2px"
    }
  };

  const onChangeField = (
    fieldName: string,
    fieldData: boolean | number
  ): void => {
    onChange({
      ...formData,
      [fieldName]: fieldData
    });
  };

  return (
    <div id="SkillGroupCapacityModal" className={styles["modal"]}>
      <div className={commonStyles["defaultModalHeader"]}>
        Управление нагрузкой на группу
      </div>
      <div className={styles["modalContentWrap"]}>
        {skillGroupName && (
          <div className={styles["modalContentField"]}>
            <span className={styles["modalContentFieldLabel"]}>Группа:</span>
            {skillGroupName}
          </div>
        )}

        <div className={styles["modalContentField"]}>
          <FormField label="Очередь обращений">
            <>
              <RadioGroup
                {...radioGroupProps}
                caption="отсутствует (новые обращения будут пропущены)"
                hint='Количество пропущенных обращений отражено в графике "Пропущенные обращения"'
                checked={!queue}
                onClick={() => onChangeField("queue", false)}
              />
              <RadioGroup
                {...radioGroupProps}
                caption="есть (новые обращения поступят в очередь)"
                hint="Обращения будут поступать в очередь, если агенты группы заняты"
                checked={queue}
                onClick={() => onChangeField("queue", true)}
              />
            </>
          </FormField>

          {queue && (
            <Grid item alignItems="center" xs={12} mt={1}>
              <FormField
                inputId="queueLimit"
                label={
                  <FieldTitle label={"Ограничение очереди"}>
                    <div className={styles["queueLimitValue"]}>
                      {queueLimit > 0 ? queueLimit : "нет ограничений"}
                    </div>
                  </FieldTitle>
                }
              >
                <Slider
                  min={0}
                  max={100}
                  value={queueLimit ?? 0}
                  onChange={(_, value: number) =>
                    onChangeField("queueLimit", value)
                  }
                />
              </FormField>
            </Grid>
          )}
        </div>

        <div className={styles["modalContentField"]}>
          <SubTitle caption="Нагрузка на операторов" />
          <Grid item alignItems="center" xs={12}>
            <FormField
              inputId="appealLimit"
              label={
                <>
                  <FieldTitle label={"Ограничение поступающих обращений"}>
                    <div className={styles["appealLimitValue"]}>
                      {appealLimit > 0 ? appealLimit : "Не установлено"}
                    </div>
                  </FieldTitle>
                  <div className={styles["appealLimitSubscription"]}>
                    Определять нагрузку персонально для каждого оператора можно
                    в разделе "Настройки {">"} Сотрудники"
                  </div>
                </>
              }
            >
              <Slider
                min={0}
                max={100}
                value={appealLimit ?? 0}
                onChange={(_, value: number) =>
                  onChangeField("appealLimit", value)
                }
              />
            </FormField>
          </Grid>
          Важно! Изменение затронет всех участников группы и для каждого из них
          будет влиять на общее количество обращений, поступающих со всех групп
        </div>
      </div>

      <div className={commonStyles["defaultModalControl"]}>
        <Button
          type="default"
          theme="green"
          text="Сохранить"
          onClick={() => onConfirmHandler()}
        />
      </div>
    </div>
  );
};

export default SkillGroupCapacityModal;
