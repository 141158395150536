import React, { FC } from "react";
import { Box, Theme } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { Button, Input } from "../../../../Components";

const ArrowBeforeIcon: FC = () => <NavigateBeforeIcon fontSize="large" />;

interface HeaderProps {
  onGoBack: () => void;
  onInputQuery: (v: string) => void;
  query: string;
}

const AgentListHeader: FC<HeaderProps> = ({
  onGoBack,
  onInputQuery,
  query
}) => {
  const btnSx = {
    btnWrap: ({ palette }: Theme) => ({
      "&.MuiButtonBase-root": {
        justifyContent: "flex-start",
        padding: "3px 0",
        marginBottom: "20px",
        backgroundColor: palette.primary.hover
      }
    }),
    text: {
      "&.MuiTypography-root.MuiTypography-body1": {
        marginLeft: 0,
        fontWeight: "700",
        lineHeight: "1.75"
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          boxSizing: "border-box",
          marginTop: "9px",
          padding: "0 16px 0 10px"
        }}
      >
        <Button
          id="goBackButton"
          text="Показать все группы"
          type="bare"
          theme="blue"
          onClick={onGoBack}
          IconCustom={ArrowBeforeIcon}
          variant="manage"
          extraSX={btnSx}
        />
      </Box>

      <Input
        placeholder="Введите фамилию или имя агента"
        value={query}
        onChange={onInputQuery}
        extraSX={{ inputBox: { margin: "0 16px 10px 10px" } }}
      />
    </>
  );
};

export default AgentListHeader;
