const MessagesTypes = {
  Default: 0,
  CreateAppeal: 1,
  CloseAppeal: 2,
  TransferBySystem: 4,
  TransferByUser: 5,
  ReopenAppeal: 6,
  Waiting: 9,
  StartCobrowsing: 10,
  EndCobrowsing: 11,
  Greeting: 12,
  // StartSharing: 13,
  // StopSharing: 14,
  AnswerDelay: 16
};

export { MessagesTypes };
