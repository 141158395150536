import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";

import ThemeProvider from "../../Components/ThemeProvider";
import { store } from "../../ReactCore";

const REACT_CHAT_ROOT_SELECTOR = "#chat_react_root";

export const chatInit = () => {
  const selector = document.querySelector(REACT_CHAT_ROOT_SELECTOR);

  import("../../UnionPage/UnionPage.provider").then((Module) => {
    window.imageViewer.init();
    const UnionPageProvider = Module.default;
    ReactDom.render(
      <ThemeProvider>
        <Provider store={store}>
          <UnionPageProvider />
        </Provider>
      </ThemeProvider>,
      selector
    );
    $(".body").removeClass("load");
  });
};

export const chatDestroy = () => {
  $(".body").addClass("load");
  const selector = document.querySelector(REACT_CHAT_ROOT_SELECTOR);
  ReactDom.unmountComponentAtNode(selector);
};
