import { fork } from "redux-saga/effects";

import getRights from "./getRights.saga";
import getRoles from "./getRoles.saga";
import sendEditedRole from "./sendEditedRole.saga";
import sendNewRole from "./sendNewRole.saga";
import deleteRole from "./deleteRole.saga";

export default function*() {
  yield fork(getRights);
  yield fork(getRoles);
  yield fork(sendEditedRole);
  yield fork(sendNewRole);
  yield fork(deleteRole);
}
