import { CategoriesListTypes } from "./Interfaces";

import { ALL } from "./Consts";

const CategoriesList: CategoriesListTypes.State = {
  page: 0,
  categories: [],
  category: ALL,
  skillGroup: 0,
  skillGroups: []
};

export default CategoriesList;
