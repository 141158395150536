import { megabytesToBytes } from "../../../arm/Constructors/Utils";
import { colorsByThemeDefault } from "../consts";
import produce from "immer";

export enum EWidgetSchedule {
  around_the_clock,
  on_schedule,
}
export interface IFormState {
  triggerMessageEnabled: boolean;
  prechatFieldsEnabled: boolean;
  widgetShedule: EWidgetSchedule;
}

export enum EReducerActions {
  ChangeName,
  PrechatsIsEnabled,
  PrechatsFieldsSkillIdIsEnabled,
  PrechatsFieldsSkillIdIsNecessary,
  PrechatsFieldsSkillIdLabel,
  PrechatsFieldsFullNameIsEnabled,
  PrechatsFieldsFullNameIsNecessary,
  PrechatsFieldsFullNameLabel,
  PrechatsFieldsPhoneIsEnabled,
  PrechatsFieldsPhoneIsNecessary,
  PrechatsFieldsPhoneLabel,
  PrechatsFieldsMessageIsEnabled,
  PrechatsFieldsMessageIsNecessary,
  BarTriggerMessageIsEnabled,
  BarTriggerMessageDelay,
  BarTriggerMessageText,
  BarTextValue,
  BarTextEnabled,
  AppearanceTheme,
  AppearanceChatShape,
  BarShape,
  AppearanceControllerShape,
  AppearanceColorsBubble,
  AppearanceColorsChat,
  AppearanceColorsMessageInput,
  AppearanceColorsTriggerButton,
  AppearanceColorsFont,
  AppearanceColorsFont2,
  FilesLimit,
  FilesSize,
  FilesAllowedTypes,
  FilesEnabled,
  ScheduleFrom,
  ScheduleTo,
  ScheduleTimeZone,
  ScheduleShowWidgetOnlyIfAgentsOnline,
  BarIcon,
  ShowWidget,
  DefaultOperatorAvatar,
  AppearanceColorsEnabled,
  BacklogEnabled,
}

export enum EAppearanceThemes {
  white = "white",
  black = "black",
}

export enum EAppearanceControllerShape {
  rounded = "rounded",
  geometric = "geometric",
  circular = "circular",
}
export enum EAppearanceChatShape {
  rounded = "rounded",
  square = "square",
}
export enum EBarShape {
  rounded = "rounded",
  square = "cilcular"
}

export type TReducerState = {
  enabled: boolean;
  backlogEnabled: boolean;
  fallbackOperatorAvatar: string;
  schedule: {
    from: number;
    to: number;
    timeZone: number;
    showWidgetOnlyIfAgentsOnline: boolean;
  };

  files: {
    enabled: boolean;
    limit: number;
    size: number;
    allowedTypes: string[];
  };

  bar: {
    triggerMessage: {
      enabled: boolean;
      delay: number;
      text: string;
    };
    text: {
      enabled: boolean;
      value: string;
    };
    icon: string;
    shape: EBarShape;
  };

  appearance: {
    theme: EAppearanceThemes;
    controllerShape: EAppearanceControllerShape;
    chatShape: EAppearanceChatShape;
    colors: {
      bubble: string;
      chat: string;
      messageInput: string;
      font: string;
      font2: string;
      triggerButton: string;
      enabled: boolean;
    };
  };

  prechats: {
    enabled: boolean;

    skillGroup: {
      enabled: boolean;
      label: string;
      required: boolean;
    };
    fullName: {
      enabled: boolean;
      label: string;
      required: boolean;
    };
    phone: {
      enabled: boolean;
      label: string;
      required: boolean;
    };
    message: {
      enabled: boolean;
      required: boolean;
    };
  };
};

export type TReducerAction = {
  type: EReducerActions;
  data: any;
};

export default function ChannelSettingsModalReducer(
  state: TReducerState,
  action: TReducerAction
): TReducerState {
  switch (action.type) {
    case EReducerActions.ChangeName:
      return produce(state, (draft) => {
        draft.name = action.data;
      });
    case EReducerActions.AppearanceColorsBubble:
      return produce(state, (draft) => {
        draft.appearance.colors.bubble = action.data;
      });
    case EReducerActions.AppearanceColorsChat:
      return produce(state, (draft) => {
        draft.appearance.colors.chat = action.data;
      });
    case EReducerActions.AppearanceColorsFont:
      return produce(state, (draft) => {
        draft.appearance.colors.font = action.data;
      });
    case EReducerActions.AppearanceColorsFont2:
      return produce(state, (draft) => {
        draft.appearance.colors.font2 = action.data;
      });
    case EReducerActions.AppearanceColorsMessageInput:
      return produce(state, (draft) => {
        draft.appearance.colors.messageInput = action.data;
      });
    case EReducerActions.AppearanceColorsTriggerButton:
      return produce(state, (draft) => {
        draft.appearance.colors.triggerButton = action.data;
      });
    case EReducerActions.AppearanceChatShape:
      return produce(state, (draft) => {
        draft.appearance.chatShape = action.data;
      });
    case EReducerActions.AppearanceControllerShape:
      return produce(state, (draft) => {
        draft.appearance.controllerShape = action.data;
      });
    case EReducerActions.AppearanceTheme:
      return produce(state, (draft) => {
        draft.appearance.theme = action.data;
      });
    case EReducerActions.BarIcon:
      return produce(state, (draft) => {
        draft.bar.icon = action.data;
      });
    case EReducerActions.BarTriggerMessageDelay:
      return produce(state, (draft) => {
        draft.bar.triggerMessage.delay = action.data;
      });
    case EReducerActions.BarTriggerMessageIsEnabled:
      return produce(state, (draft) => {
        draft.bar.triggerMessage.enabled = action.data;
      });
    case EReducerActions.BarTriggerMessageText:
      return produce(state, (draft) => {
        draft.bar.triggerMessage.text = action.data;
      });
    case EReducerActions.FilesAllowedTypes:
      return produce(state, (draft) => {
        draft.files.allowedTypes = action.data;
      });
    case EReducerActions.FilesLimit:
      return produce(state, (draft) => {
        draft.files.limit = action.data;
      });
    case EReducerActions.FilesSize:
      return produce(state, (draft) => {
        draft.files.size = megabytesToBytes(action.data);
      });
    case EReducerActions.FilesEnabled:
      return produce(state, (draft) => {
        draft.files.enabled = action.data;
      });
    case EReducerActions.PrechatsFieldsFullNameIsEnabled:
      return produce(state, (draft) => {
        draft.prechats.fullName.enabled = action.data;
      });
    case EReducerActions.PrechatsFieldsFullNameIsNecessary:
      return produce(state, (draft) => {
        draft.prechats.fullName.required = action.data;
      });
    case EReducerActions.PrechatsFieldsFullNameLabel:
      return produce(state, (draft) => {
        draft.prechats.fullName.label = action.data;
      });
    case EReducerActions.PrechatsFieldsMessageIsEnabled:
      return produce(state, (draft) => {
        draft.prechats.message.enabled = action.data;
      });
    case EReducerActions.PrechatsFieldsMessageIsNecessary:
      return produce(state, (draft) => {
        draft.prechats.message.required = action.data;
      });
    case EReducerActions.PrechatsFieldsPhoneIsEnabled:
      return produce(state, (draft) => {
        draft.prechats.phone.enabled = action.data;
      });
    case EReducerActions.PrechatsFieldsPhoneIsNecessary:
      return produce(state, (draft) => {
        draft.prechats.phone.required = action.data;
      });
    case EReducerActions.PrechatsFieldsPhoneLabel:
      return produce(state, (draft) => {
        draft.prechats.phone.label = action.data;
      });
    case EReducerActions.PrechatsFieldsSkillIdIsEnabled:
      return produce(state, (draft) => {
        draft.prechats.skillGroup.enabled = action.data;
      });
    case EReducerActions.PrechatsFieldsSkillIdIsNecessary:
      return produce(state, (draft) => {
        draft.prechats.skillGroup.required = action.data;
      });
    case EReducerActions.PrechatsFieldsSkillIdLabel:
      return produce(state, (draft) => {
        draft.prechats.skillGroup.label = action.data;
      });
    case EReducerActions.PrechatsIsEnabled:
      return produce(state, (draft) => {
        draft.prechats.enabled = action.data;
      });
    case EReducerActions.ScheduleFrom:
      return produce(state, (draft) => {
        draft.schedule.from = action.data;
      });
    case EReducerActions.ScheduleShowWidgetOnlyIfAgentsOnline:
      return produce(state, (draft) => {
        draft.schedule.showWidgetOnlyIfAgentsOnline = action.data;
      });
    case EReducerActions.ScheduleTimeZone:
      return produce(state, (draft) => {
        draft.schedule.timeZone = action.data;
      });
    case EReducerActions.ScheduleTo:
      return produce(state, (draft) => {
        draft.schedule.to = action.data;
      });
    case EReducerActions.BarTextEnabled:
      return produce(state, (draft) => {
        draft.bar.text.enabled = action.data;
      });
    case EReducerActions.BarTextValue:
      return produce(state, (draft) => {
        draft.bar.text.value = action.data;
      });
    case EReducerActions.BarShape:
      return produce(state, (draft) => {
        draft.bar.shape = action.data;
      });
    case EReducerActions.ShowWidget:
      return produce(state, (draft) => {
        draft.enabled = action.data;
      });
    case EReducerActions.DefaultOperatorAvatar:
      return produce(state, (draft) => {
        draft.fallbackOperatorAvatar = action.data;
      });
    case EReducerActions.BacklogEnabled:
      return produce(state, (draft) => {
        draft.backlogEnabled = action.data;
      });
    case EReducerActions.AppearanceColorsEnabled:
      return produce(state, (draft) => {
        if (action.data === true) {
          draft.appearance.colors = {
            enabled: action.data,
            ...colorsByThemeDefault[state.appearance.theme || "white"],
          };
        } else {
          draft.appearance.colors = {
            enabled: action.data,
          };
        }
      });
    default:
      return { ...state };
  }
}
