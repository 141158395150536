import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionRoles } from "../Store/Roles.reducer";
import Informer from "../../../arm/Wrappers/Informer";
import {
  ERROR_MESSAGE_WHILE_DELETE_DATA,
  SUCCESS_MESSAGE_DELETE_ROLE,
  ERROR_CANT_DELETE_ROLE_HAS_USERS
} from "../const";

function* deleteRoleSaga({ payload }) {
  yield put(actionRoles.setSendDataHandling(true));
  const { roleId, callback } = payload;

  try {
    const result = yield httpRequest("DELETE", `bff/settings/roles/${roleId}`);
    if (result?.success) {
      const info = new Informer(SUCCESS_MESSAGE_DELETE_ROLE);
      info.show();
    }
  } catch (error) {
    let errorText = ERROR_MESSAGE_WHILE_DELETE_DATA;
    if (/"status":409/.test(error)) {
      errorText = ERROR_CANT_DELETE_ROLE_HAS_USERS;
    }
    const info = new Informer(errorText);
    info.show();
  } finally {
    callback?.();
    yield put(actionRoles.setSendDataHandling(false));
  }
}

export default function*() {
  yield takeEvery([actionRoles.deleteRole], deleteRoleSaga);
}
