import { takeEvery, put, call } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  pushMessagesIntoDialogBottom,
  cleanAttachments
} from "../Reducers/Dialog.reducer";
import { cleanFileList } from "../../FileStorage/fileStorage.actions";
import { sendMessage } from "../Actions/Dialog.actions";

function* sendMessagesSaga(action) {
  const messages = action.payload;

  for (let message of messages) {
    yield call(sendMessageGenerator, message);
  }
}

function* sendMessageGenerator(messageData) {
  try {
    const response = yield httpRequest("POST", `bff/chat/dialog/send-message`, {
      body: {
        dialog_id: +messageData.dialogId,
        hint_uid: null,
        media_thumb: messageData.mediaThumb,
        media_url: messageData.mediaUrl,
        text: messageData.text,
        additional_data: messageData.additionalData || undefined
      }
    });

    if (response?.success) {
      yield put(pushMessagesIntoDialogBottom([response.data]));

      if (messageData.mediaUrl) {
        yield put(cleanAttachments());
        yield put(cleanFileList());
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* () {
  yield takeEvery([sendMessage], sendMessagesSaga);
}
