import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";

import { store } from "../../ReactCore";
import ThemeProvider from "../../Components/ThemeProvider";
import Chatbots from "./Chatbots.provider";

const REACT_ROOT_SELECTOR = "#settings_chatbots";

export default function initChatbotsSettings() {
  // @ts-ignore
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  ReactDom.render(
    <ThemeProvider>
      <Provider store={store}>
        <Chatbots />
      </Provider>
    </ThemeProvider>,
    selector
  );
}
