import { IWsNewMessage } from "../ReactFeatures/Dialog/Interfaces";
import Notification from "../arm/Wrappers/Notification";
import {
  setActiveDialog,
  getDialogData,
  setOpenFromNotification
} from "../ReactFeatures/Dialog/Reducers/Dialog.reducer";
const notificationTitle = "Оповещение ОмниЧат";
const titleMarqueeAppealUnreadText = "Есть непрочитанные сообщения";

import { store } from "../ReactCore";

export const showNewMessageNotyfication = (messData: IWsNewMessage) => {
  const text =
    `Новое сообщение от ${messData.clientProfile.name}` +
    ": " +
    (messData.message.mediaUrl ? "Получен файл" : messData.message.text);
  const notification = new Notification();
  notification.show({
    notificationTitle,
    titleMarqueeText: titleMarqueeAppealUnreadText,
    icon: messData.clientProfile.photo || "/www/img/notification_icon.png",
    text,
    onClick: () => {
      store.dispatch(setOpenFromNotification(true));
      store.dispatch(
        setActiveDialog({
          dialogId: messData.dialogId,
          appealId: messData.appealId
        })
      );
      core.nav.do({
        url: `/msg?sec=chat&&dialog_id=${messData.dialogId}&appeal_id=${messData.appealId}`
      });
      store.dispatch(
        getDialogData({
          dialogId: messData.dialogId,
          appealId: messData.appealId
        })
      );
    },
    tag: "msg_dialog_id_" + messData.dialogId
  });
};

export const showNewDialogNotyfication = (messData: IWsNewMessage) => {
  const text = "Получено 1 новое обращение";
  const notification = new Notification();
  notification.show({
    notificationTitle,
    titleMarqueeText: titleMarqueeAppealUnreadText,
    icon: "/www/img/notification_icon.png",
    text,
    onClick: () => {
      store.dispatch(setOpenFromNotification(true));
      store.dispatch(
        setActiveDialog({
          dialogId: messData.dialogId,
          appealId: messData.appealId
        })
      );
      core.nav.do({
        url: `/msg?sec=chat&dialog_id=${messData.dialogId}&appeal_id=${messData.appealId}`
      });
      store.dispatch(
        getDialogData({
          dialogId: messData.dialogId,
          appealId: messData.appealId
        })
      );
    },
    tag: "msg_dialog_appeals"
  });
};
