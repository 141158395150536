import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  sendValidateData,
  SEND_VALIDATE_DATA
} from "../Store/ClassifierFieldForm.reducer";
import { Validation } from "../ClassifierFieldForm";
import { ITextValidateData } from "../Components/TextValidates/TextValidate";

import Informer from "../../../arm/Wrappers/Informer";

export function* sendValidateDataSaga(action) {
  yield put(startLoading(SEND_VALIDATE_DATA));

  const { fieldId, formData, cb } = action.payload;

  const preparedData = ((data: ITextValidateData): Validation => ({
    id: data.id,
    regex: data.regexp,
    errorText: data.errorText
  }))(formData);

  try {
    const method = preparedData?.id ? "PUT" : "POST";
    const id = preparedData?.id || "";

    const res = yield httpRequest(
      method,
      `bff/settings/classifier/fields/${fieldId}/validations/${id}`,
      {
        body: preparedData
      }
    );

    if (res?.success) {
      let actionName = id ? "отредактирована" : "создана";

      cb(res.data.id);

      const info = new Informer(
        `Валидация «${res.data.name}» была ${actionName}`,
        2000
      );
      info.show();
    } else {
      if (res.error) {
        console.error(res.error);
      }
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(SEND_VALIDATE_DATA));
  }
}

export default function* () {
  yield takeEvery([sendValidateData], sendValidateDataSaga);
}
