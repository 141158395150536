import { createAction } from "@reduxjs/toolkit";

import * as ACTION from "../Сonsts";

export const getDialogTemplateModalCatalogs = createAction(
  ACTION.DIALOG_TEMPLATE_MODAL_CATEGORIES_LOADING
);

export const addDialogTemplateModalCatalogs = createAction<any>(
  ACTION.DIALOG_TEMPLATE_MODAL_CATEGORIES_ADD
);

export const setDialogTemplateModalCatalogs = createAction<any>(
  ACTION.SET_DIALOG_TEMPLATE_MODAL_CATEGORIES
);

export const changeCategoriesPage = createAction(ACTION.CHANGE_CATEGORIES_PAGE);

export const getReplies = createAction<any>(ACTION.GET_REPLIES);

export const setReplies = createAction<any>(ACTION.SET_REPLIES);

export const addReplies = createAction<any>(ACTION.ADD_REPLIES);

export const changeRepliesPage = createAction<any>(ACTION.CHANGE_REPLIES_PAGE);

export const resetModalData = createAction(ACTION.RESET_REPLIES_DATA);
