// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subTitle--LU7G3mGjDLYxp0I1AhkO{border-bottom:1px solid rgba(0,0,0,.15);padding-bottom:10px;margin-bottom:10px;font-size:18px;font-weight:bold;color:var(--color-text-default);display:flex;justify-content:space-between;margin-top:10px}.subTitle--LU7G3mGjDLYxp0I1AhkO label{cursor:pointer}.subTitleToogle--gbI3ahjhCRQslNDXyacH{margin-left:10px}", "",{"version":3,"sources":["webpack://./frontend/src/Components/SubTitle/SubTitle.module.scss"],"names":[],"mappings":"AAAA,gCACE,uCAAA,CACA,mBAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CACA,+BAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CAEA,sCACE,cAAA,CAGF,sCACE,gBAAA","sourcesContent":[".subTitle {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n  padding-bottom: 10px;\n  margin-bottom: 10px;\n  font-size: 18px;\n  font-weight: bold;\n  color: var(--color-text-default);\n  display: flex;\n  justify-content: space-between;\n  margin-top: 10px;\n\n  label {\n    cursor: pointer;\n  }\n\n  &Toogle {\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var subTitle = "subTitle--LU7G3mGjDLYxp0I1AhkO";
export var subTitleToogle = "subTitleToogle--gbI3ahjhCRQslNDXyacH";
export default ___CSS_LOADER_EXPORT___;
