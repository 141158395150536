import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";

import Informer from "../../arm/Wrappers/Informer";

import { SEND_ADDITIONAL_FIELD } from "./consts";

function* sendAdditionalField(action) {
  const { client_id } = action.payload;

  const response = yield httpRequest(
    "POST",
    `bff/client/${client_id}/save-additional-fields`,
    {
      body: action.payload
    }
  );

  if (response?.success) {
    const informer = new Informer("Информация успешно сохранена", 3000);
    informer.show();
  }
}

export default function* () {
  yield takeEvery(SEND_ADDITIONAL_FIELD, sendAdditionalField);
}
