import { isEmpty } from "@omnichat/arm_ui_kit";
import { INewMessage } from "../Interfaces/Dialog.interfaces";

/**
 * Преобразует mediaType сообщения в текстовый формат.
 *
 * @param {number} mediaType mediaType полученного сообщения.
 */
export function getMessageType(mediaType: number) {
  switch (mediaType) {
    case 1:
      return "text";
    case 2:
      return "image";
    case 3:
      return "video";
    case 4:
      return "place";
    case 5:
      return "file";
    case 6:
      return "sticker";
    case 7:
      return "email";
    default:
      return "system";
  }
}

/**
 * Добавит префикс к текстовому сообщению агента.
 * Если последнее сообщение от другого агента, то добавить его ФИ
 * @param message Объект с данными сообщения.
 * @param prefix Префикс.
 */
export function addPrefixToMessage(message: INewMessage, prefix: string) {
  if (
    message.text &&
    !isEmpty(message.user) &&
    message.type === 0 &&
    message.mediaType === 1
  ) {
    if (message.user.id === window.core.user.id) {
      return prefix + message.text;
    } else {
      return `${message.user.name} ${message.user.secName}: ${message.text}`;
    }
  }
  return message.text;
}

/**
 * Добавит префикс к теме письма.
 * Если последнее сообщение от другого агента, то добавить его ФИО
 * @param message Объект с данными сообщения.
 * @param prefix Префикс.
 */
export function addPrefixToEmailSubject(message: INewMessage, prefix: string) {
  const subject = message.additionalData?.subject || "..."
  
  if (
    message.additionalData &&
    !isEmpty(message.user) &&
    message.type === 0 &&
    message.mediaType === 7
  ) {

    if (message.user.id === window.core.user.id) {
      return prefix + subject;
    } else {
      return `${message.user.name} ${message.user.secName}: ${subject}`;
    }
  }
  return subject;
}
