export enum SUB_STATUS {
  LOGOFF = 0,
  ONLINE = 1,
  OFFLINE = 2,
  LUNCH = 3,
  TECHNO_BREAK = 4,
  TRAINING = 5,
  COACHING = 6,
  CHIEF = 7,
  OTHER = 8,
  WAITING = 9,
  NONAME = 100500
}
