import initChannelsSettings from "../Settings/Channels";
import initClassifiersSettings from "../Settings/Classifiers";
import initRepliesSettings from "../Settings/RepliesTemplate";
import initVipClients from "../Settings/VipClients";
import initSystemSettings from "../Settings/SystemSettings";
import initAutorepliesSettings from "../Settings/Autoreplies";
import initRolesSettings from "../Settings/Roles";
import initChatbotSettings from "../Settings/Chatbots";
import initUserSettings from "../Settings/Users";

import Informer from "./Wrappers/Informer";

const showInformerAfterReloadKey = "showInformerAfterReload";

export default {
  draw: {
    tpls: function(data) {
      var tpls = {};
      var section = msg.settings.nav.get_section(data);
      if (data.urlCur.sec !== "settings") tpls.settings = "body";

      if (section == "replies") {
        if (data.urlCur.params.section == "replies") {
          tpls.settings_replies = "settings_body";
        }
      } else if (section == "groups") {
        if (
          data.urlCur.params.section == "groups" &&
          data.url.params.skill_id != undefined
        ) {
          tpls.settings_groups_users_header = "settings_groups_users_header";
          tpls.settings_groups_users = "groups_users";
        }
      } else if (section == "themes") {
        if (data.urlCur.params.section == "themes") {
          tpls.settings_themes = "settings_body";
        }
      } else if (section == "reports") {
        tpls.settings_reports = "settings_body";
      } else if (section == "bots") {
      } else if (section == "channels") {
        initChannelsSettings();
      } else if (section == "autoreplies") {
        initAutorepliesSettings();
      } else if (section == "roles") {
        initRolesSettings();
      } else if (section == "vip") {
        tpls.settings_vip = "settings_vip";
        initVipClients();
      } else if (section == "system_settings") {
        tpls.settings_system_settings = "settings_system_settings";
        initSystemSettings();
      }

      if (data.el && data.urlCur.params.section !== data.url.params.section) {
        $(".settings_menu")
          .find(".sel")
          .removeClass("sel");
        data.el.addClass("sel");
      }
      return tpls;
    }
  },

  nav: {
    /**
     * MES-2803
     * Получение "section" для правильной отработки функций after(), init() и т.п. для разделов меню
     * Например: инициализация пагинатора для раздела "Сотрудники"
     * P.S.: Ранее "section" получался из REQUEST_URI, что вызывало ошибку в случае первого перехода на страницу настроек
     */
    get_section: function(data) {
      return (
        data.url.params.section || $(".settings_panel a.sel").data("section")
      );
    },

    init: function(data) {
      var section = msg.settings.nav.get_section(data);
      $(".body").removeClass("load");

      $(window)
        .on({
          resize: msg.settings.resize
        })
        .trigger("resize");

      core.tools.loadCode("scroll.js", function() {
        $(".wsScroll").nanoScroller();
        if (section === "vip") {
          initVipClients();
        } else if (section == "system_settings") {
          initSystemSettings();
        } else if (section == "replies") {
          initRepliesSettings();
        } else if (section == "themes") {
          initClassifiersSettings();
        } else if (section == "channels") {
          initChannelsSettings();
        } else if (section == "autoreplies") {
          initAutorepliesSettings();
        } else if (section == "groups") {
          initUserSettings();
        } else if (section == "roles") {
          initRolesSettings();
        } else if (section == "reports") {
          msg.settings.reports_scroll();
        } else if (section == "bots") {
          msg.pagination.init({
            el: $(".settings_bots_users_list"),
            search: null,
            tpl: "settings_bots_users_list"
          });
          initChatbotSettings();
        }
      });

      const showInformer = window.sessionStorage.getItem(
        showInformerAfterReloadKey
      );
      if (showInformer) {
        window.sessionStorage.removeItem(showInformerAfterReloadKey);
        new Informer("Изменения успешно сохранены", 3000).show();
      }
    },

    destroy: function() {
      msg.settings.height = 0;

      msg.pagination.destroy();
    },

    before: function(data) {
      msg.settings.groups.users = [];
      msg.settings.groups.users_marked = false;
      var section = msg.settings.nav.get_section(data);

      if (section == "groups") {
        msg.settings.groups_scroll(false);
      } else if (section == "reports") {
        msg.settings.reports_scroll(false);
      }
    },

    after: function(data) {
      msg.pagination.destroy();
      var section = msg.settings.nav.get_section(data);
      $(".wsScroll").nanoScroller();

      if (section == "replies") {
        initRepliesSettings();
      } else if (section == "autoreplies") {
        initAutorepliesSettings();
      } else if (section == "themes") {
        initClassifiersSettings();
      } else if (section == "groups") {
        msg.settings.groups_scroll();
        $(".groups_usersScroll").nanoScroller({
          scrollTop: msg.settings.users_scroll
        });
        msg.pagination.init({
          el: $(".groups_users"),
          search: $(".groups_usersWrap .search"),
          tpl: "settings_groups_user_list"
        });
        initUserSettings();
      } else if (section == "reports") {
        msg.settings.reports_scroll();
      } else if (section == "channels") {
        var channel = $(".settings_channels")
          .find('select[name="filter_group"]')
          .val();
        msg.settings.channels.channel_id = channel;
      } else if (section == "roles") {
        msg.settings.roles_scroll();
        initRolesSettings();
      } else if (section == "bots") {
        msg.pagination.init({
          el: $(".settings_bots_users_list"),
          search: null,
          tpl: "settings_bots_users_list"
        });
        initChatbotSettings();
      }
    }
  },

  confirm_modal: function(
    el,
    firstData,
    secondData,
    currentCategory,
    currentAction
  ) {
    if (el.hasClass("load")) return false;

    el.addClass("load");

    $.ajax({
      url: "/msg?sec=settings_confirm_modal",
      data: {
        project_id: core.nav.url.params.project_id,
        firstData: firstData,
        secondData: secondData,
        currentCategory: currentCategory,
        currentAction: currentAction,
        tpls: {
          settings_confirm_modal: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "settings_confirm_modal",
          body: r.tpls.window,
          close: {
            overlay: false,
            esc: false
          }
        });
        el.removeClass("load");
      }
    });
  },

  resize: function() {
    $("#msg-module .settings").css({
      height: core.user.winHeight
    });
  },

  users_scroll: 0,

  groups_scroll: function() {
    $(".groups_firstScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
    $(".groups_usersScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });

    $(".groups_usersScroll").on("update", function(event, vals) {
      msg.settings.users_scroll = vals.position;
    });
  },

  reports_scroll: function() {
    $(".settings_reportsWrap").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
  },

  roles_scroll: function() {
    $(".settings_rolesScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
    $(".roles_permissionScroll").nanoScroller({
      flash: !0,
      alwaysVisible: true
    });
  },

  height: 0,

  // depracated - использовались в переписанном на React модуле настроек сотрудников
  groups: {
    userEditor_modal: {},
    user_profile: false,
    users: [],
    skill_modal: {},
    selected_skill_id: !1,
    users_marked: false,
    dropdown_show_el: undefined,
    dropdown_showed: false
  },

  autoreplies_init: function(el, project_id) {
    if (el.hasClass("load")) return false;

    el.addClass("load");
    $.ajax({
      url: "/msg?sec=settings",
      data: {
        project_id: project_id,
        tpls: {
          settings_autoreplies: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "settings_autoreplies",
          body: r.tpls.window
        });
        el.removeClass("load");
      }
    });
  },

  autoreplies_submit: function(form) {
    $.ajax({
      url: "/msg?sec=save_autoreplies",
      data: form.serializeArray(),
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        if (core.form.check(form, "autorepliesEditor", r.errors)) {
          $("#msg_autorepliesEditor").modal("close");
          core.informer.show(msg.lng.settings.changeSuccess, 1500);
        } else {
          var input = $("#autorepliesEditor_form")
            .find(".imp")
            .find("input, textarea");
          $.each(input, function(i, item) {
            if ($(item).val() == 0) {
              $(item)
                .closest(".wsForm_field")
                .addClass("fail");
            }
          });

          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      }
    });
  },

  tst_init: function(project_id) {
    $.ajax({
      url: "/msg?sec=settings",
      data: {
        project_id: project_id,
        tpls: {
          settings_tst: "window"
        }
      },
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        core.draw.window({
          id: "settings_tst",
          body: r.tpls.window
        });
      }
    });
  },

  tst_submit: function(form) {
    $.ajax({
      url: "/msg?sec=save_autoreplies",
      data: form.serializeArray(),
      dataType: "JSON",
      type: "GET",
      success: function(r) {
        if (core.form.check(form, "tstEditor", r.errors)) {
          $("#msg_tstEditor").modal("close");
          core.informer.show(msg.lng.settings.changeSuccess, 1500);
        } else {
          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      }
    });
  },

  score: function(el) {
    var elem = el.closest(".wsForm_field");
    if (el.val() == 0) elem.find(".score").html("Время не установлено");
    else elem.find(".score").html(el.val() + " мин");
  },

  score_queue: function(el) {
    var elem = el.closest(".wsForm_field");
    var buf = el.val().slice(-1);

    if (el.val() == 0) elem.find(".score").html(msg.lng.chat.noLimitations);
    else elem.find(".score").html(el.val() + " Обращений");
  },

  channels: {
    modal: undefined,

    channel_id: 1,

    editor: function(project_id, channel_id) {
      $.ajax({
        url: "/msg?sec=settings",
        data: {
          channel_id: channel_id || 0,
          tpls: {
            settings_channels_editor: "window"
          }
        },
        dataType: "JSON",
        type: "POST",
        success: function(r) {
          core.draw.window({
            id: "channelEditor",
            body: r.tpls.window,
            callback: {
              beforeOpen: function(data) {
                msg.settings.channels.modal = data.body;
              },
              beforeClose: function() {
                msg.settings.channels.modal = null;
              }
            }
          });
        }
      });
    },

    prepareData: function(form) {
      var formFieldArray = form.serializeArray();

      function takeSimpleSettings(data) {
        var temp_arr = [];

        data.forEach(function(item, i, arr) {
          if (!/\$/.test(item.name)) {
            temp_arr.push(item);
          }
        });

        return temp_arr;
      }

      function takeWidgetSettings(data) {
        var temp_obj = {};

        data.forEach(function(item) {
          if (/\$/.test(item.name)) {
            var element = $("[name='" + item.name + "']"),
              value = item.value;
            if (value || element.parents(".wsForm_field").find(".imp").length) {
              temp_obj[item.name] =
                element.attr("type") === "text" ? value.trim() : value;
            }
          }
        });

        return temp_obj;
      }

      function takeEmptyCheckboxSettings(form) {
        var temp_obj = {},
          checkboxNotChecked = form.find("[type=checkbox]:not(:checked)");

        checkboxNotChecked.each(function(item, i, arr) {
          temp_obj[i.name] = "0";
        });

        return temp_obj;
      }

      var mainData = takeSimpleSettings(formFieldArray);
      var widgetData = takeWidgetSettings(formFieldArray);
      var emptyCheckboxData = takeEmptyCheckboxSettings(form);

      var finalWidgetSettings = $.extend({}, widgetData, emptyCheckboxData);

      if (Object.keys(finalWidgetSettings).length) {
        mainData.push({
          name: "data",
          value: JSON.stringify(finalWidgetSettings)
        });
      }

      return mainData;
    },

    imgToBase64: function(el) {
      if (!el.files.length) return;
      var reader = new FileReader();
      reader.readAsDataURL(el.files[0]);
      reader.onloadend = function() {
        const base64data = reader.result;
        $(el)
          .closest(".wsForm_field")
          .find("[type=hidden][name*='imgLabel']")
          .val(el.files[0].name)
          .end()
          .find("[type=hidden][name*='imgBase64']")
          .val(base64data);
      };
    },

    scriptUpdate: function(el, project_id) {
      msg.settings.channels.channel_id = el.val();

      if (el.val() != 1) {
        $(".settings_channel_edit").removeClass("disable");
      } else {
        $(".settings_channel_edit").addClass("disable");
      }

      $(".settings_channel_script").addClass("load");
      core.nav.do({
        url:
          "/msg?sec=settings&project_id=" +
          project_id +
          "&section=channels&channel_id=" +
          el.val()
      });
      $(".settings_channel_script").removeClass("load");
    }
  },

  bots: {
    bot_delete: function(el, bot_id, user_id) {
      if (el.hasClass("load") || !bot_id || !user_id) {
        return false;
      }
      el.addClass("load");

      $.ajax({
        url: "/msg?sec=bot_user_delete",
        data: {
          project_id: core.nav.url.params.project_id,
          bot_id: bot_id,
          user_id: user_id
        },
        dataType: "JSON",
        type: "POST",
        success: function(r) {
          if (r.data.success === 1) {
            var url =
              "/msg?sec=settings&project_id=" +
              core.nav.url.params.project_id +
              "&section=bots";

            core.nav.do({
              el: undefined,
              url: url
            });
            core.informer.show(msg.lng.settings.changeSuccess, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
            return;
          }
          core.informer.show(msg.lng.settings.busyBotMsg, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
          el.removeClass("load");
        }
      });
    },

    save: function(form) {
      if (form.hasClass("load")) {
        return false;
      }
      form.addClass("load");
      var data = new FormData(form[0]);
      $.ajax({
        url: "/msg?sec=bot_user_edit_save",
        data: data,
        dataType: "JSON",
        type: "POST",
        processData: false,
        async: false,
        cache: false,
        contentType: false,
        success: function(r) {
          form.removeClass("load");
          if (core.form.check(form, "botEditor", r.errors)) {
            $("#msg_settingsBotsList").modal("close");

            var url =
              "/msg?sec=settings&project_id=" +
              core.nav.url.params.project_id +
              "&section=bots";

            core.nav.do({
              el: undefined,
              url: url
            });
            core.informer.show(msg.lng.settings.changeSuccess, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
            return;
          }
          core.informer.show(msg.lng.settings.changeError, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        },
        error: (r) => {
          form.removeClass("load");
          if (r.status === 413) {
            core.form.check(form, "botEditor", {
              photo: "Размер файла превышает установленный лимит"
            });
            core.informer.show(msg.lng.settings.changeError, 1500);
            return;
          }
          core.informer.show(msg.lng.common.defaultRequest, 1500);
          core.informer.el.css({
            left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
          });
        }
      });
    },

    showModal: function(el, project_id, bot_id) {
      if (el.hasClass("load")) {
        return false;
      }
      el.addClass("load");

      $.ajax({
        url: "/msg?sec=settings",
        data: {
          project_id: project_id,
          bot_id: bot_id,
          tpls: {
            settings_bots_users_edit: "window"
          }
        },
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          core.draw.window({
            id: "msg_botEditor",
            body: r.tpls.window
          });
          el.removeClass("load");
        }
      });
    }
  }
};

$(document).ready(() => {
  $(".body")
    .parent()
    .css("overflow", "visible");
});

$(window).on({
  scroll: function() {
    var scroll = $(window).scrollTop();
    var menuHeght = $(".menu").outerHeight();
    var menu = $(".settings_menu");

    if (scroll > menuHeght) {
      menu.addClass("fixed");
      $(".editorWrap").addClass("fixed");
    } else if (scroll < menuHeght) {
      menu.removeClass("fixed");
      $(".editorWrap").removeClass("fixed");
    }
  }
});
