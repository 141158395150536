import { ChatbotsTypes } from "../Chatbots";

const Chatbots: ChatbotsTypes.State = {
  loading: [],
  chatbotList: [],
  chatbotData: null,
  error: null
};

export default Chatbots;
