import { fork } from "redux-saga/effects";

import getClassifiers from "./getClassifiers.saga";
import getThemesFields from "./getThemeFields.saga";
import getClassifierFields from "./getClassifierFields.saga";
import getClassifierFieldValues from "./getClassifierFieldValues.saga";

export default function*() {
  yield fork(getClassifiers);
  yield fork(getThemesFields);
  yield fork(getClassifierFields);
  yield fork(getClassifierFieldValues);
}
