import React from "react";
import c from "classnames";

import { Icon } from "@omnichat/arm_ui_kit";

import * as s from "./styles.module.scss";

export interface ICategoryItemProps {
  id: number | string;
  name: string;
  current: boolean;
  editable: boolean;
  onSelect: (id: number | string) => void;
  onEdit: (id: number | string) => void;
}

/**
 * Одна категория
 */
const CategoryItem = ({
  id,
  name,
  current,
  editable,
  onSelect,
  onEdit,
}: ICategoryItemProps): JSX.Element => {
  const edit = (e: any) => {
    e.stopPropagation();
    onEdit(id);
  };

  return (
    <>
      <div
        onClick={() => onSelect(id)}
        className={c(s["category"], { [s["current"]]: current })}
      >
        <div className={s["categoryName"]}>{name}</div>
        {editable && (
          <div onClick={(e) => edit(e)} className={s["categoryIcon"]}>
            <Icon
              extraClass={[{ [s["hover"]]: true }]}
              color={current ? "white" : "blue"}
              name="edit"
              width="18px"
              height="18px"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryItem;
