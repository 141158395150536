import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { store } from "../../ReactCore";

import ThemeProvider from "../../Components/ThemeProvider";
import ChannelsProvider from "./Channels.provider";

const REACT_ROOT_SELECTOR = "#settings_channels";

export default function initChannelsSettings() {
  const node = document.querySelector(REACT_ROOT_SELECTOR);

  ReactDom.render(
    <ThemeProvider>
      <Provider store={store}>
        <ChannelsProvider />
      </Provider>
    </ThemeProvider>,
    node
  );
}
