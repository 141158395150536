export const isAllowedExtetion = (fileType: string): boolean =>
  EXTENSIONS_ALLOWED_BY_STORAGE.indexOf(fileType) !== -1;

export const getAllowedExtension = (list: string[]): string[] => {
  if (!list.length) {
    return [];
  }
  let result = [];
  list.forEach(type => {
    if (isAllowedExtetion(type)) {
      result.push(type);
    }
  });
  return result;
};
