import { takeEvery } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { healthCheck } from "../Store/SystemStatistics.reducer";

export function* healthCheckSaga(action) {
  try {
    yield httpRequest("GET", `bff/auth/health-check`);
  } catch ($error) {
    console.error($error);
  }
}

export default function* () {
  yield takeEvery([healthCheck], healthCheckSaga);
}
