import React from "react";

import { Button } from "@omnichat/arm_ui_kit";

import * as s from "./DialodContextButton.module.scss";

interface IClientInfoProps {
  onClick: () => void;
}

const DialodContextButton: React.FunctionComponent<IClientInfoProps> = ({
  onClick
}): JSX.Element => {
  return (
    <Button
      extraClass={[s["styledButton"]]}
      type="default"
      theme="bare"
      text="Контекст диалога"
      onClick={onClick}
    />
  );
};

export default DialodContextButton;
