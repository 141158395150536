// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-container--Y4nlxUj5amD9tZU9jvFh{width:550px}.modal-container--Y4nlxUj5amD9tZU9jvFh header{color:var(--color-text-default);font-size:var(--font-size-header);font-weight:bold;padding:0 0 20px;border-bottom:1px solid rgba(0,0,0,.15);margin-bottom:15px}.modal-container--Y4nlxUj5amD9tZU9jvFh .appealLimitValue--uRC4IrK4P18n4nep2s1C{text-transform:lowercase;color:var(--color-primary-action)}.modal-container--Y4nlxUj5amD9tZU9jvFh .error--zZCfRwJIPy07km1vfj9w{color:var(--color-error-action-text)}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/AgentCapacityModal/styles.module.scss"],"names":[],"mappings":"AAAA,uCACE,WAAA,CAEA,8CACE,+BAAA,CACA,iCAAA,CACA,gBAAA,CACA,gBAAA,CACA,uCAAA,CACA,kBAAA,CAGF,+EACE,wBAAA,CACA,iCAAA,CAGF,oEACE,oCAAA","sourcesContent":[".modal-container {\n  width: 550px;\n\n  header {\n    color: var(--color-text-default);\n    font-size: var(--font-size-header);\n    font-weight: bold;\n    padding: 0 0 20px;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n    margin-bottom: 15px;\n  }\n\n  .appealLimitValue {\n    text-transform: lowercase;\n    color: var(--color-primary-action);\n  }\n\n  .error {\n    color: var(--color-error-action-text);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var modalContainer = "modal-container--Y4nlxUj5amD9tZU9jvFh";
export var appealLimitValue = "appealLimitValue--uRC4IrK4P18n4nep2s1C";
export var error = "error--zZCfRwJIPy07km1vfj9w";
export default ___CSS_LOADER_EXPORT___;
