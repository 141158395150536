import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { getAutorepliesSettings } from "../Actions/Autoreplies.actions";
import { setAutorepliesSettings } from "../Reducers/Autoreplies.reducer";

function* getAutorepliesSettingsSaga() {
  const response = yield httpRequest("GET", `bff/settings/autoreplies`);

  if (response?.success) {
    yield put(setAutorepliesSettings(response.data));
  }
}

export default function* () {
  yield takeEvery([getAutorepliesSettings], getAutorepliesSettingsSaga);
}
