import React, { FC, useRef, useEffect, memo } from "react";
import c from "classnames";

import {
  Label,
  Icon,
  useStateWithCallback,
  isEmpty
} from "@omnichat/arm_ui_kit";

import { convertKeyCodes } from "../../../Common/Utils/Text.utils";
import { IKeyShortcutTextValues } from "../../../Common/Interfaces/HotKeys";

import { useKeyCodesCapture } from "./KeyShortcutInput.hooks";
import * as styles from "./KeyShortcutInput.module.scss";

/**
 * Модель собственнных свойств компонента.
 *
 * @prop {string[] | null} values Значения горячих клавиш для отрисовки.
 * @prop {(newKeyCodes: string[], currentKeyShortCut: string) => void} onChangeKeyShortcut Колбэк на изменение сочетания горячих клавиш.
 * @prop {IKeyShortcutTextValues} texts Объект, содержащий в себе все текстовки для компонента.
 * @prop {"default" | "error"} [theme] Тема оформления.
 * @prop {boolean} [setOneKeyShortcutOnly] Компонент будет работать только с одной связкой горячих клавиш.
 */
interface IOwnProps {
  values: string[] | null;
  onChangeKeyShortcut: (
    newKeyCodes: string[],
    currentKeyShortCut?: string | null
  ) => void;
  texts: IKeyShortcutTextValues;
  theme?: "Default" | "Error";
  setOneKeyShortcutOnly?: boolean;
}

/**
 * Компонент ввода с возможностью отображения выбранных горячих клавиш.
 */
const KeyShortcutInput = ({
  values,
  onChangeKeyShortcut,
  texts = {
    defaultHintText: "",
    inputPlaceholder: "",
    textIfEmptyValues: ""
  },
  theme = "Default",
  setOneKeyShortcutOnly
}: IOwnProps) => {
  const [
    { pressedCodes, hotKeyDescription, hotKeyCodes },
    setKeyCodesCaptureState,
    onKeyCodesCapture
  ] = useKeyCodesCapture<HTMLInputElement>();
  const [isInputActive, setInputActive] = useStateWithCallback<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return addClickListener;
  }, []);

  useEffect(() => {
    isInputActive ? inputRef.current.focus() : inputRef.current.blur();
  }, [isInputActive]);

  const showKeyInput = () => {
    if (isInputActive) {
      return;
    }
    setInputActive(true);
    addClickListener();
  };

  const hideKeyInput = () => {
    if (!isInputActive) {
      return;
    }
    setInputActive(false);
    removeClickListener();
  };

  const addClickListener = () => {
    document.addEventListener("click", handleCloseIfAnotherTarget);
  };

  const removeClickListener = () => {
    document.removeEventListener("click", handleCloseIfAnotherTarget);
  };

  /**
   * Вернуть форму в начальное состояние.
   */
  const handleSetComponentInitialState = () => {
    hideKeyInput();

    setKeyCodesCaptureState({
      hotKeyCodes: "",
      hotKeyDescription: "",
      pressedCodes: new Set()
    });
  };

  /**
   * Если текущий таргет отличен от input, закроет input.
   *
   * @param {MouseEvent} event Событие нажатия на клавишу мыши.
   */
  const handleCloseIfAnotherTarget = (event: MouseEvent) => {
    event.target !== inputRef.current && handleSetComponentInitialState();
  };

  /**
   * Обработчик удаления сочетания клавиш.
   *
   * @param {number} index Индекс элемента для удаления.
   */
  const handleRemoveKeyShortcut = (index: number) => {
    if (!isEmpty(values)) {
      let newValues = [...(values as string[])];
      newValues.splice(index, 1);

      onChangeKeyShortcut(newValues);
    }
  };

  /**
   * Обработчик удаления всех сочетаний клавиш.
   */
  const handleRemoveKeyShortcuts = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onChangeKeyShortcut([]);
  };

  /**
   * Обработчик нажатия на клавишу.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event Событие нажатия в поле ввода.
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.keyCode === 13 || event.nativeEvent.code === "Enter") &&
      hotKeyCodes
    ) {
      let newKeyCodes = setOneKeyShortcutOnly ? [] : [...values];

      newKeyCodes.push(hotKeyCodes);

      onChangeKeyShortcut(newKeyCodes, hotKeyDescription);
      handleSetComponentInitialState();
      return;
    }

    if (event.keyCode === 27 || event.nativeEvent.code === "Escape") {
      handleSetComponentInitialState();
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    onKeyCodesCapture(event);
  };

  /**
   * Обработчик события по отжатию клавиши.
   *
   * @param {React.KeyboardEvent<HTMLInputElement} event Синтетическое событие реакта.
   */
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newPressedCodes = new Set([...pressedCodes]);

    event.preventDefault();
    event.stopPropagation();

    if (newPressedCodes.has(event.keyCode)) {
      newPressedCodes.delete(event.keyCode);

      setKeyCodesCaptureState({ pressedCodes: newPressedCodes });
    }
  };

  /**
   * Отрисует список указанных горячих клавиш.
   */
  const SelectedKeyShortcuts = memo(() => {
    let result = !isInputActive && (
      <span className={styles.keyShortcutInputPlaceholder}>
        {texts.textIfEmptyValues}
      </span>
    );

    if (!setOneKeyShortcutOnly && !isEmpty(values)) {
      result = (
        <div className={styles.keyShortcutInputValuesList}>
          {convertKeyCodes(values as string[]).map((value, index) => (
            <Label
              key={value}
              value={value}
              onRemoveItem={handleRemoveKeyShortcut}
              index={index}
            />
          ))}
        </div>
      );
    }

    if (setOneKeyShortcutOnly && !isEmpty(values)) {
      const keyShortcut = convertKeyCodes(values as string[])[0];

      result = (
        <div className={styles.keyShortcutInputOneKeyShortcutOnly}>
          {keyShortcut.split(" + ").map((value) => (
            <p key={value}>
              <span>{value}</span>
            </p>
          ))}
        </div>
      );
    }

    return result;
  });

  /**
   * Отрисовка текста подсказки в зависимости от того
   * устанавливает ли пользователь в данный момент сочетание клавиш или нет.
   */
  const HintText: FC<{ hintText: string }> = memo(
    ({ hintText }) =>
      !!hintText && (
        <div className={styles.keyShortcutInputActionText}>
          <span>{hintText}</span>
        </div>
      )
  );

  return (
    <>
      <div
        className={c(styles.keyShortcutInput, styles[`theme${theme}`], {
          [styles.focused]: isInputActive
        })}
        onClick={showKeyInput}
      >
        <SelectedKeyShortcuts />

        <input
          ref={inputRef}
          value={hotKeyDescription}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          placeholder={texts.inputPlaceholder}
          onChange={() => {}}
          autoFocus
          onBlur={hideKeyInput}
          style={{ display: !!isInputActive ? "block" : "none" }}
        />

        {!isInputActive && (
          <div className={styles.keyShortcutInputActions}>
            {!isEmpty(values) && (
              <Icon
                name="cross"
                color="blue"
                width="20px"
                height="20px"
                extraClass={[styles.keyShortcutInputRemoveKeyShortcut]}
                onClick={handleRemoveKeyShortcuts}
              />
            )}
          </div>
        )}
      </div>

      <HintText
        hintText={
          isInputActive ? texts.hintWhenSettingShortcut : texts.defaultHintText
        }
      />
    </>
  );
};

export default KeyShortcutInput;
