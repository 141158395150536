import React, { FC, useState } from "react";

import { Button, Tooltip, Icon } from "@omnichat/arm_ui_kit";
import TextTemplate, { ITextTemplateData } from "./TextTemplate";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragUpdate
} from "react-beautiful-dnd";

import * as s from "./style.module.scss";

type ITextTemplatesProps = {
  templates: ITextTemplateData[];
  onSaveTemplate: (data: ITextTemplateData) => void;
  onDeleteTemplate: (data: ITextTemplateData) => void;
};

const TextTemplates: FC<ITextTemplatesProps> = ({
  templates,
  onDeleteTemplate,
  onSaveTemplate
}) => {
  const [newTemplate, createNewTemplate] = useState<ITextTemplateData | null>(
    null
  );

  const [sourceBlock, setSourceBlock] = useState<string | null>(null);
  const handleDrop = ({ destination, source }: DropResult) => {
    setSourceBlock(null);

    const newIndex = destination?.index;
    const oldIndex = source?.index;

    if (newIndex === undefined || newIndex === null || newIndex === oldIndex)
      return;

    const template = templates?.[oldIndex];

    onSaveTemplate({
      ...template,
      order: newIndex + 1
    });
  };

  const handleDragStart = ({ source }: DragUpdate) => {
    setSourceBlock(source.droppableId);
  };

  const handleAddNewTemplate = () => {
    createNewTemplate({
      name: "",
      content: "",
      order: templates.length + 1
    });
  };

  return (
    <div>
      <div>
        <DragDropContext onDragEnd={handleDrop} onDragStart={handleDragStart}>
          <Droppable
            droppableId="templates"
            isDropDisabled={sourceBlock !== "templates"}
          >
            {(droppableFieldProvider) => (
              <div ref={droppableFieldProvider.innerRef}>
                {(newTemplate ? [...templates, newTemplate] : templates)?.map(
                  (t, i) => (
                    <Draggable
                      key={`${t.id || "new"}_${i}`}
                      draggableId={`${t.id || "new"}`}
                      index={i}
                      isDragDisabled={!t.id}
                    >
                      {(draggableFieldProvider) => (
                        <div
                          ref={draggableFieldProvider.innerRef}
                          {...draggableFieldProvider.draggableProps}
                        >
                          <div className={s.templateContainer}>
                            <div className={s.tamplateDrag}>
                              <Icon
                                name="apps"
                                color="blue"
                                width="24px"
                                height="28px"
                                {...draggableFieldProvider.dragHandleProps}
                              />
                            </div>
                            <TextTemplate
                              key={`${i}_${t.name}`}
                              onDelete={onDeleteTemplate}
                              onSave={onSaveTemplate}
                              templateData={t}
                              onCancelCreation={() => createNewTemplate(null)}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                )}
                {droppableFieldProvider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {!newTemplate && (
        <div className={s.add}>
          <Tooltip content="Добавить шаблон" position="top">
            <Button
              type="bare"
              iconColor="blue"
              iconName="plus"
              onClick={handleAddNewTemplate}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default TextTemplates;
