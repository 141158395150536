import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";

import { store } from "../../ReactCore";
import ThemeProvider from "../../Components/ThemeProvider";
import UsersProvider from "./Users.provider";

const REACT_ROOT_SELECTOR = "#settings_users";

export default function initUsersSettings() {
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  // @ts-ignore
  ReactDom.render(
    <ThemeProvider>
      <Provider store={store}>
        <UsersProvider />
      </Provider>
    </ThemeProvider>,
    selector
  );
}
