import { createAction } from "@reduxjs/toolkit";

import * as ACTION from "../Consts";

import { CategoriesListTypes } from "../Interfaces";

export const getCategories = createAction<number>(ACTION.GET_CATEGORIES);
export const getSkillGroups = createAction(ACTION.GET_SKILLGROUPS);
export const setSkillGroups = createAction<CategoriesListTypes.SkillGroup[]>(
  ACTION.SET_SKILLGROUPS
);
export const setCategories = createAction<CategoriesListTypes.Category[]>(
  ACTION.SET_CATEGORIES
);
export const addCategories = createAction<CategoriesListTypes.Category[]>(
  ACTION.ADD_CATEGORIES
);
export const getNextPage = createAction<number>(ACTION.GET_NEXT_PAGE);
export const setSkillGroup = createAction<number>(ACTION.SET_SKILLGROUP);
export const setCategory = createAction<number | string>(ACTION.SET_CATEGORY);
