import { isEmpty } from "@omnichat/arm_ui_kit";
import { put, takeEvery } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  removeAppealComment,
  saveAppealComment,
  updateAppealComment
} from "../Actions/Dialog.actions";
import Informer from "../../../arm/Wrappers/Informer";
import { IAction } from "../../Common/Interfaces/Store.interfaces";
import {
  setAppealComment,
  resetAppealComment
} from "../Reducers/Dialog.reducer";

/**
 * Сага для сохранения комментария обращения.
 *
 * @param {IAction} action Экшн, который поймала сага.
 */
function* saveAppealCommentSaga(action: IAction<any>) {
  const appealId = action.payload?.appealId;
  const comment = action.payload?.comment;

  const result = yield httpRequest("POST", `bff/chat/appeals/save-comment`, {
    body: {
      appealId,
      comment
    }
  });

  if (result?.success) {
    yield put(setAppealComment(result.data?.comment));
  } else if (
    !isEmpty(result?.error) &&
    result.error?.name === "Error" &&
    result.error?.message
  ) {
    let errorText;

    if (/404/.test(result.error.message)) {
      errorText = "Ошибка. Не был найден endpoint под запрос.";
    } else if (/500/.test(result.error.message)) {
      errorText = "Ошибка. К данному обращению уже создан комментарий.";
    } else if (/400/.test(result.error.message)) {
      errorText = "Ошибка. Комментарий не должен быть пустым.";
    }

    if (errorText) {
      const informer = new Informer(errorText, 3000);
      informer.show();
    }

    return;
  }
}

/**
 * Сага для обновления комментария обращения.
 *
 * @param {IAction} action Экшн, который поймала сага.
 */
function* updateAppealCommentSaga(action: IAction) {
  const appealId = action.payload?.appealId;
  const comment = action.payload?.comment;

  const result = yield httpRequest("PUT", `bff/chat/appeals/update-comment`, {
    body: {
      appealId,
      comment
    }
  });

  if (result?.success) {
    yield put(setAppealComment(result.data?.comment));
  } else if (
    !isEmpty(result.error) &&
    result.error.name === "Error" &&
    result.error.message
  ) {
    let errorText;

    if (/404/.test(result.error.message)) {
      errorText =
        "Ошибка. Комментария не существует или не был найден endpoint под запрос.";
    }

    if (errorText) {
      const informer = new Informer(errorText, 3000);
      informer.show();
    }

    return;
  }
}

/**
 * Сага для удаления комментария обращения.
 *
 * @param {IAction} action Экшн, который поймала сага.
 */
function* removeAppealCommentSaga(action: IAction) {
  const appealId = action.payload?.appealId;

  const result = yield httpRequest("DELETE", `bff/chat/appeals/${appealId}`);

  if (result?.success) {
    yield put(resetAppealComment());
  } else if (
    !isEmpty(result?.error) &&
    result.error.name === "Error" &&
    result.error.message
  ) {
    let errorText;

    if (/404/.test(result.error.message)) {
      errorText =
        "Ошибка. Комментария не существует или не был найден endpoint под запрос.";
    }

    if (errorText) {
      const informer = new Informer(errorText, 3000);
      informer.show();
    }

    return;
  }
}

export default function* () {
  yield takeEvery([removeAppealComment], removeAppealCommentSaga);
  yield takeEvery([updateAppealComment], updateAppealCommentSaga);
  yield takeEvery([saveAppealComment], saveAppealCommentSaga);
}
