// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal--fn33zwcURBrL3BEoBMQN{width:600px;padding:0}.modal__content--WfaHA_Sks6nVAGrgLhVk{border-top:none;padding-top:0}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/CloseDialogModal/Components/CloseAppealByAgentWithoutClassifierModal/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,SAAA,CAEA,sCACE,eAAA,CACA,aAAA","sourcesContent":[".modal {\n  width: 600px;\n  padding: 0;\n\n  &__content {\n    border-top: none;\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = "modal--fn33zwcURBrL3BEoBMQN";
export var modalContent = "modal__content--WfaHA_Sks6nVAGrgLhVk";
export default ___CSS_LOADER_EXPORT___;
