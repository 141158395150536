// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".middle_align--Nc_Et00F9TjdtJEMlxdP{display:flex}.timer_time--adc8AXsXxnQD5vtjq9xM{border-bottom:1px dashed;margin-left:5px;font-size:18px;font-weight:normal;cursor:default}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/Common/Components/AppealTimer/AppealTimer.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CAGF,kCACE,wBAAA,CACA,eAAA,CACA,cAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".middle_align{\n  display: flex;\n}\n\n.timer_time{\n  border-bottom: 1px dashed;\n  margin-left: 5px;\n  font-size: 18px;\n  font-weight: normal;\n  cursor: default;\n}"],"sourceRoot":""}]);
// Exports
export var middleAlign = "middle_align--Nc_Et00F9TjdtJEMlxdP";
export var timerTime = "timer_time--adc8AXsXxnQD5vtjq9xM";
export default ___CSS_LOADER_EXPORT___;
