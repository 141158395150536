import * as React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../ReactCore/store/types";

import { Preloader, isEmpty } from "@omnichat/arm_ui_kit";

import CategoryModal from "../Components/CategoryModal/CategoryModal";
import * as actions from "../Actions/CategoryModal.action";

import * as selectors from "../CategoryModal.selectors";

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  categoryId?: number;
  close: () => void;
}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {
  name: string;
  all: boolean;
  selectedGroups: number[];
  skillGroups: {
    label: string;
    value: number;
  }[];
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  resetModalData: () => void;
  getCategory: (id) => void;
  getSkillgroups: () => void;
  onChangeAll: () => void;
  onChangeName: (name: string) => void;
  onChangeGroup: (groups: number[]) => void;
  onSave: (close) => void;
  onRemove: (close) => void;
}

type CategoryModalProviderProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * Провайдер фичи CategoryModal.
 */
class CategoryModalProvider extends React.PureComponent<
  CategoryModalProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!isEmpty(this.props.categoryId)) {
      this.props.getCategory(this.props.categoryId);
    }
    if (isEmpty(this.props.skillGroups)) {
      this.props.getSkillgroups();
    }
  }

  componentWillUnmount() {
    this.props.resetModalData();
  }

  handleCHangeGroup = (groups) => {
    if (groups.length === this.props.skillGroups.length) {
      this.props.onChangeAll();
      this.props.onChangeGroup([]);
    } else {
      this.props.onChangeGroup(groups);
    }
  };

  render() {
    return (
      <>
        <Preloader size="mini" show={isEmpty(this.props.skillGroups)}>
          <CategoryModal
            id={this.props.categoryId}
            all={this.props.all}
            name={this.props.name}
            skillGroups={this.props.skillGroups}
            selectedGroups={this.props.selectedGroups}
            onChangeAll={() => this.props.onChangeAll()}
            onChangeName={(name) => this.props.onChangeName(name)}
            onChangeGroup={(groups) => this.handleCHangeGroup(groups)}
            onSave={() => this.props.onSave(this.props.close)}
            onRemove={() => this.props.onRemove(this.props.close)}
          />
        </Preloader>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  all: selectors.getAll(state),
  name: selectors.getName(state),
  skillGroups: selectors.getSkillGroups(state),
  selectedGroups: selectors.getSelectedSkillGroups(state),
});

const mapDispatchToProps = (dispatch): IDispatchProps => ({
  resetModalData: () => dispatch(actions.resetModalData()),
  getCategory: (id) => dispatch(actions.getCategory(id)),
  getSkillgroups: () => dispatch(actions.getSkillgroups()),
  onChangeAll: () => dispatch(actions.setAll()),
  onChangeGroup: (data) => dispatch(actions.setSelectedSkillgroups(data)),
  onChangeName: (data) => dispatch(actions.setName(data)),
  onSave: (close) => dispatch(actions.saveCategory(close)),
  onRemove: (close) => dispatch(actions.removeCategory(close)),
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryModalProvider);

export default Connect;
