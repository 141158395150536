import { createSelector } from "reselect";
import { StoreState } from "../../../ReactCore/store/types";

export const getRoles = (state: StoreState.State) => state.Roles;

export const getRolesList = createSelector(getRoles, (state) => state.roles);

export const getRightsList = createSelector(getRoles, (state) => state.rights);

export const getErrorMessage = createSelector(
  getRoles,
  (state) => state.errorMessage
);

export const getDataSendHandling = createSelector(
  getRoles,
  (state) => state.status.dataSendHandling
);
