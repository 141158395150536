import { fork } from "redux-saga/effects";

import StatisticStompSaga from "./statisticStomp.saga";
import SendStatisticActionSaga from "./sendStatisticAction.saga";
import healthCheckSaga from "./healthCheck.saga";

export default function* () {
  yield fork(StatisticStompSaga);
  yield fork(SendStatisticActionSaga);
  yield fork(healthCheckSaga);
}
