import React, { FC, memo } from "react";
import Check from "@mui/icons-material/Check";

import { Typography } from "@mui/material";

interface Props {
  isActive: boolean;
  caption: string;
}

const RightView: FC<Props> = ({ caption, isActive }) => (
  <Typography
    variant={isActive ? "body1" : "body2"}
    sx={{ display: "block", position: "relative", paddingLeft: "30px" }}
  >
    {isActive && (
      <Check
        color="success"
        sx={{ position: "absolute", left: "0", width: 22, height: 22 }}
      />
    )}
    <div>{caption}</div>
  </Typography>
);

export default memo(RightView);
