import { fork } from "redux-saga/effects";

import sendUsersProfile from "./sendUsersProfile.saga";
import getUsersProfileData from "./getUsersProfileData.saga";
import restoreUsersPass from "./restoreUsersPass.saga";

export default function*() {
  yield fork(sendUsersProfile);
  yield fork(getUsersProfileData);
  yield fork(restoreUsersPass);
}
