import { fork } from "redux-saga/effects";

import {
  getCategorySaga,
  getSkillgroupsSaga,
  saveCategoryModalSaga
} from "./CategoryModal.saga";

export default function*() {
  yield fork(getCategorySaga);
  yield fork(getSkillgroupsSaga);
  yield fork(saveCategoryModalSaga);
}
