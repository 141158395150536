import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/Charts.reducer";

function* getChartSaga({ payload: { name, filters }, type }) {
  yield put(actions.startLoading(`${type}/${name}`));

  try {
    const res = yield httpRequest("GET", `bff/dashboard/charts/stats_${name}`, {
      query: filters
    });

    if (res.success) {
      yield put(
        actions.setChart({
          [name]: res.data?.data
        })
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(actions.finishLoading(`${type}/${name}`));
  }
}

export default function*() {
  yield takeEvery([actions.getChart], getChartSaga);
}
