import produce from "immer";

import { CLOSE_APPEAL } from "../Consts";

export default function CloseDialogModalReducer(state = {}, { type, payload }) {
  switch (type) {
    default:
      return state;
  }
}
