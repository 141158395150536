import * as Yup from "yup";
import { REQUIRED_FIELD_ERROR } from "@omnichat/arm_ui_kit";

import { ERROR_INCORRECT_YEAR } from "./consts";

const MAX_STRING_LENGTH_50 = 50;
const MAX_STRING_LENGTH_256 = 256;
const getMaxStringLengthErrorText = (maxLength) => {
  return `Поле принимает не более ${maxLength} символов`;
};

export const schema = Yup.object({
  name: Yup.string()
    .trim()
    .required(REQUIRED_FIELD_ERROR)
    .max(MAX_STRING_LENGTH_50, getMaxStringLengthErrorText(MAX_STRING_LENGTH_50)),
  secName: Yup.string()
    .trim()
    .required(REQUIRED_FIELD_ERROR)
    .max(MAX_STRING_LENGTH_50, getMaxStringLengthErrorText(MAX_STRING_LENGTH_50)),
  penName: Yup.string()
    .trim()
    .max(MAX_STRING_LENGTH_256, getMaxStringLengthErrorText(MAX_STRING_LENGTH_256)),
  email: Yup.string()
    .trim()
    .email("Введен некорректный email-адрес ")
    .nullable()
    .max(MAX_STRING_LENGTH_50, getMaxStringLengthErrorText(MAX_STRING_LENGTH_50)),
  personnelNumber: Yup.string()
    .nullable()
    .matches(
      /^[0-9]*$/,
      "Некорректное значение. Может состоять только из цифр."
    )
    .max(50, getMaxStringLengthErrorText(50)),
  agentUid: Yup.string()
    .nullable()
    .max(30, getMaxStringLengthErrorText(30)),
  phoneNumber: Yup.string()
    .transform((value) => {
      return value.length > 0 ? value.replace(/[^\d]*/gi, "") : value;
    })
    .matches(/^$|[0-9]{10}$/, "Некорректный номер телефона")
    .nullable()
    .notRequired(),
  yearOfBirth: Yup.number()
    .nullable()
    .min(1950, ERROR_INCORRECT_YEAR)
    .max(new Date().getFullYear(), ERROR_INCORRECT_YEAR),

  areaId: Yup.number().nullable(),
  organizationId: Yup.number().nullable(),
  territoryId: Yup.number().nullable(),
  rate: Yup.number().nullable(),
  roles: Yup.array().min(1, REQUIRED_FIELD_ERROR),
  skillGroups: Yup.array()
    .min(1, REQUIRED_FIELD_ERROR)
    .of(Yup.object())
});

export const defaultValues = {
  name: "",
  secName: "",
  penName: "",
  email: "",
  personnelNumber: "",
  phoneNumber: "",
  yearOfBirth: null,
  areaId: null,
  organizationId: null,
  territoryId: null,
  rate: null,
  roles: [],
  skillGroups: []
};

export interface IFormData extends Yup.InferType<typeof schema> {}
