import { useMemo } from "react";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import initialState from "../../../ReactCore/store/initialState";
import { DTO } from "../UsersMultiProfileModal.d";

export const SEND_USERS_MULTI_PROFILE_DATA = "SEND_USERS_MULTI_PROFILE_DATA";

export const sendUsersMultiProfileData = createAction<{
  close: Function;
  formData: DTO;
}>(SEND_USERS_MULTI_PROFILE_DATA);

const UsersMultyProfileModalSlice = createSlice({
  name: "usersMultiProfileModal",
  initialState: initialState.UsersMultiProfileModal,
  reducers: {
    setUsersMultiProfileData(state, action: PayloadAction<DTO>) {
      state.formData = action.payload;
    },
    resetUsersMultiProfileData(state, action: PayloadAction<DTO>) {
      state.formData = initialState.UsersMultiProfileModal.formData;
    },
    setErrorMesssage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setSendDataHandling(state, action: PayloadAction<boolean>) {
      state.status.dataSendHandling = action.payload;
    }
  }
});

export const actionUsersMultiProfileModal = {
  ...UsersMultyProfileModalSlice.actions,
  sendUsersMultiProfileData
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionUsersMultiProfileModal, dispatch),
    [dispatch]
  );
};

export default UsersMultyProfileModalSlice.reducer;
