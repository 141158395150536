import React, { FunctionComponent } from "react";

import { Icon, isEmpty } from "@omnichat/arm_ui_kit";
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";

import { IThematicPickedListProps } from "./types";
import * as s from "./styles.module.scss";

/**
 * Список установленных тематик.
 */
const ThematicPickedList: FunctionComponent<IThematicPickedListProps> = ({
  errorMessage = null,
  thematics = [],
  editItem,
  cancelEdit,
  onDelete,
  onEdit
}) => {
  return (
    <>
      <div>
        {!isEmpty(thematics) &&
          thematics.map((thematic, index) => (
            <div key={index} className={s["thematicsListItem"]}>
              <div>{index + 1}.</div>
              <div className={s["thematicsListContent"]}>{thematic.text}</div>
              <div
                className={s["thematicsListDelete"]}
                onClick={() => onDelete(thematic.id)}
              >
                <Delete sx={{ color: "#0090DB" }} />
              </div>
              {thematic.canEdit && (
                <div
                  className={s["thematicsListEdit"]}
                  onClick={() =>
                    editItem === index ? cancelEdit() : onEdit(thematic.id)
                  }
                >
                  {editItem === index ? (
                    <Close sx={{ color: "#0090DB" }} />
                  ) : (
                    <Edit sx={{ color: "#0090DB" }} />
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
      {errorMessage && (
        <div className={s["thematicsListError"]}>
          <Icon color="red" name="warning" width="20px" height="20px" />
          <span>{errorMessage}</span>
        </div>
      )}

      <p>
        После закрытия обращения Вы не сможете написать в него сообщение, а
        клиенту будет отправлен запрос оценки качества обслуживания.
      </p>
    </>
  );
};

export default ThematicPickedList;
