import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/AgentCapacityModal.reducer";
import { sendFormDTO } from "../Store/AgentCapacityModal";
import Informer from "../../../arm/Wrappers/Informer";

export function* sendAgentCapacitySaga({
  payload: { formData, close }
}: PayloadAction<{
  formData: sendFormDTO;
  close: () => void;
}>) {
  yield put(actions.setSendDataHandling(true));
  try {
    const res = yield httpRequest(
      "POST",
      `bff/dashboard/agents/${formData.agent_id}`,
      {
        body: formData
      }
    );

    if (res?.success) {
      const informer = new Informer("Профиль успешно отредактирован");
      informer.show();
    } else {
      const informer = new Informer("Не удалось отредактировать профиль");
      informer.show();
      console.error(res?.error);
    }
    close();
  } catch ($error) {
    const informer = new Informer("Не удалось отредактировать профиль");
    informer.show();
    console.error($error);
  } finally {
    yield put(actions.setSendDataHandling(false));
  }
}

export default function*() {
  yield takeEvery([actions.sendAgentCapacityData], sendAgentCapacitySaga);
}
