import React, { FC, memo } from "react";

import { Box } from "@mui/material";
import { Icon, ScrollBox, isEmpty } from "@omnichat/arm_ui_kit";

import Button, { IButtonProps } from "../../../../Components/Button/Button";
import SettingsListItem from "../../../../Components/SettingsListItem";
import hasUserRight from "../../../../Utils/hasUserRight";
import { useRefScrollReturn } from "../../../../ReactFeatures/Common/Hooks/useRefScrollReturn";
import { SkillGroupData } from "../../UserGroupsManager.d";

import * as s from "./styles.module.scss";

interface GroupListProps {
  skillGroups?: SkillGroupData[];
  currentSkillGroupPage: number;
  onAddUser: () => void;
  onSelectSkillgroup: (id: string) => void;
  onEditSkillGroup: (id?: string) => void;
  onNextPage: () => void;
}

const GroupList: FC<GroupListProps> = ({
  skillGroups = [],
  currentSkillGroupPage,
  onAddUser,
  onSelectSkillgroup,
  onEditSkillGroup,
  onNextPage
}) => {
  const scrollReferenses = useRefScrollReturn({
    scrollCondition: currentSkillGroupPage === 0,
    deps: currentSkillGroupPage
  });

  const handleAddUser = () => onAddUser();

  const handleAddSkillGroup = () => onEditSkillGroup();

  const handleSelect = (id: string) => onSelectSkillgroup(id);

  const handleNextPage = () => onNextPage();

  const PlusBold: FC = () => (
    <Icon color="blue" name="plus_bold" width="1.2em" height="1.2em" />
  );

  const buttonProps: IButtonProps = {
    type: "bare",
    theme: "blue",
    iconPosition: "right",
    IconCustom: PlusBold,
    extraSX: {
      btnWrap: {
        "&.MuiButtonBase-root": {
          justifyContent: "space-between",
          padding: "10px 10px 10px 0",
          lineHeight: "1.25"
        }
      }
    }
  };

  return (
    <ScrollBox
      referens={scrollReferenses}
      autoHeight
      hasScrollControlBackground
      onPaginationBottom={handleNextPage}
    >
      <div className={s["scrollContentWrap"]}>
        <Box sx={{ boxSizing: "border-box" }}>
          {hasUserRight("create_users") && (
            <Button
              {...buttonProps}
              id="createUser"
              onClick={handleAddUser}
              text="Добавить сотрудника"
              variant="manage"
            />
          )}
          {hasUserRight("skills") && (
            <Button
              {...buttonProps}
              id="createSkillgroup"
              onClick={handleAddSkillGroup}
              text="Создать группу"
              variant="manage"
            />
          )}
        </Box>

        <div className={s["fieldBlockWrap"]}>
          <div className={s["skillgroupsWrapper"]}>
            <div className={s["skillgroupsHeader"]}>Группы</div>
          </div>

          <div>
            {!isEmpty(skillGroups) &&
              skillGroups.map(({ id, name, current, editable }) => (
                <SettingsListItem
                  key={id}
                  id={id}
                  caption={name}
                  isActive={current}
                  editable={editable && hasUserRight("skills")}
                  onSelect={handleSelect}
                  onEdit={onEditSkillGroup}
                />
              ))}
          </div>
        </div>
      </div>
    </ScrollBox>
  );
};

export default memo(GroupList);
