import { createAction } from "@reduxjs/toolkit";
import {
  setFulfilled,
  setPending,
  setRejected
} from "../../Common/ActionCreators/LoadingData.actionCreators";
import { IPageableData } from "../../Common/Interfaces/LoadingData.interfaces";
import { IGetReplyTemplatesPayload } from "../../Common/Interfaces/Template.interfaces";
import { RepliesTemplateEditModalTypes } from "../../RepliesTemplate/repliesEditModal";
import { IAppealComment, IRepliesGreeting } from "../Interfaces";

export const getPaginatedMessages = createAction(
  "GET_PAGINATED_MESSAGES",
  data => ({ payload: { ...data } })
);

export const getRepliesGreetingData = createAction<{ appealId: number }>(
  "GET_REPLIES_GREETING"
);

export const setRepliesGreeting = createAction<IRepliesGreeting>(
  "SET_REPLIES_GREETING"
);

export const getPrompterAnswers = createAction("GET_PROMPTER_ANSWERS");

export const sendMessage = createAction<any[]>("SEND_MESSAGE");

export const sendOperatorsTyping = createAction<any>("SEND_OPERATORS_TYPING");

export const saveAppealComment = createAction<IAppealComment>(
  "SAVE_APPEAL_COMMENT"
);

export const updateAppealComment = createAction<IAppealComment>(
  "UPDATE_APPEAL_COMMENT"
);

export const removeAppealComment = createAction<IAppealComment>(
  "REMOVE_APPEAL_COMMENT"
);

/**
 * Экшн для поиска шаблонов по введенному контексту.
 */
export const getReplyTemplatesByText = createAction<IGetReplyTemplatesPayload>(
  "GET_REPLY_TEMPLATES_BY_TEXT"
);

export const setReplyTemplatesByTextPending = setPending(
  "SET_REPLY_TEMPLATES_BY_TEXT",
  "foundByContextReplyTemplates"
);

export const setReplyTemplatesByTextFulfilled = setFulfilled<
  IPageableData<RepliesTemplateEditModalTypes.IReply[]>
>("SET_REPLY_TEMPLATES_BY_TEXT", "foundByContextReplyTemplates");

export const setReplyTemplatesByTextError = setRejected<string>(
  "SET_REPLY_TEMPLATES_BY_TEXT",
  "foundByContextReplyTemplates"
);

/**
 * Экшн для поиска шаблонов по введенному контексту.
 */
export const searchReplyTemplatesByText = createAction<
  IGetReplyTemplatesPayload
>("SEARCH_REPLY_TEMPLATES_BY_TEXT");

export const setOpenFromNotification = createAction<boolean>(
  "SET_OPEN_FROM_NOTIFICATION"
);
