import { useCallback, useEffect, useState } from "react";
import { httpRequest } from "../../ReactCore";
import { SettingType, SytemSetting } from "./SystemSettings";

export type UpdateSystemSetting = Pick<SytemSetting, "id" | "settingValue">;

export type SystemSettingItem = SytemSetting & { disabled?: boolean };

type SystemType2SystemSettling = Record<
  "integers" | "booleans",
  SystemSettingItem[]
>;

const BACKLOG_IS_ON = "backlog-skill-group-is-on";
const BACKLOG_SKILL_GROUP_ID = "backlog-skill-group-id";
const TRANSFER_IS_ON = "is-after-waiting-transfer-skill-group-enabled";
const TRANSFER_SKILL_GROUP_ID = "after-waiting-transfer-skill-group";

const keysBoolkeans2keysIntegers = {
  [BACKLOG_IS_ON]: BACKLOG_SKILL_GROUP_ID,
  [TRANSFER_IS_ON]: TRANSFER_SKILL_GROUP_ID
};

const updateSettings =
  (systemSetting: SystemSettingItem) => (pevSettings: SystemSettingItem[]) =>
    pevSettings.map((prevSetting) =>
      prevSetting.id === systemSetting.id ? systemSetting : prevSetting
    );

export const useSystemSettings = () => {
  const [settingsIntegers, setSettingsIntegers] = useState<SystemSettingItem[]>(
    []
  );
  const [settingsBooleans, setSettingsBooleans] = useState<SystemSettingItem[]>(
    []
  );

  const handleSaveSystemSetting = useCallback(
    (systemSetting: UpdateSystemSetting) =>
      httpRequest("POST", "bff/system-settings", {
        body: systemSetting
      }).then((res: { data: SytemSetting; success: boolean }) => {
        if (res.success && res.data?.settingType === SettingType.BOOLEAN) {
          setSettingsBooleans(updateSettings(res.data));

          const settingKey = keysBoolkeans2keysIntegers[res.data.settingKey];

          if (settingKey) {
            setSettingsIntegers((prevSystemSettings) =>
              prevSystemSettings.map((s) =>
                s.settingKey === settingKey
                  ? { ...s, disabled: res.data.settingValue !== "true" }
                  : s
              )
            );
          }
        }

        if (res.data.settingType === SettingType.INTEGER) {
          setSettingsIntegers(updateSettings(res.data));
        }
      }),
    []
  );

  useEffect(() => {
    httpRequest("GET", "bff/system-settings").then(
      (res: { data: SytemSetting[]; success: boolean }) => {
        if (!res.success) return;

        const backlogSkillGroupIsOn =
          res?.data?.find((s) => s.settingKey === BACKLOG_IS_ON)
            .settingValue === "true";

        const isAfterWaitingTransferSkillGroup =
          res?.data?.find((s) => s.settingKey === TRANSFER_IS_ON)
            .settingValue === "true";

        const { integers, booleans } =
          res?.data?.reduce<SystemType2SystemSettling>(
            (settings, systemSetting) => {
              if (systemSetting.settingType === SettingType.INTEGER) {
                const systemSettingItem: SystemSettingItem = systemSetting;

                if (systemSetting.settingKey === TRANSFER_SKILL_GROUP_ID) {
                  systemSettingItem.disabled =
                    !isAfterWaitingTransferSkillGroup;
                }

                if (systemSetting.settingKey === BACKLOG_SKILL_GROUP_ID) {
                  systemSettingItem.disabled = !backlogSkillGroupIsOn;
                }

                return {
                  ...settings,
                  integers: settings.integers.concat(systemSettingItem)
                };
              }

              return {
                ...settings,
                booleans: settings.booleans.concat(systemSetting)
              };
            },
            { integers: [], booleans: [] }
          );

        setSettingsIntegers(integers);
        setSettingsBooleans(booleans);
      }
    );
  }, []);

  return {
    handleSave: handleSaveSystemSetting,
    settingsIntegers,
    settingsBooleans
  };
};
