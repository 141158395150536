import { fork } from "redux-saga/effects";

import getFieldSaga from "./getField.saga";
import saveFieldSaga from "./saveField.saga";
import removeFieldSaga from "./removeField.saga";

import getTemplateSaga from "./getTemplate.saga";
import saveTemplateSaga from "./saveTemplate.saga";
import removeTemplateSaga from "./removeTemplate.saga";

import getValidateSaga from "./getValidate.saga";
import saveValidateSaga from "./saveValidate.saga";
import removeValidateSaga from "./removeValidate.saga";

export default function* () {
  yield fork(getFieldSaga);
  yield fork(saveFieldSaga);
  yield fork(removeFieldSaga);
  yield fork(getTemplateSaga);
  yield fork(saveTemplateSaga);
  yield fork(removeTemplateSaga);
  yield fork(getValidateSaga);
  yield fork(saveValidateSaga);
  yield fork(removeValidateSaga);
}
