import React, {
  FC,
  useRef,
  FocusEvent,
  ChangeEvent,
  KeyboardEvent
} from "react";
import {
  Box as MuiBox,
  Input as MuiInput,
  InputProps as MuiInputProps,
  SxProps,
  Theme
} from "@mui/material";

import Chip from "../Chip";
import Tooltip from "../Tooltip";
import FormField, { labelCaseType } from "../MuiFormField/FormField";
import { hash53b } from "../../Utils/hash53b.helper";

/**
 * Модель свойств Input.
 *
 * @prop {string} [value] Внешнее значение.
 * @prop {boolean} [disabled] Признак доступности компонента для редактирования.
 * @prop {string} [size] Размер Input. *
 * @prop {boolean} [isError] Признак наличия ошибки.
 * @prop {boolean} [required] Признак необходимости заполнения.
 * @prop {string} [label] Название над полем Input.
 * @prop {boolean} [labelCaseStyle] Переключатель регистра текста label.
 * @prop {string} [placeholder] Placeholder.
 * @prop {string} [actionText] Комментарий под полем Input.
 * @prop {number} [rows] Колличество строк.
 * @prop {object} [chips] Чипсы отображаемые над инпутом.
 * @prop {function} [onChange] Функция на изменение value.
 * @prop {function} [onBlur] Функция на снятие фокуса с компонента.
 * @prop {function} [onKeyDown] Функция на нажатие клавиши.
 * @prop {object as MuiSxProps} [extraSX] Кастомные стили на обертку для:
 * inputBox - внешняя обертка, labelBox - обертка лейбла,
 * withoutlabel - внутренняя обертка MuiInput, когда лейбл не указан.
 */
export interface IInputProps
  extends Omit<MuiInputProps, "onChange" | "onBlur" | "onKeyDown"> {
  value?: string;
  disabled?: boolean;
  size?: "medium" | "small";
  isError?: boolean;
  required?: boolean;
  label?: string;
  labelCaseStyle?: labelCaseType;
  placeholder?: string;
  actionText?: string;
  rows?: number;

  chips?: {
    name: string;
    value: string;
  }[];

  onChange?: (v: string, e?: ChangeEvent<HTMLInputElement>) => void;

  onBlur?: (v: string, e?: FocusEvent<HTMLInputElement>) => void;

  onKeyDown?: (e?: KeyboardEvent<HTMLInputElement>) => void;

  extraSX?: {
    inputBox?: SxProps<Theme>;
    labelBox?: SxProps<Theme>;
    withoutlabel?: SxProps<Theme>;
  };
}

const Input: FC<IInputProps> = ({
  value,
  disabled,
  size,
  isError = false,
  required,
  label,
  labelCaseStyle = "none",
  placeholder,
  actionText,
  rows,
  chips,
  onChange,
  onBlur,
  onKeyDown,
  extraSX,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const sxStyle = {
    baseStyle: {
      display: "flex",
      flexFlow: "column nowrap"
    },

    chips: {
      mb: "5px",
      display: "flex",
      flexWrap: "wrap",
      gap: "6px"
    },

    chipsUnderLabel: {
      mb: "5px"
    }
  };

  const handleChipClick = (v: string) => {
    let result = "";
    if (ref.current?.selectionEnd) {
      const { selectionEnd, value } = ref.current;
      result = value.slice(0, selectionEnd) + v + value.slice(selectionEnd);
    } else {
      result = `${value || ""}${v}`;
    }
    onChange?.(result);
  };

  interface IChipBox {
    chips?: {
      name: string;
      value: string;
    }[];
    isLabeled: boolean;
  }

  const ChipBox: FC<IChipBox> = ({ chips, isLabeled = false }): JSX.Element => {
    return (
      <MuiBox sx={[sxStyle.chips, isLabeled && sxStyle.chipsUnderLabel]}>
        {chips.map((c, i) => (
          <Tooltip key={`${i}-${c.name}`} content={`${c.name}`} position="top">
            <Chip label={c.name} onClick={() => handleChipClick?.(c.value)} />
          </Tooltip>
        ))}
      </MuiBox>
    );
  };

  const hashId = hash53b(
    `${actionText || ""} + ${label || ""} + ${placeholder || ""}`
  );

  const inputProps = {
    inputRef: ref,
    disableUnderline: true,
    disabled: disabled,
    error: isError,
    placeholder: placeholder,
    size: size,
    value: value || "",
    rows: rows,
    multiline: !!rows,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      onChange?.(e.target.value, e),
    onBlur: (e: FocusEvent<HTMLInputElement>) => onBlur?.(e.target.value, e),
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => onKeyDown?.(e),
    props: props
  };

  return (
    <MuiBox sx={{ ...sxStyle.baseStyle, ...(extraSX?.inputBox ?? {}) }}>
      {label || actionText || chips ? (
        <FormField
          label={label}
          labelCaseStyle={labelCaseStyle}
          required={required}
          disabled={disabled}
          actionText={actionText}
          isError={isError}
          inputId={hashId}
          extraSX={extraSX?.labelBox ?? {}}
        >
          {chips && <ChipBox chips={chips} isLabeled={!!label} />}

          <MuiInput
            id={`input-${hashId}`}
            aria-describedby={`action-text-${hashId}`}
            {...inputProps}
          />
        </FormField>
      ) : (
        <MuiInput
          sx={{ ...{ pt: 0 }, ...(extraSX?.withoutlabel ?? {}) }}
          {...inputProps}
        />
      )}
    </MuiBox>
  );
};

export default React.memo(Input);
