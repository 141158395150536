import { VipClientData, ValidationErrors, VipClientProps } from "./VipClient";

export const validateBeforeSubmit = (data: {
  login: VipClientData["login"];
  skillGroupId: VipClientData["skillGroupId"];
  loginsMap: VipClientProps["loginsMap"];
}): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!data.login) {
    errors.login = "Поле обязательно для заполнения";
  }

  if (data.skillGroupId === "default") {
    errors.skillGroupId = "Поле обязательно для заполнения";
  }

  if (data.login && data.loginsMap[data.login]) {
    errors.login = "Поле с таким значением уже определено";
  }

  return errors;
};

export const getInitialClientData = (
  clientData: VipClientProps["clientData"]
): VipClientData => ({
  id: clientData.id || Math.random(),
  login: clientData.login || "",
  skillGroupId: clientData.skillGroupId || "default"
});
