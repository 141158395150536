import React, { FunctionComponent } from "react";

import { IServiceTypeProps } from "./types";

import { SwitcherContainer, Select } from "@omnichat/arm_ui_kit";
import { Radio, FormField } from "../../../../Components";

import * as s from "./styles.module.scss";

const RadioGroup = SwitcherContainer(Radio);

const FEDERAL_SERVICE = 1;
const REGIONAL_SERVICE = 2;

/**
 * Выбор региона
 */
const ServiceType: FunctionComponent<IServiceTypeProps> = ({
  activeService = FEDERAL_SERVICE,
  onServiceChange = (serviceId: number) => {},
  regions = [],
  selected = null,
  onRegionChange = (regionId: number) => {}
}) => {
  return (
    <>
      <FormField label="ТИП УСЛУГИ" required>
        <>
          <RadioGroup
            name="service"
            caption="Федеральная услуга"
            checked={activeService === FEDERAL_SERVICE}
            onClick={() => onServiceChange(FEDERAL_SERVICE)}
          />
          <RadioGroup
            name="service"
            caption="Региональная услуга"
            checked={activeService === REGIONAL_SERVICE}
            onClick={() => onServiceChange(REGIONAL_SERVICE)}
          />
          {activeService === REGIONAL_SERVICE && (
            <div className={s["serviceTypeRegions"]}>
              <Select
                label=""
                required
                isError={selected ? selected.error : false}
                options={regions}
                selected={selected && selected.value ? [selected.value] : []}
                onSelectOption={(selected) => onRegionChange(selected)}
                isSearchable={true}
              />
            </div>
          )}
        </>
      </FormField>
    </>
  );
};

export default ServiceType;
