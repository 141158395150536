import React, { FC } from "react";
import { connect } from "react-redux";

import { StoreState } from "../../../ReactCore/store/types";
import { sendReopenData } from "../Actions/ReopenAppealModal.actionCreators";
import MoveAppealModalProvider from "./MoveAppealModal.provider";

/**
 * Модель свойств, полученных из store.
 *
 * @prop {IAsyncData} asyncAvailableHotKeysData Контейнер с данными МО трансфера,
 * которые получены асинхронно.
 */
interface IStateProps {}

/**
 * Экшены.
 */
interface IDispatchProps {
  sendReopenData: any;
}

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {number} appealId Идентификатор обращения.
 * @prop {Function} close Метод, закрывающий текущее модальное окно
 */
interface IOwnProps {
  appealId: string;
  onClose: () => void;
 afterConfirm: () => void;
}

type TProps = IOwnProps & IDispatchProps & IStateProps;

const ReopenAppealModalProvider: FC<TProps> = (props) => {
  const { appealId, onClose, sendReopenData, afterConfirm } = props;

  return (
    <MoveAppealModalProvider
      close={onClose}
      appealId={appealId}
      header="Открыть обращение?"
      onConfirm={sendReopenData}
      afterConfirm={afterConfirm}
    />
  );
};

const mapStateToProps = (state: StoreState.State): IStateProps => ({});

const mapDispatchToProps = {
  sendReopenData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReopenAppealModalProvider);
