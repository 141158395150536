import React from "react";

import { Icon } from "@omnichat/arm_ui_kit";

import * as s from "./ImageMessage.module.scss";

/**
 * Свойства компонента по отображению preview изображений.
 *
 * @prop {string} imgThumbSrc Ссылка на превью изображения.
 * @prop {string} imgSrc Ссылка изображения.
 * @prop {Function} onShowImageViewer Колбэк на открытие компонента для просмотра изображения.
 */
interface IImageMessageProps {
  imgThumbSrc: string;
  imgSrc: string;
  onShowImageViewer: (imageSrc: string) => void;
}

const ImageMessage: React.FunctionComponent<IImageMessageProps> = ({
  imgThumbSrc,
  imgSrc,
  onShowImageViewer,
}): JSX.Element => {
  /**
   * Обрабатывает клик по изображению.
   */
  function handleImageClick() {
    onShowImageViewer && onShowImageViewer(imgSrc);
  }

  return (
    <div onClick={handleImageClick} className={s["container"]}>
      <div className={s["shadowBgHover"]}>
        <Icon color="white" name="magnifier" width="10rem" height="10rem" />
      </div>

      <img className={s["containerImagePreview"]} src={imgThumbSrc} />
    </div>
  );
};

export default ImageMessage;
