import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";

import { store } from "../../ReactCore";

import SystemStatisticProvider from "../../ReactFeatures/SystemStatistics/SystemStatistics.provider";

const REACT_ROOT_SELECTOR = "#layout_root";

export default function initLayout() {
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  ReactDom.render(
    <Provider store={store}>
      <SystemStatisticProvider>
        <></>
      </SystemStatisticProvider>
    </Provider>,
    selector
  );
}
