import {
  FETCH_SKILLGROUP_CAPACITY,
  SET_SKILLGROUP_CAPACITY_TO_FORM,
  SEND_SKILLGROUP_CAPACITY,
  RESET_SKILLGROUP_CAPACITY
} from "./consts";

export const getSkillGroupCapacityData = skillId => ({
  type: FETCH_SKILLGROUP_CAPACITY,
  payload: { skillId }
});

export const setSkillGroupCapacityDataToForm = data => {
  if (data.queue === false) {
    data.queueLimit = 0;
  }
  return {
    type: SET_SKILLGROUP_CAPACITY_TO_FORM,
    payload: { data }
  };
};

export const sendSkillGroupCapacityData = () => ({
  type: SEND_SKILLGROUP_CAPACITY
});

export const resetStatusSkillGroupCapacity = () => ({
  type: RESET_SKILLGROUP_CAPACITY
});
