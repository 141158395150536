import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";

import { CREATE_DIALOG_BY_SESSION_ID } from "../Consts";

function* createDialogBySessionId(action) {
  const { code, cb } = action.payload;

  const response = yield httpRequest(
    "POST",
    `bff/chat/dialog/create-by-session-id`,
    {
      body: { code }
    }
  );

  if (!response?.success) {
    const informer = new Informer("Неизвестная ошибка", 1500);
    informer.show();
  }

  cb && cb();
}

export default function* () {
  yield takeEvery(CREATE_DIALOG_BY_SESSION_ID, createDialogBySessionId);
}
