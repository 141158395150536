import { isEmpty } from "@omnichat/arm_ui_kit";
import { setCsiData } from "../../ReactFeatures/Dialog/Reducers/Dialog.reducer";
import { store } from "../../ReactCore";
import { ICsiRateOperatorInfoPayload } from "./Interfaces";

const CsiRateOperatorInfoDtoAction = (payload: ICsiRateOperatorInfoPayload) => {
  if (!isEmpty(payload?.data?.csiRateInfoDto)) {
    store.dispatch(setCsiData(payload.data.csiRateInfoDto));
  }
};

export default CsiRateOperatorInfoDtoAction;
