import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  getChatbotslist,
  setError,
  removeChatbot
} from "../Store/Chatbots.reducer";

import Informer from "../../../arm/Wrappers/Informer";

function* getChatbotsSaga({ payload: { id, cb }, type }) {
  yield put(startLoading(type));

  try {
    const res = yield httpRequest("DELETE", `bff/settings/chatbots/${id}`);

    if (res.success) {
      yield cb?.();
      yield put(getChatbotslist());

      const info = new Informer(`Чат-бот был удалён`, 4500);
      info.show();
    } else {
      yield put(setError(res.error));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(finishLoading(type));
  }
}

export default function* () {
  yield takeEvery([removeChatbot], getChatbotsSaga);
}
