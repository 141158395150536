import React, { FC } from "react";
import { ScrollBox } from "@omnichat/arm_ui_kit";

import * as s from "./SectionGroups.module.scss";

interface ISectionGroups {
  title: string;
  onNextPage: () => void;
  children: JSX.Element;
}

const SectionGroups: FC<ISectionGroups> = ({ title, onNextPage, children }) => {
  const handleNextPage = () => onNextPage();

  return (
    <div className={s["sectionGroups"]}>
      <div className={s["sectionGroupsTitle"]}>
        <div className={s["sectionGroupsTitleText"]}>{title}</div>
      </div>

      <div className={s["sectionGroupsBody"]}>
        <ScrollBox
          autoHeight
          hasScrollControlBackground
          onPaginationBottom={handleNextPage}
        >
          <div className={s["sectionGroupsMain"]}>{children}</div>
        </ScrollBox>
      </div>
    </div>
  );
};

export default SectionGroups;
