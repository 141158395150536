import React, { FC } from "react";
import { Grid } from "@mui/material";
import { Button } from "@omnichat/arm_ui_kit";
import { hash53b } from "../../../../Utils/hash53b.helper";

import * as s from "./styles.module.scss";

export interface ConfirmModalProps {
  id?: string;
  title?: string;
  text: [ string ] | [ string, string ];
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  id,
  text="",
  title="Подтверждение удаления",
  onCancel,
  onConfirm
}) => {
  return (
    <div
      id={id ?? `confirmModal-${hash53b(text[0]+title)}`}
      className={s["modal"]}
    >
      <header className={s["header"]}>{title}</header>
      <p>{text[0]}</p>
      {text[1] && <p>{text[1]}</p>}

      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 8 }}
        sx={{ pt: 3 }}
      >

        <Grid container justifyContent="center" item xs={4}>
          <Button
            type="default"
            iconName="cross"
            iconColor="white"
            theme={"red"}
            onClick={() => onCancel?.()}
            text="Отменить"
          />
        </Grid>

        <Grid container justifyContent="center" item xs={4}>
          <Button
            type="default"
            iconName="check"
            iconColor="white"
            theme={"green"}
            onClick={() => onConfirm?.()}
            text="Продолжить"
          />
        </Grid>

      </Grid>
    </div>
  );
};

export default ConfirmModal;
