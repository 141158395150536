import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";

import {
  setUserStatus,
  setUserStatusPending,
  sendUserOnline
} from "../Store/OnlineToggle.reducer";

import { removeOfflineTimerFromStorage } from "../Utils";

function* sendUserOnlineSaga() {
  const response = yield httpRequest("PUT", `bff/user/online`);

  let textInformer;
  if (response?.success) {
    core.storage.set("msg_appeal_connect", true);
    removeOfflineTimerFromStorage();
    yield put(setUserStatus("ONLINE"));
    textInformer = "Получение новых обращений подключено";
  } else {
    yield put(setUserStatusPending(false));
    textInformer = "Произошла ошибка. Попробуйте еще раз";
    // Нет соединения для получения обращений
  }
  const informer = new Informer(textInformer, 1500);
  informer.show();
}

export default function* () {
  yield takeEvery([sendUserOnline], sendUserOnlineSaga);
}
