import { isEmpty } from "@omnichat/arm_ui_kit";
import { createSelector } from "reselect";

import { DTO, ErrorFields } from "../UsersProfileModal.d";
import { mapDtoToFormData } from "../utils";
import { StoreState } from "../../../ReactCore/store/types";
import { getOriginSkillGroup } from "../../../Settings/Users/Store/Users.selector"

export const getFormData = (state: StoreState.State): DTO =>
  state.UsersProfileModal.formData;

export const isNewProfile = (state: StoreState.State): boolean =>
  isEmpty(state.UsersProfileModal.formData?.id);

export const getDataSendHandling = (state: StoreState.State): boolean =>
  state.UsersProfileModal.status.dataSendHandling;

export const getUserPhoto = (state: StoreState.State): string =>
  state.UsersProfileModal.photo;

export const getErrorMessage = (state: StoreState.State): string =>
  state.UsersProfileModal.errorMessage;

export const getErrorFields = (state: StoreState.State): ErrorFields =>
  state.UsersProfileModal.errorFields || {};

export const getPreparedFormData = createSelector(
  getFormData,
  (formData: DTO) => {
    return formData ? mapDtoToFormData(formData as DTO) : null;
  }
);

export const userSkillGroups = createSelector(getOriginSkillGroup, (list) => {
  return list.map(l => l.id) || []
})
