import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import {
  isEmpty,
  Preloader,
  FileUploadInput,
  Button,
  DateInput,
  Avatar
} from "@omnichat/arm_ui_kit";
import c from "classnames";

import FormFieldConnector from "../../Components/FormFieldConnector";
import { ReactHookFormErrorHandler } from "../../Utils/HookForm.helper";
import {
  Input,
  Select,
  NumberInput,
  DragAndDropSelect,
  Autocomplete,
  ModalHeader
} from "../../Components";

import Informer from "../../arm/Wrappers/Informer";
import {
  pushFiles,
  sendFile,
  cleanFileList
} from "../FileStorage/fileStorage.actions";
import { FileStorageTypes } from "../FileStorage/fileStorage";
import * as dictionaryActions from "../Dictionaries/Store/Dictionaries.actions";
import * as dictionarySelectors from "../Dictionaries/Store/Dictionaries.selector";
import * as fileStorageSelectors from "../FileStorage/fileStorage.selector";

import { useActions } from "./Store/UsersProfileModal.reducer";
import * as selectors from "./Store/UsersProfileModal.selector";
import {
  checkIsValidFileExt,
  checkIsValidFileSize,
  isActive,
  getUploadedFileName,
  mapFormDataToDto
} from "./utils";
import * as styles from "./UsersProfileModal.module.scss";
import { UsersProfileProps } from "./UsersProfileModal.d";
import { schema, defaultValues, IFormData } from "./UsersProfileModal.schema";

const UsersProfileModal: FC<UsersProfileProps> = ({
  profileId,
  administratorId,
  close,
  onRestorePassword
}): JSX.Element => {
  const dispatch = useDispatch();
  const { ...actions } = useActions();

  const methods = useForm<IFormData, any, IFormData>({
    defaultValues,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, clearErrors, setError } = methods;

  const availableSkillsForAgent = useSelector(selectors.userSkillGroups);
  const dataSendHandling = useSelector(selectors.getDataSendHandling);
  const errorMessage = useSelector(selectors.getErrorMessage);
  const errorFields = useSelector(selectors.getErrorFields);
  const isDictionariesLoaded = useSelector(
    dictionarySelectors.getIsUsersDictionariesLoaded
  );
  const rating = useSelector(dictionarySelectors.getRatingToForm);
  const roles = useSelector(dictionarySelectors.getRolesToForm);
  const organizations = useSelector(dictionarySelectors.getOrganizationsToForm);
  const areas = useSelector(dictionarySelectors.getAreasToForm);
  const territories = useSelector(dictionarySelectors.getTerritoriesToForm);
  const dictionarySkillGroups = useSelector(
    dictionarySelectors.getSkillGroupsToForm
  );
  const formData = useSelector(selectors.getPreparedFormData);
  const photo = useSelector(selectors.getUserPhoto);
  const isNewProfile = useSelector(selectors.isNewProfile);
  const files = useSelector(fileStorageSelectors.getFiles);

  useEffect(() => {
    if (administratorId !== null && administratorId !== undefined) {
      dispatch(dictionaryActions.getUsersProfileCatalogs(administratorId));
    }

    if (profileId !== null && profileId !== undefined) {
      actions.getUsersProfileData({ profileId, forEdit: true });
    }

    return () => {
      actions.resetUsersProfileData();
      dispatch(cleanFileList());
    };
  }, []);

  useEffect(() => {
    if (!dataSendHandling) {
      reset(formData || defaultValues);
    }
  }, [formData]);

  useEffect(() => {
    if (!isEmpty(files)) {
      files.forEach((file: FileStorageTypes.IFile) => {
        !file.sended &&
          !file.pending &&
          dispatch(sendFile(file.uuid, updateProfileFileAvatar));
      });
    }
  }, [files]);

  useEffect(() => {
    if (Object.keys(errorFields).length) {
      Object.keys(errorFields).forEach((fieldName) => {
        setError(
          fieldName,
          { type: "custom", message: errorFields[fieldName] },
          { shouldFocus: true }
        );
      });
    }
  }, [errorMessage, errorFields]);

  const availableSkills = (allSkills) => {
    return allSkills?.map((i) => ({
      ...i,
      disabled: !availableSkillsForAgent.includes(i.value)
    }));
  };
  const skillsOptions = availableSkills(dictionarySkillGroups);
  const updateProfileFileAvatar = ({ data }) => {
    const fileName = getUploadedFileName(data);
    actions.setPhoto(fileName);
  };

  const handleSave = (data) => {
    actions.sendUsersProfileData({
      formData: {
        ...mapFormDataToDto(data),
        photo,
        administratorId
      },
      close
    });
  };

  const handleUploadAvatar = (event: any, file: any) => {
    if (!checkIsValidFileExt(file)) {
      const info = new Informer("Недопустимый формат файла", 4500);
      info.show();
      return;
    }

    if (!checkIsValidFileSize(file)) {
      const info = new Informer(
        `Размер файла должен быть менее ${MAX_AVATAR_SIZE_MB} Мб`,
        4500
      );
      info.show();
      return;
    }
    dispatch(pushFiles(file));
  };

  return (
    <Preloader
      size="mini"
      show={!isDictionariesLoaded || (profileId && formData === null)}
    >
      <div id="UserProfileEditModal" className={styles["usersProfileModal"]}>
        <ModalHeader>Профиль сотрудника</ModalHeader>

        <FormProvider {...methods}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item alignItems="center" xs={6}>
              <Avatar
                size="large"
                url={photo ? `${CROP_IMG_URL}${photo}` : null}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FileUploadInput
                label="Фото:"
                placeholder="Загрузить"
                onChange={handleUploadAvatar}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="secName"
                label="Фамилия"
                required
                Component={(p, state) => (
                  <Input
                    {...p}
                    isError={!isEmpty(state.error)}
                    placeholder="Не указана"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="name"
                label="Имя"
                required
                Component={(p, state) => (
                  <Input
                    {...p}
                    isError={!isEmpty(state.error)}
                    placeholder="Не указано"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="roles"
                label="Роль"
                required
                Component={(p, state) => {
                  return (
                    <Autocomplete
                      {...p}
                      placeholder="Не указана"
                      isMulti
                      isError={!isEmpty(state.error)}
                      options={roles}
                      selected={roles.filter((item) =>
                        p.value.includes(item.value)
                      )}
                      onSelectOption={(values) => {
                        clearErrors("roles");
                        p.onChange(values.map((role) => role.value));
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="email"
                label="email"
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    isError={!isEmpty(error)}
                    placeholder="mail@mail.ru"
                    onChange={(value) => {
                      p.onChange(value.replace(/[^a-zA-Z0-9._\-@]+/gi, ""));
                    }}
                    actionText={
                      error
                        ? ""
                        : "E-mail, на который будут высланы данные для входа в систему при создании пользователя"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="penName"
                label="Псевдоним"
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    placeholder="Не указан"
                    actionText={
                      error ? "" : "Имя оператора, которое видит клиент"
                    }
                    isError={!isEmpty(error)}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="rate"
                label="Рейтинг"
                Component={(p, { error }) => (
                  <Select
                    {...p}
                    label=""
                    options={rating}
                    selected={[{ value: p.value, label: p.label }]}
                    isError={!isEmpty(error)}
                    onSelectOption={(selected) => p.onChange(selected[0].value)}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="phoneNumber"
                label="Номер телефона"
                Component={(p, { error }) => (
                  <NumberInput
                    {...p}
                    maskInput={{
                      format: "+7 (###) ###-##-##",
                      filler: "_"
                    }}
                    theme={!isEmpty(error) ? "error" : "default"}
                    placeholder="Не указан"
                    isError={!isEmpty(error)}
                    value={p.value}
                    onChange={(v) => {
                      clearErrors("phoneNumber");
                      p.onChange(v.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="yearOfBirth"
                label="Год рождения"
                Component={(p, { error }) => (
                  <DateInput
                    isError={!isEmpty(error)}
                    onlyYear
                    placeholder="Не указан"
                    selected={Number(p.value)}
                    extraClass={[c(styles.usersProfileModalYearOfBirthField)]}
                    onSelectDay={(date) => {
                      clearErrors("yearOfBirth");
                      const year = new Date(date).getFullYear() + "";
                      p.onChange(year);
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="personnelNumber"
                label="Табельный номер"
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    value={p.value}
                    isError={!isEmpty(error)}
                    placeholder="Не указан"
                    actionText={error ? "" : ""}
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={6}>
              <FormFieldConnector
                name="agentUid"
                label="UID Сотрудника"
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    value={p.value}
                    isError={!isEmpty(error)}
                    placeholder="Не указан"
                    actionText={error ? "" : ""}
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12} mt={"-18px"} mb={1} pt="0">
              <Typography variant="body2">
                Могут быть использованы для связи пользователя с внешними
                системами
              </Typography>
            </Grid>

            {(!isEmpty(organizations) ||
              !isEmpty(formData?.organizationId)) && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="organizationId"
                  label="Признак организации"
                  Component={(p, { error }) => {
                    const selected = [{ value: p.value, label: p.label }];
                    return (
                      <Select
                        {...p}
                        placeholder="Не указан"
                        isError={
                          !isEmpty(error) || !isActive(organizations, p.value)
                        }
                        options={organizations}
                        selected={selected}
                        onSelectOption={(selected) =>
                          p.onChange(selected[0].value)
                        }
                        actionText={
                          !isEmpty(error)
                            ? ""
                            : isActive(organizations, p.value)
                            ? "Может быть использован для формировании отчетности во внешних системах"
                            : "Выбранная организация больше не активна"
                        }
                      />
                    );
                  }}
                />
              </Grid>
            )}
            {(!isEmpty(areas) || !isEmpty(formData?.areaId)) && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="areaId"
                  label="Площадка"
                  Component={(p, { error }) => (
                    <Select
                      {...p}
                      isError={!isEmpty(error) || !isActive(areas, p.value)}
                      options={areas}
                      placeholder="Не указан"
                      selected={[{ value: p.value, label: p.label }]}
                      onSelectOption={(selected) =>
                        p.onChange(selected[0].value)
                      }
                      actionText={
                        isActive(areas, p.value)
                          ? ""
                          : "Выбранная площадка больше не активна"
                      }
                    />
                  )}
                />
              </Grid>
            )}
            {(!isEmpty(territories) || !isEmpty(formData?.territoryId)) && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="territoryId"
                  label="Территория"
                  Component={(p, { error }) => (
                    <Select
                      {...p}
                      placeholder="Не указана"
                      isError={
                        !isEmpty(error) || !isActive(territories, p.value)
                      }
                      options={territories}
                      selected={[{ value: p.value, label: p.label }]}
                      onSelectOption={(selected) =>
                        p.onChange(selected[0].value)
                      }
                      actionText={
                        isActive(territories, p.value)
                          ? ""
                          : "Выбранная территория больше не активна"
                      }
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroups"
                label="скилл-группы операторов"
                required
                Component={(p, { error }) => (
                  <DragAndDropSelect
                    addText="Добавить группу"
                    required
                    isError={error}
                    selected={availableSkills(p.value)}
                    options={!isEmpty(skillsOptions) ? skillsOptions : []}
                    onChange={(e) => {
                      clearErrors("skillGroups");
                      p.onChange(e);
                    }}
                  />
                )}
              />
            </Grid>
            {errorMessage && (
              <Grid
                container
                justifyContent="center"
                item
                xs={12}
                paddingBottom={2}
              >
                <div className={styles["error"]}>{errorMessage}</div>
              </Grid>
            )}
            <Grid container rowSpacing={1} columnSpacing={2} mt={2} ml={0}>
              {!isNewProfile && (
                <Grid item xs={6}>
                  <Button
                    type="underline"
                    text="Восстановить пароль"
                    onClick={dataSendHandling ? () => null : onRestorePassword}
                  />
                </Grid>
              )}

              <Grid item xs={isNewProfile ? 12 : 6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: profileId ? "end" : "center"
                  }}
                >
                  <Button
                    type="default"
                    disabled={dataSendHandling}
                    theme={dataSendHandling ? "disabled" : "green"}
                    onClick={handleSubmit(
                      (data) => handleSave({ ...(formData || {}), ...data }),
                      ReactHookFormErrorHandler
                    )}
                    text="Cохранить"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
    </Preloader>
  );
};

export default UsersProfileModal;
