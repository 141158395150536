import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";
import {
  sendSkillGroupData,
  setSendDataHandling
} from "../Store/SkillGroupModal.reducer";

const ERROR_MESSAGE = "Произошла ошибка при сохранении данных";

export function* sendSkillGroupDataSaga({ payload }) {
  yield put(setSendDataHandling(true));

  const { formData, callback } = payload;
  const {
    skillGroup: { id: skillId }
  } = formData;
  let informerMessage = skillId
    ? "Группа была отредактирована"
    : "Группа была создана";
  try {
    const path = skillId
      ? `bff/settings/skill-groups/${skillId}/edit`
      : `bff/settings/skill-groups/create`;

    const response = yield httpRequest("POST", path, {
      body: formData
    });

    if (response?.success) {
      callback();
    } else {
      informerMessage = ERROR_MESSAGE;
    }
  } catch ($error) {
    informerMessage = ERROR_MESSAGE;
    console.error($error);
  } finally {
    yield put(setSendDataHandling(false));
  }
  const info = new Informer(informerMessage);
  info.show();
}

export default function*() {
  yield takeEvery([sendSkillGroupData], sendSkillGroupDataSaga);
}
