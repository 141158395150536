import { takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { httpRequest } from '../../../ReactCore/shared/httpRequest';
import Informer from "../../../arm/Wrappers/Informer";
import { actionUsers, IActionDropdownMenuUsers, USERS_TO_ARCHIVE } from '../Store/Users.reducer';

export function* usersToArchive({
  payload: { userIds, isAll, skillGroupId, query }
}: PayloadAction<IActionDropdownMenuUsers>) {

  const body = {
    userIds: isAll ? [] : userIds.map((id) => +id),
    isAll: isAll,
    skillGroupId: skillGroupId,
    query: query
  };

  try {
    const res = yield httpRequest(
      "DELETE",
      `bff/settings/profiles/multi/deactivate-users`,
      { body }
    );
    if (res?.success) {
      yield put(actionUsers.archivedUsersSuccess(userIds));
      const info = new Informer(
        `Изменения успешно сохранены`,
        4500
      );
      info.show();
    } else {
      const info = new Informer(
        `Не удалось перенести пользователя в архив`,
        2500
      );
      info.show();
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* () {
  yield takeEvery(USERS_TO_ARCHIVE, usersToArchive);
}
