import { takeEvery, put } from "redux-saga/effects";
import { isEmpty } from "@omnichat/arm_ui_kit";

import { httpRequest } from "../../ReactCore/shared/httpRequest";

import {
  getChatInitialData,
  updateDialogList,
  setChatInitialDataLoaded,
  setCsiSettings,
  updateUnreadList
} from "../store/Chat.actionCreators";
import {
  setUserStatus,
  getUserSubstatus
} from "../../ReactFeatures/OnlineToggle/Store/OnlineToggle.reducer";
import { mapDialogPreviewDataFromChatInit, setUnreadList } from "../Chat.service";
import { logoutUser } from "../../ReactFeatures/OnlineToggle/Utils";

function* getChatInitialDataSaga({ payload: callback }) {
  const response = yield httpRequest("GET", `bff/chat/init/`);

  if (isEmpty(response)) return;

  callback?.(response.usersAppeals?.data);

  if (response.userStatus?.data === "LOGOFF") {
    logoutUser();
    return;
  }

  yield put(getUserSubstatus(response.userStatus?.data));
  yield put(setUserStatus(response.userStatus?.data));
  core.storage.set(
    "msg_appeal_connect",
    response.userStatus?.data === "ONLINE"
  );
  core.storage.set("msg_appeal_count", response.usersAppeals?.data.length);
  yield put(
    updateDialogList(
      mapDialogPreviewDataFromChatInit(response.usersAppeals?.data)
    )
  );
  yield put(updateUnreadList(setUnreadList(response.usersAppeals?.data)))
  yield put(setCsiSettings(response.csiSettings?.data));
  yield put(setChatInitialDataLoaded());
}

export default function*() {
  yield takeEvery([getChatInitialData], getChatInitialDataSaga);
}
