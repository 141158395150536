import { createAction } from "@reduxjs/toolkit";
import {
  setFulfilled,
  setPending,
  setRejected
} from "../../Common/ActionCreators/LoadingData.actionCreators";

/**
 * Создаст экшн для получения данных по горячим клавишам.
 *
 * @param {number} appealId Идентификатор обращения.
 */
export const getAvailableHotKeysData = createAction<any | undefined>(
  "GET_HOT_KEYS_DATA"
);

/**
 * Создаст экшн для перевода статуса данных по горячим клавишам в PENDING.
 */
export const setHotKeysDataPending = setPending(
  `HOT_KEYS_DATA`,
  "availableHotKeysData"
);

/**
 * Экшн для прокидывания данных по горячим клавишам при успешном получении.
 */
export const setHotKeysDataFulfilled = setFulfilled(
  `HOT_KEYS_DATA`,
  "availableHotKeysData"
);

/**
 * Экшн для прокидывания ошибки при неспешном завершении сервиса по горячим клавишам.
 */
export const setHotKeysDataRejected = setRejected(
  `HOT_KEYS_DATA`,
  "availableHotKeysData"
);
