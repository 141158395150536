import React, { FC } from "react";
import { Preloader } from "@omnichat/arm_ui_kit";
import c from "classnames";

import * as styles from "./style.module.scss";

/**
 * Свойства компонента Radio.
 */
export interface ILoadingProps {
  show: boolean;
}

/**
 * Компонент Radio.
 */
const Loading: FC<ILoadingProps> = ({ show = true }) => (
  <div className={c(styles["loadingBg"], { [styles["active"]]: show })}>
    <Preloader color="white" show={show}></Preloader>
  </div>
);

export default Loading;
