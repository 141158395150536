import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";
import {
  getOfflineSubstatuses,
  setOfflineReasonList
} from "../Store/OnlineToggle.reducer";

function* getOfflineSubStatusesSaga() {
  const res = yield httpRequest("GET", `bff/user/offline-statuses`);

  if (res?.success) {
    yield put(setOfflineReasonList(res.data));
  } else {
    const informer = new Informer("Произошла ошибка. Попробуйте еще раз", 1500);
    informer.show();
  }
}

export default function* () {
  yield takeEvery([getOfflineSubstatuses], getOfflineSubStatusesSaga);
}
