export const preparePhoneToMask = (phone: number | string): string | null => {
  let preparedVal = String(phone)
    .replace(/[^0-9]/gi, "")
    ?.match(/\d{10}$/);

  if (preparedVal && preparedVal[0]) {
    return (preparedVal[0] as string) || null;
  }

  return null;
};
