import { ClassifierFormTypes } from "./ClassifierForm";

const ClassifierForm: ClassifierFormTypes.State = {
  loading: [],
  classifierData: null,
  errorMessage: null,
  channels: [],
  mrfs: [],
  skillGroups: []
};

export default ClassifierForm;
