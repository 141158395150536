import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import { store } from "../../ReactCore";
import ThemeProvider from "../../Components/ThemeProvider";
import Roles from "./Roles.provider";

const REACT_ROOT_SELECTOR = "#settings_roles";

export default function initRolesSettings() {
  // @ts-ignore
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  ReactDom.render(
    <ThemeProvider>
      <HashRouter>
        <Provider store={store}>
          <Roles />
        </Provider>
      </HashRouter>
    </ThemeProvider>,
    selector
  );
}
