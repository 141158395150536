
import React from "react";

import InfoField from "../../InfoField/InfoField";

interface IAdditionalClientInfoProps {
  /** Дополнительные данные о клиенте. */
  data: object | undefined;
}

const AdditionalClientInfo: React.FunctionComponent<IAdditionalClientInfoProps> = ({
  data
}): JSX.Element => {
  const FragmentData: React.FunctionComponent = (props): JSX.Element => {
    const { id, children } = props;
    return (
      <React.Fragment key={id}>
        {id ? id + ":" : ""}
        {children}
        <br />
      </React.Fragment>
    );
  };

  const getAdditionalUserDataContent = function(
    value: any,
    id?: string | number
  ): JSX.Element {
    if (typeof value !== "object") {
      return (
        <FragmentData key={id} id={id}>
          {value}
        </FragmentData>
      );
    }
    return (
      <FragmentData key={id} id={id}>
        <br />
        {Object.keys(value as object).map((el, i) => {
          return getAdditionalUserDataContent(value[el], el);
        })}
      </FragmentData>
    );
  };

  const renderAdditionalUserData = (d?: object) => {
    if (typeof d !== "object") return "";
    return Object.keys(d as object).map((value, key) => {
      if (!d[value]) return value;
      return (
        <React.Fragment key={key}>
          <InfoField label={value}>
            {typeof d[value] === "object"
              ? getAdditionalUserDataContent(d[value])
              : d[value]}
          </InfoField>
        </React.Fragment>
      );
    });
  };

  return <>{renderAdditionalUserData(data)}</>;
};

export default AdditionalClientInfo;
