import { ISelectedData } from "./DashboardFilters";

import { DEFAULT_LOWER_TIME_LIMIT, DEFAULT_UPPER_TIME_LIMIT } from "./consts";

import {
  convertToMoscowTimestamp,
  createDateFromUTCTimestamp,
  toUnixTimeStamp,
  dateToFormatString,
  stringToDate
} from "../../ReactFeatures/Common/Utils/DateAndTime.utils";

import { DashboardQueryParams } from "../DashboardSection/DashboardSection";
import { IData } from "./Components/FilterPanel/FilterPanel";

function prepareDate(date: Date, defaultLimit: string) {
  let result: string | number = "";

  if (date) {
    const timestamp = convertToMoscowTimestamp(date);

    result = toUnixTimeStamp(timestamp, defaultLimit);
  }

  return result;
}

export const prepareFromDate = (date) => {
  return prepareDate(date, DEFAULT_UPPER_TIME_LIMIT);
};
export const prepareToDate = (date) => {
  return prepareDate(date, DEFAULT_LOWER_TIME_LIMIT);
};

export const prepareQueryParams = (data: IData): DashboardQueryParams => {
  const params = {} as DashboardQueryParams;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (key === "remark_birthday") {
        params[key] = data[key] ? dateToFormatString(data[key]) : "";
      } else if (key === "date_start") {
        params[key] = `${prepareDate(data[key], DEFAULT_UPPER_TIME_LIMIT)}`;
      } else if (key === "date_end") {
        params[key] = `${prepareDate(data[key], DEFAULT_LOWER_TIME_LIMIT)}`;
      } else if (key === "appeal") {
      } else if (key === "tpls") {
      } else if (
        key === "remark_phone" ||
        key === "remark_fio" ||
        key === "client"
      ) {
        params[key] = data[key]?.length ? data[key] : "";
      } else if (key === "without_themes") {
        params[key] = data[key] === "1" ? "1" : "0";
      } else if (data[key]) {
        params[key] = data[key];
      }
    }
  }

  return params;
};

export const getFiltersSelectedData = (
  query: DashboardQueryParams
): ISelectedData => {
  let filtersSelectedData = {} as ISelectedData;

  function prepareDate(date: string, defaultLimit: string) {
    return createDateFromUTCTimestamp(+date, defaultLimit);
  }

  if (query.date_start) {
    filtersSelectedData.date_start = prepareDate(
      query.date_start,
      DEFAULT_UPPER_TIME_LIMIT
    );
  }

  if (query.date_end) {
    filtersSelectedData.date_end = prepareDate(
      query.date_end,
      DEFAULT_LOWER_TIME_LIMIT
    );
  }

  if (query.channel) {
    filtersSelectedData.channel = query.channel;
  }

  if (query.status) {
    filtersSelectedData.status = query.status;
  }

  if (query.csi) {
    filtersSelectedData.csi = query.csi;
  }

  if (query.sl) {
    filtersSelectedData.sl = query.sl;
  }

  if (query.region) {
    filtersSelectedData.region = query.region;
  }

  if (query.mrf) {
    filtersSelectedData.mrf = query.mrf;
  }

  if (query.rf) {
    filtersSelectedData.rf = query.rf;
  }

  if (query.who_processed) {
    filtersSelectedData.who_processed = query.who_processed;
  }

  if (query.client) {
    filtersSelectedData.client = query.client;
  }

  if (Number(WAITING_CONTROL_FILTER)) {
    filtersSelectedData.without_themes = query.without_themes || "0";
  }

  filtersSelectedData.remark_fio = query.remark_fio || "";
  filtersSelectedData.remark_phone = query.remark_phone || "";
  filtersSelectedData.remark_birthday = query.remark_birthday
    ? stringToDate(query.remark_birthday)
    : "";

  return filtersSelectedData;
};
