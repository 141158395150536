import { bytesToMegabytes } from "../../arm/Constructors/Utils";
import { IFormData } from "./UsersProfileModal.schema";
import { DTO } from "./UsersProfileModal.d";

export const checkIsValidFileExt = (file: File) => {
  const fileName = file[0].name;
  const ext = fileName
    .split(".")
    .pop()
    .toLowerCase();
  const avatarExt = ["jpg", "jpeg", "png"];
  return Boolean(avatarExt.find((item) => item === ext));
};

export const checkIsValidFileSize = (file: File) => {
  const fileSize = bytesToMegabytes(file[0].size);
  return fileSize < MAX_AVATAR_SIZE_MB;
};

export const isActive = (catalog: any, value) => {
  if (!value) return true;
  const result = catalog.find((item) => item.value === value);
  return result?.active || false;
};

export const getSingleFieldValue = (id, catalog = []) => {
  const item = catalog.find((item) => item.id === id);

  return item ? item.name : null;
};

export const getMultiFieldValue = (arrIds, catalog = []) => {
  let result = [];

  if (arrIds) {
    arrIds.map((id) => {
      const item = catalog.find((item) => item.value === id);
      item && result.push(item.label);
    });
  }
  return result;
};

export const getUploadedFileName = (data): string => {
  const ext = data.originalName
    .split(".")
    .pop()
    .toLowerCase();
  return `${data.uuid}.${ext}`;
};

export const mapFormDataToDto = ({ file, ...data }): DTO => {
  let preparedData = {} as IFormData;
  for (let key in data) {
    preparedData[key] =
      typeof data[key] === "string" && data[key].length === 0
        ? null
        : data[key];
  }

  return {
    ...preparedData,
    skillGroups: preparedData.skillGroups.map((group) => group.value)
  };
};

export const mapDtoToFormData = ({
  skillGroups,
  phoneNumber,
  ...data
}): IFormData => ({
  skillGroups: skillGroups
    ? skillGroups.map((value) => ({
        value: value,
        label: value
      }))
    : skillGroups,
  phoneNumber: phoneNumber ? phoneNumber % 70000000000 : phoneNumber,
  ...data
});
