import produce from "immer";

import { SET_ADDITIONAL_FIELDS_DATA } from "./consts";

import initialState from "../../ReactCore/store/initialState";

export default function appealInfoReducer(
  state = initialState.AdditionalFields,
  { type, payload }
) {
  switch (type) {
    default:
      return state;
  }
}
