import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import initialState from "../../../ReactCore/store/initialState";
import { Field } from "../ClassifierLinkForm";

export const GET_FIELDS_DATA = "GET_FIELDS_DATA";
export const GET_VALUES_DATA = "GET_VALUES_DATA";

export const getFieldsList = createAction(GET_FIELDS_DATA, (data) => ({
  payload: data
}));

export const getValuesList = createAction(GET_VALUES_DATA, (data) => ({
  payload: data
}));

const classifierLinkFormSlice = createSlice({
  name: "ClassifierLinkForm",
  initialState: initialState.ClassifierLinkForm,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setFieldsList(state, action: PayloadAction<Field[]>) {
      state.fieldValuesList = action.payload;
    },
    setValuesList(
      state,
      action: PayloadAction<{ fieldId: string; data: Field[] }>
    ) {
      const { fieldId, data } = action.payload;
      const updatedFieldList = [...state.fieldValuesList].map((f) =>
        `${f.id}` === fieldId ? { ...f, values: data } : f
      );
      state.fieldValuesList = updatedFieldList;
    },
    resetFieldsValuesList(state) {
      state.fieldValuesList = initialState.ClassifierLinkForm.fieldValuesList;
    }
  }
});

export const {
  startLoading,
  finishLoading,
  resetFieldsValuesList,
  setFieldsList,
  setValuesList
} = classifierLinkFormSlice.actions;

export default classifierLinkFormSlice.reducer;
