import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  startLoading,
  finishLoading,
  removeTemplate,
  REMOVE_TEMPLATE
} from "../Store/ClassifierFieldForm.reducer";

export function* removeTemplateSaga(action) {
  const { fieldId, templateId, cb } = action.payload;
  yield put(startLoading(REMOVE_TEMPLATE));

  try {
    const res = yield httpRequest(
      "DELETE",
      `bff/settings/classifier/fields/${fieldId}/templates/${templateId}`
    );

    if (res?.success) {
      yield cb();
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(REMOVE_TEMPLATE));
  }
}

export default function* () {
  yield takeEvery([removeTemplate], removeTemplateSaga);
}
