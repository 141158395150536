// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard--IfeGeCmM0OG1xrdgEKtw{display:flex;flex-direction:row;height:100%}.dashboard__left--tj5etKLzQ1nzWSjfe5L_{height:100%}.dashboard__right--CHOY15Y5lAbmBBoyx9M1{display:flex;flex-direction:column;height:100%;width:100%;padding-left:25px;overflow:hidden}.dashboard__head--SW3_Cn9T8U8u8nQJ7a7_{display:flex;border-bottom:1px #cbd7da solid}.dashboard__tabs--oiRcVAQKqvL3hfxWPb7d{padding:18px 65px 12px 0}.dashboard__export--b51i5LXOQgxNEIRrMhGS{padding:18px 18px 2px 0;margin-left:auto;display:flex;align-items:center}.dashboard__filters--c20OXWV3CCKCOPwCvZMT{margin-top:20px;padding-bottom:30px;padding-right:25px}.dashboard__items--o9QxI0wxaKhnv4OUlcKS{flex:1;padding-bottom:30px}.hide--viE51zLf5n2QjS4eX8CA{display:none}", "",{"version":3,"sources":["webpack://./frontend/src/Dashboard/DashboardSection/style.module.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,kBAAA,CACA,WAAA,CAEA,uCACE,WAAA,CAGF,wCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CAGF,uCACE,YAAA,CACA,+BAAA,CAGF,uCACE,wBAAA,CAGF,yCACE,uBAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CAGF,0CACE,eAAA,CACA,mBAAA,CACA,kBAAA,CAGF,wCACE,MAAA,CACA,mBAAA,CAIJ,4BACE,YAAA","sourcesContent":[".dashboard {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n\n  &__left {\n    height: 100%;\n  }\n\n  &__right {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n    padding-left: 25px;\n    overflow: hidden;\n  }\n\n  &__head {\n    display: flex;\n    border-bottom: 1px #cbd7da solid;\n  }\n\n  &__tabs {\n    padding: 18px 65px 12px 0;\n  }\n\n  &__export {\n    padding: 18px 18px 2px 0;\n    margin-left: auto;\n    display: flex;\n    align-items: center;\n  }\n\n  &__filters {\n    margin-top: 20px;\n    padding-bottom: 30px;\n    padding-right: 25px;\n  }\n\n  &__items {\n    flex: 1;\n    padding-bottom: 30px;\n  }\n}\n\n.hide {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export var dashboard = "dashboard--IfeGeCmM0OG1xrdgEKtw";
export var dashboardLeft = "dashboard__left--tj5etKLzQ1nzWSjfe5L_";
export var dashboardRight = "dashboard__right--CHOY15Y5lAbmBBoyx9M1";
export var dashboardHead = "dashboard__head--SW3_Cn9T8U8u8nQJ7a7_";
export var dashboardTabs = "dashboard__tabs--oiRcVAQKqvL3hfxWPb7d";
export var dashboardExport = "dashboard__export--b51i5LXOQgxNEIRrMhGS";
export var dashboardFilters = "dashboard__filters--c20OXWV3CCKCOPwCvZMT";
export var dashboardItems = "dashboard__items--o9QxI0wxaKhnv4OUlcKS";
export var hide = "hide--viE51zLf5n2QjS4eX8CA";
export default ___CSS_LOADER_EXPORT___;
