import React, { FC } from "react";

import SectionTitle from "../SectionTitle/SectionTitle";
import * as s from "./SettingsSection.module.scss";

interface ISettingsSectionProps {
  title: string;
  menuWidth?: string;
  menu?: JSX.Element;
  content: JSX.Element;
}

const SettingsSection: FC<ISettingsSectionProps> = ({
  title,
  menuWidth = "260px",
  menu = null,
  content,
}) => (
  <div className={s["settingsSectionWrapper"]}>
    <div className={s["settingsSectionTitle"]}>
      <SectionTitle title={title} />
    </div>

    <div className={s["settingsSectionBody"]}>
      {menu && (
        <div className={s["settingsSectionMenu"]} style={{ width: menuWidth }}>
          {menu}
        </div>
      )}
      <div className={s["settingsSectionContent"]}>{content}</div>
    </div>
  </div>
);

export default SettingsSection;
