import { useMemo } from "react";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import initialState from "../../../ReactCore/store/initialState";
import { initFormDTO, sendFormDTO } from "./AgentCapacityModal.d";
import { useDispatch } from "react-redux";

export const GET_AGENT_CAPACITY_DATA = "GET_AGENT_CAPACITY_DATA";
export const SEND_AGENT_CAPACITY_DATA = "SEND_AGENT_CAPACITY_DATA";

export const getAgentData = createAction<{ agentId: number }>(
  GET_AGENT_CAPACITY_DATA
);

export const sendAgentCapacityData = createAction<{
  close: Function;
  formData: sendFormDTO;
}>(SEND_AGENT_CAPACITY_DATA);

const AgentCapacityModalSlice = createSlice({
  name: "AgentCapacityModal",
  initialState: initialState.AgentCapacityModal,
  reducers: {
    setAgentCapacityData(state, { payload }: PayloadAction<initFormDTO>) {
      state.formData = payload;
    },
    resetAgentCapacityData(state) {
      state.formData = initialState.AgentCapacityModal.formData;
    },
    setErrorMesssage(state, { payload }: PayloadAction<string>) {
      state.errorMessage = payload;
    },
    setSendDataHandling(state, { payload }: PayloadAction<boolean>) {
      state.status.dataSendHandling = payload;
    }
  }
});

export const actions = {
  ...AgentCapacityModalSlice.actions,
  getAgentData,
  sendAgentCapacityData
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export default AgentCapacityModalSlice.reducer;
