import React, { FC } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { Button } from "@omnichat/arm_ui_kit";

import * as cs from "../../../styles/common.module.scss";
import { IActionDropdownMenuUsers } from "../Store/Users.reducer";
import { restoreUsersPass } from "../../../ReactFeatures/UsersProfileModal/Store/UsersProfileModal.reducer";

import * as s from "./RestorePasswordModal.module.scss";

export interface RestoreData {
  targetUsersInfo: IActionDropdownMenuUsers;
  close: () => void;
}

interface DataActionTargetUsers {
  userIds: string[];
  isAll: boolean;
  skillGroupId: string;
  query: string;
}

interface RestorePasswordProps {
  targetUsersAction: DataActionTargetUsers;
  onCancel: () => void;
  onConfirm: () => void;
}

const RestorePasswordModal: FC<RestorePasswordProps> = ({
  targetUsersAction,
  onCancel,
  onConfirm
}) => {
  const dispatch = useDispatch();

  const confirmHandle = () => {
    dispatch(
      restoreUsersPass({
        targetUsersInfo: targetUsersAction,
        close: onConfirm
      })
    );
  };

  return (
    <div
      id="restorePasswordModal"
      className={cn(cs["defaultModal"], s["restorePasswordModal"])}
    >
      <header
        className={cn(
          cs["defaultModalHeader"],
          s["restorePasswordModalHeader"]
        )}
      >
        Восстановить пароль?
      </header>

      <div className={s["restorePasswordModalCaption"]}>
        Текущий пароль сотрудника(ов) будет сброшен и автоматически сгенерирован
        новый. Если в профиле сотрудника не указан e-mail,
        <br />
        новый пароль будет отправлен Вам. Хотите продолжить?
      </div>

      <div className={s["restorePasswordModalButtons"]}>
        <Button
          type="default"
          iconName="cross"
          iconColor="white"
          theme="red"
          onClick={onCancel}
          text="Отменить"
        />

        <Button
          type="default"
          iconName="check"
          iconColor="white"
          theme={"green"}
          onClick={confirmHandle}
          text="Продолжить"
        />
      </div>
    </div>
  );
};

export default RestorePasswordModal;
