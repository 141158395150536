import { AnyAction } from "redux";
import { EDOWNLOAD_STATUS } from "../Enums/LoadingData.enums";

/**
 * Редьюсер для установки статуса PENDING для контейнера данных.
 *
 * @param {string} field Наименование поля в ветке store.
 */
export function setPendingReducer(state, { payload }: AnyAction) {
  if (payload?.meta?.key && state[payload?.meta.key]) {
    state[payload?.meta.key].status = EDOWNLOAD_STATUS.PENDING;
    state[payload?.meta.key].error = null;
  }
}

/**
 * Редьюсер для установки статуса PENDING для контейнера данных.
 *
 * @param {string} field Наименование поля в ветке store.
 */
export function setFulfilledReducer(state, { payload }: AnyAction) {
  if (payload?.meta?.key && state[payload?.meta.key]) {
    state[payload?.meta.key].status = EDOWNLOAD_STATUS.FULFILLED;
    state[payload?.meta.key].data = payload.data;
    state[payload?.meta.key].error = null;
  }
}

/**
 * Редьюсер для установки статуса PENDING для контейнера данных.
 *
 * @param {string} field Наименование поля в ветке store.
 */
export function setRejectedReducer(state, { payload }: AnyAction) {
  if (payload?.meta?.key && state[payload?.meta.key]) {
    state[payload?.meta.key].status = EDOWNLOAD_STATUS.REJECTED;
    state[payload?.meta.key].error = payload.error;
  }
}
