import React, { memo, useState, useEffect, SyntheticEvent, FC } from "react";
import { isEmpty } from "@omnichat/arm_ui_kit";
import {
  Autocomplete as MuiAutocomplete,
  Box,
  TextField,
  Chip
} from "@mui/material";

import ActionText from "../ActionText";

/**
 * Свойства option`s Select.
 * @prop {string | number} value значение option
 * @prop {string} label подпись option
 * @prop {string | any} key ключевое поле
 */
export interface IOption {
  value: string | number;
  label: string;
  [key: string]: string | any;
}

/**
 * Свойства компонента Select.
 * @prop {IOption[]} options список для отображения в селекте
 * @prop {IOption[]} selected выбранный вариант
 * @prop {Function} onOpen обработчик события открытия списка
 * @prop {selected: (IOption[]) => void} onSelectOption обработчик события выбора варианта
 * @prop {(option: IOption) => JSX.Element} children
 * @prop {stringt} placeholder
 * @prop {boolean} isMulti признак мультивыбора
 * @prop {boolean} isSearchable Возможность поиска
 * @prop {string} iconName Наименование иконки
 * @prop {boolean} isError признак ошибки ввода
 * @prop {(string | object)[]} extraClassOptions дополнительный стиль вариантов селекта
 * @prop {string} label
 * @prop {boolean} required является ли обязательным для заполнения
 * @prop {string} actionText текст подсказки при обязательном заполнении поля
 */
interface ISelectProps {
  options: IOption[];
  selected: IOption[];
  disabled?: boolean;
  onOpen?: () => void;
  onSelectOption: (selected: IOption[], e: SyntheticEvent) => void;
  children?: (option: IOption) => JSX.Element;
  placeholder?: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  iconName?: string;
  isError?: boolean;
  extraClassWrapper?: (string | object)[];
  extraClassOptions?: (string | object)[];
  label?: string;
  required?: boolean;
  actionText?: string;
  onPaginationBottom?: () => void;
  onSearch?: (query: string) => void;
  isPending?: boolean;
}

const Autocomplete: FC<ISelectProps> = ({
  options = [],
  selected = [],
  onOpen,
  onSelectOption = (): void => {},
  isMulti,
  isError = false,
  disabled = false,
  actionText,
  placeholder = "Не указано",
  ...props
}) => {
  let inputRef;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      inputRef.blur();
      return;
    }
    inputRef.focus();
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e: SyntheticEvent<Element, Event>): void => {
    if (disabled || open) {
      return;
    }
    setOpen(true);
    onOpen && onOpen();
  };

  const handleChange = (
    e: React.SyntheticEvent,
    option: (string | IOption)[] | NonNullable<string | IOption> | null
  ) => {
    if (option === null) {
      onSelectOption([], e);
      return;
    }

    if (options.length === selected.length + 1) {
      handleClose();
    }

    if (Array.isArray(option)) {
      onSelectOption(option as IOption[], e);
    } else {
      onSelectOption([option as IOption], e);
    }
  };

  return (
    <Box display="flex" flexDirection="column" onClick={handleOpen}>
      <MuiAutocomplete<IOption, boolean, boolean, boolean>
        {...props}
        id="tags-outlined"
        disableClearable
        clearOnBlur
        disableCloseOnSelect={isMulti}
        filterSelectedOptions={isMulti}
        clearIcon={null}
        multiple={isMulti}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        error={isError}
        value={isMulti ? selected : selected[0] ?? null}
        onChange={handleChange}
        disabled={disabled}
        options={options}
        noOptionsText="Не найдено ни одного значения"
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => (option as IOption)?.label ?? ""}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={(input) => {
              inputRef = input;
            }}
            variant="standard"
            placeholder={isEmpty(selected) && placeholder ? placeholder : ""}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              size="small"
              key={option.value}
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
        }
      />

      {actionText && <ActionText isError={isError} actionText={actionText} />}
    </Box>
  );
};

export default memo(Autocomplete);
