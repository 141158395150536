import produce from "immer";

import * as ACTION from "../Consts";
import initialState from "../CategoryModal.initialData";

export default function CategoryModalReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ACTION.SET_ALL:
      return produce(state, draft => {
        draft.all = !draft.all;
      });
    case ACTION.SET_NAME:
      return produce(state, draft => {
        draft.name = payload;
      });
    case ACTION.SET_SELECTED_SKILLGROUPS:
      return produce(state, draft => {
        draft.selectedGroups = payload;
      });
    case ACTION.SET_SKILLGROUPS:
      return produce(state, draft => {
        draft.skillGroups = payload;
      });
    case ACTION.SET_CATEGORY:
      return produce(state, draft => {
        draft.all = payload.all;
        draft.categoryId = payload.id;
        draft.name = payload.name;
        draft.selectedGroups = payload.skillgroups;
      });
    case ACTION.RESET:
      return produce(state, draft => {
        draft.all = initialState.all;
        draft.categoryId = initialState.categoryId;
        draft.name = initialState.name;
        draft.selectedGroups = initialState.selectedGroups;
        draft.skillGroups = initialState.skillGroups;
      });
    default:
      return state;
  }
}
