import { store } from "../../ReactCore";

import { IWsNewMessage } from "../../ReactFeatures/Dialog/Interfaces";

import * as chatSelectors from "../store/Chat.selectors";
import * as chatActions from "../store/Chat.actionCreators";

import * as dialogSelectors from "../../ReactFeatures/Dialog/Dialog.selectors";
import { resetDialogData } from "../../ReactFeatures/Dialog/Reducers/Dialog.reducer";

import { getWithRemovedDialogPreview } from "../Chat.service";

const appealRemoveAction = (payload: IWsNewMessage) => {
  let dialogList = chatSelectors.getDialogList(store.getState());
  let activeDialog = dialogSelectors.getActiveDialog(store.getState());

  if (!dialogList.length && !payload.data && !payload.data.dialog_id) return;

  if (activeDialog && ~payload.data.dialog_id.indexOf(activeDialog)) {
    store.dispatch(resetDialogData());
  }
  store.dispatch(
    chatActions.updateDialogList(
      getWithRemovedDialogPreview(dialogList, payload.data.dialog_id)
    )
  );
};

export default appealRemoveAction;
