import React from "react";
import c from "classnames";

import { Avatar } from "@omnichat/arm_ui_kit";

import * as s from "./CustomOptionGroup.module.scss";

interface ICustomOptionGroupProps {
  label: string;
  isSelected?: boolean;
  online?: boolean;
  group?: string;
  avatar?: string;
  count?: number;
  onSelectOption?: () => void;
}

const CustomOptionGroup: React.FunctionComponent<ICustomOptionGroupProps> = ({
  label,
  isSelected = false,
  online = false,
  group,
  avatar,
  count,
  onSelectOption = () => {},
}): JSX.Element => {
  return (
    <div
      onClick={onSelectOption}
      className={c(s["option"], { [s["optionSelected"]]: isSelected })}
    >
      {avatar != undefined && (
        <Avatar url={avatar} extraClass={[s["optionAvatar"]]} />
      )}

      <div className={s["optionInformation"]}>
        <div className={s["optionDescription"]}>
          <p>{label}</p>
          {group && <p>{group}</p>}
        </div>

        <div
          className={c(s["optionState"], {
            [s["optionStateOffline"]]: !online,
          })}
        >
          <span>
            {`
              ${count && count > 0 ? count : ""}
              ${online ? "online" : "offline"}
            `}
          </span>

          <span />
        </div>
      </div>
    </div>
  );
};

export default CustomOptionGroup;
