// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chartBlock--H1Ki44MB2AcrrqOzIQtp{position:relative;height:200px;margin-bottom:65px;margin-right:10px}.title--VKb2ELxXu5yv5mRvvzmw{display:flex;line-height:130%;color:#829199;text-transform:uppercase;font-size:12px;font-weight:bold;margin-bottom:20px}.sum--QlZ2pqntft7TqX8f6PnG{color:#525f67;font-size:13px;margin-left:5px}.empty--UMY7Dw_TI9MleJ_ZSTEN{background:#fff;color:#a0abb1;text-align:center;line-height:200px}", "",{"version":3,"sources":["webpack://./frontend/src/Dashboard/Charts/Components/Chart/style.module.scss"],"names":[],"mappings":"AAAA,kCACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CAGF,6BACE,YAAA,CACA,gBAAA,CACA,aAAA,CACA,wBAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CAGF,2BACE,aAAA,CACA,cAAA,CACA,eAAA,CAEF,6BACE,eAAA,CACA,aAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".chartBlock {\n  position: relative;\n  height: 200px;\n  margin-bottom: 65px;\n  margin-right: 10px;\n}\n\n.title {\n  display: flex;\n  line-height: 130%;\n  color: #829199;\n  text-transform: uppercase;\n  font-size: 12px;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n\n.sum {\n  color: #525f67;\n  font-size: 13px;\n  margin-left: 5px;\n}\n.empty {\n  background: #fff;\n  color: #a0abb1;\n  text-align: center;\n  line-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export var chartBlock = "chartBlock--H1Ki44MB2AcrrqOzIQtp";
export var title = "title--VKb2ELxXu5yv5mRvvzmw";
export var sum = "sum--QlZ2pqntft7TqX8f6PnG";
export var empty = "empty--UMY7Dw_TI9MleJ_ZSTEN";
export default ___CSS_LOADER_EXPORT___;
