import { string } from "prop-types";
import { OnlineToggleTypes } from "../OnlineToggle";

const OnlineToggleInitial: OnlineToggleTypes.State = {
  status: {
    isPending: false,
    online: false,
    substatusId: null,
    startStatusDate: null
  },
  offlineReasonList: null
};

export default OnlineToggleInitial;
