import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../ReactCore/store/types";
import { IRegion } from "../../Dialog/Interfaces";

import {
  ThematicModalTypes,
  IComputedField,
  IComputedTheme,
  ISelectedFieldsValue
} from "../Interfaces";

import { Preloader } from "@omnichat/arm_ui_kit";

import {
  getThemes,
  getThemesFields,
  setSelectedFieldsValues,
  deleteTheme,
  editTheme,
  cancelEdit,
  saveTheme,
  setRegion,
  setServiceTag,
  resetData,
  validateResult
} from "../Actions/ThematicModal.action";
import ThematicModal from "../Components/ThematickModal";
import { getRegions } from "../../Dictionaries/Store/Dictionaries.actions";

import {
  getComputedFields,
  getServiceRegion,
  getComputedThemes,
  selectRegions,
  getActiveService,
  getSelectedRegion,
  getErrorMessage,
  getServiceEnabled,
  getEditThemeId
} from "../ThematicModal.selectors";

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  appealId: string;
  onConfirm: () => void;
}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {
  editThemeId: number | null;
  errorMessage?: string;
  activeService?: number;
  selectedRegion?: ThematicModalTypes.ISelectedRegion;
  regions: { value: number; label: string }[];
  themesFields: IComputedField[];
  appealThemes: IComputedTheme[];
  serviceRegion: IRegion;
  serviceEnabled: boolean;
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  cancelEdit: () => void;
  getRegions: () => void;
  getThemes: (appealId: string) => any;
  getThemesFields: (appealId: number) => any;
  resetData: () => any;
  saveTheme: () => any;
  deleteTheme: (id: number) => any;
  editTheme: (id: number) => any;
  setRegion: (id: number) => any;
  setServiceTag: (id: number) => any;
  validateResult: () => any;
  setSelectedFieldsValues: (selectedValues: ISelectedFieldsValue[]) => any;
}

type ThematicModalProviderProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * Провайдер фичи ThematicModal.
 */
class ThematicModalProvider extends PureComponent<ThematicModalProviderProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { appealId, getRegions, getThemes, resetData } = this.props;
    resetData();
    getThemes(appealId);
    getRegions();
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  /**
   * Добавить новые тематики в список
   */
  saveFields = () => {
    this.props.saveTheme();
  };

  onSendThemes = () => {
    const {
      onConfirm,
      validateResult,
      appealThemes,
      selectedRegion,
      activeService,
      serviceEnabled
    } = this.props;

    validateResult();

    if (!appealThemes.length) return false;

    if (serviceEnabled && activeService === 2 && !selectedRegion) return false;

    onConfirm();
  };

  changeFieldsValues = (data) => {
    this.props.setSelectedFieldsValues(data);
  };

  render() {
    const {
      regions,
      themesFields,
      appealThemes,
      editThemeId,
      cancelEdit,
      editTheme,
      deleteTheme,
      setRegion,
      setServiceTag,
      selectedRegion,
      activeService,
      errorMessage,
      serviceEnabled
    } = this.props;

    return (
      <>
        <Preloader
          size="mini"
          show={!regions.length || !themesFields || !appealThemes}
        >
          <ThematicModal
            serviceType={serviceEnabled}
            activeService={activeService}
            regions={regions}
            selectedRegions={selectedRegion}
            onServiceChange={(selected) => setServiceTag(selected)}
            onRegionChange={(selected) => setRegion(selected[0])}
            thematics={appealThemes}
            themesFields={themesFields}
            editItem={editThemeId}
            cancelEdit={cancelEdit}
            onFieldsChange={(data) => this.changeFieldsValues(data)}
            onDeleteThematic={(id) => deleteTheme(id)}
            onEditThematic={(id) => editTheme(id)}
            onSaveFields={() => this.saveFields()}
            errorMessage={errorMessage}
            onSend={() => this.onSendThemes()}
          />
        </Preloader>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({
  editThemeId: getEditThemeId(state),
  errorMessage: getErrorMessage(state),
  activeService: getActiveService(state),
  selectedRegion: getSelectedRegion(state),
  regions: selectRegions(state),
  themesFields: getComputedFields(state),
  appealThemes: getComputedThemes(state),
  serviceRegion: getServiceRegion(state),
  serviceEnabled: getServiceEnabled(state)
});

const mapDispatchToProps = (dispatch) => ({
  resetData: () => dispatch(resetData()),
  getRegions: () => dispatch(getRegions()),
  setRegion: (id) => dispatch(setRegion(id)),
  setServiceTag: (id) => dispatch(setServiceTag(id)),
  deleteTheme: (id) => dispatch(deleteTheme(id)),
  editTheme: (id) => dispatch(editTheme(id)),
  cancelEdit: () => dispatch(cancelEdit()),
  saveTheme: () => dispatch(saveTheme()),
  validateResult: () => dispatch(validateResult()),
  getThemes: (appealId) => dispatch(getThemes(appealId)),
  getThemesFields: (appealId) => dispatch(getThemesFields(appealId)),
  setSelectedFieldsValues: (selectedValues) =>
    dispatch(setSelectedFieldsValues(selectedValues))
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThematicModalProvider);

export default Connect;
