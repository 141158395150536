export const PUSH_FILES = "PUSH_FILES";

export const REMOVE_FILE = "REMOVE_FILE";

export const CANCEL_PENDING = "CANCEL_PENDING";

export const SEND_FILE = "SEND_FILE";

export const UPDATE_PROGRESS = "UPDATE_PROGRESS";

export const FILE_LOADED = "FILE_LOADED";

export const FILE_LOAD_FAILED = "FILE_LOAD_FAILED";

export const CLEAN_FILE_LIST = "CLEAN_FILE_LIST";
