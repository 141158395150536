import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  getClassifierCatalogs,
  setChannelsList,
  setMrfList,
  setSkillGroupList,
  setAvailableSkillGroupList,
  startLoading,
  finishLoading,
  GET_CLASSIFIER_CATALOGS
} from "../Store/ClassifierForm.reducer";

export function* getClassifierCatalogsSaga() {
  yield put(startLoading(GET_CLASSIFIER_CATALOGS));
  try {
    const catalogs = yield httpRequest(
      "GET",
      "bff/settings/classifier/catalogs"
    );

    if (catalogs?.mrfs?.success) {
      yield put(setMrfList(catalogs.mrfs.data));
    }
    if (catalogs?.channels?.success) {
      yield put(setChannelsList(catalogs.channels.data));
    }
    if (catalogs?.skillGroups?.success) {
      yield put(setSkillGroupList(catalogs.skillGroups.data.content));
    }
    if (catalogs?.availableSkillGroups?.success) {
      yield put(
        setAvailableSkillGroupList(catalogs.availableSkillGroups.data.content)
      );
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(GET_CLASSIFIER_CATALOGS));
  }
}

export default function* () {
  yield takeEvery([getClassifierCatalogs], getClassifierCatalogsSaga);
}
