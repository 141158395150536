import { takeEvery, put, all } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { IThemeField } from "../../../ReactFeatures/ThematicModal/Interfaces";

import {
  getThemesFields,
  setThemesFields,
  GET_CLISSIFIER_THEMES_FIELDS,
  startLoading,
  finishLoading
} from "../Store/Classifiers.reducer";

/**
 * Сага получения тем
 */
function* getThemesFieldsData(action) {
  yield put(startLoading(GET_CLISSIFIER_THEMES_FIELDS));

  const classifierId = action.payload;
  try {
    if (classifierId) {
      const response: { data: IThemeField[]; success: boolean } =
        yield httpRequest("GET", `bff/chat/thematics/themes/${classifierId}`);

      if (response?.success) {
        let fields = [...response.data];

        fields = yield all(
          fields.map(async (f) => {
            if (!["TEXTAREA", "INPUT"].includes(f.type)) return f;

            const templates = await httpRequest(
              "GET",
              `bff/settings/classifier/fields/${f.id}/templates`
            );

            return {
              ...f,
              templates: templates?.data || []
            };
          })
        );

        fields = yield all(
          fields.map(async (f) => {
            if (!["TEXTAREA", "INPUT"].includes(f.type)) return f;

            const validations = await httpRequest(
              "GET",
              `bff/settings/classifier/fields/${f.id}/validations`
            );

            return {
              ...f,
              validations: validations?.data || []
            };
          })
        );

        yield put(setThemesFields(fields));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(finishLoading(GET_CLISSIFIER_THEMES_FIELDS));
  }
}

export default function* () {
  yield takeEvery([getThemesFields], getThemesFieldsData);
}
