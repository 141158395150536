import React, { FC } from "react";
import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from "@mui/material";

export interface IChipProps extends MuiBadgeProps {
  id?: string;
};

const Chip: FC<IChipProps> = ({ ...props }) => {
  return <MuiBadge {...props} />;
};

export default Chip;
