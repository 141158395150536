import React, { FC, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Preloader,
  Button,
  getToday,
  DatePicker,
  REQUIRED_FIELD_ERROR,
  isEmpty,
  FieldTitle
} from "@omnichat/arm_ui_kit";

import { Checkbox, FormFieldConnector, Autocomplete } from "../../Components";
import SectionTitle from "../Common/Components/SectionTitle/SectionTitle";
import { ReactHookFormErrorHandler } from "../../Utils/HookForm.helper";

import { useActions } from "./Store/AppealReport.reducer";
import {
  loading,
  reportCreating,
  modalData,
  file,
  skillGroups,
  modalState
} from "./Store/AppealReport.selector";

import Informer from "../../arm/Wrappers/Informer";

import {
  validatePeriodLessOneWeek,
  secondsInWeek,
  setEndOfTheDay
} from "./utils";

import { selectedFilters } from "../../Dashboard/DashboardFilters/Store/DashboardFilters.selectors";

import * as s from "./style.module.scss";

const schema = Yup.object({
  dates: Yup.object({
    dateStart: Yup.date().required(REQUIRED_FIELD_ERROR),
    dateEnd: Yup.date().required(REQUIRED_FIELD_ERROR)
  }),
  allSkill: Yup.boolean(),
  skillIds: Yup.array().when(["allSkill"], {
    is: (isAll) => !isAll,
    then: (sch) => sch.min(1, REQUIRED_FIELD_ERROR).of(Yup.number()),
    otherwise: (sch) => sch.of(Yup.number())
  })
});

const defaultValues = {
  dates: {
    dateStart: new Date(),
    dateEnd: new Date()
  },
  allSkill: true,
  skillIds: []
};

export enum STATUS {
  "READY",
  "PROCESSED",
  "ERROR"
}

type Props = {};

export interface IModalForm extends Yup.InferType<typeof schema> {}

const AppealsReportModal: FC<Props> = ({}) => {
  const {
    getSkillGroups,
    getAppealReport,
    createAppealReport,
    downloadAppealReport
  } = useActions();

  const state = useSelector(modalState);
  const isLoading = useSelector(loading);
  const isReportCreating = useSelector(reportCreating);
  const filters = useSelector(selectedFilters);
  const data = useSelector(modalData);
  const metaData = useSelector(file);
  const groupList = useSelector(skillGroups);

  const methods = useForm<IModalForm, any, IModalForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch } = methods;

  const allGroups = watch("allSkill");

  useEffect(() => {
    getSkillGroups();
    getAppealReport();
  }, []);

  useEffect(() => {
    if (data) {
      reset({
        dates: {
          dateStart: new Date(data.dateStart),
          dateEnd: new Date(data.dateEnd)
        },
        allSkill: data.allSkill,
        skillIds: data.skillIds
      });
    } else {
      const isMoreThenWeek = !validatePeriodLessOneWeek(
        filters.date_start,
        filters.date_end
      );
      const start = isMoreThenWeek
        ? new Date(filters.date_end.getTime() - secondsInWeek)
        : new Date(filters.date_start);
      const end = new Date(filters.date_end);

      isMoreThenWeek && showWarning();

      reset({
        ...defaultValues,
        dates: {
          dateStart: start,
          dateEnd: end
        }
      });
    }
  }, [data]);

  const handleDate = (start, end, prevDates) => {
    if (validatePeriodLessOneWeek(start, end)) {
      return {
        dateStart: start,
        dateEnd: end !== start ? end : setEndOfTheDay(end)
      };
    } else {
      showWarning();

      return {
        dateStart: prevDates.dateStart,
        dateEnd: prevDates.dateEnd
      };
    }
  };

  const showWarning = () => {
    const info = new Informer(`Диапазон не может быть больше недели`, 3500);
    info.show();
  };

  return (
    <div id="AppealsReportModal">
      <FormProvider {...methods}>
        <Grid container spacing={1} width={600}>
          <Grid item alignItems="center" xs={12} paddingBottom={2}>
            <SectionTitle title="Экспорт обращений" />
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <FormFieldConnector
              name="dates"
              label="Период"
              required
              Component={(p) => (
                <DatePicker
                  {...p}
                  required
                  max={getToday()}
                  popupPosition="bottom"
                  dates={{
                    startDate: p.value.dateStart,
                    endDate: p.value.dateEnd
                  }}
                  onChangeDates={(v) => {
                    p.onChange(handleDate(v.startDate, v.endDate, p.value));
                  }}
                />
              )}
            />
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <FieldTitle label="Группы" required />
            <FormFieldConnector
              name="allSkill"
              required
              Component={(p) => (
                <Checkbox
                  {...p}
                  captionPosition="end"
                  caption="Все группы"
                  checked={p.value ? true : false}
                />
              )}
            />
            {!allGroups && (
              <FormFieldConnector
                name="skillIds"
                Component={(p, { error }) => (
                  <Autocomplete
                    {...p}
                    placeholder="Не указана"
                    isMulti
                    isError={!isEmpty(error)}
                    options={groupList}
                    selected={groupList.filter((item) =>
                      p.value.includes(item.value)
                    )}
                    onSelectOption={(v) => p.onChange(v.map((v) => v.value))}
                  />
                )}
              />
            )}
          </Grid>

          <Grid container item xs={12}>
            {state.reportFailed ? (
              <div className={s.loaderError}>
                Функционал временно недоступен. Пожалуйста, попробуйте позже
              </div>
            ) : (
              <>
                {isReportCreating && (
                  <div className={s.exportFileBlock}>
                    <div className={s.loaderWraper}>
                      <div className={s.loader}>
                        <Preloader size="mini" show></Preloader>
                      </div>
                      Формирование отчета
                    </div>
                    <div className={s.loaderText}>
                      Вы можете закрыть окно и вернуться к нему позже
                    </div>
                  </div>
                )}
                {!isReportCreating && metaData?.fileName && (
                  <Button
                    type="fill"
                    theme="default"
                    text={metaData.fileName}
                    onClick={() => downloadAppealReport()}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid container justifyContent="center" item xs={12} marginTop={3}>
            <Button
              type="default"
              theme={isLoading ? "disabled" : "bare"}
              onClick={handleSubmit((formData) => {
                const { dates, allSkill, skillIds } = formData;
                createAppealReport({
                  dateStart: dates.dateStart.getTime() / 1000,
                  dateEnd: dates.dateEnd.getTime() / 1000,
                  allSkill,
                  skillIds
                });
              }, ReactHookFormErrorHandler)}
              text="Сформировать отчет"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </div>
  );
};

export default AppealsReportModal;
