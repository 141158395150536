import { fork } from "redux-saga/effects";

import sendUserOnlineSaga from "./sendUserOnline.saga";
import sendUserOfflineSaga from "./sendUserOffline.saga";
import sendUserLogoffSaga from "./sendUserLogoff.saga";
import getOfflineSubStatusesSaga from "./getOfflineSubstatuses.saga";
import transferAllAppealsSaga from "./transferAppeals.saga";
import sendUserSubstatusSaga from "./sendUserSubstatus.saga";
import getUserSubstatusSaga from "./getUserSubstatus.saga";

export default function*() {
  yield fork(sendUserOnlineSaga);
  yield fork(sendUserOfflineSaga);
  yield fork(sendUserLogoffSaga);
  yield fork(getOfflineSubStatusesSaga);
  yield fork(transferAllAppealsSaga);
  yield fork(sendUserSubstatusSaga);
  yield fork(getUserSubstatusSaga);
}
