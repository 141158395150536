import { takeEvery, put, all } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { isEmpty } from "@omnichat/arm_ui_kit";

import {
  getThemesFields,
  setThemesFields,
  setThemesFieldsLoaded
} from "../Actions/ThematicModal.action";

/**
 * Сага получения тем
 */
function* getThemesFieldsData(action) {
  const appealId = action.payload;

  const classifierId = yield getClassifierId(appealId);
  try {
    if (classifierId) {
      const response = yield httpRequest(
        "GET",
        `bff/chat/thematics/themes/${classifierId}`
      );

      if (response?.success) {
        let fields = [...(response?.data || [])];

        fields = yield all(
          fields.map(async (f) => {
            if (!["TEXTAREA", "INPUT"].includes(f.type)) return f;

            const templates = await httpRequest(
              "GET",
              `bff/settings/classifier/fields/${f.id}/templates`
            );

            return {
              ...f,
              templates: templates?.data || []
            };
          })
        );

        fields = yield all(
          fields.map(async (f) => {
            if (!["TEXTAREA", "INPUT"].includes(f.type)) return f;

            const validations = await httpRequest(
              "GET",
              `bff/settings/classifier/fields/${f.id}/validations`
            );

            return {
              ...f,
              validations: validations?.data || []
            };
          })
        );

        yield put(setThemesFields(fields));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setThemesFieldsLoaded());
  }
}

function* getClassifierId(appealId) {
  const result = yield httpRequest(
    "GET",
    `bff/chat/thematics/classifier/${appealId}`
  );

  if (result?.success && result.data?.id) {
    return result.data.id;
  }
  return false;
}

export default function* () {
  yield takeEvery([getThemesFields], getThemesFieldsData);
}
