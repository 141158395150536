import { isNil } from "../../../Common/Utils/CommonUtils";
import { captureKeyCodesStuff } from "../../../Common/Utils/Text.utils";
import { IKeyCodesStuff } from "../../../Common/Interfaces/HotKeys";
import { useState, useEffect, useRef } from "react";

/**
 * Типы хука по захвату сочетаний клавиш.
 */
declare namespace KeyCodesCaptureHookTypes {
  /**
   * Модель состояния хука по захвату кодов зажатых клавиш.
   *
   * @prop {Set<number>} pressedCodes Коллекция кодов, зажатых в данный момент клавиш.
   * @prop {string} hotKeyDescription Описание сочетания горячих клавиш.
   * @prop {string} hotKeyCodes Числовые коды сочетания горячих клавиш.
   */
  interface IState extends IKeyCodesStuff {}

  /**
   * Модель возвращаемого значения хуком.
   */
  type IReturnType<T> = [IState, ISetState, IKeyCodesCapture<T>];

  /**
   * Модель функции для прокидывания состояния.
   */
  interface ISetState {
    (newState: IState, callback?: () => void): void;
  }

  /**
   * Модель функции для захвата кодов сочетания клавиш.
   */
  interface IKeyCodesCapture<T> {
    (event: React.KeyboardEvent<T>): IState;
  }
}

/**
 * Кастомный хук для захвата зажатой комбинации клавиш.
 */
export function useKeyCodesCapture<T>(): KeyCodesCaptureHookTypes.IReturnType<
  T
> {
  const [pressedCodes, setPressedCodes] = useState<Set<number>>(new Set());
  const [hotKeyDescription, setHotKeyDescription] = useState<string>("");
  const [hotKeyCodes, setKeyCodes] = useState<string>("");
  const callBackRef = useRef<(() => void) | null>(null); // Если не передать в Generic | null, то ref вернет immutable object.

  useEffect(() => {
    if (callBackRef.current) {
      callBackRef.current();

      callBackRef.current = null;
    }
  }, [pressedCodes, hotKeyDescription, hotKeyCodes]);

  const handleSetState: KeyCodesCaptureHookTypes.ISetState = (
    newState,
    callback
  ) => {
    if (callback) callBackRef.current = callback;

    !isNil(newState.pressedCodes) &&
      setPressedCodes(newState.pressedCodes as Set<number>);
    !isNil(newState.hotKeyDescription) &&
      setHotKeyDescription(newState.hotKeyDescription as string);
    !isNil(newState.hotKeyCodes) && setKeyCodes(newState.hotKeyCodes as string);
  };

  /**
   * Обрабатывает захват кодов сочетания клавиш.
   */
  const handleKeyCodesCapture: KeyCodesCaptureHookTypes.IKeyCodesCapture<T> = (
    event
  ) => {
    let result = captureKeyCodesStuff(event, pressedCodes);

    result?.pressedCodes && setPressedCodes(result?.pressedCodes);
    result?.hotKeyDescription &&
      setHotKeyDescription(result?.hotKeyDescription);
    result?.hotKeyCodes && setKeyCodes(result?.hotKeyCodes);

    return {
      hotKeyCodes: result?.hotKeyCodes || null,
      hotKeyDescription: result?.hotKeyDescription || "",
      pressedCodes: result?.pressedCodes || null,
    };
  };

  return [
    {
      pressedCodes,
      hotKeyDescription,
      hotKeyCodes,
    },
    handleSetState,
    handleKeyCodesCapture,
  ];
}
