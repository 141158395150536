import { createAction } from "@reduxjs/toolkit";
import { createRoutine } from "redux-saga-routines";

export const sendUserEvent = createRoutine("SEND_USER_EVENT");

export const incomingMessageRoutine = createRoutine("INCOMING_MESSAGE");
export const disconnectStomp = createRoutine("DISCONNECT_STOMP");

export const CONNECT_ACTION = "@stomp/CONNECTED";
export const DISCONNECT_ACTION = "@stomp/DISCONNECTED";

export const stompConnected = createAction(CONNECT_ACTION);
export const stompDisconnected = createAction(DISCONNECT_ACTION);
