import React, { FC, memo, MouseEvent } from "react";
import c from "classnames";
import { Icon } from "@omnichat/arm_ui_kit";

import * as styles from "./styles.module.scss";

interface SkillGroupProps {
  id: string;
  caption: string;
  isActive?: boolean;
  editable?: boolean;
  onSelect: (id: string) => void;
  onEdit: (id: string) => void;
}

const SettingsListItem: FC<SkillGroupProps> = ({
  id,
  caption,
  isActive,
  onSelect,
  editable,
  onEdit
}) => {
  const handleEdit = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onEdit(id);
  };

  const handleSelect = () => onSelect(id);

  return (
    <div
      onClick={handleSelect}
      className={c(styles.listItem, { [styles.current]: isActive })}
    >
      <div id={`list_item_name_${id}`} className={styles.listItemName}>
        {caption}
      </div>
      {editable && (
        <div onClick={handleEdit} className={styles.listItemIcon}>
          <Icon
            id={`list_item_icon_${id}`}
            color={isActive ? "white" : "blue"}
            name="edit"
            width="18px"
            height="18px"
          />
        </div>
      )}
    </div>
  );
};

export default memo(SettingsListItem);
