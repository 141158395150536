const NAMESPACE = "RepliesList";

export const ACTIVE = `ACTIVE`;
export const NOT_ACTIVE = `NOT_ACTIVE`;

export const GET_REPLIES = `${NAMESPACE}_GET_REPLIES`;

export const GET_NEXT_PAGE = `${NAMESPACE}_GET_NEXT_PAGE`;

export const SET_REPLIES = `${NAMESPACE}_SET_REPLIES`;
export const ADD_REPLIES = `${NAMESPACE}_ADD_REPLIES`;
export const SET_QUERY = `${NAMESPACE}_SET_QUERY`;
export const SET_CATEGORY = `${NAMESPACE}_SET_CATEGORY`;
export const SET_ALL_SELECTED = `${NAMESPACE}_SET_ALL_SELECTED`;
export const RESET_SELECTED = `${NAMESPACE}_RESET_SELECTED`;
export const SET_SELECTED_REPLIES = `${NAMESPACE}_SET_SELECTED_REPLIES`;
export const RESET_PAGE = `${NAMESPACE}_RESET_PAGE`;

export const REMOVE_REPLIES = `${NAMESPACE}_REMOVE_REPLIES`;
