import React, { FC } from "react";
import { FormHelperText as MuiFormHelperText } from "@mui/material";
import Info from "@mui/icons-material/InfoOutlined";

interface IProps {
  id?: string;
  isError?: boolean;
  disabled?: boolean;
  actionText: string;
}

const ActionText: FC<IProps> = ({
  id,
  isError,
  disabled,
  actionText
}): JSX.Element => {
  return (
    <>
      {actionText && (
        <MuiFormHelperText
          id={id ? `action-text-${id}` : null}
          error={isError}
          disabled={disabled}
        >
          {isError && <Info />}
          <span>{actionText}</span>
        </MuiFormHelperText>
      )}
    </>
  );
};

export default ActionText;
