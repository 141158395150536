import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { StoreState } from "../../../ReactCore/store/types";
import { IFormData } from "../AgentCapacityModal";

const agentCapcityModal = (state: StoreState.State) => {
  return state.AgentCapacityModal;
};

export const getFormData = createSelector(
  agentCapcityModal,
  (state): IFormData => {
    const { formData } = state;

    const mapDtoToFormData = ({ skillGroups, ...data }): IFormData => ({
      ...data,
      skillGroups: !isEmpty(skillGroups)
        ? skillGroups.map(({ id, name }) => ({
            value: id,
            label: name
          }))
        : []
    });

    return formData ? mapDtoToFormData(formData) : null;
  }
);

export const getErrorMessage = createSelector(
  agentCapcityModal,
  (state): string => state.errorMessage
);

export const getDataSendHandling = createSelector(
  agentCapcityModal,
  (state): boolean => state.status.dataSendHandling
);
