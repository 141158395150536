import * as React from "react";

import styled from "styled-components";

import VipClientsComponent from "./Components/VipClients";

const Wrapper = styled.div`
  height: 85%;
  padding: 32px 32px 0;
`;

import { useSkillGroups, useVipClients } from "./VipClients.hooks";

const VipClients: React.FC = () => {
  const { loading: skillGroupsLoading, skillGroups } = useSkillGroups();
  const {
    loading: loadingClients,
    clients,
    createClient,
    editClient,
    deleteClient
  } = useVipClients();

  return (
    <Wrapper>
      <VipClientsComponent
        loading={loadingClients || skillGroupsLoading}
        skillGroups={skillGroups}
        clients={clients}
        onCreate={createClient}
        onEdit={editClient}
        onDelete={deleteClient}
      />
    </Wrapper>
  );
};

export default VipClients;
