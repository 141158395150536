import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../../../ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";

export const chatbotsState = (state: StoreState.State) => {
  return state.Chatbots;
};

export const loading = createSelector(chatbotsState, (state) => {
  return !isEmpty(state.loading);
});

export const chatbotList = createSelector(chatbotsState, (state) => {
  return state.chatbotList;
});

export const chatbotData = createSelector(chatbotsState, (state) => {
  return state.chatbotData;
});

export const error = createSelector(chatbotsState, (state) => {
  return state.error;
});
