import * as Yup from "yup";
import { ThemesFields } from "../Components/ThematickModal/types";
import { FieldTypes } from "../Components/ThematickModal/types";
import { REQUIRED_FIELD_ERROR } from "@omnichat/arm_ui_kit";

export const useDynamicSchema = (schema, config) => {
  const { name, validationType, validations = [] } = config;
  if (!Yup[validationType]) {
    return schema;
  }
  let validator = Yup[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[name] = validator;
  return schema;
};

export const useThematicFieldsValidation = (fields: ThemesFields[]) => {
  return fields.map((f, i) => {
    switch (f.fieldType) {
      case FieldTypes.INPUT:
        return {
          name: `${f.id}`,
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [REQUIRED_FIELD_ERROR]
            },
            ...(f.validations?.map((v) => ({
              type: "test",
              params: [
                "constaint",
                v.errorText,
                (value) => !new RegExp(v.regex, "gi").test(value)
              ]
            })) || [])
          ]
        };
      case FieldTypes.TEXTAREA:
        return {
          name: `${f.id}`,
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [REQUIRED_FIELD_ERROR]
            },
            ...(f.validations?.map((v) => ({
              type: "test",
              params: [
                "constaint",
                v.errorText,
                (value) => !new RegExp(v.regex, "gi").test(value)
              ]
            })) || [])
          ]
        };
      case FieldTypes.DROPDOWN:
        return {
          name: `${f.id}`,
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [REQUIRED_FIELD_ERROR]
            }
          ]
        };
      case FieldTypes.RADIO:
        return {
          name: `${f.id}`,
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [REQUIRED_FIELD_ERROR]
            }
          ]
        };
      case FieldTypes.CHECKBOX:
        return {
          name: `${f.id}`,
          validationType: "string",
          validations: [
            {
              type: "required",
              params: [REQUIRED_FIELD_ERROR]
            }
          ]
        };
      default:
        return {
          name: `${f.id}`
        };
    }
  });
};
