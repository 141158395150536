import { useEffect, useRef } from "react";

export const useDidUpdate = (
  updateCallback: () => void | (() => void),
  deps: unknown[]
) => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) {
      const unmountCallback = updateCallback();
      return unmountCallback;
    }
  }, deps);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);
};
