/**
 * Регистрация actions для всех ReactFeatures
 */
const featureActions = {};
const requireFeaturesActions = require.context(
  "../../ReactFeatures",
  true,
  /\.actions\.ts/
);
requireFeaturesActions.keys().forEach(name => {
  const actionName = name.split("/")[1];
  featureActions[actionName] = { ...requireFeaturesActions(name) };
});

/**
 * Регистрация actions для всех Settings
 */
const settingsActions = {};
const requireSettingsActions = require.context(
  "../../Settings",
  true,
  /\.actions\.ts/
);
requireSettingsActions.keys().forEach(name => {
  const actionName = name.split("/")[1];
  settingsActions[actionName] = { ...requireSettingsActions(name) };
});

export default { ...featureActions, ...settingsActions };
