import { createAction, PrepareAction } from "@reduxjs/toolkit";
import { PENDING, FULFILLED, REJECTED } from "../Consts/LoadingData.consts";
import { IAbstractPayload } from "../Interfaces/Store.interfaces";

/**
 * Создаст экшн для отправки в store статуса PENDING для данных, загружаемых асинхронно.
 *
 * @param {string} actionType Тип экшена.
 * @param {string} key Наименование поля ветки redux-store.
 */
export const setPending = <T>(actionType: string, key: string) =>
  createAction<PrepareAction<IAbstractPayload<T>>, string>(
    `${actionType}/${PENDING}`,
    data => ({
      payload: {
        data,
        meta: {
          key
        }
      }
    })
  );

/**
 * Создаст экшн для отправки в store статуса FULFILLED и данных.
 *
 * @param {string} actionType Тип экшена.
 * @param {string} key Наименование поля ветки redux-store.
 */
export const setFulfilled = <T>(actionType: string, key: string) =>
  createAction<PrepareAction<IAbstractPayload<T>> | undefined>(
    `${actionType}/${FULFILLED}`,
    data => ({
      payload: {
        data,
        meta: {
          key
        }
      }
    })
  );
/**
 * Создаст экшн для отправки в store статуса REJECTED и ошибки.
 *
 * @param {string} actionType Тип экшена.
 * @param {string} key Наименование поля ветки redux-store.
 */
export const setRejected = <T>(actionType: string, key: string) =>
  createAction<PrepareAction<IAbstractPayload<T>> | undefined>(
    `${actionType}/${REJECTED}`,
    data => ({
      payload: {
        meta: {
          key
        },
        error: data
      }
    })
  );
