import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../ReactCore/store/types";

import { Preloader } from "@omnichat/arm_ui_kit";

import {
  getSkillGroupCapacityData,
  setSkillGroupCapacityDataToForm,
  sendSkillGroupCapacityData,
  resetStatusSkillGroupCapacity
} from "./skillGroupCapacityModal.actions";
import SkillGroupCapacityModal from "./Components/SkillGroupCapacityModal/SkillGroupCapacityModal";

class SkillGroupCapacityModalProvider extends PureComponent<{
  close;
  status;
  skillGroupName;
  skillId;
  skillGroupsData;
  getSkillGroupCapacityData: (data: any) => null;
  setSkillGroupCapacityDataToForm: (data: any) => null;
  sendSkillGroupCapacityData: () => null;
  resetStatusSkillGroupCapacity: () => null;
}> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { skillId, getSkillGroupCapacityData } = this.props;

    if (skillId !== null && skillId !== undefined) {
      getSkillGroupCapacityData(skillId);
    }
  }

  componentWillUnmount() {
    this.props.resetStatusSkillGroupCapacity();
  }

  render() {
    const {
      close,
      status,
      skillGroupName,
      skillId,
      skillGroupsData,
      setSkillGroupCapacityDataToForm,
      sendSkillGroupCapacityData
    } = this.props;

    /**
     * Формирование данных для МО
     */
    const formData = {
      skillId: skillId,
      queue: skillGroupsData.queue,
      queueLimit: skillGroupsData.queueLimit,
      appealLimit: skillGroupsData.appealLimit
    };

    return (
      <Preloader size="mini" show={!status.skillGroupsDataLoaded}>
        {status.skillGroupsDataLoaded && (
          <SkillGroupCapacityModal
            onConfirmHandler={() => {
              sendSkillGroupCapacityData();
              close();
            }}
            onChange={(data) => {
              setSkillGroupCapacityDataToForm(data);
            }}
            skillGroupName={skillGroupName}
            formData={formData}
          />
        )}
      </Preloader>
    );
  }
}

export default connect(
  (state: StoreState.State) => ({
    status: state.SkillGroupCapacityModal.status,
    skillGroupsData: state.SkillGroupCapacityModal.skillGroupsData
  }),
  (dispatch) => ({
    getSkillGroupCapacityData: (data) =>
      dispatch(getSkillGroupCapacityData(data)),
    setSkillGroupCapacityDataToForm: (data) =>
      dispatch(setSkillGroupCapacityDataToForm(data)),
    sendSkillGroupCapacityData: () => dispatch(sendSkillGroupCapacityData()),
    resetStatusSkillGroupCapacity: () =>
      dispatch(resetStatusSkillGroupCapacity())
  })
)(SkillGroupCapacityModalProvider);
