import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { Preloader, Button, isEmpty } from "@omnichat/arm_ui_kit";

import { ReactHookFormErrorHandler } from "../../Utils/HookForm.helper";
import {
  FormFieldConnector,
  Slider,
  DragAndDropSelect
} from "../../Components";
import { Option } from "../../Components/DragAndDropSelect/DragAndDropSelect";

import hasUserRight from "../../../src/Utils/hasUserRight";
import * as disctionaryAction from "../Dictionaries/Store/Dictionaries.actions";
import * as disctionarySelector from "../Dictionaries/Store/Dictionaries.selector";
import * as monitoringSelector from "../../Dashboard/Monitoring/Store/Monitoring.selector";

import { useActions } from "./Store/AgentCapacityModal.reducer";
import * as selectors from "./Store/AgentCapacityModal.selector";
import * as styles from "./styles.module.scss";
import { initFormDTO } from "./Store/AgentCapacityModal";

const schema = Yup.object({
  appealLimit: Yup.number().nullable(),
  skillGroups: Yup.array().nullable()
});

const defaultValues: initFormDTO = {
  appealLimit: null,
  skillGroups: []
};

export interface IFormData extends Yup.InferType<typeof schema> {}

type Props = {
  agentId: number;
  close: () => void;
};

const AgentCapacityModal: FC<Props> = ({ agentId, close }) => {
  const dispatch = useDispatch();
  const actions = useActions();

  const errorMessage = useSelector(selectors.getErrorMessage);
  const formData = useSelector(selectors.getFormData);
  const dataSendHandling = useSelector(selectors.getDataSendHandling);
  const availableSkillsForAgent = useSelector(
    monitoringSelector.skillGroupListIds
  );
  const isDictionariesLoaded = useSelector(
    disctionarySelector.getIsUsersDictionariesLoaded
  );
  const dictionarySkillGroups = useSelector(
    disctionarySelector.getSkillGroupsToForm
  );

  const availableSkills = (allSkills: Option[]) =>
    allSkills?.map((i) => ({
      ...i,
      disabled: !availableSkillsForAgent.includes(i.value)
    }));

  const skillsOptions = availableSkills(dictionarySkillGroups);

  const methods = useForm<IFormData, any, IFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch, clearErrors } = methods;

  const appealLimit = watch("appealLimit");

  useEffect(() => {
    if (!isDictionariesLoaded) {
      dispatch(disctionaryAction.getUsersProfileCatalogs(core.user.id));
    }
    actions.getAgentData({ agentId });

    return () => {
      actions.resetAgentCapacityData();
    };
  }, []);

  useEffect(() => {
    if (!dataSendHandling) reset(formData || defaultValues);
  }, [formData]);

  const handleSave = (data: IFormData) => {
    actions.sendAgentCapacityData({
      formData: {
        agent_id: agentId,
        has_group_right: hasUserRight("user_groups"),
        appeals_limit: data.appealLimit,
        skills: data.skillGroups.map(({ value }) => `${value}`)
      },
      close
    });
  };

  return (
    <Preloader
      size="mini"
      show={!isDictionariesLoaded || (agentId && formData === null)}
    >
      <div id="AgentCapacityModal" className={styles["modalContainer"]}>
        <header>Управление нагрузкой на сотрудника</header>

        <FormProvider {...methods}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            {hasUserRight("agent_limit") && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="appealLimit"
                  label={"Количество открытых обращений"}
                  actionText="Установите максимально допустимое количество одновременно открытых обращений"
                  Component={(p) => <Slider {...p} />}
                  customTitle={
                    <div className={styles["appealLimitValue"]}>
                      {appealLimit > 0 ? appealLimit : "Нет ограничений"}
                    </div>
                  }
                />
              </Grid>
            )}

            {hasUserRight("user_groups") && (
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="skillGroups"
                  label="группы"
                  actionText="Чем выше группа в списке, тем выше приоритет поступивших в эту группу обращений при их распределении на сотрудника"
                  required
                  Component={(p, { error }) => (
                    <DragAndDropSelect
                      addText="Добавить группу"
                      required
                      isError={error}
                      selected={availableSkills(p.value)}
                      options={!isEmpty(skillsOptions) ? skillsOptions : []}
                      onChange={(e) => {
                        clearErrors("skillGroups");
                        p.onChange(e);
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {errorMessage && (
              <Grid
                container
                justifyContent="center"
                item
                xs={12}
                paddingBottom={2}
              >
                <div className={styles["error"]}>{errorMessage}</div>
              </Grid>
            )}

            <Grid item alignItems="center" xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px"
                }}
              >
                <Button
                  type="default"
                  disabled={dataSendHandling}
                  theme={dataSendHandling ? "disabled" : "green"}
                  onClick={handleSubmit(
                    (data) => handleSave({ ...(formData || {}), ...data }),
                    ReactHookFormErrorHandler
                  )}
                  text="Cохранить"
                />
              </div>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
    </Preloader>
  );
};

export default AgentCapacityModal;
