import { CORERESPONSESTATUS } from "./consts";

interface IParams {
  query?: { [key: string]: any };
  body?: { [key: string]: any };
  contentType?: string;
  signal?: AbortSignal;
}

type Types = string;

export async function httpRequest(
  method: string,
  url: string,
  params: IParams = {},
  contentType?: Types,
  fileName?: string,
  open?: boolean
) {
  let path: string = /http/.test(url) ? url : `/${url}`;
  let body: string = null;

  if (params.query) {
    path = path + getQuery(params.query);
  }

  if (params.body) {
    body = JSON.stringify(params.body);
  }

  const response = await fetch(path, {
    method,
    body,
    signal: params.signal,
    headers: {
      "Content-type": params?.contentType || "application/json; charset=utf-8",
      "User-Id": String(core.user.id),
      "User-Login": core.user.login,
      "User-Hash": core.user.hash
    }
  });

  if (contentType === "blob") {
    return response.blob().then((blob) => {
      if (open) {
        const file = new Blob([blob], { type: "application/download" });
        const objUrl = window.URL.createObjectURL(file);
        window.open(objUrl);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName || "";
        link.click();
      }
    });
  }

  if (response.status === 200 || response.status === 201) {
    const responseData = await response.json();

    if ("success" in responseData && !responseData.success) {
      if (
        responseData.error?.status === 400 ||
        responseData.error?.status === 500
      ) {
        return {
          ...responseData,
          request: { path },
          error: responseData?.error?.message,
          additionalData: responseData?.error
        };
      } else {
        console.error(responseData.error);
        throw new Error(JSON.stringify(responseData.error));
      }
    }

    return {
      ...responseData,
      request: { path }
    };
  } else if (response.status === 401) {
    // Перезагрузка страницы редиректит на страницу авторизации
    location.reload();
  } else {
    console.log("failed", { path, body });
    throw new Error(JSON.stringify({ path, body }));
  }
}

export function getQuery(params = {}) {
  const query = Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key])) {
        return params[key].map((val) => `${key}[]=${val}`).join("&");
      }

      if (typeof params[key] !== "object") {
        return `${key}=${params[key]}`;
      }

      return Object.keys(params[key])
        .map(($key) => `${key}[${$key}]=${params[key][$key]}`)
        .join("&");
    })
    .join("&");

  return `?${query}`;
}

export function responseSuccess(response) {
  if (response.data) {
    switch (response.data.status) {
      case CORERESPONSESTATUS.SUCCESS:
        return true;

      case CORERESPONSESTATUS.FAILURE:
      default:
        throw new Error(`Response error: ${response.request.path}`);
    }
  }

  return false;
}
