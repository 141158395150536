import React, { FC } from "react";
import { Avatar } from "@omnichat/arm_ui_kit";
import c from "classnames";
import * as style from "./style.module.scss";

export type CommonType = {
  id?: number;
  label: string | number;
  value?: string | number;
  avatar?: string | null;
  center?: boolean;
  icon?: string;
};

type Props = CommonType;

export const CommonItem: FC<Props> = ({
  label,
  value,
  avatar,
  center,
  icon
}) => {
  return (
    <div className={c(style["item"], { [style["center"]]: center })}>
      {typeof avatar === "string" && (
        <div className={style["avatar"]}>
          <Avatar
            person={"user"}
            url={avatar ? `/fileStorage/files/crop/${avatar}` : ""}
          />
        </div>
      )}
      <div className={style["container"]}>
        <div className={style["label"]}>{label}</div>
        <div className={style["content"]}>
          {icon && (
            <div>
              <span className={c(style["channelIcon"], style[icon])} />
            </div>
          )}
          <div className={style["value"]}>{value}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonItem;
