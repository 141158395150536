import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Preloader, Button, isEmpty } from "@omnichat/arm_ui_kit";
import { Grid, Typography } from "@mui/material";

import FormFieldConnector from "../../Components/FormFieldConnector";
import { ReactHookFormErrorHandler } from "../../Utils/HookForm.helper";
import {
  Input,
  Select,
  Checkbox,
  Slider,
  TimeInput,
  Autocomplete,
  ModalHeader
} from "../../Components";
import SubTitle from "../../Components/SubTitle";
import hasUserRight from "../../Utils/hasUserRight";

import * as actions from "./Store/SkillGroupModal.reducer";
import * as selectors from "./Store/SkillGroupModal.selector";
import { schema, defaultValues, IFormData } from "./SkillGroupModal.schema";
import { SkillGroupModalProps } from "./SkillGroupModal.d";
import {
  mapFormToDto,
  getHoursValue,
  getMinutesValue,
  getChangedTimeInSumMinutes
} from "./utils";
import * as styles from "./styles.module.scss";
import {
  DEFAULT_SKILLGROUP_DISCLAIMER,
  DEFAULT_SKILLGROUP_ALIAS_DISCLAIMER
} from "./consts";

const checkboxLabelSx = {
  display: "flex",
  justifyContent: "space-between"
};

const SkillGroupModal: FC<SkillGroupModalProps> = ({
  editMode,
  skillId,
  onSave,
  onDelete
}): JSX.Element => {
  const dispatch = useDispatch();

  const methods = useForm<IFormData, any, IFormData>({
    defaultValues,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch, clearErrors } = methods;

  const appointToBot = watch("skillGroup.appointToBot");
  const autoStatusEnabled = watch("skillGroup.autoStatusEnabled");
  const timeLimitEnabled = watch("skillGroup.timeLimitEnabled");
  // const mainBot = watch("mainBot");
  const toBotPercent = watch("skillGroup.toBotPercent");
  const delayReappealToBot = watch("skillGroup.delayReappealToBot");

  const {
    regions = [],
    botProfiles = [],
    mrfs = [],
    channels = []
  } = useSelector(selectors.getDictionariesToForm);
  const formData = useSelector(selectors.getFormData);
  const isDictionariesLoaded = useSelector(selectors.getDictionariesLoaded);
  const dataSendHandling = useSelector(selectors.getDataSendHandling);
  const errorMessage = useSelector(selectors.getErrorMessage);

  useEffect(() => {
    dispatch(actions.getSkillGroupDictionaries());
    if (editMode) {
      dispatch(actions.getSkillGroupData({ skillId }));
    }

    return () => {
      dispatch(actions.resetSkillGroupData());
    };
  }, []);

  useEffect(() => {
    if (!dataSendHandling) {
      if (!editMode) {
        reset({
          ...defaultValues,
          skillGroup: {
            ...defaultValues.skillGroup,
            disclaimer: DEFAULT_SKILLGROUP_DISCLAIMER,
            aliasDisclaimer: DEFAULT_SKILLGROUP_ALIAS_DISCLAIMER
          }
        });
      } else {
        reset(formData || defaultValues);
      }
    }
  }, [formData]);

  const handleSave = async (data) => {
    dispatch(
      actions.sendSkillGroupData({
        formData: mapFormToDto(data),
        callback: onSave
      })
    );
  };

  const handleDelete = async () => {
    if (editMode) {
      onDelete();
    }
  };

  return (
    <Preloader
      size="mini"
      show={!isDictionariesLoaded || (editMode && isEmpty(formData))}
    >
      <div id="SkillGroupEditModal" className={styles.modalContainer}>
        <ModalHeader>
          {editMode ? "Редактирование группы" : "Создание группы"}
        </ModalHeader>

        <FormProvider {...methods}>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.name"
                label="Название"
                required
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    isError={!isEmpty(error)}
                    placeholder="Не указано"
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.disclaimer"
                label="Приветствие в очереди"
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    isError={!isEmpty(error)}
                    rows={3}
                    placeholder="Не указано"
                    actionText={
                      !isEmpty(error)
                        ? ""
                        : "Приветствие будет отправлено посетителю, обращение которого попало в очередь"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.aliasDisclaimer"
                label="Приветствие при недоступности"
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    isError={!isEmpty(error)}
                    rows={3}
                    placeholder="Не указано"
                    actionText={
                      !isEmpty(error)
                        ? ""
                        : "Приветствие будет отправлено посетителю, если все агенты оффлайн"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <SubTitle caption="Маршрутизация обращений" />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.alias"
                label="Псевдоним"
                required
                Component={(p, { error }) => (
                  <Input
                    {...p}
                    isError={!isEmpty(error)}
                    placeholder="Не указан"
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="mrfs"
                label="МРФ"
                Component={(p, { error }) => {
                  return (
                    <Autocomplete
                      {...p}
                      isMulti
                      isSearchable
                      isError={!isEmpty(error)}
                      options={mrfs}
                      selected={p.value}
                      onSelectOption={p.onChange}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="regions"
                label="Регионы"
                Component={(p, { error }) => {
                  return (
                    <Autocomplete
                      {...p}
                      isMulti
                      isSearchable
                      isError={!isEmpty(error)}
                      options={regions}
                      selected={p.value}
                      onSelectOption={p.onChange}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="channels"
                label="Каналы"
                Component={(p, { error }) => {
                  return (
                    <Autocomplete
                      {...p}
                      isMulti
                      isSearchable
                      isError={!isEmpty(error)}
                      options={channels}
                      selected={p.value}
                      onSelectOption={p.onChange}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item alignItems="center" xs={12} mt={2}>
              <FormFieldConnector
                name="skillGroup.returnFromWaiting"
                Component={(p, { error }) => (
                  <Checkbox
                    {...p}
                    caption="Возвращать обращения в группу из режима ожидания"
                    labelSx={checkboxLabelSx}
                    type="slider"
                    checked={p.value}
                    id="returnFromWaiting"
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.vip"
                Component={(p, { error }) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Распределение VIP"
                    disabled={!hasUserRight("vip")}
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="skillGroupVip"
                    actionText={
                      !isEmpty(error)
                        ? ""
                        : "VIP пользователи распределяются В текущую группу"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.excludeGroupFromVipsRedistribute"
                Component={(p, { error }) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Исключать из распределения VIP"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    disabled={!hasUserRight("vip")}
                    id="skillGroupExcludeGroupFromVipsRedistribute"
                    actionText={
                      !isEmpty(error)
                        ? ""
                        : "VIP пользователи не распределяются ИЗ текущей группы"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="skillGroup.backlogEnabled"
                Component={(p, { error }) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Помещать обращения в BACKLOG"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="skillGroupBacklogEnabled"
                    actionText={
                      !isEmpty(error)
                        ? ""
                        : "Осуществлять перевод обращений из текущей группы в бэклог при превышении лимита обращений"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12} mb={1}>
              <FormFieldConnector
                name="skillGroup.excludeGroupFromCountingBacklogAppeals"
                Component={(p, { error }) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Исключить группу из подсчета обращений BACKLOG"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="excludeGroupFromCountingBacklogAppeals"
                    actionText={
                      !isEmpty(error)
                        ? ""
                        : "Исключить обращения группы из общего количества при подсчете обращений в бэклог"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item alignItems="center" xs={12}>
              <SubTitle
                htmlForId="autoStatusEnabled"
                caption="Автосмена статуса обращения"
                toggleComponent={
                  <FormFieldConnector
                    name="skillGroup.autoStatusEnabled"
                    extraClass={[styles.subTitleField]}
                    Component={(p, { error }) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        checked={p.value}
                        id="autoStatusEnabled"
                      />
                    )}
                  />
                }
              />
            </Grid>

            {autoStatusEnabled && (
              <>
                <Grid item alignItems="center" xs={12} mt={0}>
                  <Typography variant="body2">
                    Статус обращения будет изменён, если клиент бездействует
                  </Typography>
                </Grid>

                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="skillGroup.timeWait"
                    label="В ожидание через"
                    Component={(p, { error }) => (
                      <div className={styles.time}>
                        <TimeInput
                          isError={!isEmpty(error)}
                          hours={true}
                          minutes={true}
                          hoursValue={getHoursValue(p.value)}
                          minutesValue={getMinutesValue(p.value)}
                          onChangeHours={(newHours) => {
                            clearErrors("skillGroup.timeWait");
                            const minutes = getMinutesValue(p.value);
                            p.onChange(
                              getChangedTimeInSumMinutes(newHours, minutes)
                            );
                          }}
                          onChangeMinutes={(newMinutes) => {
                            clearErrors("skillGroup.timeWait");
                            const hours = getHoursValue(p.value);
                            p.onChange(
                              getChangedTimeInSumMinutes(hours, newMinutes)
                            );
                          }}
                        />
                        <div className={styles["timeName"]}>мин</div>
                      </div>
                    )}
                  />
                </Grid>

                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="skillGroup.timeClose"
                    label="Закрыто через"
                    Component={(p, { error }) => (
                      <div className={styles["time"]}>
                        <TimeInput
                          isError={!isEmpty(error)}
                          hours={true}
                          minutes={true}
                          hoursValue={getHoursValue(p.value)}
                          minutesValue={getMinutesValue(p.value)}
                          onChangeHours={(newHours) => {
                            clearErrors("skillGroup.timeClose");
                            const minutes = getMinutesValue(p.value);
                            p.onChange(
                              getChangedTimeInSumMinutes(newHours, minutes)
                            );
                          }}
                          onChangeMinutes={(newMinutes) => {
                            clearErrors("skillGroup.timeClose");
                            const hours = getHoursValue(p.value);
                            p.onChange(
                              getChangedTimeInSumMinutes(hours, newMinutes)
                            );
                          }}
                        />
                        <div className={styles["timeName"]}>мин</div>
                      </div>
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item alignItems="center" xs={12}>
              <SubTitle
                htmlForId="timeLimitEnabled"
                caption="Информирование агента о бездействии"
                toggleComponent={
                  <FormFieldConnector
                    name="skillGroup.timeLimitEnabled"
                    extraClass={[styles.subTitleField]}
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        checked={p.value}
                        id="timeLimitEnabled"
                      />
                    )}
                  />
                }
              />
            </Grid>

            {timeLimitEnabled && (
              <>
                <Grid item alignItems="center" xs={12} mt={0}>
                  <Typography variant="body2">
                    Время работы с обращением будет автоматически остановлено,
                    если агент бездействует более указанного количества времени
                  </Typography>
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="skillGroup.timeLimit"
                    label="Через"
                    Component={(p, { error }) => (
                      <div className={styles["time"]}>
                        <TimeInput
                          isError={!isEmpty(error)}
                          minutes={true}
                          minutesValue={p.value}
                          onChangeMinutes={(e) => {
                            clearErrors("skillGroup.timeLimit");
                            p.onChange(e);
                          }}
                        />
                        <div className={styles.timeName}>мин</div>
                      </div>
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item alignItems="center" xs={12}>
              <SubTitle
                htmlForId="botFunctionalEnabled"
                caption="Чат-бот в обращениях"
                toggleComponent={
                  <FormFieldConnector
                    name="skillGroup.appointToBot"
                    extraClass={[styles.subTitleField]}
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        checked={p.value}
                        id="botFunctionalEnabled"
                      />
                    )}
                  />
                }
              />
            </Grid>

            {appointToBot && (
              <>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="mainBot"
                    label="Имя бота"
                    required
                    Component={(p, { error }) => (
                      <Select
                        {...p}
                        placeholder="Не указан"
                        isError={!isEmpty(error)}
                        options={botProfiles}
                        selected={p.value}
                        onSelectOption={(e) => {
                          clearErrors("mainBot");
                          p.onChange(e);
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="skillGroup.toBotPercent"
                    label={"Чат-бот будет обрабатывать"}
                    Component={(p) => <Slider {...p} />}
                    customTitle={<>{`${toBotPercent || 0}%`}</>}
                  />
                </Grid>

                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="skillGroup.delayReappealToBot"
                    label={"Чат-бот будет игнорировать повторное обращение"}
                    Component={(p) => <Slider {...p} />}
                    customTitle={
                      <>
                        {delayReappealToBot && delayReappealToBot > 0
                          ? `${delayReappealToBot} мин`
                          : "Нет ограничений"}
                      </>
                    }
                  />
                </Grid>

                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="skillGroup.afterBotDisclaimer"
                    label="Приветствие в очереди после чат-бота"
                    Component={(p, { error }) => (
                      <Input
                        {...p}
                        rows={3}
                        placeholder="Не указано"
                        actionText={
                          !isEmpty(error)
                            ? ""
                            : "Приветствие будет отправлено посетителю, если обращение поступило в очередь после чат-бота"
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {/* <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="studiedBots"
                label="Чат-боты на обучении"
                Component={(p, { error }) => {
                  return (
                    <Autocomplete
                      {...p}
                      isMulti
                      isError={!isEmpty(error)}
                      options={
                        isEmpty(mainBot)
                          ? botProfiles
                          : botProfiles.filter(
                              (bot) => bot.value !== mainBot[0].value
                            )
                      }
                      selected={p.value}
                      onSelectOption={p.onChange}
                    />
                  );
                }}
              />
            </Grid> */}

            {errorMessage && (
              <Grid
                container
                justifyContent="center"
                item
                xs={12}
                paddingBottom={2}
              >
                <div className={styles["error"]}>{errorMessage}</div>
              </Grid>
            )}

            <Grid container rowSpacing={1} columnSpacing={2} mt={2} ml={0}>
              {editMode && onDelete && (
                <Grid item xs={6}>
                  <Button
                    type="default"
                    iconColor="white"
                    theme="red"
                    onClick={handleDelete}
                    text="Удалить"
                  />
                </Grid>
              )}
              <Grid item xs={editMode && onDelete ? 6 : 12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: editMode && onDelete ? "end" : "center"
                  }}
                >
                  <Button
                    type="default"
                    disabled={dataSendHandling}
                    theme={dataSendHandling ? "disabled" : "green"}
                    onClick={handleSubmit(
                      (data) => handleSave({ ...(formData || {}), ...data }),
                      ReactHookFormErrorHandler
                    )}
                    text="Сохранить"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
    </Preloader>
  );
};

export default SkillGroupModal;
