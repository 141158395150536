// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".restore_password_modal--XGErc_gi3D6a2qNwyHta{width:550px;padding:0}.restore_password_modal__header--WqibeQRQMP5Ju0BA5OsH{margin-bottom:20px;padding-bottom:2px;border-bottom:1px solid rgba(0,0,0,.15)}.restore_password_modal__caption--G09XIq7aVLRUkF3Ol2gb{margin-top:2px}.restore_password_modal__buttons--bD6NQCqtC_74VsTVrDQp{display:flex;justify-content:space-around}", "",{"version":3,"sources":["webpack://./frontend/src/Settings/Users/RestorePasswordModal/RestorePasswordModal.module.scss"],"names":[],"mappings":"AAAA,8CACE,WAAA,CACA,SAAA,CAEA,sDACE,kBAAA,CACA,kBAAA,CACA,uCAAA,CAGF,uDACE,cAAA,CAGF,uDACE,YAAA,CACA,4BAAA","sourcesContent":[".restore_password_modal {\n  width: 550px;\n  padding: 0;\n\n  &__header {\n    margin-bottom: 20px;\n    padding-bottom: 2px;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n  }\n\n  &__caption {\n    margin-top: 2px;\n  }\n\n  &__buttons {\n    display: flex;\n    justify-content: space-around;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var restorePasswordModal = "restore_password_modal--XGErc_gi3D6a2qNwyHta";
export var restorePasswordModalHeader = "restore_password_modal__header--WqibeQRQMP5Ju0BA5OsH";
export var restorePasswordModalCaption = "restore_password_modal__caption--G09XIq7aVLRUkF3Ol2gb";
export var restorePasswordModalButtons = "restore_password_modal__buttons--bD6NQCqtC_74VsTVrDQp";
export default ___CSS_LOADER_EXPORT___;
