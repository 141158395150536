import { fork } from "redux-saga/effects";

import getValueSaga from "./getValue.saga";
import removeValueSaga from "./removeValue.saga";
import saveValueSaga from "./saveValue.saga";

export default function*() {
  yield fork(getValueSaga);
  yield fork(removeValueSaga);
  yield fork(saveValueSaga);
}
