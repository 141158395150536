import { useCallback, useState } from "react";
import debounce from "lodash.debounce";
import { useDidUpdate } from "./useDidUpdate";

interface IDelayedSendQuery {
  cb: (query: string) => void | ((query: string) => void);
  query?: string;
  delay?: number;
}

export const useDelayedSendQuery = ({
  cb,
  query,
  delay = 500
}: IDelayedSendQuery) =>
  useCallback(
    debounce(() => cb(query), delay),
    [query]
  );

export const useDidUptateByDelayedSendQuery = ({
  cb,
  delay
}: IDelayedSendQuery) => {
  const [inputText, setText] = useState("");

  const onInputQuery = (v: string) => setText(v);

  const delayedSendQuery = useDelayedSendQuery({
    cb,
    query: inputText,
    delay
  });

  useDidUpdate(() => {
    delayedSendQuery();
    return delayedSendQuery.cancel;
  }, [inputText, delayedSendQuery]);

  return { inputText, onInputQuery };
};
