import React, { useState, useEffect } from "react";
import { Button, Select } from "@omnichat/arm_ui_kit";

import { FormField } from "../../../../Components";

import * as s from "./CategoryForRepliesModal.module.scss";

export interface ICategoryForRepliesModalProps {
  onConfirm: (id: number) => void;
  onNextPage: () => void;
  onCancel: () => void;
  categories: Category[];
}

interface Category {
  value: number;
  label: string;
}

/**
 * Модальное окно изменения категории для шаблонов
 */
const CategoryForRepliesModal = ({
  categories,
  onNextPage,
  onCancel,
  onConfirm
}: ICategoryForRepliesModalProps): JSX.Element => {
  const [selected, setSelected] = useState<number | null>(null);
  const [error, setError] = useState(false);

  useEffect(
    () => () => {
      setSelected(null);
      setError(false);
    },
    []
  );

  const validate = () => {
    if (selected === null) {
      setError(true);
      return;
    }

    onConfirm(selected);
  };

  const changeValue = (selected: any) => {
    setError(false);
    setSelected(+selected[0]);
  };

  return (
    <div className={s["modal"]}>
      <header className={s["header"]}>Перенести в категорию?</header>
      <p className={s["modalDescription"]}>
        Шаблон(-ы) будет перенесен в выбранную категорию.
      </p>
      <FormField label="КАТЕГОРИИ" required={true}>
        <Select
          label=""
          isError={error}
          options={categories}
          selected={selected !== null ? [selected] : []}
          onSelectOption={changeValue}
          actionText={"Укажите категорию"}
          onPaginationBottom={() => onNextPage()}
        />
      </FormField>
      <div className={s["modalButtonGroup"]}>
        <Button
          type="default"
          iconName="cross"
          iconColor="white"
          theme={"red"}
          onClick={() => onCancel()}
          text="Отменить"
        />
        <Button
          type="default"
          iconName="check"
          iconColor="white"
          theme={"green"}
          onClick={() => validate()}
          text="Продолжить"
        />
      </div>
    </div>
  );
};

export default CategoryForRepliesModal;
