import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import ImageViewer from "./arm/Wrappers/ImageViewer";
import core from "./arm/core.js";
import msg from "./arm/msg.js";
import workspace from "./arm/workspace.js";

if (SENTRY_KEY !== "" && SENTRY_ID !== "" && SENTRY_HOST !== "") {
  Sentry.init({
    dsn: `https://${SENTRY_KEY}@${SENTRY_HOST}/${SENTRY_ID}`,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

window.core = core;
window.msg = msg;
window.workspace = workspace;
window.imageViewer = new ImageViewer();
