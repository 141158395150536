import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

import { ChatbotData } from "../Chatbots";

import initialState from "../../../ReactCore/store/initialState";

export const GET_CHATBOT_LIST = "GET_CHATBOT_LIST";
export const GET_CHATBOT_DATA = "GET_CHATBOT_DATA";
export const SEND_CHATBOT_DATA = "SEND_CHATBOT_DATA";
export const REMOVE_CHATBOT = "REMOVE_CHATBOT";

export const getChatbotslist = createAction(GET_CHATBOT_LIST);
export const getChatbotData = createAction<string>(GET_CHATBOT_DATA);
export const sendChatbotData = createAction<{
  data: ChatbotData;
  cb: Function;
}>(SEND_CHATBOT_DATA);
export const removeChatbot = createAction<{ id: string; cb: Function }>(
  REMOVE_CHATBOT
);

const ChatbotSlice = createSlice({
  name: "chatbots",
  initialState: initialState.Chatbots,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setChatbots(state, action: PayloadAction<ChatbotData[]>) {
      state.chatbotList = action.payload;
    },
    setChatbot(state, action: PayloadAction<ChatbotData>) {
      state.chatbotData = action.payload;
    },
    cleanChatbot(state) {
      state.chatbotData = null;
      state.error = null;
    },
    cleanList(state) {
      state.chatbotList = [...initialState.Chatbots.chatbotList];
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    }
  }
});

export const {
  startLoading,
  finishLoading,
  setChatbot,
  setChatbots,
  cleanChatbot,
  cleanList,
  setError
} = ChatbotSlice.actions;

export default ChatbotSlice.reducer;
