import produce from "immer";

import initialState from "./RepliesTemplateEditModal.initialData";

import * as ACTION from "../consts";

export default function dataReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION.SET_REPLIES_DATA:
      return produce(state, (draft) => {
        draft.replyData = payload;
      });
    case ACTION.CHANGE_CATEGORIES_PAGE:
      return produce(state, (draft) => {
        draft.templateModalCategoryPage = draft.templateModalCategoryPage + 1;
      });
    case ACTION.RESET_DATA:
      return produce(state, (draft) => {
        draft.replyData = null;
        draft.templateModalCategoryPage = 0;
      });

    default:
      return state;
  }
}
