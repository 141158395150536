import produce from "immer";

import initialState from "../repliesEditModal.initialData";
import * as ACTION from "../Сonsts";

export default function dataReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION.SET_REPLIES:
      return produce(state, (draft) => {
        draft.replies = payload;
      });
    case ACTION.SET_DIALOG_TEMPLATE_MODAL_CATEGORIES:
      return produce(state, (draft) => {
        draft.categoryPage = 0;
        draft.dialogReplyCategories = payload;
      });
    case ACTION.DIALOG_TEMPLATE_MODAL_CATEGORIES_ADD:
      return produce(state, (draft) => {
        draft.dialogReplyCategories = [
          ...draft.dialogReplyCategories,
          ...payload,
        ];
      });
    case ACTION.ADD_REPLIES:
      return produce(state, (draft) => {
        draft.replies = [...draft.replies, ...payload];
      });
    case ACTION.CHANGE_REPLIES_PAGE:
      return produce(state, (draft) => {
        draft.replyPage = payload.page;
        draft.isLastReplyPage = payload.isLast;
      });
    case ACTION.CHANGE_CATEGORIES_PAGE:
      return produce(state, (draft) => {
        draft.categoryPage = draft.categoryPage + 1;
      });
    case ACTION.RESET_REPLIES_DATA:
      return produce(state, (draft) => {
        draft.replies = [];
        draft.categoryPage = 0;
        draft.replyPage = 0;
        draft.dialogReplyCategories = [];
        draft.isLastReplyPage = false;
      });
    default:
      return state;
  }
}
