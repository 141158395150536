import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  pushFiles,
  sendFile,
} from "../FileStorage/fileStorage.actions";
import { FileStorageTypes } from "../FileStorage/fileStorage";
import { FileUploadInput, isEmpty } from "@omnichat/arm_ui_kit";

import Informer from "../../arm/Wrappers/Informer";
import { bytesToMegabytes } from "../../arm/Constructors/Utils";

import { avatarFile } from "./AvatarUploader.selector";

type Props = {
  name?: string;
  onChange: (url: string) => void;
};

const AvatarUploader: FC<Props> = ({ name, onChange }) => {
  const dispatch = useDispatch();
  const files = useSelector(avatarFile);

  useEffect(() => {
    if (!isEmpty(files)) {
      files.forEach((file: FileStorageTypes.IFile) => {
        !file.sended &&
          !file.pending &&
          dispatch(sendFile(file.uuid, updateAvatar));
      });
    }
  }, [files]);

  const updateAvatar = ({ data }) => {
    const ext = data.originalName.split(".").pop().toLowerCase();
    const fileName = `${data.uuid}.${ext}`;

    onChange(fileName);
  };

  const uploadAvatar = (e, file) => {
    const fileName = file[0].name;
    const fileSize = bytesToMegabytes(file[0].size);
    const avatarExt = ["jpg", "jpeg", "png"];
    const ext = fileName.split(".").pop().toLowerCase();

    const isValidExt = avatarExt.find((item) => item === ext);
    const isValidSize = fileSize < MAX_AVATAR_SIZE_MB;

    if (!isValidExt) {
      const info = new Informer("Недопустимый формат файла", 4500);
      info.show();
      return;
    }

    if (!isValidSize) {
      const info = new Informer(
        `Размер файла должен быть менее ${MAX_AVATAR_SIZE_MB} Мб`,
        4500
      );
      info.show();
      return;
    }

    dispatch(pushFiles(file));
  };

  return (
    <>
      <FileUploadInput
        label="Фото:"
        placeholder={name || "Загрузить"}
        onChange={uploadAvatar}
        description={`.jpeg, .jpg или .png до ${MAX_AVATAR_SIZE_MB} Мб`}
      />
    </>
  );
};

export default AvatarUploader;
