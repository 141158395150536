export const ReplyTemplateEditDictionary = {
  Modal: {
    keyShortcut: {
      label: "Горячие клавиши",
      formFieldHint:
        "Указанная комбинация клавиш будет доступна оператору для быстрого выбора данного шаблона",
      emptyValues: "Не указано",
      inputPlaceholder: "Зажмите сочетание клавиш",
      inputHint: 'Для применения изменений нажмите клавишу "Enter"'
    }
  }
};
