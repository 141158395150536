import { fork } from "redux-saga/effects";

import initSkillGroupDictionay from "./initSkillGroupDictionary.saga";
import getSkillGroupData from "./getSkillGroupData.saga";
import sendSkillGroupData from "./sendSkillGroupData.saga";

export default function*() {
  yield fork(getSkillGroupData);
  yield fork(initSkillGroupDictionay);
  yield fork(sendSkillGroupData);
}
