import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { store } from "../../ReactCore";
import SystemSettings from "./SystemSettings";
import ThemeProvider from "../../Components/ThemeProvider";

const REACT_ROOT_SELECTOR = ".settings_body";

export default function initSystemSettings() {
  const node = document.querySelector(REACT_ROOT_SELECTOR);

  ReactDom.render(
    <ThemeProvider>
      <Provider store={store}>
        <SystemSettings />
      </Provider>
    </ThemeProvider>,
    node
  );
}
