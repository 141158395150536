import { createAction } from "@reduxjs/toolkit";

import {
  CONNECTION_SUCCESS,
  CONNECTION_ERROR,
  DISCONNECT_SUCCESS,
  WS_RECEIVED,
  WS_SEND
} from "./Consts";

const WebsocketActions = (prefix = "") => ({
  connectionSuccess: createAction(`${prefix}${CONNECTION_SUCCESS}`, (data) => ({
    payload: data
  })),

  connectionError: createAction(`${prefix}${CONNECTION_ERROR}`, (data) => ({
    payload: data
  })),

  disconnectSuccess: createAction(`${prefix}${DISCONNECT_SUCCESS}`),

  incomingEvent: createAction(`${prefix}${WS_RECEIVED}`, (data) => ({
    payload: data
  })),

  sendWs: createAction(`${prefix}${WS_SEND}`, (data) => ({
    payload: data
  }))
});

export default WebsocketActions;
