import { createSelector } from "reselect";
import { StoreState } from "frontend/src/ReactCore/store/types";

export const dashboardFilters = (state: StoreState.State) => {
  return state.DashboardFilters;
};

export const getRegions = (state) => {
  return state.DashboardFilters.regionList;
};

export const getMrfs = (state) => {
  return state.DashboardFilters.mrfList;
};

export const getCheckedMrfId = (state) => {
  const {
    mrfList,
    formData: { mrf: mrfCode }
  } = state.DashboardFilters;

  return mrfList.find((item) => item.id && item.value === mrfCode);
};

export const getRfs = (state) => {
  return state.DashboardFilters.rfList;
};

export const getCheckedRf = (state) => {
  return state.DashboardFilters.formData.rf;
};

export const getFilteredRfs = createSelector(
  getRfs,
  getCheckedMrfId,
  (rfs, checkedMrf) => {
    let isMrfSelected = !(
      !checkedMrf ||
      checkedMrf.value === "any" ||
      checkedMrf.value === "none"
    );
    if (isMrfSelected) {
      return rfs.filter((rf) => {
        return (
          rf.value === "any" ||
          rf.value === "none" ||
          rf.mrfId === checkedMrf.id
        );
      });
    }
    return rfs;
  }
);

export const getFilteredRegions = createSelector(
  getRegions,
  getCheckedRf,
  getCheckedMrfId,
  (regions, checkedRf, checkedMrf) => {
    let isMrfSelected = !(
      !checkedMrf ||
      checkedMrf.value === "any" ||
      checkedMrf.value === "none"
    );
    let isRfSelected = !(
      !checkedRf ||
      checkedRf === "any" ||
      checkedRf === "none"
    );
    if (isMrfSelected || isRfSelected) {
      return regions.filter((region) => {
        return (
          region.value === "any" ||
          region.value === "none" ||
          ((!isMrfSelected || region.mrfId === checkedMrf.id) &&
            (!isRfSelected || region.rfId === checkedRf))
        );
      });
    }
    return regions;
  }
);

export const getConfirmInProgress = (state) => {
  return state.DashboardFilters.confirmInProgress;
};

export const selectedFilters = createSelector(dashboardFilters, (state) => {
  return state.formData;
});

export const recentAppeals = createSelector(dashboardFilters, (state) => {
  return state.recentAppeals;
});
