import React, { FC, memo } from "react";
import c from "classnames";

import * as s from "./styles.module.scss";
import { ChatbotData } from "../../Chatbots";

const baseBotAvatar = "/www/img/msg_bot_avatar.png";

interface BotProps {
  bot: ChatbotData;
  onBotClick: (id: string) => void;
}


const Bot: FC<BotProps> = ({
  bot: {
    id,
    botAgentPenName,
    botAgentPhoto,
    heartBeatUrl,
    name
  },
  onBotClick
}) => {
  const handleBotClick = () => onBotClick(id);

  return (
    <div className={s["botWrap"]} onClick={handleBotClick} key={id}>
      <div className={s["bot"]}>
        <div className={c(s["avatar"], s["floatLeft"])}>
          <img
            src={
              botAgentPhoto
                ? `/fileStorage/files/crop/${botAgentPhoto}`
                : baseBotAvatar
            }
          />
        </div>
        <div className={s["info"]}>
          <div className={c(s["name"], s["floatLeft"])}>{name}</div>
          <div className={c(s["role"], s["floatRight"])}>
            {`${heartBeatUrl}`}
          </div>
        </div>
        <div className={s["info"]}>
          <div className={s["role"]}>{botAgentPenName}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(Bot);
