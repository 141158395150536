import { fork } from "redux-saga/effects";

import {
  getCategoriesSaga,
  saveRepliesSaga
} from "./CategoryForRepliesModal.saga";

export default function*() {
  yield fork(getCategoriesSaga);
  yield fork(saveRepliesSaga);
}
