import { takeEvery, select } from "redux-saga/effects";

import { httpRequest } from "../../ReactCore/shared/httpRequest";
import Informer from "../../arm/Wrappers/Informer";

import { SEND_SKILLGROUP_CAPACITY } from "./consts";
import { ISelect } from "../Common/Interfaces/Store.interfaces";

export function* sendSkillGroupCapacitySaga() {
  const SkillGroupCapacityModalData = yield select<ISelect>(
    ({ SkillGroupCapacityModal }) => SkillGroupCapacityModal.skillGroupsData
  );
  try {
    const res = yield httpRequest(
      "POST",
      `bff/settings/skill-groups/${SkillGroupCapacityModalData.skillId}/queue`,
      { body: SkillGroupCapacityModalData }
    );

    let infoText = "Ошибка при сохранении";
    if (res?.success) {
      infoText = "Изменения успешно сохранены";
    }

    const info = new Informer(infoText);
    info.show();
  } catch ($error) {
    const info = new Informer(`Неизвестная ошибка`);
    info.show();
    console.error($error);
  }
}

export default function*() {
  yield takeEvery(SEND_SKILLGROUP_CAPACITY, sendSkillGroupCapacitySaga);
}
