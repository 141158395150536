import { takeEvery, put, select } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";

import { RepliesListTypes } from "../Interfaces";
import { CategoriesListTypes } from "../../CategoriesList/Interfaces";

import { ACTIVE, NOT_ACTIVE, GET_NEXT_PAGE } from "../Consts";
import { ALL, DELETED } from "../../CategoriesList/Consts";

import { setCategory } from "../../CategoriesList/Actions/CategoriesList.action";

import {
  getReplies,
  setQuery,
  setReplies,
  removeReplies,
  getNextPage,
  addReplies
} from "../Actions/RepliesList.action";

function* getRepliesListData(action) {
  const { query, page }: RepliesListTypes.State = yield select(
    ({ RepliesList }) => RepliesList
  );

  const { category }: CategoriesListTypes.State = yield select(
    ({ CategoriesList }) => CategoriesList
  );

  let queryData = {
    query,
    page
  };

  if (category !== ALL && category !== DELETED) {
    queryData["categoryId"] = category;
  }

  queryData["status"] = ACTIVE;
  const response = yield httpRequest("GET", `bff/settings/replies`, {
    query: queryData
  });

  if (response?.success) {
    if (action.type === GET_NEXT_PAGE) {
      if (!!response.data?.content?.length) {
        yield put(addReplies(response.data.content));
      }
    } else {
      yield put(setReplies(response.data?.content));
    }
  }
}

function* removeRepliesData(action) {
  const { onSuccess, onError } = action.payload;
  const { all, selected }: RepliesListTypes.State = yield select(
    ({ RepliesList }) => RepliesList
  );
  const { category }: CategoriesListTypes.State = yield select(
    ({ CategoriesList }) => CategoriesList
  );
  let query = {};
  let body = { all, status: NOT_ACTIVE };

  if (!all) {
    body["ids"] = selected;
  } else if (typeof category === "number") {
    query["categoryId"] = category;
  }

  const response = yield httpRequest("PUT", `bff/settings/replies`, {
    query,
    body
  });

  if (response?.success) {
    yield put(getReplies());
    onSuccess();
  } else {
    onError();
  }
}

export const getRepliesListSaga = function* () {
  yield takeEvery(
    [getReplies, setQuery, setCategory, getNextPage],
    getRepliesListData
  );
};

export const removeRepliesSaga = function* () {
  yield takeEvery([removeReplies], removeRepliesData);
};
