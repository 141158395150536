import { store } from "../../ReactCore";
import * as chatSelectors from "../store/Chat.selectors";
import * as chatActions from "../store/Chat.actionCreators";

import {
  getUpdatedDialogListData,
  getWithUpdatedIsWaitingOn
} from "../Chat.service";

const noThemesAction = (payload: { data: any }) => {
  let dialogList = chatSelectors.getDialogList(store.getState());

  if (!dialogList.length) return;

  store.dispatch(
    chatActions.updateDialogList(
      getUpdatedDialogListData(
        dialogList,
        getWithUpdatedIsWaitingOn(dialogList, payload?.data)
      )
    )
  );
};

export default noThemesAction;
