import { Selector } from "reselect";
import { StoreState } from "../../ReactCore/store/types";
import { IPreparedNewMessageData } from "../Chat";
import { EDOWNLOAD_STATUS } from "../../ReactFeatures/Common/Enums/LoadingData.enums";

export const getChatInitialDataLoadedStatus = (state): boolean => {
  return state.Chat.status === EDOWNLOAD_STATUS.FULFILLED;
};

export const getShowCsiOperator = (state): boolean => {
  return state.Chat.csiSettings?.showCsiOperator;
};

export const getUnreadList = (state: StoreState.State) => {
  return state.Chat.unreadList;
};

/**
 * Селектор на получение списка диалогов.
 *
 * @param {StoreState.State} state Селектор диалогов.
 */
export const getDialogList: Selector<
  StoreState.State,
  IPreparedNewMessageData[]
> = (state: StoreState.State) => {
  let dialogs = [...state.Chat.dialogs];
  return dialogs.sort(sorting);
};

const sorting = (a, b) => {
  var sortField = "time";
  return b[sortField] - a[sortField];
};

export const getIntegrations = (state): boolean => {
  return state.Chat.integrations;
};
