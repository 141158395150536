import React, { FC, memo } from "react";
import { Grid, Typography } from "@mui/material";
import { Button } from "@omnichat/arm_ui_kit";

import { ModalHeader } from "../../../../Components";

interface Props {
  handling?: boolean;
  onConfirm: Function;
}

const RoleDeleteModal: FC<Props> = ({ onConfirm, handling }): JSX.Element => (
  <div id="SkillGroupEditModal" style={{ width: "550px" }}>
    <ModalHeader>Подтверждение удаления</ModalHeader>

    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography>
          Выбранная роль будет удалена.
          <br />
          Хотите продолжить?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            type="default"
            disabled={handling}
            theme={handling ? "disabled" : "green"}
            onClick={onConfirm}
            text="Продолжить"
          />
        </div>
      </Grid>
    </Grid>
  </div>
);

export default memo(RoleDeleteModal);
