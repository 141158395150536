import React, { FC, ReactNode } from "react";

import {
  ThemeProvider as MUIThemeProvider,
  createTheme
} from "@mui/material/styles";

import Colors from "./colors";

export enum Fonts {
  sizeHeader1 = 20,
  sizeHeader2 = 18,
  sizeHeader3 = 16,
  sizeHeader4 = 16,
  sizeDefault = 16,
  sizeSubtitle = 14
}

const theme = createTheme({
  palette: {
    primary: {
      extraLight: Colors.primaryText,
      light: Colors.primaryBg,
      main: Colors.primaryAction,
      dark: Colors.primaryAction,
      contrastText: Colors.white,
      hover: Colors.primaryHoverBg,
      disabled: Colors.primaryDisabled
    },
    error: {
      extraLight: Colors.errorBg,
      light: Colors.errorText,
      main: Colors.errorAction,
      dark: Colors.errorActionText
    },
    success: {
      main: Colors.successAction
    },
    info: {
      light: Colors.textHints,
      main: Colors.textDefault,
      label: Colors.textLabelDefault
    },
    tooltip: {
      light: Colors.tooltipBg,
      main: Colors.tooltipArrow
    },
    gray: {
      extraLight: Colors.lightGray,
      main: Colors.gray
    }
  },
  typography: {
    h1: {
      fontSize: Fonts.sizeHeader1,
      color: Colors.textDefault,
      fontWeight: "bold"
    },
    h2: {
      fontSize: Fonts.sizeHeader2,
      color: Colors.textDefault,
      fontWeight: "bold"
    },
    h3: {
      fontSize: Fonts.sizeHeader3,
      color: Colors.textHints,
      textTransform: "uppercase",
      fontWeight: "bold"
    },
    h4: {
      fontSize: Fonts.sizeHeader4,
      color: Colors.textDefault,
      fontWeight: "bold"
    },
    body1: {
      fontSize: Fonts.sizeDefault,
      color: Colors.textDefault
    },
    body2: {
      fontSize: Fonts.sizeDefault,
      color: Colors.textHints
    },
    subtitle1: {
      fontSize: Fonts.sizeSubtitle,
      color: Colors.textDefault
    },
    fontFamily: [
      "PT Sans",
      "Arial",
      "sans-serif",
      "Candara",
      "Segoe",
      "Segoe UI",
      "Optima"
    ].join(","),
    fontSize: Fonts.sizeDefault
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span"
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "dropdown" },
          style: ({ theme }) => ({
            textTransform: "none",
            lineHeight: 1,
            borderRadius: 0,
            width: "auto",
            height: "auto",
            "& span.MuiButton-startIcon": {
              marginRight: "-2px",
              marginLeft: "-4px"
            },
            "& span.MuiButton-endIcon": {
              margin: "4px 2px 0 10px",
              "& .MuiSvgIcon-root": {
                width: "16px",
                height: "16px"
              }
            },
            ":hover": {
              background: theme.palette.primary.hover
            },
            "&[aria-expanded='true']": {
              background: theme.palette.primary.main,
              span: {
                "&.MuiButton-startIcon, &.MuiButton-endIcon, &.MuiTypography-root": {
                  color: theme.palette.primary.contrastText
                }
              }
            }
          })
        },
        {
          props: { variant: "manage" },
          style: ({ theme }) => ({
            display: "flex",
            width: "100%",
            fontSize: theme.typography.fontSize,
            textDecoration: "none",
            textTransform: "none",
            color: theme.palette.primary.main,
            "& span.MuiTypography-root": {
              color: theme.palette.primary.main,
              whiteSpace: "normal",
              marginLeft: "10px"
            },
            ":hover": {
              background: theme.palette.primary.hover
            }
          })
        }
      ],
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === "dropdown" && {
            span: {
              "&.MuiButton-startIcon, &.MuiButton-endIcon, &.MuiTypography-root": {
                color: ownerState.disabled
                  ? theme.palette.primary.disabled
                  : theme.palette.primary.main
              }
            }
          })
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: ({ theme }) => ({
          padding: "5px",
          "& hr.MuiDivider-root": { margin: 0 },
          ".MuiSvgIcon-root": {
            color: theme.palette.primary.main
          },
          ".MuiTypography-root": {
            color: theme.palette.primary.main,
            fontSize: theme.typography.fontSize,
            textTransform: "none"
          }
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: "7px",
          height: "30px",
          ".MuiListItemIcon-root ": { minWidth: "30px" },
          ":hover": { background: theme.palette.primary.hover }
        })
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: { margin: 0 },
        label: ({ ownerState, theme }) => ({
          color: theme.palette.primary.main,
          fontSize: theme.typography.fontSize,

          ...(ownerState.disabled && {
            opacity: 0.5
          })
        }),
        labelPlacementStart: {
          marginRight: "10px"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: "flex",
          flexFlow: "column nowrap",
          marginTop: "2px"
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 0,
          height: "22px",
          width: "36px",
          opacity: 1,
          ...(ownerState.disabled && {
            opacity: "0.25"
          }),
          ".MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
            opacity: "1"
          }
        }),
        switchBase: ({ ownerState }) => ({
          padding: 0,
          top: "2px",
          left: "3px",

          ...(ownerState.checked && {
            transform: "translateX(14px) !important"
          }),
          ...(ownerState.disabled && {
            opacity: 1
          })
        }),
        track: ({ ownerState, theme }) => ({
          width: "36px",
          height: "20px",
          background: theme.palette.error.main,
          borderRadius: "10px",
          opacity: "0.5",

          ...(ownerState.checked && {
            background: `${theme.palette.success.main} !important`,
            opacity: "1"
          }),
          ...(ownerState.disabled && {
            opacity: 1
          })
        }),
        thumb: ({ theme }) => ({
          boxShadow: "none",
          height: "16px",
          width: "16px",
          background: theme.palette.primary.contrastText
        })
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: "14px",
          height: "14px",
          color: theme.palette.gray.extraLight
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: "23px",
          fontSize: "16px",
          margin: "0px 2px",
          maxWidth: "200px"
        }),
        deleteIcon: {
          color: "inherit",
          opacity: 0.8,

          "&:hover": {
            color: "inherit",
            opacity: 1
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.primary.light,
          borderColor: theme.palette.primary.light,
          fontSize: theme.typography.fontSize,
          display: "flex",
          alignItems: "center",
          minHeight: 44,
          boxSizing: "border-box",

          ...(ownerState.value
            ? { color: theme.palette.info.main }
            : ownerState.error
            ? { color: theme.palette.error.main } // or theme.palette.error.light
            : { color: theme.palette.primary.extraLight }),

          ...(ownerState.open && {
            border: `2px solid ${theme.palette.primary.main}`,
            background: theme.palette.primary.contrastText
          }),

          ...{
            padding: ownerState.multiple ? "8px" : "8px 24px"
          },

          ...(ownerState.error && {
            backgroundColor: theme.palette.error.extraLight,
            border: `2px solid ${theme.palette.error.extraLight}`,
            background: theme.palette.error.extraLight,

            ...(ownerState.open && {
              border: `2px solid ${theme.palette.error.main}`,
              background: theme.palette.primary.contrastText
            }),

            ":focus, :active": {
              border: `2px solid ${theme.palette.error.main}`,
              background: theme.palette.error.extraLight
            }
          })
        }),
        icon: ({ ownerState, theme }) => ({
          left: 0,
          width: 24,
          height: 24,
          top: "calc(50% - 12px)",

          ...(ownerState.multiple && { display: "none" }),

          ...{
            color: ownerState.error
              ? theme.palette.error.main
              : theme.palette.primary.main
          }
        })
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          cursor: "pointer",
          ":focus, :active": {
            backgroundColor: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.main
          }
        }),

        inputRoot: ({ ownerState, theme }) => ({
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.light}`,
          display: "flex",
          rowGap: "2px",
          columnGap: "2px",
          alignItems: "center",
          padding: "7px 24px 7px 26px",
          paddingRight: "10px !important",
          backgroundColor: theme.palette.primary.light,
          marginTop: 0,

          ...(ownerState.freeSolo && {
            paddingLeft: "7px"
          }),

          ...(ownerState.open && {
            backgroundColor: theme.palette.primary.contrastText,
            boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`
          }),

          ...(ownerState.error && {
            backgroundColor: theme.palette.error.extraLight,
            boxShadow: `inset 0 0 0 2px ${theme.palette.error.extraLight}`,

            ...(ownerState.open && {
              boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}`,
              background: theme.palette.primary.contrastText
            })
          })
        }),

        endAdornment: ({ ownerState, theme }) => ({
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          transform: "none",

          svg: {
            width: 24,
            height: 24,
            fill: ownerState.error
              ? theme.palette.error.main
              : theme.palette.primary.main
          }
        }),

        popupIndicator: {
          position: "absolute",
          left: 0,
          width: 30,
          height: "100%",
          top: 0,
          ":hover": {
            backgroundColor: "transparent"
          }
        },
        clearIndicator: ({ ownerState }) => ({
          position: "absolute",
          right: 0,
          width: 40,
          height: "100%",
          top: 0,
          ":hover": {
            backgroundColor: "transparent"
          },
          ...(ownerState.multiple && { display: "none" })
        }),

        tag: {
          padding: "15px 0",
          margin: "0",
          height: "30px",
          svg: {
            zIndex: 1
          }
        },

        input: ({ ownerState }) => ({
          marginLeft: "5px",
          height: "30px",

          "::placeholder": {
            color: ownerState.error
              ? theme.palette.error.main
              : theme.palette.primary.extraLight
          },
          ...(!ownerState.open &&
            ownerState.value?.length > 0 && {
              minWidth: "1px",
              display: "none"
            })
        }),

        inputFocused: () => ({
          borderColor: "transparent",
          background: "transparent"
        }),

        hasClearIcon: true,
        noOptions: ({ theme }) => ({
          ...theme.typography.body1,
          lineHeight: "15px"
        }),

        option: ({ theme }) => ({
          fontSize: theme.typography.fontSize,
          lineHeight: "15px"
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: theme.palette.tooltip.light,
          color: theme.palette.primary.contrastText,
          fontSize: theme.typography.fontSize
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.tooltip.light
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          outline: "none",
          display: "block",
          padding: "0",
          fontSize: theme.typography.fontSize,
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          }
        }),
        input: ({ ownerState, theme }) => ({
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
          padding: "10px 8px",
          border: `2px solid ${theme.palette.primary.light}`,
          background: theme.palette.primary.light,

          "::placeholder": {
            color: theme.palette.primary.extraLight,
            opacity: 1
          },

          ":focus, :active": {
            border: `2px solid ${theme.palette.primary.main}`,
            background: theme.palette.primary.contrastText,
            color: theme.palette.info.main
          },

          ...(ownerState.error && {
            border: `2px solid ${theme.palette.error.extraLight}`,
            background: theme.palette.error.extraLight,
            color: theme.palette.info.main,

            "::placeholder": {
              color: theme.palette.error.light
            },

            ":focus, :active": {
              border: `2px solid ${theme.palette.error.main}`,
              background: theme.palette.error.extraLight,
              color: theme.palette.info.main
            }
          }),

          ...(ownerState.size === "small" && {
            padding: "6px"
          })
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          marginRight: 0,
          textTransform: "uppercase",
          fontWeight: "bolder",
          fontSize: "100%",
          marginBottom: "8px",
          lineHeight: "1.2em",
          color: theme.palette.info.light,
          transform: "none",

          ...(ownerState.required && {
            ".MuiFormLabel-asterisk": {
              color: theme.palette.error.dark
            }
          })
        })
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          lineHeight: `${Fonts.sizeHeader2}px`,
          color: ownerState.error
            ? theme.palette.error.dark
            : theme.palette.info.light,
          marginTop: "3px",
          fontSize: theme.typography.fontSize,
          display: "flex",
          alignItems: "center",

          ...(ownerState.disabled && {
            opacity: 0.5
          }),

          span: {
            lineHeight: `${Fonts.sizeHeader2}px`,
            ...(ownerState.error && {
              margin: "4px 0 0 5px",
              color: theme.palette.error.dark
            })
          },
          svg: {
            width: "24px",
            height: "24px",
            color: theme.palette.error.dark
          }
        })
      }
    },
    MuiModal: {
      styleOverrides: {
        backdrop: () => ({
          backgroundColor: "transparent"
        })
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: () => ({
          display: "none"
        })
      }
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingBottom: 0,
          cursor: "pointer",
          "& .MuiTypography-root": {
            color: theme.palette.primary.main,
            lineHeight: 1.25,
            textTransform: "capitalize",
            borderBottom: `1px dashed ${theme.palette.primary.main}`
          },

          "&.Mui-selected": {
            cursor: "default",
            "& .MuiTypography-root": {
              color: theme.palette.info.label,
              borderBottom: `none`,
              paddingBottom: "1px"
            }
          }
        })
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxSizing: "border-box",
          scrollbarColor: "grey transparent",
          scrollbarWidth: "thin",

          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0"
          },

          "&::-webkit-scrollbar-thumb": {
            border: "5px solid transparent",
            borderRadius: "10px",
            backgroundColor: "#ccc",
            cursor: "pointer"
          },

          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#42abe1"
          },

          "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: "#f0f0f0"
          }
        })
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          paddingRight: "25px"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:nth-of-type(2n+1)": {
            backgroundColor: "#F6FBFC"
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.hover
          }
        }),
        head: ({ theme }) => ({
          "&:nth-of-type(2n+1)": {
            backgroundColor: theme.palette.primary.contrastText
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.contrastText
          }
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderBottom: "1px #E9EEEF solid",
          borderRight: `1px solid ${theme.palette.primary.contrastText}`,
          cursor: "pointer",
          borderRadius: "3px"
        }),
        head: ({ theme }) => ({
          color: theme.palette.info.light,
          padding: "0px 10px 15px",
          textTransform: "uppercase",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center"
        })
      }
    }
  }
});

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};

export default ThemeProvider;
