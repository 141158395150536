import { createSelector } from "reselect";

export const getName = state => {
  return state.CategoryModal.name;
};

export const getAll = state => {
  return state.CategoryModal.all;
};

export const getSelectedSkillGroups = state => {
  return state.CategoryModal.selectedGroups;
};

export const getSkillGroups = state => {
  return state.CategoryModal.skillGroups.map(item => ({
    label: item.name,
    value: item.id
  }));
};
