// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".linkForm--mXKx5fbxft_wkzVX8w76{min-height:150px}.buttons--yrq1097kcANdQ3w7DVMP{display:flex;justify-content:space-between;margin-top:35px}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/ClassifierLinkForm/Components/ClassifierLinkForm/style.module.scss"],"names":[],"mappings":"AAAA,gCACE,gBAAA,CAEF,+BACE,YAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".linkForm {\n  min-height: 150px;\n}\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
export var linkForm = "linkForm--mXKx5fbxft_wkzVX8w76";
export var buttons = "buttons--yrq1097kcANdQ3w7DVMP";
export default ___CSS_LOADER_EXPORT___;
