import React, { FC } from "react";
import c from "classnames";
import { ScrollBox } from "@omnichat/arm_ui_kit";

import { ModalHeader } from "../../../../Components";
import { IMessage } from "../../../Common/Interfaces/Message.interfaces";

import * as cs from "../../../../styles/common.module.scss";
import * as s from "./DialogContextModal.module.scss";

export interface IDialogContext extends IDialogContextMeta {
  messages: IMessage[];
}

export interface IDialogContextMeta {
  pageUrl: string;
  serviceName: string;
  displayHeader: string;
  clientRole: string;
}

interface IDialogContextModal {
  data: IDialogContextMeta;
  children: JSX.Element;
}

const DialogContextModal: FC<IDialogContextModal> = ({
  data,
  children
}): JSX.Element => {
  const { pageUrl, displayHeader, serviceName, clientRole } = data;

  return (
    <div id="DialogContextModal" className={c(cs["defaultModal"], s["modal"])}>
      <ModalHeader>
        Контекст диалога
        <ul className={s["modalHeaderContextInfo"]}>
          {pageUrl ? <li>{`Адрес: ${pageUrl}`}</li> : null}
          {displayHeader ? <li>{`Шаг услуги: ${displayHeader}`}</li> : null}
          {serviceName ? <li>{`Название услуги: ${serviceName}`}</li> : null}
          {clientRole ? <li>{`Роль клиента: ${clientRole}`}</li> : null}
        </ul>
      </ModalHeader>

      <div className={c(cs["defaultModalContent"], s["modalContent"])}>
        <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
          <div className={s["marginScrollBoxContent"]}>{children}</div>
        </ScrollBox>
      </div>
    </div>
  );
};

export default DialogContextModal;
