import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { LOADED_REGIONS, GET_REGIONS } from "../consts";

export function* getRegionsSaga() {
  try {
    const response = yield httpRequest("GET", "bff/dictionary/regions");
    if (response?.success) {
      yield put({
        type: LOADED_REGIONS,
        payload: response.data?.map((el) => ({
          id: el.id,
          value: el.id,
          label: el.region
        }))
      });
    }
  } catch (e) {
    console.error("Saga: getRegionsSaga error", e);
  }
}

export default function*() {
  yield takeEvery(GET_REGIONS, getRegionsSaga);
}
