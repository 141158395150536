import auth from "./workspace_auth";

export default {
  auth,
  draw: {},

  websocket: {
    logout: {
      onmessage: function(data) {
        if (core.user.id == data.user_id) window.location.reload();
      }
    }
  },

  logout: function() {
    $.ajax({
      url: "/workspace?sec=logout",
      success: function() {
        location.reload();
      }
    });
  },

  nav: {
    init: function() {
      if (core.nav.url.sec !== "auth") {
        $(window)
          .on({
            resize: workspace.winResize
          })
          .trigger("resize");

        if (core.nav.url.sec === "stats") {
          workspace.watch.start();
        }
        $.ajaxSetup({
          complete: function(jqXHR, textStatus) {
            if (jqXHR.status == 302) {
              clearInterval(workspace.watch.interval);
              core.websocket.disconnect();
              workspace.reauth.init();
            }
          }
        });
      }
    },

    before: function(data) {
      workspace.menu_handler(data.url.sec);
      if (core.nav.url.sec !== "stats" && core.websocket.listen) {
        core.websocket.disconnect();
      }
    },

    after: function(data) {
      if (core.nav.url.sec === "stats") {
        workspace.watch.start();
      }
    }
  },

  menu_handler: function(section) {
    $("#workspace_dashboard_menu")
      .find(".sel")
      .removeClass("sel");
    $("#workspace_dashboard_menu")
      .find("." + section)
      .addClass("sel");
  },

  winResize() {
    var height = core.user.winHeight;

    $("#page").css({
      minHeight: height
    });
  },

  watch: {
    start: function() {
      localStorage.user_id = core.user.id;

      if (core.user.id)
        workspace.watch.interval = setInterval(workspace.watch.check, 5000);
    },

    check: function() {
      if (core.user.id != localStorage.user_id) {
        clearInterval(workspace.watch.interval);
        core.websocket.disconnect();
        workspace.reauth.init();
      }
    }
  },

  reauth: {
    init: function() {
      core.nav.send(
        {
          url: {
            sol: "workspace",
            sec: "reauth"
          },
          after: function(data) {
            core.draw.window({
              id: "workspace_reauth",
              body: data.tpls.window,
              opacity: 1,
              hover: true,
              backgroundColor: "#FFF",
              close: {
                overlay: false,
                esc: false
              }
            });
          }
        },
        false
      );
    },

    submit: function() {
      if (core.user.id == localStorage.user_id) {
        core.websocket.connect();
        workspace.watch.start();
        $("#workspace_reauth").modal("close");
      } else window.location.reload();
    }
  }
};
