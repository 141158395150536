import * as React from "react";
import * as ReactDom from "react-dom";
import { Provider } from "react-redux";

import { store } from "../ReactCore";
import ThemeProvider from "../Components/ThemeProvider";
import { HashRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import DashboardSection from "./DashboardSection";
import AppealInfo from "../ReactFeatures/AppealInfo";

const REACT_ROOT_SELECTOR = "#reactRoot_dashboard";

export const initDashboard = () => {
  // @ts-ignore
  const selector = document.querySelector(REACT_ROOT_SELECTOR);

  window.imageViewer.init();

  ReactDom.render(
    <ThemeProvider>
      <HashRouter>
        <Provider store={store}>
          <Routes>
            <Route
              key="Dashboard"
              path="/:currentTab?"
              element={<DashboardSection />}
            >
              <Route
                key="Dashboard"
                path=":appealId"
                element={<AppealInfo />}
              />
            </Route>
          </Routes>
        </Provider>
      </HashRouter>
    </ThemeProvider>,
    selector
  );
};

export const dashboardDestroy = () => {
  $(".body").addClass("load");
  const selector = document.querySelector(REACT_ROOT_SELECTOR);
  ReactDom.unmountComponentAtNode(selector);
};
