import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "frontend/src/ReactCore/store/types";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { getClassifiers } from "../../../Settings/Classifiers/Store/Classifiers.selectors";

export const ClassifierFieldForm = (state: StoreState.State) => {
  return state.ClassifierFieldForm;
};

export const getFieldsList = createSelector(getClassifiers, (state) => {
  return state.fields;
});
export const getLinksList = createSelector(ClassifierFieldForm, (state) => {
  return state.linksData || [];
});
export const getLoadingStatus = createSelector(ClassifierFieldForm, (state) => {
  return !isEmpty(state.loading);
});

export const getResponseError = createSelector(ClassifierFieldForm, (state) => {
  return state.errorMessage;
});

export const getFieldData = createSelector(ClassifierFieldForm, (state) => {
  return state.fieldData || null;
});

export const getTemplateData = createSelector(ClassifierFieldForm, (state) => {
  return state.templateData
    ? state.templateData.map((v) => ({
        id: v.id,
        content: v.context,
        name: v.name,
        order: v.order
      }))
    : null;
});
export const getValidateData = createSelector(ClassifierFieldForm, (state) => {
  return state.validateData
    ? state.validateData.map((v) => ({
        id: v.id,
        regexp: v.regex,
        errorText: v.errorText
      }))
    : null;
});

export const getReadableLinks = createSelector(ClassifierFieldForm, (state) => {
  return state.linksData?.map((d) => d.map((l) => l.valueName).join(" > "));
});
