import { takeEvery, put, select } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  sendClassifierData,
  setClassifierResponseError,
  startLoading,
  finishLoading,
  setClassifierData,
  SEND_CLASSIFIER_DATA
} from "../Store/ClassifierForm.reducer";
import { ClassifierFormTypes, Data } from "../ClassifierForm";

import Informer from "../../../arm/Wrappers/Informer";
import { ISelect } from "../../Common/Interfaces/Store.interfaces";

export function* sendClassifierSaga(action) {
  yield put(startLoading(SEND_CLASSIFIER_DATA));

  const classifierData: Data = yield select<ISelect>(
    ({ ClassifierForm }) => ClassifierForm.classifierData
  );

  const { formData, close: closeModal, updateClassifierList } = action.payload;

  const preparedClassifierData = ((data: Data): ClassifierFormTypes.DTO => ({
    id: classifierData?.id,
    isForAll: data.isForAll,
    channelIds: data.channels,
    mrfIds: data.mrfs,
    skillGroupIds: data.skillGroups,
    name: data.name
  }))(formData);

  try {
    const method = preparedClassifierData.id ? "PUT" : "POST";
    const id = preparedClassifierData.id || "";

    const res = yield httpRequest(method, `bff/settings/classifier/${id}`, {
      body: preparedClassifierData
    });

    if (res?.success) {
      let actionName = preparedClassifierData.id ? "отредактирован" : "создан";

      yield put(setClassifierData(res.data));
      updateClassifierList();

      const info = new Informer(
        `Классификатор «${res.data.name}» был ${actionName}`,
        4500
      );
      info.show();
      closeModal();
    } else {
      yield put(setClassifierResponseError("Ошибка сервера"));
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(finishLoading(SEND_CLASSIFIER_DATA));
  }
}

export default function* () {
  yield takeEvery([sendClassifierData], sendClassifierSaga);
}
