import { UserGropsManagerTypes } from "../UserGroupsManager";
import { ALL } from "../consts";

const Users: UserGropsManagerTypes.State = {
  currentUsersPage: 0,
  currentSkillGroupPage: 0,
  currentSkillGroupId: ALL,
  query: "",
  checkedUsers: [],
  checkAllUsers: false,
  loading: [],
  restorePasswordHandling: false,
  skillGroupList: null,
  skillGroupData: null,
  userList: null,
  userData: null
};

export default Users;
