import {
  takeEvery,
  call,
  put,
  select,
  take,
  cancel,
  cancelled,
  fork
} from "redux-saga/effects";
import {
  SEND_FILE,
  UPDATE_PROGRESS,
  FILE_LOADED,
  FILE_LOAD_FAILED
} from "./consts";
import { createUploadFileChannel } from "./fileStorage.service";
import { removeFile } from "./fileStorage.actions";
import { ISelect } from "../Common/Interfaces/Store.interfaces";

function* sendFile(action) {
  const { uuid, onSuccess, onError } = action.payload;
  const files = yield select<ISelect>(({ FileStorage }) => FileStorage.files);

  const { file } = files.find(file => file.uuid === uuid);

  yield call(uploadFileSaga, file, uuid, onSuccess, onError);
}

export function* uploadFileSaga(
  file: File,
  uuid: string,
  onSuccess: any,
  onError: any
) {
  const channel = yield call(createUploadFileChannel, file);

  try {
    while (true) {
      const { progress = 0, err, success, response } = yield take(channel);

      if (success) {
        yield put({ type: FILE_LOADED, payload: { uuid, data: response } });
        onSuccess && onSuccess({ uuid, data: response });
        return;
      }

      yield put({
        type: UPDATE_PROGRESS,
        payload: { uuid, progress, cancel: () => channel.close() }
      });
    }
  } catch (err) {
    channel.close();
    yield put({ type: FILE_LOAD_FAILED, payload: { uuid, data: err } });
    yield put(removeFile(uuid));
    onError && onError({ uuid, data: err });
  }
}

export default function*() {
  yield takeEvery(SEND_FILE, sendFile);
}
