import React, { FunctionComponent } from "react";

import c from "classnames";
import { Icon, Preloader } from "@omnichat/arm_ui_kit";

import * as s from "./ReplyTemplateEdit.module.scss";

interface IAttachment {
  name: string;
  uuid: string;
  sended: boolean;
  loading?: boolean;
  progress?: number;
  failed?: boolean;
  removeAttachment: () => void;
}

const Attachment: FunctionComponent<IAttachment> = ({
  name,
  sended,
  loading = false,
  progress,
  removeAttachment,
}): JSX.Element => {
  return (
    <li className={s["item"]}>
      <div className={s["fileContainer"]}>
        <div className={s["fileIcon"]}>
          <Preloader size="mini" show={loading} color={"blue"}>
            <Icon name="file" color="gray" />
          </Preloader>
        </div>
        <div className={s["fileName"]}>{name}</div>
        <div className={s["fileRemove"]} onClick={() => removeAttachment()}>
          <Icon name="cross" color="red" />
        </div>
      </div>
      <div
        className={c(s["fileProgress"], sended && s["fileProgressComplete"])}
        style={{ width: `${progress}%` }}
      >
        <div className={s["bar"]} />
      </div>
    </li>
  );
};
export default Attachment;
