import createSagaMiddleware from "redux-saga";

import initialState from "./initialState";

import ChatWs from "../../Chat/Sagas/root.saga";
import { configureStore } from "@reduxjs/toolkit";

import reducers from "./reducers";

export const saga = createSagaMiddleware();

let store = configureStore({
  name: "OMNICHAT_STORE",
  reducer: reducers,
  middleware: [saga],
  preloadedState: initialState,
  devTools: NODE_ENV === "development"
});

export type TypeRootState = ReturnType<typeof store.getState>;

/**
 * Регистрация root.saga для всех ReactFeatures
 */
const requireFeaturesSaga = require.context(
  "../../ReactFeatures",
  true,
  /root\.saga\.ts/
);
requireFeaturesSaga.keys().forEach((name) => {
  saga.run(requireFeaturesSaga(name).default);
});

/**
 * Регистрация root.saga для всех Settings
 */
const requireSettingsSaga = require.context(
  "../../Settings",
  true,
  /root\.saga\.ts/
);
requireSettingsSaga.keys().forEach((name) => {
  saga.run(requireSettingsSaga(name).default);
});

/**
 * Регистрация root.saga для всех Dashboard
 */
const requireDashboardSaga = require.context(
  "../../Dashboard",
  true,
  /root\.saga\.ts/
);
requireDashboardSaga.keys().forEach((name) => {
  saga.run(requireDashboardSaga(name).default);
});

/**
 * Регистрация root.saga для микроприложения чата
 */
saga.run(ChatWs);
export { store };
