import produce from "immer";

import initialState from "../../../ReactCore/store/initialState";

import {
  SET_THEMES_FIELDS,
  ADD_APPEAL_THEMES_TO_DIALOG,
  SET_REGION,
  SET_SERVICE,
  SET_SELECTED_FIELDS_VALUES,
  DELETE_THEME,
  EDIT_THEME,
  CANCEL_EDIT,
  SAVE_THEME,
  RESET_DATA,
  ADD_APPEAL_ORIGIN_THEMES,
  ADD_APPEAL_ORIGIN_SERVICES,
  SET_SERVICE_ENABLED,
  VALIDATE_RESULT,
  GET_THEMES_FIELDS,
  GET_THEMES_FIELDS_LOADED
} from "../Consts";

import {
  changedValues,
  prepareFields,
  prepareValue,
  cleanValues,
  deleteTheme,
  setFields,
  validateRegion,
  validateThemes
} from "../Services/ThematicModal.service";

export default function ThematicModalReducer(
  state = initialState.ThematicModal,
  { type, payload }
) {
  switch (type) {
    case RESET_DATA:
      return produce(state, draft => {
        draft.themeFields = cleanValues(draft.themeFields);
        draft.activeService = initialState.ThematicModal.activeService;
        draft.appealThemes = initialState.ThematicModal.appealThemes;
        draft.errorMessage = initialState.ThematicModal.errorMessage;
        draft.selectedRegion = initialState.ThematicModal.selectedRegion;
        draft.editTheme = initialState.ThematicModal.editTheme;
      });
    case GET_THEMES_FIELDS:
      return produce(state, draft => {
        draft.isLoading = true;
      });
    case GET_THEMES_FIELDS_LOADED:
      return produce(state, draft => {
        draft.isLoading = false;
      });
    case SET_SELECTED_FIELDS_VALUES:
      return produce(state, draft => {
        draft.themeFields = changedValues(payload, draft.themeFields);
      });
    case SET_THEMES_FIELDS:
      return produce(state, draft => {
        draft.themeFields = prepareFields(payload);
      });
    case DELETE_THEME:
      return produce(state, draft => {
        draft.editTheme = null;
        draft.appealThemes = deleteTheme(payload, draft.appealThemes);
      });
    case CANCEL_EDIT:
      return produce(state, draft => {
        draft.themeFields = cleanValues(draft.themeFields);
        draft.errorMessage = null;
        draft.editTheme = null;
      });
    case EDIT_THEME:
      return produce(state, draft => {
        draft.themeFields = setFields(
          payload,
          draft.themeFields,
          draft.appealThemes
        );
        draft.editTheme = payload;
      });
    case SAVE_THEME:
      return produce(state, draft => {
        draft.appealThemes = prepareValue(
          draft.editTheme,
          draft.appealThemes,
          draft.themeFields
        );
        draft.themeFields = cleanValues(draft.themeFields);
        draft.errorMessage = null;
        draft.editTheme = null;
      });
    case ADD_APPEAL_THEMES_TO_DIALOG:
      return produce(state, draft => {
        draft.appealThemes = [...payload];
      });
    case SET_SERVICE:
      return produce(state, draft => {
        draft.activeService = payload;
      });
    case SET_REGION:
      return produce(state, draft => {
        draft.selectedRegion = {
          value: payload,
          error: false
        };
      });
    case VALIDATE_RESULT:
      return produce(state, draft => {
        if (draft.serviceEnabled) {
          draft.selectedRegion = validateRegion(draft.selectedRegion);
        }
        draft.errorMessage = validateThemes(draft.appealThemes);
      });
    case ADD_APPEAL_ORIGIN_THEMES:
      return produce(state, draft => {
        draft.originAppealThemes = payload;
      });
    case ADD_APPEAL_ORIGIN_SERVICES:
      return produce(state, draft => {
        draft.originAppealService = payload;
      });
    case SET_SERVICE_ENABLED:
      return produce(state, draft => {
        draft.serviceEnabled = payload;
      });
    default:
      return state;
  }
}
