import React, { FC } from "react";

import { isEmpty } from "@omnichat/arm_ui_kit";

import { AgentData } from "../../Monitoring";
import AgentItem from "./AgentItem";

import * as s from "./styles.module.scss";

interface AgentListProps {
  agents?: AgentData[];
  onManageLoad: (id: number) => void;
  onSelect: (id: number) => void;
  hasQuery: boolean;
  currentAgentId?: number;
  editable: boolean;
}

const AgentList: FC<AgentListProps> = ({
  editable,
  agents = [],
  onManageLoad,
  onSelect,
  hasQuery,
  currentAgentId
}) => {
  return (
    <div className={s["agentsWrap"]}>
      {isEmpty(agents) && (
        <div className={s["agentsEmpty"]}>
          {hasQuery ? "нет совпадений" : "нет агентов в группе"}
        </div>
      )}

      {!isEmpty(agents) && (
        <div>
          {agents.map((agent, i) => (
            <AgentItem
              key={`${i}_${agent.id}`}
              agent={agent}
              current={currentAgentId === agent.id}
              onSelect={onSelect}
              onEdit={onManageLoad}
              editable={editable}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AgentList;
