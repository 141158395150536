import { takeEvery, put } from "redux-saga/effects";
import {
  sendKeydownEvent,
  sendMouseMoveEvent
} from "../Store/SystemStatistics.reducer";
import { sendUserEvent, stompConnected } from "../../../Stomp/Stopm.actions";

function* sendStatisticEvent(action) {
  yield put(
    sendUserEvent.trigger({
      user_id: window.core.user.id,
      action: action.type,
      data: {}
    })
  );
}

function* initStatisticEvent() {
  yield put(
    sendUserEvent.trigger({
      user_id: window.core.user.id,
      action: "WEBSOCKET_CONNECTED",
      data: {}
    })
  );
}

export default function* () {
  yield takeEvery([stompConnected], initStatisticEvent);
  yield takeEvery([sendKeydownEvent, sendMouseMoveEvent], sendStatisticEvent);
}
