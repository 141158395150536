// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".csiInfo--gOoCPi6hoW4iLSZnOCaO p{display:inline-block}.csiInfo_label--XiZlqiHRIYhDk6cKq96T{font-weight:bold;color:var(--color-textLabel-default);margin-right:5px}.csiInfo_content--Lh9Z6qMguwe9hKUhEPOa{font-weight:normal}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/Common/Components/CsiInfo/CsiInfo.module.scss"],"names":[],"mappings":"AACE,iCACE,oBAAA,CAEF,qCACE,gBAAA,CACA,oCAAA,CACA,gBAAA,CAGF,uCACE,kBAAA","sourcesContent":[".csiInfo {\n  p {\n    display: inline-block;\n  }\n  &_label {\n    font-weight: bold;\n    color: var(--color-textLabel-default);\n    margin-right: 5px;\n  }\n\n  &_content {\n    font-weight: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var csiInfo = "csiInfo--gOoCPi6hoW4iLSZnOCaO";
export var csiInfoLabel = "csiInfo_label--XiZlqiHRIYhDk6cKq96T";
export var csiInfoContent = "csiInfo_content--Lh9Z6qMguwe9hKUhEPOa";
export default ___CSS_LOADER_EXPORT___;
