import React from "react";
import {
  DEFAULT_LOWER_TIME_LIMIT,
  DEFAULT_UPPER_TIME_LIMIT
} from "@omnichat/arm_ui_kit";

import { initDashboard, dashboardDestroy } from "../Dashboard";

export default {
  charts_loading: {},

  charts: {
    el: {}
  },

  draw: {
    tpls: function(data) {
      var tpls = {};
      tpls.stats = "body";

      return tpls;
    }
  },

  nav: {
    init: function() {
      $(".body").removeClass("load");
      initDashboard();
    },

    destroy: function() {
      dashboardDestroy();
    },

    before: function(r) {},

    after: function(r) {
      initDashboard();
    }
  }
};
