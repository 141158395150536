import { takeEvery, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import {
  setSkillGroupDictionaries,
  getSkillGroupDictionaries
} from "../Store/SkillGroupModal.reducer";

export function* getSkillGroupDictionariesSaga(action) {
  try {
    const response = yield httpRequest(
      "GET",
      `bff/settings/skill-groups/modal/init`
    );
    if (
      response?.botProfiles?.success &&
      response?.regions?.success &&
      response?.mrf?.success &&
      response?.channels?.success
    ) {
      const { botProfiles, regions, mrf, channels } = response;
      yield put(
        setSkillGroupDictionaries({
          botProfiles: botProfiles.data,
          regions: regions.data,
          mrfs: mrf.data,
          channels: channels.data
        })
      );
    }
  } catch ($error) {
    console.error($error);
  }
}

export default function*() {
  yield takeEvery([getSkillGroupDictionaries], getSkillGroupDictionariesSaga);
}
